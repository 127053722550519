import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import ReactTable from "../../../../components/table/react-table";
import { spqColumns, spqData } from "../demo-data";
import FloatingButton from "../../../../components/form-components/floating-button";
import useRedirect from "../../../../components/custom-hooks/use-redirect";
import { useLocation } from "react-router-dom";

const AllSpqList = () => {
  const [tableData, setTableData] = useState([]);
  const { redirectTo } = useRedirect();
  const { pathname } = useLocation();
  console.log(pathname);
  useEffect(() => {
    const filterData = spqData.filter((_d) => {
      if (pathname.includes("survey-listing")) {
        return _d.category == "survey";
      } else if (pathname.includes("poll-listing")) {
        return _d.category == "poll";
      } else if (pathname.includes("quiz-listing")) {
        return _d.category == "quiz";
      }
    });
    setTableData(filterData);
  }, [pathname]);
  return (
    <>
      <FloatingButton onClick={() => redirectTo("/spq/create")} />
      <div className="d-flex justify-content-between align-items-center flex-wrap mb-4 gap-3">
        <div className="d-flex flex-column">
          <h3 className="m-0">Spq Listing</h3>
        </div>
        <div className="d-flex justify-content-between align-items-center rounded flex-wrap gap-3"></div>
      </div>
      <Card>
        <Card.Body>
          <ReactTable
            data={tableData}
            columns={spqColumns}
            recordsTotal={spqData.length}
            // onTableStateChange={onTableStateChange}
            // initialState={post_json.current}
            // recordsPerPage={10}
          />
        </Card.Body>
      </Card>
    </>
  );
};

export default AllSpqList;

import React from "react";
import Card from "../../components/bootstrap/card";
import { Skeleton } from "@mui/material";

const KanbanCardLoader = () => {
  return (
    <>
      <Card>
        <Card.Body>
          <Skeleton variant="text" width={200} height={20} />
          <Skeleton variant="text" width="100%" height={15} />
          <Skeleton variant="text" width="100%" height={15} />
          <Skeleton variant="text" width="50%" height={15} />
          {/* expected due date and priority */}
          <div className="d-flex align-items-center justify-content-between">
            <Skeleton variant="text" width={80} height={20} />

            <Skeleton variant="text" width={40} height={20} />
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default KanbanCardLoader;

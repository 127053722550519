import { Skeleton } from "@mui/material";
import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import Masonry from "react-masonry-css";

const breakpointColumnsObj = {
  default: 3,
  1100: 3,
  768: 2,
  500: 1,
};

const RequisitionReviewApprovalLoader = () => {
  return (
    <>
      <Row className="mb-3">
        <div className="col-12 col-md-8">
          <Card className="p-3 mb-3">
            <Skeleton variant="text" width="70%" height={30} className="mb-2" />
            <Skeleton variant="text" width="100%" height={15} />
            <Skeleton variant="text" width="100%" height={15} />
            <Skeleton variant="text" width="50%" height={15} />
            <div className="d-flex flex-row flex-wrap gap-2 mt-4">
              <Skeleton
                variant="rounded"
                width={130}
                height={30}
                className="mb-2"
              />
              <Skeleton
                variant="rounded"
                width={130}
                height={30}
                className="mb-2"
              />
              <Skeleton
                variant="rounded"
                width={150}
                height={30}
                className="mb-2"
              />
              <Skeleton
                variant="rounded"
                width={150}
                height={30}
                className="mb-2"
              />
              <Skeleton
                variant="rounded"
                width={130}
                height={30}
                className="mb-2"
              />
              <Skeleton
                variant="rounded"
                width={130}
                height={30}
                className="mb-2"
              />
            </div>
          </Card>
          <Card className="p-3 mb-0">
            <Skeleton variant="text" width="50%" height={20} />
            <Card.Body>
              <Masonry
                breakpointCols={breakpointColumnsObj}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column"
              >
                {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((_i) => (
                  <div className="d-flex justify-content-start align-items-center gap-2 mb-4">
                    <Skeleton variant="rounded" width={40} height={40} />
                    <Skeleton variant="text" width="40%" height={25} />
                  </div>
                ))}
              </Masonry>
            </Card.Body>
          </Card>
        </div>
        <div className="col-12 col-md-4 bg-white p-3 rounded-3">
          <Skeleton variant="text" width="50%" height={20} />
          <Col lg="12 border border-1 rounded-2 mt-3 p-3">
            <Col lg="12" className="mb-2 mt-0">
              <Skeleton variant="rounded" width="100%" height={100} />
            </Col>
            <Col lg="12" className="mb-2">
              <Skeleton variant="text" width="40%" height={20} />
              <Skeleton variant="rounded" width="100%" height={40} />
            </Col>
            <Col lg="12" className="d-flex gap-3 mt-3">
              <Skeleton variant="rounded" width="100%" height={40} />
              <Skeleton variant="rounded" width="100%" height={40} />
            </Col>
          </Col>
        </div>
      </Row>
    </>
  );
};

export default RequisitionReviewApprovalLoader;

import React, { useEffect, useRef, useState, useCallback } from "react";
import { Col, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Masonry from "react-masonry-css";

import toast from "react-hot-toast";
import {
  CONTENT_DETAILS_GET,
  CONTENT_LIST_POST,
} from "../../../../api/endpoints/content-endpoints";
import {
  ADD_CONTENT_TO_VAULT,
  GET_CONTENT_VAULT,
  REMOVE_FROM_VAULT,
} from "../../../../api/endpoints/requisition-endpoints";
import { getService } from "../../../../api/services/get-services";
import postService from "../../../../api/services/post-service";
import searchService from "../../../../api/services/search-service";
import ContentCard from "../../content-library/components/content-card";
import RequisitionLeftSection from "./requisition-left-section";
import ContentCardLoader from "../../../../skeleton/common/content-card-loader";
import { findIconByFileCode } from "../../master-management/file-type-management/constants/file-type-icons";
const ContentRepurposeStep = ({
  values,
  brands,
  user,
  setStep,
  selectedFileTypes,
  vault,
}) => {
  const initialFileState = {
    title: "",
    file_url: "",
    description: "",
    createdBy: "",
    brand: "",
    icon: "",
    type: "",
  };
  const [vaultContent, setVaultContent] = useState(vault ?? []);
  const [content, setContent] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [showFilePreview, setShowFilePreview] = React.useState("");
  const [fileDetails, setFileDetails] = useState(initialFileState);
  console.log("REQUISITION_INPUT_VALUES", values);
  const breakpointColumnsObj = {
    default: 3,
    1100: 3,
    768: 2,
    500: 1,
  };
  const breakpointColumnsContentCard = {
    default: 2,
    1100: 2,
    768: 2,
    500: 1,
  };
  let post_json = useRef({
    default: "all",
    pagination: {
      limit: 100,
      offset: 0,
    },
  });
  const getVault = async () => {
    let response = await postService(GET_CONTENT_VAULT, post_json.current);
    //console.log("vault response", response);
    let rows = response.data.data?.rows;
    let vault = [];
    if (rows?.length == 0) {
      return;
    }
    rows?.forEach((row) => {
      vault.push({
        id: row.content_id?.content_id,
        icon: row.content_id?.content_file_type_id?.file_type_icon,
        type: row.content_id?.content_file_type_id?.file_type_name,
        title: row?.content_id?.content_title,
      });
    });
    //console.log("vault", vault);
    setVaultContent(vault);
  };
  const fetchContents = async () => {
    const response = await postService(CONTENT_LIST_POST, post_json.current);
    if (response?.data?.data?.rows?.length > 0) {
      //console.log("contents_response", response?.data?.data?.rows);
      //console.log("total_count", response?.data?.data?.totalCount);
      //console.log("COLUMN", Object.keys(response?.data?.data?.rows[0]));
      setContent(response?.data?.data?.rows);
      setTotalCount(response?.data?.data?.totalCount);
    }
  };
  const fetchSearch = async () => {
    let response = await searchService("cipla_requisition", {
      requisition: values?.requisition_title,
      description: values?.requisition_description,
    });
    console.log("search_response", response);
    if (response.status == 200) {
      let output = response?.data?.output || null;
      let toProceedLoop =
        output &&
        typeof output == "object" &&
        Array.isArray(output) &&
        output?.length > 0;
      if (toProceedLoop) {
        setContent(output);
      }
    }
  };
  useEffect(() => {
    getVault();
    // fetchContents();
    fetchSearch();
  }, []);
  //console.log("content", content);
  const onSelect = async (id) => {
    //console.log("id", id);
    let selected = content.find((item) => item.content_id == id);
    //console.log("selected", selected);
    let newVault = [...vaultContent];
    if (newVault.some((_) => _.id == selected.content_id)) {
      newVault = newVault.filter((_) => _.id != selected.content_id);
      setVaultContent(newVault);
      //remove content from the vault
      let response = await getService(REMOVE_FROM_VAULT + selected.content_id);
      console.log("response", response);
      toast.success("Content removed from vault");
    } else {
      newVault.push({
        id: selected.content_id,
        title: selected.content_title,
      });
      setVaultContent(newVault);
      //add content to the vault
      let response = await postService(ADD_CONTENT_TO_VAULT, {
        user_id: user?.id,
        content_id: id,
      });
      console.log("response", response);
      toast.success("Content added to vault");
    }
  };
  const onClickPreviewContent = useCallback(
    async (id, isContentCard = true) => {
      let response = await getService(CONTENT_DETAILS_GET + id);
      if (response.isError) {
        console.log(response);
      } else {
        console.log("CONTENT_RESPONSE", response);
        let fileResponse = response.data.data.detail;
        let {
          content_to_file = {},
          content_to_av = {},
          content_to_text = {},
          content_brand_id = {},
          content_file_type_id = {},
          created_by = {},
          content_to_image = {},
        } = fileResponse;
        let content = content_to_file || content_to_av || content_to_text;
        let {
          title_html,
          description_html,
          storage_link = "",
          path = "",
        } = content;
        let file_url = storage_link || path || "";

        if (fileResponse) {
          setFileDetails((prev) => {
            return {
              ...prev,
              title: title_html,
              description: description_html,
              file_url: file_url,
              citation: content_to_text?.citation_html ?? "",
              createdBy: created_by?.name,
              coverImage: content_to_image?.content_image_id?.thumbnail,
              brand: content_brand_id?.principal_entity_name,
              icon: content_file_type_id?.file_type_icon,
              type: content_file_type_id?.file_type_code,
            };
          });
          setShowFilePreview(id);
        } else {
          toast.error("File not found");
        }
      }
    },
    [showFilePreview, fileDetails]
  );
  const onViewModalClose = useCallback(() => {
    setShowFilePreview("");
    setFileDetails(initialFileState);
  }, [showFilePreview, fileDetails]);
  return (
    <>
      <Row className="">
        <Col lg={7}>
          <div className="p-3 bg-soft-primary rounded-3 overflow-auto scroll-content">
            <RequisitionLeftSection
              brands={brands}
              values={values}
              selectedFileTypes={selectedFileTypes}
              user={user}
              vaultContent={vaultContent}
            />
          </div>
        </Col>
        <Col lg={5}>
          <div className="p-3 bg-soft-primary rounded-3 overflow-auto scroll-content">
            <div className="d-flex flex-column">
              <h5 className="mb-3">Please check similar content below</h5>
            </div>
            <Masonry
              breakpointCols={breakpointColumnsContentCard}
              className="my-masonry-grid"
              columnClassName="my-masonry-grid_column"
            >
              {content?.length > 0 ? (
                content?.map((item, index) => (
                  <ContentCard
                    key={`content-${index}`}
                    id={item.content_id}
                    src={findIconByFileCode(item?.content_file_type)}
                    date={item.added_on}
                    title={item.content_file_name}
                    citation={fileDetails?.citation}
                    titleHtml={fileDetails?.title}
                    coverImage={fileDetails?.coverImage}
                    descriptionHtml={fileDetails?.description}
                    description={fileDetails?.description}
                    // brand={item.content_brand_id?.principal_entity_name}
                    icon={""}
                    onClickPreview={onClickPreviewContent}
                    file={fileDetails?.file_url}
                    filePreviewModal={showFilePreview == item.content_id}
                    type={item?.content_file_type}
                    onViewModalClose={onViewModalClose}
                    createdBy=""
                    onSelect={onSelect}
                    checked={vaultContent.some(
                      (vault) => vault.id == item.content_id
                    )}
                  />
                ))
              ) : (
                <>
                  {[1, 2, 3, 4].map((_) => (
                    <ContentCardLoader />
                  ))}
                </>
              )}
            </Masonry>
          </div>
        </Col>
      </Row>

      <div className="d-flex justify-content-end align-items-center gap-2 my-5">
        <Button
          className="flex-grow-1 flex-sm-grow-0 "
          type="submit"
          // onClick={() => redirectTo("requisition/listing")}
          onClick={() => setStep(3)}
        >
          {vaultContent?.length > 0
            ? "Proceed to Repurpose"
            : "Proceed to Create New"}
        </Button>
      </div>
    </>
  );
};

export default React.memo(ContentRepurposeStep);

import { memo, Fragment, useEffect, useState } from "react";

//react-bootstrap
import { Row, Col, Image, Form, Button, FormCheck } from "react-bootstrap";

//components
import Card from "../../../../components/bootstrap/card";

//router
import { Link, useParams } from "react-router-dom";
// react-select
import Select from "react-select";
import useRedirect from "../../../../components/custom-hooks/use-redirect";
import { getService } from "../../../../api/services/get-services";
import {
  ADD_USER_POST,
  CREATE_USER_GET,
  UPDATE_USER_POST,
  USER_EDIT_GET,
} from "../../../../api/endpoints/user-management-endpoints";

// import yup and formik
import * as Yup from "yup";
import { useFormik } from "formik";
import postService from "../../../../api/services/post-service";
import PaginationLoader from "../../../../components/loader/pagination-loader";
import toast from "react-hot-toast";
import withAuthorization from "../../../../components/hoc/with-authorization";
import AddUserLoader from "../../../../skeleton/user-management-loader/add-user-loader";
const AddUser = memo(() => {
  const { redirectTo } = useRedirect();
  const params = useParams();
  //usestate for the departments, principal entity, reporting manager, role
  const [department, setDepartment] = useState([]);
  const [loading, setLoading] = useState(false); //loading state
  const [pageLoader, setPageLoader] = useState(true); //loading state
  const [masterEntity, setMasterEntity] = useState([]);
  const [reportingManager, setReportingManager] = useState([]);
  const [role, setRole] = useState([]);
  //usestate for useSelectedRole and userSelectedMasterEntity
  const [userSelectedRole, setUserSelectedRole] = useState(null);
  const [userSelectedMasterEntity, setUserSelectedMasterEntity] =
    useState(null);

  //arrange principal entity in the form of array of objects with value and label with parent marked as first then their child
  const arrangePrincipalEntity = (data) => {
    let principalEntity = [];
    data.forEach((_) => {
      if (_.principal_entity_parent_id == 0) {
        console.log("parent", _);
        principalEntity.push({
          principal_entity_id: _.principal_entity_id,
          principal_entity_name: _.principal_entity_name,
          principal_entity_parent_id: null,
        });
      } else {
        console.log("child", _);
        //find its parent
        let parent = data.find(
          (__) => __.principal_entity_id == _.principal_entity_parent_id
        );
        // find parent of parent
        let parentOfParent = data.find(
          (__) => __?.principal_entity_id == parent?.principal_entity_parent_id
        );
        console.log("parent_of_parent", parentOfParent);
        console.log("parent_of_child", parent);
        principalEntity.push({
          principal_entity_id: _.principal_entity_id,
          principal_entity_name:
            (parent?.principal_entity_name
              ? parent?.principal_entity_name + " -> "
              : "") + _.principal_entity_name,
          principal_entity_parent_id:
            parentOfParent?.principal_entity_id || null,
        });
      }
    });
    return principalEntity;
  };
  const getPrincipleEntitiesForSelect = (data) => {
    //untill all elements have principal_entity_parent_id null perform the arrangePrincipalEntity function
    let principalEntity = arrangePrincipalEntity(data);
    if (principalEntity.every((_) => _.principal_entity_parent_id == null)) {
      return principalEntity.map((_) => ({
        value: _.principal_entity_id,
        label: _.principal_entity_name,
      }));
    } else {
      return getPrincipleEntitiesForSelect(principalEntity);
    }
  };

  const getCreateUserData = async (id = "") => {
    console.log("getCreateUserData");
    const response = !id
      ? await getService(CREATE_USER_GET)
      : await getService(`${USER_EDIT_GET}/${id}`);
    console.log("UserAdd", response);
    let { department, master_entity, reporting_manager, role, user } =
      response.data?.data;
    setDepartment(
      department.map((_) => ({
        value: _.department_id,
        label: _.department_name,
      }))
    );
    console.log("MASTER_ENTITIES", master_entity);
    let arrangedPrincipalEntity = getPrincipleEntitiesForSelect(master_entity);
    console.log("arrangedPrincipalEntity", arrangedPrincipalEntity);
    setMasterEntity(arrangedPrincipalEntity);
    setReportingManager(
      reporting_manager.map((_) => ({ value: _.id, label: _.name }))
    );
    setRole(
      role.map((_) => ({
        value: _.id,
        label: _.name,
        description: _.description,
      }))
    );

    if (user) {
      //setValues of formik
      formik.setValues({
        name: user.name,
        mobile: user.phone,
        email: user.email,
        employeeCode: user.employee_code,
        department: user.departments[0]?.department_id,
        reportingManager: user.reporting_manager_id,
        // role: "",
        // principalEntity: "",
        // status: "",
      });
      setUserSelectedRole(user.user_has_role?.role_id);
      setUserSelectedMasterEntity(
        arrangedPrincipalEntity.filter(
          (_) => _.value == user.principal_entity[0]?.principal_entity_id
        )
      );
    } else {
      formik.resetForm();
    }
    setPageLoader(false);
  };

  useEffect(() => {
    getCreateUserData(params?.id);
    return () => {};
  }, [params?.id]);
  //initial values
  const initialValues = {
    name: "",
    mobile: "",
    email: "",
    employeeCode: "",
    department: "",
    reportingManager: "",
    // role: "",
    // principalEntity: "",
    // status: "",
  };

  const onSubmit = (values) => {
    console.log("values", values);
    redirectTo("/user-management");
  };
  //schema validation of forms yup
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .matches(/^\D*$/, "Name should not contain any integers")
      .required("please enter your name"),
    mobile: Yup.string()
      .matches(
        /^(?:\+?1[-.\s]?)?\(?([0-9]{3})\)?[-.\s]?([0-9]{3})[-.\s]?([0-9]{4})$/,
        "Invalid mobile number"
      )
      .required("Mobile number is required"),
    email: Yup.string()
      .required("Please enter your email")
      .test("Validate Email", "Please enter valid email", (value) => {
        const re =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(value).toLowerCase());
      }),
    employeeCode: Yup.string().required("Please Enter Your Employee Code"),
    department: Yup.string().required("Please Select Your Department"),
    reportingManager: Yup.string().required("Please Select Reporting Manager"),
    // role: Yup.string().required("Required"),
    // principalEntity: Yup.string().required("Required"),
    // status: Yup.string().required("Required"),
  });
  //using useFormik hook

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      if (!userSelectedMasterEntity) {
        toast.error("Please select a principal entity");
        return;
      }
      if (!userSelectedRole) {
        toast.error("Please select a role");
        return;
      }

      let base_url = window.location.href;
      const splitter = "user-management/create";
      const parts = base_url.split(splitter);
      const actualUrl = parts[0] + "auth/create-password";

      console.log("actualUrl", actualUrl);
      let AddData = {
        name: values.name,
        email: values.email,
        phone: values.mobile,
        deperment: values.department,
        principal_entity_id: userSelectedMasterEntity[0].value,
        reporting_manager: values.reportingManager,
        user_role: userSelectedRole,
        employee_code: values.employeeCode,
        status: 1,
        created_by: 1,
        frontend_url: actualUrl,
      };

      let UpdateData = {
        name: values.name,
        email: values.email,
        phone: values.mobile,
        deperment: values.department,
        principal_entity_id: userSelectedMasterEntity[0].value,
        reporting_manager: values.reportingManager,
        user_role: userSelectedRole,
        employee_code: values.employeeCode,
        status: 1,
        created_by: 1,
      };

      setLoading(true);
      let response = await postService(
        params.id ? `${UPDATE_USER_POST}/${params.id}` : ADD_USER_POST,
        params.id ? UpdateData : AddData
      );

      if (response) {
        if (response.isError) {
          console.log("response", response);
          toast.error(response.error);
          setLoading(false);
        } else {
          // console.log("response", response);
          setLoading(false);
          toast.success(response?.data?.message);
          redirectTo("user-management/listing");
        }
      } else {
        setLoading(false);
      }
    },
  });
  // const { handleSubmit, handleChange, values, errors } = formik;
  const { handleSubmit, handleChange, values, errors, touched } = formik;
  console.log("values", values);
  console.log("errors", errors);
  console.log("touched", touched);

  const handleRoleChange = (e, item) => {
    if (!e.target.checked) return;
    setUserSelectedRole(item);
  };

  return (
    <>
      <Fragment>
        <div className="d-flex justify-content-between align-items-center flex-wrap mb-4 gap-3">
          <div className="d-flex flex-column">
            <h3 className="m-0">
              {params.id ? "Edit Employee" : "Add New Employee"}
            </h3>
          </div>
        </div>
        {!pageLoader ? (
          <Row>
            <Col lg="12">
              <Card>
                <Card.Body>
                  <Form>
                    <Row>
                      <Col lg="12" className="mb-3">
                        <h6 className="fs-6 fw-400 gray-300">
                          Employee Details
                        </h6>
                      </Col>
                      <Col lg="6" xl="3" className="mb-4">
                        <Form.Floating className="custom-form-floating">
                          <Form.Control
                            type="name"
                            className=""
                            autoComplete="Name"
                            placeholder="Name"
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                            isInvalid={!!errors.name && touched.name}
                          />
                          <label htmlFor="floatingInput">Name</label>
                          <Form.Control.Feedback type="invalid">
                            {errors.name}
                          </Form.Control.Feedback>
                        </Form.Floating>
                      </Col>
                      <Col lg="6" xl="3" className="mb-4">
                        <Form.Floating className="custom-form-floating">
                          <Form.Control
                            type="name"
                            className=""
                            autoComplete="mobile no."
                            placeholder="Mobile no."
                            name="mobile"
                            value={values.mobile}
                            onChange={handleChange}
                            isInvalid={!!errors.mobile && touched.mobile}
                          />
                          <label htmlFor="floatingInput">Mobile No.</label>
                          <Form.Control.Feedback type="invalid">
                            {errors.mobile}
                          </Form.Control.Feedback>
                        </Form.Floating>
                      </Col>
                      <Col lg="6" xl="3" className="mb-4">
                        <Form.Floating className="custom-form-floating">
                          <Form.Control
                            type="name"
                            className=""
                            autoComplete="email"
                            placeholder="Email"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            isInvalid={!!errors.email && touched.email}
                          />
                          <label htmlFor="floatingInput">Email</label>
                          <Form.Control.Feedback type="invalid">
                            {errors.email}
                          </Form.Control.Feedback>
                        </Form.Floating>
                      </Col>
                      <Col lg="6" xl="3" className="mb-4">
                        <Form.Floating className="custom-form-floating">
                          <Form.Control
                            type="name"
                            className=""
                            autoComplete="Employee Code"
                            placeholder="Employee Code"
                            name="employeeCode"
                            value={values.employeeCode}
                            onChange={handleChange}
                            isInvalid={
                              !!errors.employeeCode && touched.employeeCode
                            }
                          />
                          <label htmlFor="floatingInput">Employee Code</label>
                          <Form.Control.Feedback type="invalid">
                            {errors.employeeCode}
                          </Form.Control.Feedback>
                        </Form.Floating>
                      </Col>
                      <Col lg="12" className="mb-3">
                        <h6 className="fs-6 fw-400 gray-300">
                          Department & Reporting
                        </h6>
                      </Col>
                      <Col lg="6" className="mb-4">
                        <Form.Floating className="custom-form-floating">
                          <Form.Select
                            className=""
                            id="floatingInput1"
                            name="department"
                            value={values.department}
                            onChange={handleChange}
                            isInvalid={
                              !!errors.department && touched.department
                            }
                          >
                            <option value="">Select Department</option>

                            {department.map((item, index) => (
                              <option key={index} value={item.value}>
                                {item.label}
                              </option>
                            ))}
                          </Form.Select>
                          <Form.Label htmlFor="floatingInput">
                            Select Department
                          </Form.Label>

                          <Form.Control.Feedback type="invalid">
                            {errors.department}
                          </Form.Control.Feedback>
                        </Form.Floating>
                      </Col>
                      <Col lg="6" className="mb-4">
                        <Form.Floating className="custom-form-floating">
                          <Form.Select
                            className=""
                            id="floatingInput1"
                            name="reportingManager"
                            value={values.reportingManager}
                            onChange={handleChange}
                            isInvalid={
                              !!errors.reportingManager &&
                              touched.reportingManager
                            }
                          >
                            <option value="">Select Reporting Manager</option>

                            {reportingManager.map((item, index) => (
                              <option key={index} value={item.value}>
                                {item.label}
                              </option>
                            ))}
                          </Form.Select>
                          <Form.Label htmlFor="floatingInput">
                            Select Reporting Manager
                          </Form.Label>
                          <Form.Control.Feedback type="invalid">
                            {errors.reportingManager}
                          </Form.Control.Feedback>
                        </Form.Floating>
                      </Col>
                      <Col lg="12" className="mb-3">
                        <h6 className="fs-6 fw-400 gray-300">
                          Principal Entity
                        </h6>
                      </Col>
                      <Col lg="6" className="mb-4">
                        {masterEntity.length > 0 && (
                          <div className="d-flex w-100">
                            <Form.Floating className="custom-form-floating flex-grow-1">
                              <Select
                                name="principalEntity"
                                options={masterEntity}
                                placeholder="Select Principal Entity"
                                value={userSelectedMasterEntity}
                                onChange={(value) => {
                                  console.log("value", value);
                                  setUserSelectedMasterEntity(value);
                                }}
                                isMulti
                              />
                            </Form.Floating>
                          </div>
                        )}
                      </Col>

                      <Col lg="12" className="mb-3">
                        <h6 className="fs-6 fw-400 gray-300">Add User Role</h6>
                      </Col>
                      {role.map((item, index) => (
                        <Col
                          lg="12"
                          className="mb-4 d-flex flex-wrap"
                          key={index + 1}
                        >
                          <Form.Check className="form-check-inline d-flex align-items-center">
                            <FormCheck.Input
                              type="radio"
                              className="form-check-input mt-0"
                              id="customCheck5"
                              name="role"
                              onChange={(e) => handleRoleChange(e, item.value)}
                              checked={userSelectedRole === item.value}
                            />
                            <FormCheck.Label
                              className="form-check-label ps-2"
                              htmlFor="customCheck5"
                            >
                              {item.label}
                            </FormCheck.Label>
                          </Form.Check>
                          {item.description && (
                            <span className="ms-3 mt-1 mt-md-0 fs-7 p-2 bg-soft-secondary rounded-3">
                              {item.description}
                            </span>
                          )}
                        </Col>
                      ))}

                      <Col lg="12" className="d-flex justify-content-end">
                        <Button
                          className="btn btn-primary flex-grow-1 flex-sm-grow-0"
                          type="submit"
                          onClick={handleSubmit}
                          disabled={loading}
                        >
                          {loading ? <PaginationLoader /> : "Submit"}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        ) : (
          <AddUserLoader />
        )}
      </Fragment>
    </>
  );
});

AddUser.displayName = "AddUser";
export default withAuthorization(AddUser);

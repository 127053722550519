import React from "react";
import ContentDetailsCardLoader from "../common/content-details-card-loader";
import { Card, Col, Row } from "react-bootstrap";
import { Skeleton } from "@mui/material";

const AddHtmlLoader = () => {
  return (
    <>
      <ContentDetailsCardLoader />
      <Col lg="12" className="d-flex">
        <Card className="w-100">
          <Card.Body>
            <Col lg="12" className="mb-4">
              <Skeleton
                variant="rounded"
                width="100%"
                height={45}
                className="mb-3"
              />
              <Skeleton
                variant="text"
                width={100}
                height={20}
                className="mb-2"
              />
              <Skeleton
                variant="rounded"
                width="100%"
                height={90}
                className="mb-3"
              />
              <Skeleton
                variant="text"
                width={100}
                height={20}
                className="mb-2"
              />
              <Skeleton
                variant="rounded"
                width="100%"
                height={90}
                className="mb-3"
              />

              <Row className="gap-4 gap-lg-0 mt-4">
                <Col lg="6">
                  <Skeleton variant="rounded" width='100%' height={150} />
                </Col>
                <Col lg="6">
                  <Skeleton variant="rounded" width='100%' height={150} />
                </Col>
              </Row>
            </Col>

            <Skeleton variant="rectangular" width='100%' height={300} className="mb-2" />
            <Skeleton variant="rounded" width='10%' height={40} />
          </Card.Body>
        </Card>
      </Col>
    </>
  );
};

export default AddHtmlLoader;

import React from "react";

// layout

import Default from "../../../../layouts/dashboard/default";
import Vault from "../pages/vault";


// pages

export const VaultRouter = [
  {
    path: "/vault",
    element: <Default />,
    children: [
      {
        path: "/vault",
        element: <Vault />,
      },
    ],
  },
];

import { useState, useCallback, Fragment, useMemo, memo } from "react";
import CourseDetailFields from "../components/course-detail-fields";
import { Button, Card, Col, Form, Row, Image } from "react-bootstrap";
import AddModule from "../components/add-module";
import AddContent from "../components/add-content";
import FormSteps from "../../../../components/form-components/form-steps";

import Medwiki from "../../../../assets/images/icons/medwiki.svg";
import liveCME from "../../../../assets/images/icons/liveCME.svg";
import clinicalVideo from "../../../../assets/images/icons/clinicalVideo.svg";
import polls from "../../../../assets/images/icons/polls.svg";
import training from "../../../../assets/images/icons/training.svg";

const AddCourses = () => {
  const [step, setStep] = useState(3);
  const [courseData, setCourseData] = useState({});
  const [showDetailedFields, setShowDetailedFields] = useState(false);
  const nextStep = useCallback(() => {
    setStep((prev) => prev + 1);
  }, [step]);
  const prevStep = useCallback(() => {
    setStep((prev) => prev - 1);
  }, [step]);
  const handleCourseTitleChange = useCallback(
    (e) => setCourseData({ ...courseData, [e.target.name]: e.target.value }),
    [courseData]
  );

  const onStepClick = useCallback((step) => setStep(step), [step]);

  return (
    <Fragment>
      <Row>
        {!showDetailedFields && (
          <Col
            lg="8"
            className="d-flex flex-row gap-3 shadow-sm p-3 bg-white rounded-3 mx-auto mb-5"
          >
            <Form.Floating className="custom-form-floating flex-grow-1">
              <Form.Control
                type="name"
                placeholder="Course Title"
                name="course_title"
                onChange={handleCourseTitleChange}
              />
              <Form.Label>Enter Course Title</Form.Label>
            </Form.Floating>
            <Button className="" variant="danger">
              Cancel
            </Button>
            <Button
              className=""
              variant="primary"
              onClick={() => setShowDetailedFields(true)}
            >
              Create Course
            </Button>
          </Col>
        )}

        <Col lg="12">{showDetailedFields && <CourseDetailFields />}</Col>

        {!showDetailedFields && (
          <Col lg="12">
            <FormSteps
              onStepClick={onStepClick}
              steps={[
                {
                  name: "Create Course",
                  active: step == 1,
                },
                {
                  name: "Add Modules",
                  active: step == 2,
                },
                {
                  name: "Add Content",
                  active: step == 3,
                },
              ]}
            />
          </Col>
        )}
        <Col lg="12" className="d-flex gap-3 mb-5">
          {step == 1 && (
            <>
              <Col lg="12">
                <Card>
                  <Card.Body>
                    <div className="d-flex align-items-center gap-3">
                      <span>
                        <Image
                          src={Medwiki}
                          className="icon-40"
                          alt="profile-image"
                        />
                      </span>
                      <span>
                        <span className="d-block">Add New MedWiki</span>
                        <span className="fs-7">
                          {"Go to Mediki listing > Add new medwiki > Submit"}
                        </span>
                      </span>
                      <Button
                        className="btn-sm rounded-2 ms-auto"
                        variant="outline-primary"
                      >
                        Add Medwiki
                      </Button>
                    </div>
                  </Card.Body>
                </Card>
                <Card>
                  <Card.Body>
                    <div className="d-flex align-items-center gap-3">
                      <span>
                        <Image
                          src={liveCME}
                          className="icon-40"
                          alt="profile-image"
                        />
                      </span>
                      <span>
                        <span className="d-block">Add New Session</span>
                        <span className="fs-7">
                          {"Go to Live Session > Add new session > submit"}
                        </span>
                      </span>
                      <Button
                        className="btn-sm rounded-2 ms-auto"
                        variant="outline-primary"
                      >
                        Add Session
                      </Button>
                    </div>
                  </Card.Body>
                </Card>
                <Card>
                  <Card.Body>
                    <div className="d-flex align-items-center gap-3">
                      <span>
                        <Image
                          src={clinicalVideo}
                          className="icon-40"
                          alt="profile-image"
                        />
                      </span>
                      <span>
                        <span className="d-block">Add New Clinical Video</span>
                        <span className="fs-7">
                          {
                            "Go to Clinical Video listing > Add new Clinical Video > Submit"
                          }
                        </span>
                      </span>
                      <Button
                        className="btn-sm rounded-2 ms-auto"
                        variant="outline-primary"
                      >
                        Add Clinical Video
                      </Button>
                    </div>
                  </Card.Body>
                </Card>
                <Card>
                  <Card.Body>
                    <div className="d-flex align-items-center gap-3">
                      <span>
                        <Image
                          src={polls}
                          className="icon-40"
                          alt="profile-image"
                        />
                      </span>
                      <span>
                        <span className="d-block">Add New SPQ</span>
                        <span className="fs-7">
                          {
                            "Go to Clinical Video listing > Add new Clinical Video > Submit"
                          }
                        </span>
                      </span>
                      <Button
                        className="btn-sm rounded-2 ms-auto"
                        variant="outline-primary"
                      >
                        Add SPQ
                      </Button>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </>
          )}
          {step == 2 && (
            <>
              {/* <Col
                lg="12"
                className="bg-white shadow-sm rounded-3 p-3 border border-1 border-dashed border-primary"
              >
                <p>
                  {
                    "Go to course listing > Add New Course > Add Course Title and click on 'Create Course' button"
                  }
                </p>
                <p>{"Go to Spq listing > Add new Spq>Submit"}</p>
              </Col> */}
              <Col lg="12">
                <Card>
                  <Card.Body>
                    <div className="d-flex align-items-center gap-3">
                      <span>
                        <Image
                          src={training}
                          className="icon-44"
                          alt="profile-image"
                        />
                      </span>
                      <span>
                        <span className="d-block">Create New Course</span>
                        <span className="fs-7">
                          {
                            "Go to course listing > Add New Course > Add Course Title and click on 'Create Course' button"
                          }
                        </span>
                      </span>
                      <Button
                        className="btn-sm rounded-2 ms-auto"
                        variant="outline-primary"
                      >
                        Create Course
                      </Button>
                    </div>
                  </Card.Body>
                </Card>
                <Card>
                  <Card.Body>
                    <div className="d-flex align-items-center gap-3">
                      <span>
                        <Image
                          src={training}
                          className="icon-44"
                          alt="profile-image"
                        />
                      </span>
                      <span>
                        <span className="d-block">Add Modules on Course</span>
                        <span className="fs-7">
                          {
                            "After add new Course > Go to Add Module > Create New Module > Submit"
                          }
                        </span>
                      </span>
                      <Button
                        className="btn-sm rounded-2 ms-auto"
                        variant="outline-primary"
                      >
                        Add Module
                      </Button>
                    </div>
                  </Card.Body>
                </Card>
                <Card>
                  <Card.Body>
                    <div className="d-flex align-items-center gap-3">
                      <span>
                        <Image
                          src={training}
                          className="icon-44"
                          alt="profile-image"
                        />
                      </span>
                      <span>
                        <span className="d-block">
                          Add Content on Course Modules
                        </span>
                        <span className="fs-7">
                          {
                            "After add new Module > Add content to module > Submit"
                          }
                        </span>
                      </span>
                      <Button
                        className="btn-sm rounded-2 ms-auto"
                        variant="outline-primary"
                      >
                        Add New Content
                      </Button>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </>
          )}
          {step == 3 && (
            <>
              <Col lg="12" className="bg-white shadow-sm rounded-3 p-3">
                <p>
                  {
                    "Clicking on 'Add Module here'will open a new page where new modules can be created. Add Modules by clicking on"
                  }
                </p>
                <p>{"To add content,"}</p>
                <p>{"View the course summary and 'Submit for Approval'"}</p>
              </Col>
            </>
          )}
        </Col>
      </Row>
    </Fragment>
  );

  // <>
  //     <div className="d-flex justify-content-between align-items-center flex-wrap mb-4 gap-3">
  //       <div className="d-flex flex-column">
  //         <h3 className="m-0">{headerNameHandler}</h3>
  //       </div>
  //       <div className="d-flex justify-content-between align-items-center rounded flex-wrap gap-3"></div>
  //     </div>
  //     {(step == 1 || step == 2) && (
  //       <CourseDetailFields
  //         handleCourseTitleChange={handleCourseTitleChange}
  //         nextStep={nextStep}
  //         step={step}
  //       />
  //     )}
  //     {step == 3 && <AddModule nextStep={nextStep} courseData={courseData} />}
  //     {step == 4 && <AddContent prevStep={prevStep} courseData={courseData} />}
  //   </>
};

export default memo(AddCourses);

import React from "react";

import Horizontal from "../layouts/dashboard/horizontal";
import Boxed from "../layouts/dashboard/boxed";
import DualHorizontal from "../layouts/dashboard/dual-horizontal";
import DualCompact from "../layouts/dashboard/dual-compact";
import BoxedFancy from "../layouts/dashboard/boxed-fancy";
import { DefaultRouter } from "./default-router";
import { SimpleRouter } from "./simple-router";
import { SimpleAuthRouter } from "../views/modules/auth/router/simple-router";
import { UserRouter } from "../views/modules/user-activity/router/user-router";
import { CreativeApprovalRouter } from "../views/modules/creative-approval/router/creative-approval-router";
import { RequisitionRouter } from "../views/modules/requisition/router/requisition-router";
import { ActionRouter } from "../views/modules/actions/router/action-router";
import { UserManagementRouter } from "../views/modules/user-management/router/user-management-router";
import { VendorManagementRouter } from "../views/modules/vendor-management/router/vendor-management-router";
import { ContentLibraryRouter } from "../views/modules/content-library/router/content-library-router";
import { SearchRouter } from "../views/modules/search/router/search-router";
import { TagsRouter } from "../views/modules/content-tags/router/tags-router";
import { VaultRouter } from "../views/modules/vault/router/vault-router";
import { HelpAndSupportRouter } from "../views/modules/help-and-support/router/help-and-support-router";
import { ToolsRouter } from "../views/modules/tools/router/tools-router";
import { RoleManagementRouter } from "../views/modules/role-management/router/role-management-router";
import { ActionTypeRouter } from "../views/modules/master-management/action-type-management/router/actiontype-management-router";
import { PriorityRouter } from "../views/modules/master-management/priority-management/router/priority-router";
import { MenuManagement } from "../views/modules/master-management/menu-management/router/menu-management-router";
import { SpecialityManagementRouter } from "../views/modules/master-management/speciality-management/router/speciality-management-router";
import { CouncilRouter } from "../views/modules/master-management/council-management/router/council-router";
import { CountryRouter } from "../views/modules/master-management/country-management/router/country-router";
import { CityRouter } from "../views/modules/master-management/city-management/router/city-router";
import { StateRouter } from "../views/modules/master-management/state-management/router/state-router";
import { ZoneRouter } from "../views/modules/master-management/zone-management/router/zone-router";
import { DeparmentManagementRouter } from "../views/modules/master-management/deparment-management/router/deparment-router";
import { TargetManagementRouter } from "../views/modules/master-management/target-management/router/target-source-router";
import { FileTypeManagementRouter } from "../views/modules/master-management/file-type-management/router/file-type-router";
import { ContentCategoryRouter } from "../views/modules/master-management/content-category-management/router/content-category-router";
import { ClientRouter } from "../views/modules/master-management/client-management.js/router/client-router";
import { PrincipleEntityManagementRouter } from "../views/modules/master-management/principle-entity-management.js/router/principle-entity-router";
import { AssociationRouter } from "../views/modules/master-management/association-manage/router/association-router";
import { CreatorRecommendationRouter } from "../views/modules/creator-recommendation/router/creator-recommendation-router";
import { AddContentRouter } from "../views/modules/add-content/router/add-content-router";
import { GrRouter } from "../views/modules/gr/router/gr-router";
import { CoursesRouter } from "../views/modules/courses/router/courses-router";
import { SpqRouter } from "../views/modules/spq/router/spq-router";
import { ChannelRouter } from "../views/modules/channel/router/courses-router";
import { CampaignRouter } from "../views/modules/campaign/router/campaign-router";
import { ClientCampaignRouter } from "../views/modules/client -campaign/router/client-campaign-router";
export const IndexRouters = [
  {
    path: "horizontal",
    element: <Horizontal />,
  },
  {
    path: "dual-horizontal",
    element: <DualHorizontal />,
  },
  {
    path: "dual-compact",
    element: <DualCompact />,
  },
  {
    path: "boxed",
    element: <Boxed />,
  },
  {
    path: "boxedFancy",
    element: <BoxedFancy />,
  },
  ...DefaultRouter,
  ...ContentLibraryRouter,
  ...CreativeApprovalRouter,
  ...SimpleRouter,
  ...SimpleAuthRouter,
  ...UserRouter,
  ...RequisitionRouter,
  ...ActionRouter,
  ...UserManagementRouter,
  ...VendorManagementRouter,
  ...SearchRouter,
  ...TagsRouter,
  ...VaultRouter,
  ...HelpAndSupportRouter,
  ...ToolsRouter,
  ...RoleManagementRouter,
  ...ActionTypeRouter,
  ...PriorityRouter,
  ...MenuManagement,
  ...SpecialityManagementRouter,
  ...CouncilRouter,
  ...CountryRouter,
  ...CityRouter,
  ...StateRouter,
  ...ZoneRouter,
  ...AssociationRouter,
  ...DeparmentManagementRouter,
  ...TargetManagementRouter,
  ...FileTypeManagementRouter,
  ...ContentCategoryRouter,
  ...ClientRouter,
  ...PrincipleEntityManagementRouter,
  ...CreatorRecommendationRouter,
  ...AddContentRouter,
  ...GrRouter,
  ...CoursesRouter,
  ...SpqRouter,
  ...ChannelRouter,
  ...CampaignRouter,
  ...ClientCampaignRouter,
];

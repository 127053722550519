import React, { memo } from 'react';
import withAuthorization from '../../../../components/hoc/with-authorization';

const EmailGenerator = () => {
  return (
    <div>
      EmailGenerator
    </div>
  );
}

export default withAuthorization(memo(EmailGenerator));

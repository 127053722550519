const pdfPages1 = [
  {
    page_no: "1",
    url: "https://storage.googleapis.com/medwiki/epubimages/15/1.jpg",
  },
  {
    page_no: "2",
    url: "https://storage.googleapis.com/medwiki/epubimages/15/2.jpg",
  },
  {
    page_no: "3",
    url: "https://storage.googleapis.com/medwiki/epubimages/15/3.jpg",
  },
  {
    page_no: "4",
    url: "https://storage.googleapis.com/medwiki/epubimages/15/4.jpg",
  },
  {
    page_no: "5",
    url: "https://storage.googleapis.com/medwiki/epubimages/15/5.jpg",
  },
  {
    page_no: "6",
    url: "https://storage.googleapis.com/medwiki/epubimages/15/6.jpg",
  },
  {
    page_no: "7",
    url: "https://storage.googleapis.com/medwiki/epubimages/15/7.jpg",
  },
  {
    page_no: "8",
    url: "https://storage.googleapis.com/medwiki/epubimages/15/8.jpg",
  },
  {
    page_no: "9",
    url: "https://storage.googleapis.com/medwiki/epubimages/15/9.jpg",
  },
  {
    page_no: "10",
    url: "https://storage.googleapis.com/medwiki/epubimages/15/10.jpg",
  },
];
const pdfPages2 = [
  {
    page_no: "1",
    url: "https://storage.googleapis.com/medwiki/epubimages/71/1.jpg",
  },
  {
    page_no: "2",
    url: "https://storage.googleapis.com/medwiki/epubimages/71/2.jpg",
  },
  {
    page_no: "3",
    url: "https://storage.googleapis.com/medwiki/epubimages/71/3.jpg",
  },
  {
    page_no: "4",
    url: "https://storage.googleapis.com/medwiki/epubimages/71/4.jpg",
  },
  {
    page_no: "5",
    url: "https://storage.googleapis.com/medwiki/epubimages/71/5.jpg",
  },
  {
    page_no: "6",
    url: "https://storage.googleapis.com/medwiki/epubimages/71/6.jpg",
  },
  {
    page_no: "7",
    url: "https://storage.googleapis.com/medwiki/epubimages/71/7.jpg",
  },
  {
    page_no: "8",
    url: "https://storage.googleapis.com/medwiki/epubimages/71/8.jpg",
  },
  {
    page_no: "9",
    url: "https://storage.googleapis.com/medwiki/epubimages/71/9.jpg",
  },
  {
    page_no: "10",
    url: "https://storage.googleapis.com/medwiki/epubimages/71/10.jpg",
  },
];
const pdfPages3 = [
  {
    page_no: "1",
    url: "https://storage.googleapis.com/medwiki/epubimages/54/1.jpg",
  },
  {
    page_no: "2",
    url: "https://storage.googleapis.com/medwiki/epubimages/54/2.jpg",
  },
  {
    page_no: "3",
    url: "https://storage.googleapis.com/medwiki/epubimages/54/3.jpg",
  },
  {
    page_no: "4",
    url: "https://storage.googleapis.com/medwiki/epubimages/54/4.jpg",
  },
  {
    page_no: "5",
    url: "https://storage.googleapis.com/medwiki/epubimages/54/5.jpg",
  },
  {
    page_no: "6",
    url: "https://storage.googleapis.com/medwiki/epubimages/54/6.jpg",
  },
  {
    page_no: "7",
    url: "https://storage.googleapis.com/medwiki/epubimages/54/7.jpg",
  },
  {
    page_no: "8",
    url: "https://storage.googleapis.com/medwiki/epubimages/54/8.jpg",
  },
  {
    page_no: "9",
    url: "https://storage.googleapis.com/medwiki/epubimages/54/9.jpg",
  },
  {
    page_no: "10",
    url: "https://storage.googleapis.com/medwiki/epubimages/54/10.jpg",
  },
];
//return a random pdf between pdfPages1, pdfPages2, pdfPages3
function randomBetweenThreeVariables(variable1, variable2, variable3) {
  // Generate a random number between 0 and 3 (exclusive)
  const randomValue = Math.random() * 3;

  // Use Math.floor to get an integer value between 0 and 2
  const randomIndex = Math.floor(randomValue);

  // Depending on the random index, return one of the three variables
  switch (randomIndex) {
    case 0:
      return variable1;
    case 1:
      return variable2;
    case 2:
      return variable3;
  }
}
const pdfPages = randomBetweenThreeVariables(pdfPages1, pdfPages2, pdfPages3);

export default pdfPages2;

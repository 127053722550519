export const tasksList = [
  {
    _id: 1,
    title: "Create a folder structure for the project",
    description:
      "This will help to keep all of the files organized and easy to find.",
  },
  {
    _id: 2,
    title: "Create a file for the UI mockup",
    description:
      "This file should contain the wireframe or sketch of the UI design.",
  },
  {
    _id: 3,
    title: "Create a file for the content brief.",
    description:
      "This file should include the following information :The purpose of the content, The target audience, The key messages, The tone and style, The length of the content",
  },
  {
    _id: 4,
    title: "Create a file for the content outline.",
    description:
      "This file should provide a high-level overview of the content, including the following information:The main headings, The subheadings, The bullet points",
  },
  {
    _id: 5,
    title: "Create a file for the content.",
    description:
      "This file should contain the actual content, written in accordance with the content brief and outline.",
  },
  {
    _id: 6,
    title: "Create a file for the images and graphics.",
    description:
      "This file should contain all of the images and graphics that will be used in the content.",
  },
  {
    _id: 7,
    title: "Create a file for the style guide.",
    description:
      "This file should contain the following information:The font family, The font size, The line spacing, The color palette, The overall design of the content",
  },
  {
    _id: 8,
    title: "Create a file for the accessibility guidelines.",
    description:
      "This file should contain the following information: The use of headings and subheadings, The use of alt text for images, The use of accessible colors, The use of accessible fonts",
  },
  {
    _id: 9,
    title: "Create a file for the SEO guidelines.",
    description:
      "This file should contain the following information:The use of keywords,The use of meta descriptions, The use of internal links, The use of social media sharing buttons",
  },
  {
    _id: 10,
    title: "Create a file for the final deliverables.",
    description:
      "This file should contain the following information:The final content, The final images and graphics, The final style guide, The final accessibility guidelines, The final SEO guidelines",
  },
];

import React, { useRef, useEffect, forwardRef, memo } from "react";
let requestId = null;
const CanvasPulse = forwardRef(({ x, y, isAnimation }, ref) => {
  const requestAnimationFrame =
    window.requestAnimationFrame ||
    window.mozRequestAnimationFrame ||
    window.webkitRequestAnimationFrame ||
    window.msRequestAnimationFrame;

  const cancelAnimationFrame =
    window.cancelAnimationFrame || window.mozCancelAnimationFrame;
  useEffect(() => {
    if (!ref.current || !isAnimation || (x == 0 && y == 0)) return;
    const canvas = ref.current;
    const context = canvas.getContext("2d");
    let radius = 10;
    let increasing = true;

    const animate = () => {
      context.clearRect(0, 0, canvas.width, canvas.height);

      context.beginPath();
      context.arc(x, y, radius, 0, 2 * Math.PI);
      context.fillStyle = "rgba(0, 0, 255, 0.5)";
      context.fill();

      if (increasing) {
        radius += 1;
        if (radius >= 20) {
          increasing = false;
        }
      } else {
        radius -= 1;
        if (radius <= 10) {
          increasing = true;
        }
      }

      requestId = requestAnimationFrame(animate);
    };

    animate();

    return () => {
      context.clearRect(0, 0, canvas.width, canvas.height);
      cancelAnimationFrame(requestId);
      requestId = null;
    };
  }, [x, y, isAnimation, ref]);

  return null;
});

export default memo(CanvasPulse);

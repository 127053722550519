import { memo, useEffect, useRef, useState } from "react";
import { Card } from "react-bootstrap";
import Masonry from "react-masonry-css";
// import ContentDetails from "../../../../components/partials/common/content-details";

//Img
import { toast } from "react-hot-toast";
import {
  DELETE_VAULT,
  VAULT_LIST_POST,
} from "../../../../api/endpoints/vault-endpoints";
import { getService } from "../../../../api/services/get-services";
import postService from "../../../../api/services/post-service";
import pdf from "../../../../assets/modules/file-manager/images/pdf.svg";
import withAuthorization from "../../../../components/hoc/with-authorization";
import { findIcon } from "../../../dashboard/icons";

const breakpointColumnsObj = {
  default: 3,
  1100: 3,
  768: 2,
  500: 1,
};
const breakpointColumnsObjright = {
  default: 2,
  1100: 3,
  768: 2,
  500: 1,
};

const Vault = () => {
  const [step, setStep] = useState(1);
  const [vaultList, setVautList] = useState([]);
  const post_json = useRef({
    default: "all",

    pagination: {
      limit: 10,
      offset: 0,
    },
  });
  const getVaultList = async (data) => {
    try {
      const response = await postService(VAULT_LIST_POST, data);
      // console.log("response", response?.data?.data?.rows);
      if (response?.data?.data?.rows) {
        setVautList(response?.data?.data?.rows);
      }
    } catch (error) {}
  };
  useEffect(() => {
    getVaultList(post_json.current);
  }, []);
  const deleteVault = async (id) => {
    try {
      const response = await getService(DELETE_VAULT + id);
      if (response?.data?.success) {
        toast.success("Your vault has been deleted successfully");
        setVautList(vaultList.filter((item) => item.id !== id));
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  return (
    <>
      <div className="height80 row">
        <div className="col-12 col-md-12 h-100">
          <div className="d-flex flex-column">
            <h5 className="m-0 pb-3">Vault</h5>
          </div>
          <div className="d-flex w-100">
            <Card className="w-100">
              <Card.Body>
                <Masonry
                  breakpointCols={breakpointColumnsObj}
                  className="my-masonry-grid"
                  columnClassName="my-masonry-grid_column"
                >
                  {vaultList.map((_v) => (
                    <div
                      className="d-flex gap-2 align-items-center p-2 border rounded-3 mb-4 position-relative"
                      key={_v.id}
                    >
                      <span
                        className="position-absolute top-0 end-0 me-n2 text-danger cursor-pointer"
                        onClick={() => {
                          deleteVault(_v.id);
                        }}
                      >
                        {findIcon("Trash", "dual-tone", 24)}
                      </span>
                      <div className="avatar-40 rounded-3 bg-soft-primary lh-1 d-flex align-items-center justify-content-center flex-shrink-0 p-2">
                        <img
                          className="img-fluid"
                          src={
                            _v?.content_id?.content_to_image[0]?.content_image_id?.thumbnail?.trim() ??
                            pdf
                          }
                          alt="profile"
                          loading="lazy"
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = pdf;
                          }}
                        />
                      </div>
                      <h6 className="m-0 textDoubleLine">
                        <small>{_v?.content_id?.content_title}</small>
                      </h6>
                    </div>
                  ))}
                </Masonry>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default withAuthorization(memo(Vault));

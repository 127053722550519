import { configureStore } from "@reduxjs/toolkit";
import settingReducer from "./setting/reducers";
import authReducer from "./auth/reducers";
import reactTableReducer from "./react-table/react-table.reducer";
import commonReducer from "./common/reducers.js";
export const store = configureStore({
  reducer: {
    setting: settingReducer,
    auth: authReducer,
    reactTable: reactTableReducer,
    common: commonReducer,
  },
});

import React, {
  Fragment,
  useRef,
  useState,
  useCallback,
  useEffect,
  memo,
} from "react";
import { Button, Card, Col, Form, Row, Modal, Badge } from "react-bootstrap";
import { findIcon } from "../../../../dashboard/icons";
import ReactTable from "../../../../../components/table/react-table";
import postService from "../../../../../api/services/post-service";
import ViewModal from "../../../../../components/partials/common/view-modal";
import DeleteModal from "../../../../../components/partials/common/delete-modal";
import { getService } from "../../../../../api/services/get-services";
import {
  changeTableStateToPayload,
  statusToBg,
} from "../../../../../common/utils/utils";
import * as yup from "yup";
import { useFormik } from "formik";
import {
  ADD_ACTION_POST,
  DELETE_ACTION_POST,
  ACTION_DETAILS_GET,
  ALL_ACTIONS_POST,
  UPDATE_ACTION_POST,
  CREATE_ACTION_GET,
} from "../../../../../api/endpoints/action-type-endpoints";
import toast from "react-hot-toast";
import FloatingButton from "../../../../../components/form-components/floating-button";
import withAuthorization from "../../../../../components/hoc/with-authorization";
import ReactTableLoader from "../../../../../skeleton/react-table-loader/react-table-loader";

const AllAction = () => {
  const [createModalShow, setCreateModalShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [actionList, setActionList] = useState([]);
  const [viewModalShow, setViewModalShow] = useState(false);
  const [viewAction, setViewAction] = useState({});
  const [totalCount, setTotalCount] = useState(0);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [selectedAction, setSelectedAction] = useState(null);
  const [countryList, setCountryList] = useState([]);
  const [zoneList, setZoneList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  // const actionDetails = useRef({ countryId: "" });
  const post_json = useRef({
    default: "all",

    pagination: {
      limit: 10,
      offset: 0,
    },
    sort: {
      action_type_id: "desc",
    },
  });
  //yup and formik for validation of form fields of priority_name   /^\s*$/

  const validationSchema = yup.object().shape({
    action_type_name: yup.string().trim().required("Action Name is required"),
    // .matches(/^\s*$/, "Action Name must not contain only whitespace")
    // action_type_code: yup
    //   .string()
    // .required("Action Code is required")
    // .matches(/^\S+$/, "Action Code must not contain only whitespace")
    // ,
    action_type_description: yup
      .string()
      .trim()
      .required("Description is required"),
    // .matches(/^\S+$/, "Description must not contain only whitespace")
    // status: yup.string().required("Zone id is required"),
  });
  //formik
  const formik = useFormik({
    initialValues: {
      // action_type_id: "",
      action_type_name: "",
      // action_type_code: "",
      action_type_description: "",
      // created_by: "",
      // status: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      // alert(JSON.stringify(values, null, 2));
      console.log("values===>", values);

      const postValues = {
        action_type_name: values.action_type_name.trim(),
        action_type_description: values.action_type_description.trim(),
      };

      try {
        setLoading(true);
        const response = await postService(
          isEdit ? UPDATE_ACTION_POST + "/" + selectedAction : ADD_ACTION_POST,
          {
            ...postValues,
            // action_active: 1,
            status: isEdit ? values.status : 1,
          }
        );
        if (response) {
          toast.success(
            isEdit
              ? "Action has been Updated successfully!"
              : "Action has been added successfully!"
          );
          setLoading(false);
          setCreateModalShow(false);
          fetchAction(post_json.current);
          isEdit && setIsEdit(false);
        }
      } catch (error) {
        setLoading(false);
        console.log("ACTION_TYPE_SERVICE_ERROR", error);
      }
    },
  });

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setValues,
    resetForm,
  } = formik;
  console.log("formic test", values, errors);
  //fetch countries
  const fetchAction = async (data) => {
    try {
      // alert("fetching");
      // setLoading(true);
      const response = await postService(ALL_ACTIONS_POST, data);
      const response_country_list = await getService(CREATE_ACTION_GET, {});
      console.log("COUNTRY_LIST", response_country_list?.data?.data?.country);
      console.log("ACTION_SERVICE_RESPONSE", response.data.data.rows);
      console.log("COLUMNS", Object.keys(response.data.data.rows[0]));
      if (response.data.data.rows.length > 0) {
        setActionList(response.data.data.rows);
        setTotalCount(response.data.data.totalCount);
        setLoading(false);
      }
      if (response_country_list?.data?.data?.country?.length > 0) {
        setCountryList(response_country_list?.data?.data?.country);
      }
    } catch (error) {
      setLoading(false);
      console.log("ACTION_SERVICE_ERROR", error);
    }
  };
  const viewModal = async (action) => {
    console.log("action===>", action);
    let dataJson = {
      "Action ID": action.action_type_id,
      "Action Type Name": action.action_type_name,
      // "Action Type Code": action.action_type_code,
      "Action Type Description": action.action_type_description,
      "Created by": action.created_by.name,
      Status: action.status.value,
    };
    setViewAction(dataJson);
    setViewModalShow(true);
  };
  const viewModalClose = () => {
    setViewModalShow(false);
  };
  const deleteAction = async () => {
    try {
      setLoading(true);
      const response = await getService(
        `${DELETE_ACTION_POST}/${selectedAction}`
      );
      if (response) {
        setLoading(false);
        setDeleteModalShow(false);
        fetchAction(post_json.current);
        toast.success("Action has been deleted successfully!");
      }
    } catch (error) {
      setLoading(false);
      console.log("ACTION_SERVICE_ERROR", error);
    }
  };
  useEffect(() => {
    setLoading(true);
    fetchAction(post_json.current);
  }, []);
  const onTableStateChange = useCallback(
    async (state) => {
      let payload = changeTableStateToPayload(state);
      post_json.current = payload;
      fetchAction(payload);
    },
    [actionList, loading, post_json.current]
  );
  const onDeleteAction = (action) => {
    setSelectedAction(action.action_type_id);
    setDeleteModalShow(true);
  };
  const columns = [
    {
      Header: "Action Type ID",
      accessor: "action_type_id",
    },
    {
      Header: "Action Type Name",
      accessor: "action_type_name",
    },
    {
      Header: "Action Type Description",
      accessor: "action_type_description",
    },
    // {
    //   Header: "Action Type Code",
    //   accessor: "action_type_code",
    // },
    {
      Header: "Created by",
      accessor: "created_by",
      Cell: ({ value }) => value?.name,
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ value }) => (
        <Badge bg={statusToBg(value.id)} className="me-2">
          <span className="btn-inner">{value.value}</span>
        </Badge>
      ),
    },
    {
      Header: "Action",
      accessor: "action",
      Cell: ({ row }) => (
        <div className="d-flex justify-content-center align-items-center gap">
          <div className="d-flex justify-content-center">
            <Button
              className="btn btn-icon btn-sm rounded-pill"
              to="#"
              role="button"
              onClick={() => viewModal(row.original)}
            >
              <span className="btn-inner">
                {findIcon("Eye", "dual-tone", 32)}
              </span>
            </Button>
          </div>

          <div className="d-flex justify-content-center">
            <Button
              className="btn btn-icon btn-sm rounded-pill"
              to="#"
              role="button"
              onClick={() => onEditActionClick(row.original)}
            >
              <span className="btn-inner">
                {findIcon("Pencil", "dual-tone")}
              </span>
            </Button>
          </div>
          <div className="d-flex justify-content-center">
            <Button
              className="btn btn-icon btn-sm rounded-pill"
              to="#"
              role="button"
              onClick={() => onDeleteAction(row.original)}
            >
              <span className="btn-inner">
                {findIcon("Trash", "dual-tone", 32)}
              </span>
            </Button>
          </div>
        </div>
      ),
    },
  ];

  const handleCreate = () => {
    resetForm();
    setCreateModalShow(true);
  };
  const onEditActionClick = (action) => {
    console.log("action===>", action);
    setIsEdit(true);
    setSelectedAction(action.action_type_id);
    //set formik values
    setValues({
      action_type_name: action.action_type_name,
      action_type_code: action.action_type_code,
      action_type_description: action.action_type_description,
      status: action.status.id,
    });
    setCreateModalShow(true);
  };
  return (
    <Fragment>
      <FloatingButton onClick={handleCreate} />
      <Row>
        <Col sm="12">
          <Card>
            <Card.Header className="d-flex justify-content-between align-items-center">
              <h4 className="card-title">All Action Names</h4>
              {/* <Button
                type="button"
                variant="primary"
                className="fw-bold rounded-3"
                onClick={() => handleCreate()}
              >
                {findIcon("Plus", "dual-tone", 32)} Add Action Name
              </Button> */}
            </Card.Header>
            <Card.Body className="px-3">
              {!loading ? (
                actionList.length > 0 && (
                  <ReactTable
                    data={actionList}
                    columns={columns}
                    recordsTotal={totalCount}
                    onTableStateChange={onTableStateChange}
                    initialState={post_json.current}
                    isRowSelectionAvailable={false}
                    recordsPerPage={10}
                  />
                )
              ) : (
                <ReactTableLoader />
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <DeleteModal
        show={deleteModalShow}
        modalClose={() => setDeleteModalShow(false)}
        onSubmit={deleteAction}
      />
      <ViewModal
        title={"View Priority Name Information"}
        show={viewModalShow}
        modalClose={viewModalClose}
        data={viewAction}
      />
      {/* create a add Priority Name modal with Priority Name name and description */}
      <Modal
        show={createModalShow}
        size="lg"
        onHide={() => {
          setCreateModalShow(false);
          setIsEdit(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{isEdit ? "Edit" : "Add"} Action </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {/* ACTION TYPE NAME */}
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>
                Action Name <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Action Name"
                name="action_type_name"
                onChange={handleChange}
                value={values.action_type_name}
                onBlur={handleBlur}
                isInvalid={touched.action_type_name && errors.action_type_name}
              />
              <Form.Control.Feedback type="invalid">
                {errors.action_type_name}
              </Form.Control.Feedback>
            </Form.Group>
            {/* ACTION CODE */}
            {/* <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Code</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Action Code"
                name="action_type_code"
                onChange={handleChange}
                value={values.action_type_code}
                onBlur={handleBlur}
                isInvalid={touched.action_type_code && errors.action_type_code}
              />
              <Form.Control.Feedback type="invalid">
                {errors.action_type_code}
              </Form.Control.Feedback>
            </Form.Group> */}
            {/* ACTION DESCRIPTION */}
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>
                Description <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Action Description"
                name="action_type_description"
                onChange={handleChange}
                value={values.action_type_description}
                onBlur={handleBlur}
                isInvalid={
                  touched.action_type_description &&
                  errors.action_type_description
                }
              />
              <Form.Control.Feedback type="invalid">
                {errors.action_type_description}
              </Form.Control.Feedback>
            </Form.Group>
            {isEdit && (
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Action type Status </Form.Label>
                <Form.Select
                  name="status"
                  value={values?.status}
                  isInvalid={touched.status && errors.status}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option value="">Select Status</option>
                  <option value="1">Enabled</option>
                  <option value="2">Disabled</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors?.status}
                </Form.Control.Feedback>
              </Form.Group>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setCreateModalShow(false);
              setIsEdit(false);
            }}
          >
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            {isEdit ? "Update" : "Add Action Type"}
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default withAuthorization(memo(AllAction));

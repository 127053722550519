import React, {
  forwardRef,
  useLayoutEffect,
  useRef,
  useState,
  useEffect,
} from "react";
import { Button } from "react-bootstrap";
import { findIcon } from "../../../../../dashboard/icons";
import CanvasPulse from "../../canvas-pulse";

const CanvasPlayground = forwardRef(
  (
    {
      selectedImage,
      zoom,
      handleCanvasClick,
      fileType,
      pulseAnimation,
      selectedCoordinate,
      onReturnToVideoClick,
    },
    ref
  ) => {
    const canvas = ref;
    const pulseCanvas = useRef();

    const previewRef = useRef();
    const [canvasWidth, setCanvasWidth] = useState(0);
    useLayoutEffect(() => {
      if (previewRef?.current?.offsetWidth) {
        setCanvasWidth(previewRef?.current?.offsetWidth ?? 0);
      }
    }, [previewRef?.current?.offsetWidth]);
    const drawDots = (x, y) => {
      if (!ref?.current) return;
      const canvasRef = ref.current;
      const context = canvasRef.getContext("2d");
      let radius = 10;
      context.beginPath();
      context.arc(x, y, radius, 0, 2 * Math.PI);
      context.fillStyle = "rgba(0, 0, 255, 0.5)";
      context.fill();
    };
    const fillCanvasWithDots = () => {
      let myComments =
        selectedImage?.myComments?.length > 0 ? selectedImage?.myComments : [];
      let otherPeopleComments =
        selectedImage?.otherPeopleComments?.length > 0
          ? selectedImage?.otherPeopleComments
          : [];

      let comments = [...myComments, ...otherPeopleComments];
      if (comments?.length > 0) {
        comments.forEach((comment) => {
          drawDots(comment?.x, comment?.y);
        });
      }
    };
    const clearCanvas = () => {
      if (!ref?.current) return;
      const canvasRef = ref.current;
      const context = canvasRef.getContext("2d");
      context.clearRect(0, 0, canvasRef.width, canvasRef.height);
    };

    //if selectedImage changes then fill dots without clearing the canvas, and if selectedImage.PageNumber change then clear the canvas and fill dots
    useEffect(() => {
      if (selectedImage?.PageNumber > 0) {
        clearCanvas();
        fillCanvasWithDots();
      }
    }, [selectedImage]);

    return (
      <>
        <div
          className="position-relative d-flex align-items-center justify-content-center h-100 w-100"
          ref={previewRef}
        >
          {canvasWidth > 0 && (
            <>
              {fileType == "video" && (
                <Button
                  variant="info"
                  className="rounded-pill position-absolute top-0 end-0 mt-2 me-2 cursor-pointer shadow-lg z-3"
                  // style={topRightStyle(99)}
                  onClick={onReturnToVideoClick}
                >
                  Done {findIcon("Square Tick", "outline", 20)}
                </Button>
              )}
              <div className="position-relative h-100 w-100 scrollbar-custom-xy overflow-auto bg-dark d-flex align-items-center justify-content-center w-100 h-100">
                <div className=" position-relative z-2 ">
                  <canvas
                    className="rounded-3"
                    ref={canvas}
                    id="dotCanvas"
                    onClick={handleCanvasClick}
                    width={canvasWidth}
                    height="500"
                    style={{
                      backgroundImage: `url(${selectedImage?.Url})`,
                      // backgroundSize: fileType == "image" ? "cover" : "contain",
                      backgroundSize: "contain",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      cursor: "copy",
                      scale: `${zoom}`,
                      transformOrigin: "top left",
                    }}
                  ></canvas>
                </div>
                {/* a canvas that will overlay the canvas */}
                {pulseAnimation &&
                  selectedCoordinate.x > 0 &&
                  selectedCoordinate.y > 0 && (
                    <div className="position-absolute z-2 start-0 top-0 h-100 w-100 rounded-3 justify-content-center d-flex align-items-center">
                      <canvas
                        // onWheel={(e) => {
                        //   e.preventDefault();
                        //   if (e.deltaY < 0) {
                        //     setZoom(zoom + 0.1);
                        //   } else {
                        //     setZoom(zoom - 0.1);
                        //   }
                        // }}
                        ref={pulseCanvas}
                        id="pulseCanvas"
                        width={canvasWidth}
                        height="500"
                        onClick={handleCanvasClick}
                        style={{
                          cursor: "copy",
                          scale: `${zoom}`,
                          transformOrigin: "top left",
                        }}
                      ></canvas>
                    </div>
                  )}
              </div>

              <CanvasPulse
                isAnimation={pulseAnimation}
                x={selectedCoordinate.x}
                y={selectedCoordinate.y}
                ref={pulseCanvas}
              />
            </>
          )}
        </div>
      </>
    );
  }
);

export default React.memo(CanvasPlayground);

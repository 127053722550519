import React, { memo } from "react";
import { Button, Col, Image } from "react-bootstrap";
import ReactPlayer from "react-player";
import { previewSecondstoMinutes } from "../../../../../../common/utils/utils";
const VideoPreviewSection = React.forwardRef(
  (
    {
      playing,
      muted,
      onChangeVideoState,
      onChangeProgress,
      onSnapThumbnailClick,
      onRaiseProblemClick,
      activeElement,
      onReadyPlayer,
      videoUrl = "",
    },
    ref
  ) => {
    const topRightStyle = (zIndex) => {
      return {
        position: "absolute",
        top: "2rem",
        right: "3rem",
        zIndex,
      };
    };
    const bottomLeftStyle = (zIndex) => {
      return {
        position: "absolute",
        bottom: "14rem",
        left: "3rem",
        zIndex,
      };
    };

    return (
      <>
        <ReactPlayer
          url={
            "https://dx3rpg9leyeqc.cloudfront.net/file_library/videos/vod_non_drm_ios/3867427/1700551808_7627364227851855/5_major_causes_of_lung_cancer_in_non-smokers.m3u8"
          }
          controls={true}
          ref={ref}
          onPlay={() => onChangeVideoState("playing", true)}
          onPause={() => onChangeVideoState("playing", false)}
          playing={playing}
          playsinline
          muted={muted}
          onStart={onReadyPlayer}
          onProgress={(e) => onChangeProgress(e.playedSeconds, "video")}
          onSeek={(e) => onChangeProgress(e, "video")}
          height={"auto"}
          width="100%"
          //height="500px"
          className="w-100 overflow-hidden d-flex align-items-center justify-content-center rounded-3 bg-dark videoPreview"
        />

        <Button
          variant="danger"
          className="rounded-pill position-absolute top-0 end-0 mt-3 me-3 cursor-pointer"
          // style={topRightStyle(99)}
          onClick={onRaiseProblemClick}
        >
          Raise a Problem
        </Button>
      </>
    );
  }
);
export default memo(VideoPreviewSection);

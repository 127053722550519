import { Fragment, memo } from "react";
import { getFormatedDate } from "../../../../../../common/utils/utils";
const Reviews = ({ comments, onEachReviewClick, selectedComment }) => {
  const getFirstLetter = (name) => {
    return name ? name?.charAt(0).toUpperCase() : "";
  };
  const AccordionBodyComments = ({ comments, type }) => (
    // <div className="reviews overflow-scroll flex-grow-1 mb-3 h-100">
    <div className="flex-fill overflow-auto p-2 d-flex flex-column">
      {comments?.length > 0 &&
        comments?.map((_comment, index) => {
          // //console.log("COMMENT", _comment);
          let { created_at, comment, user_id, x, y } = _comment;
          let isActive =
            selectedComment &&
            selectedComment.x === x &&
            selectedComment.y === y;
          if (comment)
            return (
              <div
                className={`d-flex flex-column justify-content-between mb-2 rounded-3 cursor-pointer position-relative overflow-visible z-1 bg-white border ${isActive ? "border-info" : ""
                  }`}
                key={index + 1}
                onClick={() => onEachReviewClick && onEachReviewClick(_comment)}
              >
                <div className="d-flex align-align-items-start px-2 py-2 gap-2 border-bottom">
                  <span className="rounded-circle icon-40 bg-info fs-7 text-white d-flex justify-content-center align-items-center flex-shrink-0">
                    {getFirstLetter(user_id?.name ?? "")}
                  </span>
                  <div className="d-flex flex-column">
                    <span className="fs-7 text-black">
                      {user_id?.name ?? ""}
                    </span>
                    <span className="fs-7">{getFormatedDate(created_at)}</span>

                  </div>

                </div>
                <div className="lh-base text-black p-2 fs-7">
                  {/* {index + 1} {"->"} {text || ""} */}
                  {comment || ""}
                </div>

              </div>
            );
          else return <></>;
        })}
    </div>
  );
  return (
    <Fragment>
      <AccordionBodyComments comments={comments} type={"my"} />
    </Fragment>
  );
};
export default memo(Reviews);

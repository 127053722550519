import React, { memo } from "react";
import ReactTable from "../../../../components/table/react-table";
import Card from "../../../../components/bootstrap/card";
import { Row, Col, Button, Badge, Image } from "react-bootstrap";
import Chart from "react-apexcharts";
import { findIcon } from "../../../dashboard/icons";
import pdf from "../../../../assets/modules/file-manager/images/pdf.svg";
import excel from "../../../../assets/modules/file-manager/images/excel.svg";
import word from "../../../../assets/modules/file-manager/images/word.svg";
import Document from "../../content-library/components/document";
import withAuthorization from "../../../../components/hoc/with-authorization";

const Dashboard = () => {
  const vendorActionListTable = {
    data: [
      {
        taskName: "Action 1",
        taskType: "Review Pending",
        deadline: "2023-05-31",
        priority: "High",
        status: { status: "Pending", color: "danger" },
        taskId: findIcon("Eye Fish", "dual-tone"),
        assignedBy: "Arnab Saha",
      },
      {
        taskName: "Action 2",
        taskType: "Approval Pending",
        deadline: "2023-06-15",
        priority: "Medium",
        status: { status: "Completed", color: "primary" },
        taskId: findIcon("Eye Fish", "dual-tone"),
        assignedBy: "Sumit Mandal",
      },
      {
        taskName: "Action 3",
        taskType: "Vendor Acceptance Pending",
        deadline: "2023-06-15",
        priority: "Low",
        status: { status: "In Progress", color: "primary" },
        taskId: findIcon("Eye Fish", "dual-tone"),
        assignedBy: "Indranil Kundu",
      },
      {
        taskName: "Action 4",
        taskType: "Creation in Progress",
        deadline: "2023-06-15",
        priority: "Low",
        status: { status: "In Progress", color: "primary" },
        taskId: findIcon("Eye Fish", "dual-tone"),
        assignedBy: "Indranil Kundu",
      },
      {
        taskName: "Action 5",
        taskType: "Vendor Acceptance Pending",
        deadline: "2023-06-15",
        priority: "Low",
        status: { status: "In Progress", color: "primary" },
        taskId: findIcon("Eye Fish", "dual-tone"),
        assignedBy: "Indranil Kundu",
      },
      {
        taskName: "Action 5",
        taskType: "Approval Pending",
        deadline: "2023-06-15",
        priority: "Low",
        status: { status: "In Progress", color: "primary" },
        taskId: findIcon("Eye Fish", "dual-tone"),
        assignedBy: "Indranil Kundu",
      },
    ],
    columns: [
      {
        Header: "Action Name",
        accessor: "taskName",
      },
      {
        Header: "Action Type",
        accessor: "taskType",
        // Cell: ({ row }) => (
        //   // console.log("row", row, row.values.status.status),
        //   // <div className="d-flex flex-column align-items-center justify-content-center px-2 py-2">
        //   //   {row.values.taskType}<p className=''>Task A</p>
        //   // </div>
        //   <div className="d-flex align-items-center">
        //     <img
        //       className="rounded img-fluid avatar-40 me-3 bg-soft-primary px-2 py-2"
        //       src={row.values.taskType}
        //       alt="profile"
        //       loading="lazy"
        //     />
        //     <h6>Add Progress Track</h6>
        //   </div>
        // ),
      },
      {
        Header: "Deadline",
        accessor: "deadline",
      },
      {
        Header: "Priority",
        accessor: "priority",
        Cell: ({ row }) => (
          // console.log("row", row, row.values.status.status),
          <div className="d-flex align-items-center justify-content-center">
            <Badge
              bg={
                row.values.priority == "Low"
                  ? "success"
                  : row.values.priority == "Medium"
                  ? "warning"
                  : row.values.priority == "High"
                  ? "danger"
                  : ""
              }
              className="me-2"
            >
              <span className="btn-inner">{row.values.priority}</span>
            </Badge>
          </div>
        ),
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ row }) => (
          // console.log("row", row, row.values.status.status),
          <div className="d-flex align-items-center justify-content-center">
            <Badge
              bg={
                row.values.status.status == "In Progress"
                  ? "primary"
                  : row.values.status.status == "Completed"
                  ? "success"
                  : row.values.status.status == "Pending"
                  ? "danger"
                  : ""
              }
              className="me-2"
            >
              <span className="btn-inner">{row.values.status.status}</span>
            </Badge>
          </div>
        ),
      },
      {
        Header: "Assigned by",
        accessor: "assignedBy",
      },
      {
        Header: "Action",
        accessor: "taskId",
        // disableSortBy: true,
        Cell: ({ row }) => (
          <div className="d-flex justify-content-center">
            <Button
              className="btn btn-icon btn-sm rounded-pill"
              to="#"
              role="button"
            >
              <span className="btn-inner">{row.values.taskId}</span>
            </Button>
          </div>
        ),
      },
    ],
  };
  const pendingRequisitionTable = {
    data: [
      {
        taskName: "Action 1",
        taskType: "Add Article",
        deadline: "2023-05-31",
        priority: "High",
        status: { status: "Pending", color: "danger" },
        taskId: findIcon("Square Tick", "dual-tone"),
        taskClose: findIcon("Square X", "dual-tone"),
        assignedBy: "Arnab Saha",
      },
      {
        taskName: "Action 2",
        taskType: "Add Video",
        deadline: "2023-06-15",
        priority: "Medium",
        status: { status: "Completed", color: "primary" },
        taskId: findIcon("Square Tick", "dual-tone"),
        taskClose: findIcon("Square X", "dual-tone"),
        assignedBy: "Sumit Mandal",
      },
      {
        taskName: "Action 3",
        taskType: "Add Infographics",
        deadline: "2023-06-15",
        priority: "Low",
        status: { status: "In Progress", color: "primary" },
        taskId: findIcon("Square Tick", "dual-tone"),
        taskClose: findIcon("Square X", "dual-tone"),
        assignedBy: "Indranil Kundu",
      },
    ],
    columns: [
      {
        Header: "Action Name",
        accessor: "taskName",
      },
      {
        Header: "Action Type",
        accessor: "taskType",
        // Cell: ({ row }) => (
        //   // console.log("row", row, row.values.status.status),
        //   // <div className="d-flex flex-column align-items-center justify-content-center px-2 py-2">
        //   //   {row.values.taskType}<p className=''>Task A</p>
        //   // </div>
        //   <div className="d-flex align-items-center">
        //     <img
        //       className="rounded img-fluid avatar-40 me-3 bg-soft-primary px-2 py-2"
        //       src={row.values.taskType}
        //       alt="profile"
        //       loading="lazy"
        //     />
        //     <h6>Add Progress Track</h6>
        //   </div>
        // ),
      },
      {
        Header: "Deadline",
        accessor: "deadline",
      },
      {
        Header: "Priority",
        accessor: "priority",
        Cell: ({ row }) => (
          // console.log("row", row, row.values.status.status),
          <div className="d-flex align-items-center justify-content-center">
            <Badge
              bg={
                row.values.priority == "Low"
                  ? "success"
                  : row.values.priority == "Medium"
                  ? "warning"
                  : row.values.priority == "High"
                  ? "danger"
                  : ""
              }
              className="me-2"
            >
              <span className="btn-inner">{row.values.priority}</span>
            </Badge>
          </div>
        ),
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ row }) => (
          // console.log("row", row, row.values.status.status),
          <div className="d-flex align-items-center justify-content-center">
            <Badge
              bg={
                row.values.status.status == "In Progress"
                  ? "primary"
                  : row.values.status.status == "Completed"
                  ? "success"
                  : row.values.status.status == "Pending"
                  ? "danger"
                  : ""
              }
              className="me-2"
            >
              <span className="btn-inner">{row.values.status.status}</span>
            </Badge>
          </div>
        ),
      },
      {
        Header: "Assigned by",
        accessor: "assignedBy",
      },
      {
        Header: "Action",
        accessor: "taskId",
        // disableSortBy: true,
        Cell: ({ row }) => {
          console.log("ROW_VALUES", row.values);
          return (
            <div className="d-flex justify-content-center">
              <Button
                className="btn btn-icon btn-sm rounded-pill"
                to="#"
                role="button"
              >
                <span className="btn-inner">{row.values.taskId}</span>
              </Button>
              <Button
                className="btn btn-icon btn-sm rounded-pill ms-2"
                to="#"
                role="button"
              >
                <span className="btn-inner">
                  <svg
                    width="32"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon-32"
                    height="32"
                  >
                    <path
                      opacity="0.4"
                      d="M16.34 1.99976H7.67C4.28 1.99976 2 4.37976 2 7.91976V16.0898C2 19.6198 4.28 21.9998 7.67 21.9998H16.34C19.73 21.9998 22 19.6198 22 16.0898V7.91976C22 4.37976 19.73 1.99976 16.34 1.99976Z"
                      fill="currentColor"
                    ></path>
                    <path
                      d="M15.0158 13.7703L13.2368 11.9923L15.0148 10.2143C15.3568 9.87326 15.3568 9.31826 15.0148 8.97726C14.6728 8.63326 14.1198 8.63426 13.7778 8.97626L11.9988 10.7543L10.2198 8.97426C9.87782 8.63226 9.32382 8.63426 8.98182 8.97426C8.64082 9.31626 8.64082 9.87126 8.98182 10.2123L10.7618 11.9923L8.98582 13.7673C8.64382 14.1093 8.64382 14.6643 8.98582 15.0043C9.15682 15.1763 9.37982 15.2613 9.60382 15.2613C9.82882 15.2613 10.0518 15.1763 10.2228 15.0053L11.9988 13.2293L13.7788 15.0083C13.9498 15.1793 14.1728 15.2643 14.3968 15.2643C14.6208 15.2643 14.8448 15.1783 15.0158 15.0083C15.3578 14.6663 15.3578 14.1123 15.0158 13.7703Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </span>
              </Button>
            </div>
          );
        },
      },
    ],
  };
  const chart1 = {
    options: {
      chart: {
        width: 380,
        type: "pie",
      },
      labels: ["Video", "Article", "Infographic", "Documents"],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
    series: [44, 55, 13, 43],
  };
  const chart2 = {
    options: {
      chart: {
        width: 380,
        type: "polarArea",
      },
      labels: ["High", "Medium", "Low"],
      fill: {
        opacity: 1,
      },
      stroke: {
        width: 1,
        colors: undefined,
      },
      yaxis: {
        show: false,
      },
      // legend: {
      //   position: "bottom",
      // },
      plotOptions: {
        polarArea: {
          rings: {
            strokeWidth: 0,
          },
          spokes: {
            strokeWidth: 0,
          },
        },
      },
    },

    series: [14, 23, 21],
  };
  const chart3 = {
    options: {
      chart: {},
      colors: ["#0000ff", "#ff0000", "#66cdaa"],
      labels: ["Completed", "Pending", "In Progress"],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
    series: [44, 55, 41],
  };
  return (
    <>
      <div className="d-flex flex-column mb-4">
        <h3 className="m-0">Analytics</h3>
      </div>
      <Row>
        <Col sm="12">
          <Card>
            <Card.Body className="px-0">
              {/* <div className="d-flex justify-content-start gap-2 px-2">
                <p>
                  <b>Name :</b> Swagata Das
                </p>
                <p>
                  <b>Department :</b> Web Developer
                </p>
              </div> */}
              <div className="d-flex justify-content-between align-items-center px-2">
                {/* <div className="d-flex flex-column justify-content-start ps-3">
                  <p>
                    <b>Name :</b> Swagata Das
                  </p>
                  <p>
                    <b>Department :</b> Web Developer
                  </p>
                </div> */}
                <div className="d-flex flex-column justify-content-center gap-2 px-2">
                  <Chart
                    options={chart1.options}
                    series={chart1.series}
                    type="pie"
                    height="100"
                  />
                  <p className="d-flex flex-column justify-content-center align-items-center">
                    Task Type
                  </p>
                </div>
                <div className="d-flex flex-column justify-content-center gap-2 px-2">
                  <Chart
                    options={chart2.options}
                    series={chart2.series}
                    type="polarArea"
                    height="100"
                  />
                  <p className="d-flex flex-column justify-content-center align-items-center">
                    Task Priority
                  </p>
                </div>
                <div className="d-flex flex-column justify-content-center justify-items-center gap-2 px-2">
                  <Chart
                    options={chart3.options}
                    series={chart3.series}
                    type="donut"
                    height="100"
                  />{" "}
                  <p className="d-flex flex-column justify-content-center align-items-center">
                    Task Status
                  </p>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>

        <Col sm="12">
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">My Actions</h4>
              </div>
            </Card.Header>
            <Card.Body className="px-2">
              <ReactTable
                data={vendorActionListTable.data}
                columns={vendorActionListTable.columns}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default withAuthorization(memo(Dashboard));

import React from "react";
import Card from "../../components/bootstrap/card";
import { Skeleton } from "@mui/material";

const MyActionAnalyticsLoader = () => {
  return (
    <>
      <Card>
        <Card.Body className="px-0">
          <div className="d-flex justify-content-between align-items-center px-4">
            <div className="d-flex flex-column justify-content-center gap-2 mx-4">
              <div className="d-flex justify-content-center">
                <Skeleton variant="circular" width={80} height={80} className="me-4"/>
                <div className="d-flex flex-column justify-content-center gap-2 ms-4">
                  <div className="d-flex justify-content-center gap-2">
                    <Skeleton variant="circular" width={15} height={15} />
                    <Skeleton variant="text" width={100} height={15} />
                  </div>
                  <div className="d-flex justify-content-center gap-2">
                    <Skeleton variant="circular" width={15} height={15} />
                    <Skeleton variant="text" width={100} height={15} />
                  </div>
                  <div className="d-flex justify-content-center gap-2">
                    <Skeleton variant="circular" width={15} height={15} />
                    <Skeleton variant="text" width={100} height={15} />
                  </div>
                </div>
              </div>

              <p className="d-flex flex-column justify-content-center align-items-center">
              <Skeleton variant="text" width={70} height={30} />
              </p>
            </div>
            <div className="d-flex flex-column justify-content-center gap-2 mx-4">
              <div className="d-flex justify-content-center">
                <Skeleton variant="circular" width={80} height={80} className="me-4"/>
                <div className="d-flex flex-column justify-content-center gap-2 ms-4">
                  <div className="d-flex justify-content-center gap-2">
                    <Skeleton variant="circular" width={15} height={15} />
                    <Skeleton variant="text" width={100} height={15} />
                  </div>
                  <div className="d-flex justify-content-center gap-2">
                    <Skeleton variant="circular" width={15} height={15} />
                    <Skeleton variant="text" width={100} height={15} />
                  </div>
                  <div className="d-flex justify-content-center gap-2">
                    <Skeleton variant="circular" width={15} height={15} />
                    <Skeleton variant="text" width={100} height={15} />
                  </div>
                </div>
              </div>

              <p className="d-flex flex-column justify-content-center align-items-center">
              <Skeleton variant="text" width={70} height={30} />
              </p>
            </div>
            <div className="d-flex flex-column justify-content-center gap-2 mx-4">
              <div className="d-flex justify-content-center">
                <Skeleton variant="circular" width={80} height={80} className="me-4"/>
                <div className="d-flex flex-column justify-content-center gap-2 ms-4">
                  <div className="d-flex justify-content-center gap-2">
                    <Skeleton variant="circular" width={15} height={15} />
                    <Skeleton variant="text" width={100} height={15} />
                  </div>
                  <div className="d-flex justify-content-center gap-2">
                    <Skeleton variant="circular" width={15} height={15} />
                    <Skeleton variant="text" width={100} height={15} />
                  </div>
                  <div className="d-flex justify-content-center gap-2">
                    <Skeleton variant="circular" width={15} height={15} />
                    <Skeleton variant="text" width={100} height={15} />
                  </div>
                </div>
              </div>

              <p className="d-flex flex-column justify-content-center align-items-center">
              <Skeleton variant="text" width={70} height={30} />
              </p>
            </div>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default MyActionAnalyticsLoader;

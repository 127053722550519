import React, { Fragment, useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";

import Masonry from "react-masonry-css";

//Img
import toast from "react-hot-toast";
import {
  UPDATE_ACTION_POST,
  UPLOAD_FILES_POST,
} from "../../../../api/endpoints/action-endpoints";
import postService from "../../../../api/services/post-service";
import uploadService from "../../../../api/services/upload-service";
import article_1 from "../../../../assets/modules/file-manager/images/article_1.jpg";
import excel from "../../../../assets/modules/file-manager/images/excel.svg";
import pdf from "../../../../assets/modules/file-manager/images/pdf.svg";
import word from "../../../../assets/modules/file-manager/images/word.svg";
import app_config from "../../../../common/data/app_config";
import { getFormatedDate } from "../../../../common/utils/utils";
import useRedirect from "../../../../components/custom-hooks/use-redirect";
import { findIcon } from "../../../dashboard/icons";
import { STATUS_CONSTANTS } from "../constants/action-constants";
import ContentApproval from "./content-approval";
import ContentReview from "./content-review";
import RequisitionReviewApprovalLoader from "../../../../skeleton/action/requisition-review-approval-loader";
import SubmitButton from "../../../../components/form-components/submit-button";
import useBackdrop from "../../../../components/custom-hooks/use-backdrop";
import searchService from "../../../../api/services/search-service";
import { findIconByFileCode } from "../../master-management/file-type-management/constants/file-type-icons";
import { Skeleton } from "@mui/material";

const breakpointColumnsObj = {
  default: 3,
  1100: 3,
  768: 2,
  500: 1,
};
const HeroSection = ({
  requisitionTitle,
  requisitionDescription,
  createdBy,
  category,
  vendor,
  deliveryDate,
  priority,
  createdOn,
}) => {
  return (
    <Fragment>
      <h5 className="mb-3 pt-3 ps-3">Title: {requisitionTitle}</h5>
      <p className="px-3">
        <strong>Description:</strong> {requisitionDescription}
      </p>
      <div className="d-flex flex-row flex-wrap px-3 mt-4">
        <span className="rounded-3 border border-1 px-3 py-2 me-3 mb-3 fs-7">
          Created By: <strong>{createdBy}</strong>
        </span>
        <span className="rounded-3 border border-1 px-3 py-2 me-3 mb-3 fs-7">
          Action Type: <strong>{category}</strong>
        </span>
        <span className="rounded-3 border border-1 px-3 py-2 me-3 mb-3 fs-7">
          Vendor: <strong>{vendor}</strong>
        </span>
        <span className="rounded-3 border bg-soft-primary px-3 py-2 me-3 mb-3 fs-7">
          Exp Delivery Date : <strong>{deliveryDate}</strong>
        </span>
        <span className="rounded-3 bg-soft-warning border px-3 py-2 me-3 mb-3 fs-7">
          Priority :{" "}
          <strong className="bg-soft-warning px-2 py-1">{priority}</strong>
        </span>
        <span className="rounded-3 bg-soft-info border px-3 py-2 me-3 mb-3 fs-7">
          Created : <strong>{createdOn}</strong>
        </span>
      </div>
    </Fragment>
  );
};

const Reviews = ({ comments }) => {
  //console.log("comments", comments);
  return (
    <div className="reviews">
      {comments?.length > 0 &&
        comments?.map((_comment, _idx) => {
          let { created_at, comment, user_id } = _comment;
          return (
            <div
              className="d-flex flex-column justify-content-between shadow-sm p-3 mb-3 rounded-3"
              key={_idx + 1}
            >
              <div className="d-flex flex-row justify-content-between mb-2">
                <div className="d-flex flex-column">
                  <span>{user_id?.name ?? ""}</span>
                  <span className="fs-7 text-black">
                    {getFormatedDate(created_at)}
                  </span>
                </div>
              </div>
              <div>{comment || ""}</div>
            </div>
          );
        })}
    </div>
  );
};
const AddApprovalForm = ({ actionId, actionType }) => {
  const { redirectTo } = useRedirect();
  const [values, setValues] = useState({ requisition_files: [], input: "" });
  const uploadFiles = (files) => {
    return new Promise(async (resolve, reject) => {
      // Check if files array is empty
      if (files.length === 0) {
        reject("No files to upload.");
        return;
      }
      let formdata = new FormData();
      files.forEach((file) => {
        formdata.append("files[]", file);
      });

      // 1. Upload files to server
      let response = await uploadService(UPLOAD_FILES_POST, formdata);
      if (response.data?.success) {
        // 2. Simulating successful upload and generating URLs
        const urls = response.data.data.map((file) => file.name);
        resolve(urls);
      } else {
        reject("Something went wrong");
      }
    });
  };
  const handleChangeReferenceFiles = async (e) => {
    const { files } = e.target;
    const urls = await uploadFiles([...files]);
    if (urls) {
      setValues({
        ...values,
        requisition_files: [...values.requisition_files, ...urls],
      });
    }
  };
  const { showBackdrop, hideBackdrop } = useBackdrop();
  const submitReview = async (status) => {
    showBackdrop();

    let response = await postService(
      UPDATE_ACTION_POST + actionId,
      actionType == "requisition-review"
        ? { action_id: actionId, comment: values.input }
        : {
            action_id: actionId,
            status,
            comment: values.input,
            // reference_files: values.requisition_files,
          }
    );
    if (response.isError) {
      hideBackdrop();
      toast.error(response.error);
      return;
    }
    // //console.log("response", response.data?.success);
    if (response.data.success) {
      hideBackdrop();
      toast.success("Your action has been submitted successfully");
      redirectTo("action/pending");
      return;
    }
  };
  const onCrossImageClick = (e) => {
    let tempRequisitionFiles = values.requisition_files
      ? [...values.requisition_files]
      : [];
    tempRequisitionFiles = tempRequisitionFiles.filter(
      (file, idx) => idx !== e
    );
    setValues({
      ...values,
      requisition_files: tempRequisitionFiles,
    });
  };
  return (
    <Form>
      <Col lg="12" className="mb-2 mt-0">
        <Form.Floating className="custom-form-floating">
          <textarea
            className="form-control"
            placeholder="Leave a comment here"
            id="floatingTextarea2"
            name="input"
            onChange={(e) => {
              setValues({ ...values, input: e.target.value });
            }}
          ></textarea>
          <Form.Label htmlFor="fname">Write your comments</Form.Label>
        </Form.Floating>
      </Col>
      <Col lg="12" className="mb-2">
        <h6 className="mb-2 mt-3 fw-normal">Add Reference Files</h6>
        <Form.Control
          type="file"
          id="customFile"
          multiple
          name="requisition_files"
          onChange={handleChangeReferenceFiles}
        />
        <div className="d-flex flex-wrap flex-row gap-3 mt-3">
          {values.requisition_files?.map((file, _idx) => (
            <div className="position-relative" key={_idx + 1}>
              <img
                className="rounded img-fluid avatar-120"
                src={app_config.fileStoragePath + file}
                alt="profile"
                loading="lazy"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = article_1;
                }}
              />
              <span
                className="position-absolute top-0 end-0"
                onClick={() => onCrossImageClick(_idx)}
              >
                {findIcon("Square X", "solid", 20)}
              </span>
            </div>
          ))}
        </div>
      </Col>
      <Col lg="12" className="d-flex gap-3">
        <Button
          type="button"
          variant="warning"
          onClick={() => submitReview(STATUS_CONSTANTS.REJECTED)}
          className="w-100 py-3 px-1 mt-2 fs-6 rounded-3"
        >
          Reject
        </Button>
        <SubmitButton
          type="button"
          variant="primary"
          onClick={() => submitReview(STATUS_CONSTANTS.COMPLETED)}
          className="w-100 py-3 px-1 mt-2 fs-6 fw-semibold rounded-3"
        >
          {actionType == "requisition-review" ? "Submit Review" : "Approve"}
        </SubmitButton>
      </Col>
    </Form>
  );
};
const RequisitionReviewApproval = ({
  actionType,
  actionEditData,
  actionId,
}) => {
  const [loading, setLoading] = useState(false);
  console.log("actionEditData", actionEditData);
  return (
    <>
      {actionType == "requisition-review" &&
        (!loading ? (
          <>
            <Row className="mb-3">
              <div className="col-12 col-md-8">
                <Card className="p-3 mb-3">
                  <HeroSection
                    requisitionTitle={
                      actionEditData?.origin?.requisition_title ?? ""
                    }
                    requisitionDescription={
                      actionEditData?.origin?.requisition_description ?? ""
                    }
                    createdBy={actionEditData?.action_assign_by?.name ?? ""}
                    category={
                      actionEditData?.action_type_id?.action_type_name ?? ""
                    }
                    vendor={actionEditData?.vendor_id?.name ?? ""}
                    deliveryDate={
                      getFormatedDate(actionEditData?.action_due_date) ?? ""
                    }
                    priority={
                      actionEditData?.action_priority_id?.priority_name ?? ""
                    }
                    createdOn={
                      getFormatedDate(actionEditData?.created_at) ?? ""
                    }
                  />
                </Card>
                <SimilarContents
                  title={actionEditData?.origin?.requisition_title ?? ""}
                  description={
                    actionEditData?.origin?.requisition_description ?? ""
                  }
                />
              </div>
              <div className="col-12 col-md-4 bg-white p-3 rounded-3">
                <h5 className="mb-3">Reviews and Comments</h5>
                <Col lg="12 border border-1 rounded-2 mt-3 p-3">
                  <AddApprovalForm
                    actionType={actionType}
                    actionId={actionId}
                  />
                </Col>
              </div>
            </Row>
          </>
        ) : (
          <RequisitionReviewApprovalLoader />
        ))}

      {actionType == "requisition-approval" &&
        (!loading ? (
          <Row className="mb-3">
            <div className="col-12 col-md-8">
              <Card className="p-3 mb-3">
                <HeroSection
                  requisitionTitle={
                    actionEditData?.origin?.requisition_title ?? ""
                  }
                  requisitionDescription={
                    actionEditData?.origin?.requisition_description ?? ""
                  }
                  createdBy={actionEditData?.action_assign_by?.name ?? ""}
                  category={
                    actionEditData?.action_type_id?.action_type_name ?? ""
                  }
                  vendor={actionEditData?.vendor_id?.name ?? ""}
                  deliveryDate={
                    getFormatedDate(actionEditData?.action_due_date) ?? ""
                  }
                  priority={
                    actionEditData?.action_priority_id?.priority_name ?? ""
                  }
                  createdOn={getFormatedDate(actionEditData?.created_at) ?? ""}
                />
              </Card>
              <SimilarContents
                title={actionEditData?.origin?.requisition_title ?? ""}
                description={
                  actionEditData?.origin?.requisition_description ?? ""
                }
              />
            </div>
            <div className="col-12 col-md-4 bg-white p-3 rounded-3">
              <Reviews comments={actionEditData?.comments ?? []} />
              <Col lg="12 border border-1 rounded-2 mt-3 p-3">
                <AddApprovalForm actionType={actionType} actionId={actionId} />
              </Col>
            </div>
          </Row>
        ) : (
          <RequisitionReviewApprovalLoader />
        ))}

      {actionType == "content-approval" && (
        <ContentReview
          actionId={actionId}
          actionEditData={actionEditData}
          isApproval
        />
      )}
      {actionType == "content-review" && (
        <ContentReview actionId={actionId} actionEditData={actionEditData} />
      )}
    </>
  );
};

export default React.memo(RequisitionReviewApproval);

function SimilarContents({ title, description }) {
  const [content, setContent] = useState([]);
  const [loading, setLoading] = useState(true);
  const fetchSimilarContents = async () => {
    let response = await searchService("cipla_requisition", {
      requisition: title,
      description: description,
    });
    console.log("search_response", response);
    if (response.status == 200) {
      let output = response?.data?.output || null;
      let toProceedLoop =
        output &&
        typeof output == "object" &&
        Array.isArray(output) &&
        output?.length > 0;
      if (toProceedLoop) {
        setContent(output);
        setLoading(false);
      }
    }
  };
  useEffect(() => {
    if (title && description) {
      fetchSimilarContents();
    }
  }, []);
  if (!title || !description) {
    return null;
  }
  return (
    <Card className="p-3 mb-0">
      <h5 className="mb-3 pt-3 ps-3">Please check similar content below</h5>
      <Card.Body>
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {!loading
            ? content.map((item) => {
                return (
                  <div
                    className="d-flex align-items-center mb-5"
                    key={item?.content_id}
                  >
                    <img
                      className="rounded img-fluid avatar-40 me-3 bg-soft-primary px-2 py-2"
                      src={findIconByFileCode(item?.content_file_type)}
                      alt="profile"
                      loading="lazy"
                    />
                    <h6 className="m-0 text-truncate">
                      {item.content_file_name}
                    </h6>
                  </div>
                );
              })
            : [1, 2, 3, 4, 5, 6].map((_) => (
                <div className="d-flex align-items-center mb-5" key={_}>
                  <img
                    className="rounded img-fluid avatar-40 me-3 bg-soft-primary px-2 py-2"
                    src={findIconByFileCode("pdf")}
                    alt="profile"
                    loading="lazy"
                  />
                  <h6 className="m-0 text-truncate">
                    <Skeleton variant="text" width={100} height={20} />
                  </h6>
                </div>
              ))}
        </Masonry>
      </Card.Body>
    </Card>
  );
}

import React, {
  useState,
  memo,
  Fragment,
  useCallback,
  useEffect,
  useRef,
} from "react";

import {
  Button,
  Card,
  Col,
  Form,
  FormCheck,
  Row,
  Badge,
  Modal,
} from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
// import Card from "../../../../components/bootstrap/card";
import { findIcon } from "../../../dashboard/icons";
import ReactTable from "../../../../components/table/react-table";
import { getService } from "../../../../api/services/get-services";
import {
  EDIT_ACTION_GET,
  FILE_TYPE_SUBMISSION,
  MY_ACTIONS_POST,
  UPDATE_ACTION_POST,
} from "../../../../api/endpoints/action-endpoints";
import {
  changeTableStateToPayload,
  getFormatedDate,
} from "../../../../common/utils/utils";
import postService from "../../../../api/services/post-service";
import toast from "react-hot-toast";
import { STATUS_CONSTANTS } from "../constants/action-constants";
import useRedirectToAction from "../hooks/use-redirect-to-action";
import withAuthorization from "../../../../components/hoc/with-authorization";
import RequisitionAcceptanceLoader from "../../../../skeleton/action/requisition-acceptance-loader";
import ReactTableLoader from "../../../../skeleton/react-table-loader/react-table-loader";
import SubmitButton from "../../../../components/form-components/submit-button";
import useBackdrop from "../../../../components/custom-hooks/use-backdrop";
import { fileTypes } from "../../master-management/file-type-management/constants/file-type-icons";

const RequisitionAcceptance = () => {
  const redirectToAction = useRedirectToAction();
  const details = (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="icon-22"
      width="32"
      height="32"
      viewBox="0 0 24 24"
    >
      <path
        d="M12.1415 6.5899C11.9075 6.71614 11.7616 6.95618 11.7616 7.21726V11.2827H3.73429C3.32896 11.2827 3 11.604 3 12C3 12.3959 3.32896 12.7172 3.73429 12.7172H11.7616V16.7827C11.7616 17.0447 11.9075 17.2848 12.1415 17.4101C12.3755 17.5372 12.6614 17.5286 12.8875 17.39L20.6573 12.6073C20.8708 12.4753 21 12.2467 21 12C21 11.7532 20.8708 11.5247 20.6573 11.3927L12.8875 6.60998C12.7681 6.5373 12.632 6.5 12.4959 6.5C12.3745 6.5 12.2521 6.5306 12.1415 6.5899Z"
        fill="currentColor"
      ></path>
    </svg>
  );
  const [fileTypeInputs, setFileTypeInputs] = useState([
    {
      id: "file_type_0",
      file_type: "",
    },
  ]);
  const [acceptRequest, setAcceptRequest] = useState(false);
  const [submissionListing, setSubmissionListing] = useState(false);
  const [submissionModal, setSubmissionModal] = useState(false);
  const [showPlusBtn, setShowPlusBtn] = useState(false);
  const [actionEditData, setActionEditData] = useState({});
  const [loading, setLoading] = useState(true);
  const [myActionLoading, setMyActionLoading] = useState(false);
  const { actionId } = useParams();
  const [myActions, setMyActions] = useState([]);
  const [myActionsCount, setMyActionsCount] = useState(0);
  const my_actions_post_json = React.useRef({
    default: "all",
    pagination: {
      offset: 0,
      limit: 10,
    },
    sort: {
      action_id: "desc",
    },
    filter: {
      action_parent_id: {
        type: "eq",
        value: [actionId],
      },
    },
  });
  const COLUMNS = [
    {
      Header: "Action ID",
      accessor: "action_id",
    },
    {
      Header: "Action Name",
      accessor: "action_name",
    },
    {
      Header: "Action Priority",
      accessor: "action_priority_id",
      Cell: ({ value }) => {
        console.log("action_priority_value", value);
        //return a bootstrap badge according to priority if priority is 1 then danger else if 2 then warning else if 3 then primary
        let bg = "primary";
        if (value?.priority_id == 1) {
          bg = "danger";
        } else if (value?.priority_id == 2) {
          bg = "warning";
        } else if (value?.priority_id == 3) {
          bg = "primary";
        }
        return <Badge bg={bg}>{value.priority_name}</Badge>;
      },
    },
    {
      Header: "Action Assign By",
      accessor: "action_assign_by",
      Cell: ({ value }) => {
        return value?.name ?? "";
      },
    },
    {
      Header: "Created At",
      accessor: "created_at",
      Cell: ({ value }) => {
        return getFormatedDate(value);
      },
    },
    {
      Header: "Action Type",
      accessor: "action_type_id",
      Cell: ({ value }) => {
        return value?.action_type_name ?? "";
      },
    },
    {
      Header: "Action",
      Cell: ({ row }) => {
        return (
          <div className="d-flex justify-content-center">
            <Button
              className="btn btn-icon btn-sm rounded-pill"
              to="#"
              role="button"
              onClick={() => {
                const { action_type_id, action_id, action_to_filetypes } =
                  row.original;
                redirectToAction(action_type_id?.action_type_code, action_id, {
                  ...action_to_filetypes,
                });
              }}
            >
              <span className="btn-inner">{details}</span>
            </Button>
          </div>
        );
      },
    },
  ];
  const fetchMyActions = async () => {
    let response = await postService(
      MY_ACTIONS_POST,
      my_actions_post_json.current
    );
    console.log("MY_ACTIONS", response.data.data.rows);
    if (response.data?.data?.rows?.length > 0) {
      setMyActions(response.data.data.rows);
      setMyActionsCount(response.data.data.totalCount);
      setMyActionLoading(false);
    }
  };
  const onTableStateChange = useCallback(
    (state) => {
      console.log("state", state);
      my_actions_post_json.current = {
        ...my_actions_post_json.current,
        ...changeTableStateToPayload(state),
      };
      // setMyActionLoading(true);
      fetchMyActions();
    },
    [my_actions_post_json]
  );

  const HeroSection = ({
    requisitionTitle,
    requisitionDescription,
    createdBy,
    category,
    vendor,
    deliveryDate,
    priority,
    createdOn,
  }) => {
    return (
      <Fragment>
        <h5 className="mb-3 pt-3 ps-3">Title: {requisitionTitle}</h5>
        <p className="px-3">
          <strong>Description:</strong>
          {requisitionDescription}
        </p>
        <div className="d-flex flex-row flex-wrap px-3 mt-4">
          <span className="rounded-3 border border-1 px-3 py-2 me-3 mb-3 fs-7">
            Created By: <strong>{createdBy}</strong>
          </span>
          <span className="rounded-3 border border-1 px-3 py-2 me-3 mb-3 fs-7">
            Category: <strong>{category}</strong>
          </span>
          <span className="rounded-3 border border-1 px-3 py-2 me-3 mb-3 fs-7">
            Vendor: <strong>{vendor}</strong>
          </span>
          <span className="rounded-3 border bg-soft-primary px-3 py-2 me-3 mb-3 fs-7">
            Exp Delivery Date : <strong>{deliveryDate}</strong>
          </span>
          <span className="rounded-3 bg-soft-warning border px-3 py-2 me-3 mb-3 fs-7">
            Priority :{" "}
            <strong className="bg-soft-warning px-2 py-1">{priority}</strong>
          </span>
          <span className="rounded-3 bg-soft-info border px-3 py-2 me-3 mb-3 fs-7">
            Created : <strong>{createdOn}</strong>
          </span>
        </div>
        {!acceptRequest && (
          <div className="text-end">
            <span className="justify-content-end">
              <b>Status:</b> Pending Submission
            </span>
          </div>
        )}
      </Fragment>
    );
  };
  const { showBackdrop, hideBackdrop } = useBackdrop();
  const handleAcceptRequest = async (status) => {
    showBackdrop();
    let response = await postService(UPDATE_ACTION_POST + actionId, {
      status,
    });
    if (response.isError) {
      toast.error(response.error);
      return;
    }
    // console.log("response", response.data?.success);
    if (response.data.success) {
      hideBackdrop();
      if (status == STATUS_CONSTANTS.REJECTED) {
        toast.success("Request Rejected Successfully");
        setAcceptRequest(false);
      } else {
        toast.success("Request Accepted Successfully");
        setAcceptRequest(true);
        setShowPlusBtn(true);
      }
    }
  };

  const handleCreateSubmission = async () => {
    //call file type submission api
    showBackdrop();
    let response = await postService(FILE_TYPE_SUBMISSION, {
      action_id: actionId,
      filetype_submission: fileTypeInputs.map((_) => {
        return { file_type_id: _.file_type };
      }),
    });
    if (response.isError) {
      toast.error(response.error);
      return;
    }
    // console.log("response", response.data?.success);
    if (response.data.success) {
      setMyActionLoading(true);
      fetchMyActions();
      toast.success("Submission Created Successfully");
      setSubmissionModal(false);
      setSubmissionListing(true);
      setShowPlusBtn(false);
      hideBackdrop();
    }
  };
  const handlePlusClick = () => {
    setSubmissionModal(true);
  };

  const fetchActionEdit = async () => {
    let response = await getService(EDIT_ACTION_GET + actionId);
    console.log("action_edit_response", response);
    setActionEditData(response.data.data);
    setLoading(false);
  };
  useEffect(() => {
    fetchActionEdit();
    // fetchMyActions();
  }, []);
  const onAddMoreClick = () => {
    setFileTypeInputs((prevState) => [
      ...prevState,
      {
        id: `file_type_${prevState.length}`,
        file_type: "",
      },
    ]);
  };
  const handleFileTypeChange = (e, i) => {
    console.log("e", e.target.value);
    let temp = [...fileTypeInputs];
    temp[i].file_type = e.target.value;
    setFileTypeInputs(temp);
  };
  const onDeleteClick = (i) => {
    let temp = [...fileTypeInputs];
    temp.splice(i, 1);
    setFileTypeInputs(temp);
  };

  return (
    <Fragment>
      <Row>
        {showPlusBtn && (
          <div
            className="position-absolute z-10 bg-primary rounded-circle w-fit-content p-3 text-white fw-semibold floating-action"
            onClick={() => handlePlusClick()}
          >
            {findIcon("Plus", "dual-tone", 42)}
          </div>
        )}
        {!loading ? (
          <>
            {!acceptRequest && (
              <Col>
                <Card>
                  <div className="card-header mb-4">
                    <h4 className="card-title">Requisition Acceptance</h4>
                  </div>
                </Card>
              </Col>
            )}
            <Card className="p-3 mb-3">
              <HeroSection
                requisitionTitle={
                  actionEditData?.origin?.requisition_title ?? ""
                }
                requisitionDescription={
                  actionEditData?.origin?.requisition_description ?? ""
                }
                createdBy={actionEditData?.action_assign_by?.name ?? ""}
                category={
                  actionEditData?.action_type_id?.action_type_name ?? ""
                }
                vendor={actionEditData?.vendor?.name ?? ""}
                deliveryDate={
                  getFormatedDate(actionEditData?.action_due_date) ?? ""
                }
                priority={
                  actionEditData?.action_priority_id?.priority_name ?? ""
                }
                createdOn={getFormatedDate(actionEditData?.created_at) ?? ""}
              />
            </Card>

            {!acceptRequest && (
              <div className="d-flex gap-2 gap-md-3 justify-content-end flex-wrap flex-md-nowrap my-3 mx-3">
                <Button
                  type="button"
                  variant="btn btn-primary flex-md-grow-0 flex-grow-1 text-nowrap"
                  onClick={() =>
                    handleAcceptRequest(STATUS_CONSTANTS.COMPLETED)
                  }
                >
                  Accept Request
                </Button>

                <SubmitButton
                  type="button"
                  variant="btn btn-light flex-md-grow-0 flex-grow-1 text-nowrap"
                  onClick={() => handleAcceptRequest(STATUS_CONSTANTS.REJECTED)}
                >
                  Reject Request
                </SubmitButton>
              </div>
            )}

            {acceptRequest && (
              <Modal
                show={submissionModal}
                // onHide={(!submissionModal)}
                onHide={() => {
                  setSubmissionModal(false);
                }}
                size="lg"
              >
                <Modal.Header
                  closeButton
                  // onClick={setSubmissionModal(false)}
                >
                  <h3 className="fs-4">Create Submission Task</h3>
                </Modal.Header>
                <Modal.Body className="text-start ">
                  <Col lg="12" className="mb-4 mt-4">
                    {fileTypeInputs.map((val, i) => {
                      return (
                        <Row
                          className="d-flex justify-content-center"
                          key={i + 1}
                        >
                          <Col lg="9" className="mb-3">
                            <div
                              className={`text-secondary border border-1 "border-light" p-3 rounded-3  w-100 h-100 d-flex flex-row gap-2`}
                            >
                              <Form.Floating className="custom-form-floating flex-grow-1 w-auto">
                                <Form.Select
                                  id={`file_type_${i}`}
                                  name="file_type"
                                  onChange={(e) => handleFileTypeChange(e, i)}
                                >
                                  <option value="">Select File Type</option>
                                  {fileTypes?.map((item) => {
                                    return (
                                      <option value={item.file_type_id}>
                                        {item.file_type_name}
                                      </option>
                                    );
                                  })}
                                </Form.Select>

                                <Form.Label htmlFor="floatingInput">
                                  Select Type
                                </Form.Label>
                              </Form.Floating>

                              <Button
                                variant="danger"
                                className="px-3"
                                id={`file_type_${i}`}
                                onClick={() => onDeleteClick(i)}
                              >
                                {findIcon("Trash", "dual-tone", 20)}
                              </Button>
                            </div>
                          </Col>

                          <Col
                            lg="3"
                            className="mb-2 d-flex align-items-center justify-content-start"
                            onClick={onAddMoreClick}
                          >
                            {i == 0 && (
                              <Button
                                variant="outline-primary"
                                className="rounded-3  d-flex align-items-center justify-content-center gap-2"
                              >
                                {findIcon("Plus", "dual-tone", 20)}
                                Add More
                              </Button>
                            )}
                          </Col>
                        </Row>
                      );
                    })}
                    <div className="d-flex gap-2 gap-md-3 justify-content-center flex-wrap flex-md-nowrap">
                      <SubmitButton
                        type="button"
                        variant="btn btn-primary flex-md-grow-0 flex-grow-1 text-nowrap"
                        onClick={() => handleCreateSubmission()}
                      >
                        Create Submission
                      </SubmitButton>
                    </div>
                  </Col>
                </Modal.Body>
              </Modal>
            )}
          </>
        ) : (
          <RequisitionAcceptanceLoader />
        )}

        {submissionListing && (
          <Col sm="12">
            <Card>
              <Card.Header className="d-flex justify-content-between">
                <div className="header-title">
                  <h4 className="card-title">Submissions</h4>
                </div>
              </Card.Header>
              <Card.Body className="px-0">
                {!myActionLoading ? (
                  myActions?.length > 0 && (
                    <ReactTable
                      data={myActions}
                      columns={COLUMNS}
                      onTableStateChange={onTableStateChange}
                      recordsTotal={myActionsCount}
                      initialState={my_actions_post_json.current}
                      isRowSelectionAvailable={false}
                      recordsPerPage={10}
                    />
                  )
                ) : (
                  <ReactTableLoader />
                )}
              </Card.Body>
            </Card>
          </Col>
        )}
      </Row>
    </Fragment>
  );
};

export default withAuthorization(memo(RequisitionAcceptance));

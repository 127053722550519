// https://asia-south1-clirnet-dev.cloudfunctions.net/

import axios from "axios";

const mlService = async (endpoint, data) => {
  let response = await axios.post(
    "https://asia-south1-clirnet-dev.cloudfunctions.net/" + endpoint,
    data
  );

  if (response) {
    console.log("response", response);
    return response;
  }
};
export default mlService;

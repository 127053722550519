import React, {
  Fragment,
  useRef,
  useState,
  useCallback,
  useEffect,
  memo,
} from "react";
import { Button, Card, Col, Form, Row, Modal, Badge } from "react-bootstrap";
import { findIcon } from "../../../../dashboard/icons";
import ReactTable from "../../../../../components/table/react-table";
import postService from "../../../../../api/services/post-service";
import {
  ADD_DEPARTMENT,
  ALL_DEPARTMENTS,
  DELETE_DEPARTMENT,
  UPDATE_DEPARTMENT,
  DEPARTMENT_BY_ID,
} from "../../../../../api/endpoints/department-endpoints";
import ViewModal from "../../../../../components/partials/common/view-modal";
import DeleteModal from "../../../../../components/partials/common/delete-modal";
import { getService } from "../../../../../api/services/get-services";
import {
  changeTableStateToPayload,
  statusToBg,
} from "../../../../../common/utils/utils";
import * as yup from "yup";
import { useFormik } from "formik";
import ActionButtonBar from "../../../../../components/partials/common/action-button-bar";
import toast from "react-hot-toast";
import FloatingButton from "../../../../../components/form-components/floating-button";
import withAuthorization from "../../../../../components/hoc/with-authorization";
import ReactTableLoader from "../../../../../skeleton/react-table-loader/react-table-loader";

const AllDepartments = () => {
  const [createModalShow, setCreateModalShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [departmentList, setDepartmentList] = useState([]);
  const [viewModalShow, setViewModalShow] = useState(false);
  const [viewDepartment, setViewDepartment] = useState({});
  const [totalCount, setTotalCount] = useState(0);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const post_json = useRef({
    default: "all",
    pagination: {
      limit: 10,
      offset: 0,
    },
    sort: {
      department_id: "desc",
    },
  });
  //yup and formik for validation of form fields of department_name

  const validationSchema = yup.object().shape({
    department_name: yup
      .string()
      .trim()
      .required("Department Name is required"),
    department_description: yup
      .string()
      .trim()
      .required("Department Description is required"),
  });
  //formik
  const formik = useFormik({
    initialValues: {
      department_name: "",
      department_description: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const postValues = {
        department_name: values.department_name.trim(),
        department_description: values.department_description.trim(),
      };

      try {
        setLoading(true);
        const response = await postService(
          isEdit
            ? UPDATE_DEPARTMENT + "/" + selectedDepartment
            : ADD_DEPARTMENT,
          {
            ...postValues,
            status: isEdit ? values.status : 1,
          }
        );
        if (response) {
          setLoading(false);
          setCreateModalShow(false);
          fetchDepartments(post_json.current);
          isEdit && setIsEdit(false);
          isEdit && setSelectedDepartment(null);
          toast.success(response?.data?.message);
          // isEdit
          //   ? toast.success("Department Updated")
          //   : toast.success("Department Added");
        }
      } catch (error) {
        setLoading(false);
        console.log("DEPARTMENT_SERVICE_ERROR", error);
      }
    },
  });
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setValues,
    resetForm,
  } = formik;
  //fetch departments

  console.log(values);
  const fetchDepartments = async (json) => {
    try {
      // setLoading(true);
      const response = await postService(ALL_DEPARTMENTS, json);
      console.log("DEPARTMENT_SERVICE_RESPONSE", response.data.data.rows);
      console.log("COLUMNS", Object.keys(response.data.data.rows[0]));
      if (response.data.data.rows.length > 0) {
        setDepartmentList(response.data.data.rows);
        setTotalCount(response.data.data.totalCount);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log("DEPARTMENT_SERVICE_ERROR", error);
    }
  };
  const viewModal = async (department) => {
    const { department_id, department_name, department_description, status } =
      department;
    let dataJson = {
      "Department ID": department_id,
      "Department Name": department_name,
      "Department Status": status.value,
      "Department Description": department_description,
    };
    setViewDepartment(dataJson);
    setViewModalShow(true);
  };
  const viewModalClose = () => {
    setViewModalShow(false);
  };
  const deleteDepartment = async () => {
    try {
      setLoading(true);
      const response = await getService(
        `${DELETE_DEPARTMENT}/${selectedDepartment}`
      );
      if (response) {
        setLoading(false);
        setDeleteModalShow(false);
        fetchDepartments(post_json.current);
        toast.success(response?.data?.message);
      }
    } catch (error) {
      setLoading(false);
      console.log("DEPARTMENT_SERVICE_ERROR", error);
    }
  };
  useEffect(() => {
    setLoading(true);
    fetchDepartments(post_json.current);
  }, []);
  const onTableStateChange = useCallback(
    async (state) => {
      let payload = changeTableStateToPayload(state);
      post_json.current = payload;
      fetchDepartments(payload);
    },
    [departmentList, loading, post_json.current]
  );
  const onDeleteDepartment = (department) => {
    // alert("deleting" + department.department_id);
    setSelectedDepartment(department.department_id);
    setDeleteModalShow(true);
  };
  const columns = [
    {
      Header: "Department ID",
      accessor: "department_id",
    },
    {
      Header: "Department Name",
      accessor: "department_name",
    },
    {
      Header: "Department Description",
      accessor: "department_description",
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ value }) => (
        <Badge bg={statusToBg(value.id)} className="me-2">
          <span className="btn-inner">{value.value}</span>
        </Badge>
      ),
    },
    {
      Header: "Created By",
      accessor: "created_by",
      Cell: ({ value }) => value.name,
    },
    {
      Header: "Action",
      accessor: "action",
      Cell: ({ row }) => (
        <ActionButtonBar
          row={row}
          onView={(data) => viewModal(data)}
          onEdit={(data) => onEditDepartmentClick(data)}
          onDelete={(data) => onDeleteDepartment(data)}
        />
      ),
    },
  ];

  const handleCreate = () => {
    resetForm();
    setCreateModalShow(true);
  };
  const onEditDepartmentClick = (department) => {
    console.log("department===>", department);
    setIsEdit(true);
    setSelectedDepartment(department.department_id);
    //set formik values
    setValues({
      department_name: department.department_name,
      department_description: department.department_description,
      status: department.status.id,
    });
    setCreateModalShow(true);
  };

  return (
    <Fragment>
      <FloatingButton onClick={handleCreate} />
      <Row>
        <Col sm="12">
          <Card>
            <Card.Header className="d-flex justify-content-between align-items-center">
              <h4 className="card-title">All Departments</h4>
              {/* <Button
                type="button"
                variant="primary"
                className="fw-bold rounded-3"
                onClick={() => handleCreate()}
              >
                {findIcon("Plus", "dual-tone", 32)} Add Department
              </Button> */}
            </Card.Header>
            <Card.Body className="px-3">
              {!loading ? (
                departmentList.length > 0 && (
                  <ReactTable
                    data={departmentList}
                    columns={columns}
                    recordsTotal={totalCount}
                    onTableStateChange={onTableStateChange}
                    initialState={post_json.current}
                    isRowSelectionAvailable={false}
                    recordsPerPage={10}
                  />
                )
              ) : (
                <ReactTableLoader />
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <DeleteModal
        show={deleteModalShow}
        modalClose={() => setDeleteModalShow(false)}
        onSubmit={deleteDepartment}
      />
      <ViewModal
        title={"View Department Information"}
        show={viewModalShow}
        modalClose={viewModalClose}
        data={viewDepartment}
      />
      {/* create a add Department modal with Department name and description */}
      <Modal
        show={createModalShow}
        onHide={() => {
          setCreateModalShow(false);
          setIsEdit(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{isEdit ? "Edit" : "Add"} Department</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>
                Department Name <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Department Name"
                name="department_name"
                onChange={handleChange}
                value={values.department_name}
                onBlur={handleBlur}
                isInvalid={touched.department_name && errors.department_name}
              />
              <Form.Control.Feedback type="invalid">
                {errors.department_name}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>
                Department Description <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Department Description"
                name="department_description"
                onChange={handleChange}
                value={values.department_description}
                onBlur={handleBlur}
                isInvalid={
                  touched.department_description &&
                  errors.department_description
                }
              />
              <Form.Control.Feedback type="invalid">
                {errors.department_description}
              </Form.Control.Feedback>
            </Form.Group>
            {isEdit && (
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>
                  Speciality Status <span className="text-danger">*</span>
                </Form.Label>
                <Form.Select
                  name="status"
                  value={values?.status}
                  isInvalid={touched.status && errors.status}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option value="">Select Status</option>
                  <option value="1">Enabled</option>
                  <option value="2">Disabled</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors?.status}
                </Form.Control.Feedback>
              </Form.Group>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setCreateModalShow(false);
              setIsEdit(false);
            }}
          >
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            {isEdit ? "Update" : "Add Department"}
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default withAuthorization(memo(AllDepartments));

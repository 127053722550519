import { Badge, Button, Image } from "react-bootstrap";
import { findIcon } from "../../dashboard/icons";
import * as dayjs from "dayjs";
import img1 from "../../../assets/modules/file-manager/images/8.png";

export const spqData = [
  {
    id: 1,
    survey_title: "Treatment for Breast Fibroadenomas	",
    image: img1,
    survey_point: 100,
    survey_time: "10 minutes",
    question_count: 20,
    published_date: "2023-01-01",
    added_by: "Sumit Mandal",
    modify_by: "Indranil Kundu",
    client: "Clirnet",
    category: "survey",
    status: "Active",
  },
  {
    id: 1,
    survey_title: "Treatment of Gastric Cancer",
    image: img1,
    survey_point: 70,
    survey_time: "5 minutes",
    question_count: 9,
    published_date: "2023-01-04",
    added_by: "Sumit Mandal",
    modify_by: "Indranil Kundu",
    client: "Clirnet",
    category: "poll",
    status: "Active",
  },
  {
    id: 1,
    survey_title: "Surgical Treatment of Gastric Cancer",
    image: img1,
    survey_point: 90,
    survey_time: "9 minutes",
    question_count: 5,
    published_date: "2023-01-06",
    added_by: "Sumit Mandal",
    modify_by: "Indranil Kundu",
    client: "Clirnet",
    category: "quiz",
    status: "Active",
  },
  // Add more data objects here...
];

// ID	Title	Date Of Publication	Client Name	Added	Added By	Modified By	Status	Actions

export const spqColumns = [
  {
    Header: "ID",
    accessor: "id",
  },
  {
    Header: "Survey Title",
    accessor: "survey_title",
  },
  {
    Header: "Image",
    accessor: "image",
    Cell: ({ value }) => (
      <Image src={value} className="img-fluid avatar-120" alt="" />
    ),
  },
  {
    Header: "Survey Point",
    accessor: "survey_point",
  },
  {
    Header: "Survey Time",
    accessor: "survey_time",
  },
  {
    Header: "Question Count",
    accessor: "question_count",
  },
  {
    Header: "Publishing Date",
    accessor: "published_date",
  },
  {
    Header: "Added By",
    accessor: "added_by",
  },
  {
    Header: "Modified By",
    accessor: "modify_by",
  },
  {
    Header: "Client",
    accessor: "client",
  },
  // {
  //   Header: 'Category',
  //   accessor: 'Category'
  // },
  {
    Header: "Status",
    accessor: "status",
    Cell: ({ value }) => <Badge bg="primary">{value}</Badge>,
  },
  {
    Header: "Actions",
    Cell: ({ row }) => (
      <div className="d-flex justify-content-center align-items-center gap">
        <div className="d-flex justify-content-center gap-2">
          <Button
            className="btn btn-icon btn-sm rounded-pill"
            to="#"
            role="button"
            // onClick={() => editShortLink(row.original)}
          >
            <span className="btn-inner">
              {findIcon("Pencil", "dual-tone", 32)}
            </span>
          </Button>
          <Button
            className="btn btn-icon btn-sm rounded-pill"
            to="#"
            role="button"
            // onClick={() =>
            // //   redirectTo(`short-link/details/${row.original.url_key.url_key}`)
            // }
          >
            <span className="btn-inner">
              {findIcon("Arrow Right", "dual-tone", 32)}
            </span>
          </Button>
        </div>
      </div>
    ),
  },
];

import React from "react";

// layout

import Default from "../../../../layouts/dashboard/default";
import ManageCreative from "../pages/campaign-group";
import AllCampaignList from "../pages/brand-management";
import AdCampaign from "../pages/brand-report";
import CampaignGroup from "../pages/campaign-group";
import BrandReport from "../pages/brand-report";
import BrandManagement from "../pages/brand-management";


// pages

export const ClientCampaignRouter = [
  {
    path: "/client-campaign",
    element: <Default />,
    children: [
      {
        path: "brand-report",
        element: <BrandReport />,
      },
      {
        path: "brand-management",
        element: <BrandManagement />,
      },
      {
        path: "group",
        element: <CampaignGroup />,
      },
    ],
  },
];

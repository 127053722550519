import React, { memo } from 'react';
import withAuthorization from '../../../../components/hoc/with-authorization';

const ImageGenerator = () => {
  return (
    <>
      ImageGenerator
    </>
  );
}

export default withAuthorization(memo(ImageGenerator));

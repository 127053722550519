import React from "react";
import { Card } from "react-bootstrap";
import ReactTable from "../../../../components/table/react-table";
import { channelColumns, channelData } from "../demo-data";
import FloatingButton from "../../../../components/form-components/floating-button";
import useRedirect from "../../../../components/custom-hooks/use-redirect";

const Subscription = () => {
  const { redirectTo } = useRedirect();
  return (
    <>
      <FloatingButton onClick={() => redirectTo("/channel/create")} />
      <div className="d-flex justify-content-between align-items-center flex-wrap mb-4 gap-3">
        <div className="d-flex flex-column">
          <h3 className="m-0">Subscription Listing</h3>
        </div>
        <div className="d-flex justify-content-between align-items-center rounded flex-wrap gap-3"></div>
      </div>
      <Card>
        <Card.Body>
          <ReactTable
            data={channelData}
            columns={channelColumns}
            recordsTotal={channelData.length}
            // onTableStateChange={onTableStateChange}
            // initialState={post_json.current}
            // recordsPerPage={10}
          />
        </Card.Body>
      </Card>
    </>
  );
};

export default Subscription;

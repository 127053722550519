import React from "react";

// layout

import Default from "../../../../layouts/dashboard/default";
import Tags from "../pages/tags";


// pages

export const TagsRouter = [
  {
    path: "/content-tags",
    element: <Default />,
    children: [
      {
        path: "/content-tags",
        element: <Tags />,
      },
    ],
  },
];

import React from "react";
import { Card, Button, Table } from "react-bootstrap";

const GrDetails = () => {
  const grandRoundData = [
    { label: "Grand Round Title", value: "test grand round" },
    { label: "Grand Round Video Title", value: "test grand round video" },
    { label: "Grand Round Type", value: "test grand round type" },
    { label: "Publication Date", value: "test publication date" },
    { label: "Chief Scientific Editor", value: "test chief scientific editor" },
    { label: "Description", value: "test description" },
    { label: "Notes", value: "test notes" },
    { label: "Privacy Status", value: "test privacy status" },
    {
      label: "Grand Round Preview Image",
      value: "test grand round preview image",
    },
    { label: "Clients", value: "test clients" },
    { label: "Tags", value: "test tags" },
    { label: "Template ID", value: "test template id" },
    { label: "Color Palette", value: "test color palette" },
    { label: "Grand Round Q&A Status", value: "test grand round q&a status" },
    { label: "Grand Round Live Status", value: "test grand round live status" },
    {
      label: "Grand Round Live Video Source",
      value: "test grand round live video source",
    },
    { label: "Grand Round Start Date", value: "test grand round start date" },
    { label: "Grand Round End Date", value: "test grand round end date" },

    { label: "Environment", value: "test environment" },
    { label: "Association Status", value: "test association status" },
    // { label: "Association Setting", value: "test association setting" },
  ];

  return (
    <div>
      <h3 className="mb-4">Grand Round Details</h3>
      <Card>
        <Card.Body>
          <Table striped bordered responsive>
            <tbody>
              {grandRoundData.map((dataItem, index) => (
                <tr key={index}>
                  <td>{dataItem.label}</td>
                  <td>{dataItem.value}</td>
                </tr>
              ))}
            </tbody>
          </Table>

          {/* Render the session doctors list */}
          <h4>Session Doctors</h4>
          <Table striped bordered responsive>
            <tbody>
              <tr>
                <th>Session Doctor ID</th>
                <th>Session Doctor Description</th>
              </tr>
              {/* Iterate over the session doctors list and render each item */}
              <tr>
                <td>...</td>
                <td>...</td>
              </tr>
            </tbody>
          </Table>

          {/* Render the attachments list */}
          <h4>Attachments</h4>
          <Table striped bordered responsive>
            <tbody>
              <tr>
                <th>Title</th>
                <th>Description</th>
                <th>Type</th>
                <th>URL</th>
              </tr>
              {/* Iterate over the attachments list and render each item */}
              <tr>
                <td>...</td>
                <td>...</td>
                <td>...</td>
                <td>...</td>
              </tr>
            </tbody>
          </Table>

          {/* Render the SPQ list */}
          <h4>SPQs</h4>
          <Table striped bordered responsive>
            <tbody>
              <tr>
                <th>SPQ ID</th>
                <th>Duration</th>
                <th>Skip</th>
              </tr>
              {/* Iterate over the SPQs list and render each item */}
              <tr>
                <td>...</td>
                <td>...</td>
                <td>...</td>
              </tr>
            </tbody>
          </Table>

          {/* Render any additional details you may have */}

          {/* Render the Medwiki list */}
          <h4>Medwiki</h4>
          <Table striped bordered responsive>
            <tbody>
              <tr>
                <th>Medwiki ID</th>
                <th>Medwiki Title</th>
                <th>Medwiki Type</th>
                <th>Medwiki Description</th>
                <th>Medwiki URL</th>
              </tr>
              {/* Iterate over the Medwiki list and render each item */}
              <tr>
                <td>...</td>
                <td>...</td>
                <td>...</td>
                <td>...</td>
                <td>...</td>
              </tr>
            </tbody>
          </Table>

          {/* Render the Archived List list */}
          <h4>Archived List</h4>
          <Table striped bordered responsive>
            <tbody>
              <tr>
                <th>Archived List ID</th>
                <th>Archived List Title</th>
                <th>Archived List Type</th>
                <th>Archived List Description</th>
                <th>Archived List URL</th>
              </tr>
              {/* Iterate over the Archived List list and render each item */}
              <tr>
                <td>...</td>
                <td>...</td>
                <td>...</td>
                <td>...</td>
                <td>...</td>
              </tr>
            </tbody>
          </Table>

          {/* Button Section */}
          <div className="d-flex justify-content-end mt-4">
            <Button className="me-2" variant="secondary">
              Save
            </Button>
            <Button variant="primary">Update</Button>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default GrDetails;

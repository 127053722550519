import React from "react";

// layout

import Default from "../../../../layouts/dashboard/default";
import ViewCloud from "../pages/view-cloud";
import ViewCloudDetails from "../pages/view-cloud-details";

// pages

export const CreatorRecommendationRouter = [
  {
    path: "/creator-recommendation",
    element: <Default />,
    children: [
      {
        path: "view",
        element: <ViewCloud />,
      },
      {
        path: "view/:keyword",
        element: <ViewCloudDetails />,
      },
    ],
  },
];

import React from "react";

// layout

import Default from "../../../../layouts/dashboard/default";
import PageGenerator from "../pages/page-generator";
import ImageGenerator from "../pages/image-generator";
import EmailGenerator from "../pages/email-generator";


// pages

export const ToolsRouter = [
  {
    path: "/tools",
    element: <Default />,
    children: [
      {
        path: "page-generator",
        element: <PageGenerator />,
      },
      {
        path: "image-generator",
        element: <ImageGenerator />,
      },
      {
        path: "email-generator",
        element: <EmailGenerator />,
      },
    ],
  },
];

import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import ContentDetailsCardLoader from "../common/content-details-card-loader";
import Card from "../../components/bootstrap/card";
import { Skeleton } from "@mui/material";

const AddArticleLoader = () => {
  return (
    <>
      <Row>
        <Col lg={6}>
          <ContentDetailsCardLoader />
          {/* card for article meta */}
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <Skeleton variant="text" width={100} height={40} />
              </div>
            </Card.Header>
            <Card.Body>
              <Col lg="12" className="mb-4">
                <Skeleton
                  variant="rounded"
                  width="100%"
                  height={45}
                  className="mb-4"
                />
              </Col>
            </Card.Body>
          </Card>
        </Col>

        <Col lg={6}>
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <Skeleton variant="text" width={100} height={40} />
              </div>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col lg="12" className="mb-4">
                  <Skeleton variant="rounded" width="100%" height={150} />
                </Col>
                <Col lg="12" className="mb-4">
                  <Skeleton variant="rounded" width="100%" height={150} />
                </Col>
                <Col lg="12" className="mb-3">
                  <Skeleton
                    variant="text"
                    width={100}
                    height={20}
                    className="mb-2"
                  />
                  <Skeleton variant="rounded" width="100%" height={100} />
                </Col>
                <Col lg="12" className="mb-3">
                  <Skeleton
                    variant="text"
                    width={100}
                    height={20}
                    className="mb-2"
                  />
                  <Skeleton variant="rounded" width="100%" height={100} />
                </Col>
                <Col lg="12" className="mb-3">
                  <Skeleton
                    variant="text"
                    width={100}
                    height={20}
                    className="mb-2"
                  />
                  <Skeleton variant="rounded" width="100%" height={100} />
                </Col>
                <Col lg="12" className="mb-3">
                  <Skeleton
                    variant="text"
                    width={100}
                    height={20}
                    className="mb-2"
                  />
                  <Skeleton variant="rounded" width="100%" height={45} />
                </Col>
              </Row>

              <div className="d-flex gap-2 gap-md-3 justify-content-end flex-wrap flex-md-nowrap">
                <Skeleton variant="rounded" width="20%" height={40} />

                <Skeleton variant="rounded" width="20%" height={40} />
                <Skeleton variant="rounded" width="20%" height={40} />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default AddArticleLoader;

import useRedirect from "../../../../components/custom-hooks/use-redirect";

const useRedirectToAction = () => {
  const { redirectTo } = useRedirect();
  const redirectToAction = (action_type = "", action_id, other = {}) => {
    let { action_file_type_id, requisition_id = "" } = other;
    // alert(
    //   action_type + " " + action_id + " " + action_file_type_id?.file_type_code
    // );
    switch (action_type) {
      case "requisition_edit":
        redirectTo("requisition/edit/" + (requisition_id || 0));
        break;
      case "requisition_review":
        redirectTo(`action/details/requisition-review/${action_id}`);
        break;
      case "requisition_approval":
        redirectTo(`action/details/requisition-approval/${action_id}`);
        break;

      case "requisition_acceptance":
        redirectTo(`action/requisition-acceptance/${action_id}`);
        break;
      case "content_create":
        redirectTo(
          `action/details/${action_file_type_id?.file_type_code}/${action_id}`
        );
        break;
      case "content_review":
        redirectTo(`action/details/content-review/${action_id}`);
        break;
      case "content_approval":
        redirectTo(`action/details/content-approval/${action_id}`);
        break;

      case "content_publish":
        redirectTo(`action/post-approval/${action_id}`);
        break;
      default:
        break;
    }
  };
  return redirectToAction;
};

export default useRedirectToAction;

import { Badge, Button } from "react-bootstrap";
import { findIcon } from "../../dashboard/icons";
import * as dayjs from "dayjs";

export const channelData = [
  {
    id: 123,
    title: "Clinical Protocol",
    publication_date: "2023-06-22",
    client_name: "CLIRNET",
    added: "2023-06-22 17:31:03",
    added_by: "Sumit Mandol",
    modified_by: "Indranil Kundu",
    status: "Pending",
  },
  // Add more data as needed
];
// ID	Title	Date Of Publication	Client Name	Added	Added By	Modified By	Status	Actions

export const channelColumns = [
  {
    Header: "ID",
    accessor: "id",
  },
  {
    Header: "Title",
    accessor: "title",
  },
  {
    Header: "Date Of Publication",
    accessor: "publication_date",
    Cell: ({ values }) => dayjs(values).format("DD MMM YYYY"),
  },
  {
    Header: "Client Name",
    accessor: "client_name",
  },
  {
    Header: "Added",
    accessor: "added",
    Cell: ({ values }) => dayjs(values).format("DD MMM YYYY"),
  },
  {
    Header: "Added By",
    accessor: "added_by",
  },
  {
    Header: "Modified By",
    accessor: "modified_by",
  },
  {
    Header: "Status",
    accessor: "status",
    Cell: ({ value }) => (
        <Badge
          bg='primary'
        >
          {value}
        </Badge>
      ),
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: ({ row }) => (
      <div className="d-flex justify-content-center align-items-center gap">
        <div className="d-flex justify-content-center gap-2">
          <Button
            className="btn btn-icon btn-sm rounded-pill"
            to="#"
            role="button"
            // onClick={() => editShortLink(row.original)}
          >
            <span className="btn-inner">
              {findIcon("Pencil", "dual-tone", 32)}
            </span>
          </Button>
          <Button
            className="btn btn-icon btn-sm rounded-pill"
            to="#"
            role="button"
            // onClick={() =>
            // //   redirectTo(`short-link/details/${row.original.url_key.url_key}`)
            // }
          >
            <span className="btn-inner">
              {findIcon("Arrow Right", "dual-tone", 32)}
            </span>
          </Button>
        </div>
      </div>
    ),
  },
];

import React, { useEffect } from "react";
import { VERIFY_GOOGLE_LOGIN } from "../../../../api/endpoints/auth-endpoints";
import { getService } from "../../../../api/services/get-services";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";
import { updateStorage } from "../../../../utilities/setting";
import { storages } from "../../../../common/utils/utils";
import { useDispatch } from "react-redux";
import useRedirect from "../../../../components/custom-hooks/use-redirect";
import { loginSuccess } from "../../../../store/auth/actions";
import { Spinner } from "react-bootstrap";

const SocialHandler = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { redirectTo } = useRedirect();
  const handleGoogleUrlVerification = async (payload) => {
    let response = await getService(VERIFY_GOOGLE_LOGIN, payload);
    if (response.isError) {
      toast.error(response?.error);
    } else {
      console.log("google login response", response?.data);
      let access_token = response?.data?.access_token ?? "";
      if (!access_token) return;
      updateStorage(storages.local, "access_token", access_token);
      //sync the values to store
      dispatch(
        loginSuccess({
          user: response?.data?.user ?? {},
          userRoles: response.data?.userRoles ?? [],
          token: access_token,
          isAuthenticated: true,
        })
      );

      redirectTo("dashboard");
    }
  };
  //function to take search params as parameter and return an object
  const getSearchParams = (search) => {
    let params = new URLSearchParams(search);
    let paramsObj = Object.fromEntries(params.entries());
    return paramsObj;
  };

  useEffect(() => {
    let searchParams = getSearchParams(location.search);
    let payload = {
      ...searchParams,
      redirect_url:
        window.location.origin +
        process.env.REACT_APP_SUB_PATH +
        "login/callback/google",
    };
    handleGoogleUrlVerification(payload);
  }, []);

  return (
    <div
      className="d-flex justify-content-center align-items-center flex-column"
      style={{ height: "100vh" }}
    >
      <Spinner animation="border" variant="primary" />
      {/* add a loading text */}
      <span className="mt-3 d-block fs-3 text-black">Please Wait...</span>
    </div>
  );
};

export default SocialHandler;

import React, { Fragment, memo } from "react";
import ContentDetails from "../../../../components/partials/common/content-details";

import {
  Button,
  Card,
  Col,
  Form,
  FormCheck,
  Row,
  Accordion,
  Image,
  Badge,
} from "react-bootstrap";

import img1 from "../../../../assets/images/hover-effects/09.jpg";
import img2 from "../../../../assets/images/img1.jpeg";
import img3 from "../../../../assets/images/img2.jpg";
import img4 from "../../../../assets/images/img3.jpeg";

import { findIcon } from "../../../dashboard/icons";
import withAuthorization from "../../../../components/hoc/with-authorization";

const ActionResubmit = () => {
  return (
    <Fragment>
      <Col>
        <Row className="mb-3">
          <div className="col-12 col-md-8">
            <Card className="mb-0">
              <h5 className="mb-3 pt-3 ps-3">Title: Natural Image Creation</h5>
              <p className="m-0 px-3">
                <strong>Description:</strong> Heart failure (HF) affects roughly
                26 million individuals globally and is a leading cause of
                cardiomyopathy-related death worldwide. Fortunately,
                understanding of the pathophysiology of this disease has
                advanced drastically in the last 30 years, leading to the
                development of disease-modifying medications, which can
                significantly improve the prognosis among HF patients.{" "}
              </p>
              {/* <div className="d-flex gap-2 px-3 pb-0">
                            <div className="p-2 border border-1 rounded-3 border-primary bg-soft-primary">
                                <Image src={img1} className="bg-soft-primary rounded img-fluid avatar-90" alt="profile-image" />
                                <span className="d-block fs-7 mt-1">12th Feb 2023</span>
                            </div>
                            <div className="p-2 border border-1 rounded-3">
                                <Image src={img2} className="bg-soft-primary rounded img-fluid avatar-90" alt="profile-image" />
                                <span className="d-block fs-7 mt-1">10th Feb 2023</span>
                            </div>
                            <div className="p-2 border border-1 rounded-3">
                                <Image src={img3} className="bg-soft-primary rounded img-fluid avatar-90" alt="profile-image" />
                                <span className="d-block fs-7 mt-1">8th Feb 2023</span>
                            </div>
                            <div className="p-2 border border-1 rounded-3">
                                <Image src={img4} className="bg-soft-primary rounded img-fluid avatar-90" alt="profile-image" />
                                <span className="d-block fs-7 mt-1">5th Feb 2023</span>
                            </div>
                        </div> */}
              <div className="mt-3 p-3 border-top border-bottom rounded-0">
                <Image
                  src={img1}
                  className="img-fluid rounded effect-1"
                  alt=""
                />
              </div>
              <div className="m-3">
                <Accordion defaultActiveKey="0" className="custom-accordion">
                  <Accordion.Item eventKey="1" className="bg-white">
                    <Accordion.Header>
                      <div className="d-flex flex-column">
                        <span className="fw-semibold">Old Files</span>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body className="bg-white text-black border border-1 rounded-3 px-0">
                      <div className="d-flex gap-2 px-3 pb-0">
                        <div className="p-2 border border-1 rounded-3 border-primary bg-soft-primary">
                          <Image
                            src={img1}
                            className="bg-soft-primary rounded img-fluid avatar-90"
                            alt="profile-image"
                          />
                          <span className="d-block fs-7 mt-1">
                            12th Feb 2023
                          </span>
                        </div>
                        <div className="p-2 border border-1 rounded-3">
                          <Image
                            src={img2}
                            className="bg-soft-primary rounded img-fluid avatar-90"
                            alt="profile-image"
                          />
                          <span className="d-block fs-7 mt-1">
                            10th Feb 2023
                          </span>
                        </div>
                        <div className="p-2 border border-1 rounded-3">
                          <Image
                            src={img3}
                            className="bg-soft-primary rounded img-fluid avatar-90"
                            alt="profile-image"
                          />
                          <span className="d-block fs-7 mt-1">
                            8th Feb 2023
                          </span>
                        </div>
                        <div className="p-2 border border-1 rounded-3">
                          <Image
                            src={img4}
                            className="bg-soft-primary rounded img-fluid avatar-90"
                            alt="profile-image"
                          />
                          <span className="d-block fs-7 mt-1">
                            5th Feb 2023
                          </span>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </Card>
          </div>
          <div className="col-12 col-md-4 bg-white p-3 rounded-3">
            <div className="reviews">
              <div className="d-flex flex-column justify-content-between shadow-sm p-3 mb-3 rounded-3">
                <div className="d-flex flex-row justify-content-between mb-3">
                  <div className="d-flex flex-column">
                    <span>Tapas kumar Roy</span>
                    <span className="fs-7">12 Mar 2023</span>
                  </div>
                  <div className="d-flex flex-row gap-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      class="bi bi-check2 text-primary"
                      viewBox="0 0 16 16"
                    >
                      <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-x-lg text-black"
                      viewBox="0 0 16 16"
                    >
                      <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                    </svg>
                  </div>
                </div>
                <div>
                  "Heart failure (HF) affects roughly 26 million individuals
                  globally and is a leading cause"
                </div>
              </div>
              <div className="d-flex flex-column justify-content-between shadow-sm p-3 mb-3 rounded-3">
                <div className="d-flex flex-row justify-content-between mb-3">
                  <div className="d-flex flex-column">
                    <span>Rabindran Kumar Jain</span>
                    <span className="fs-7">16 Mar 2023</span>
                  </div>
                  <div className="d-flex flex-row gap-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      class="bi bi-check2 text-primary"
                      viewBox="0 0 16 16"
                    >
                      <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-x-lg text-black"
                      viewBox="0 0 16 16"
                    >
                      <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                    </svg>
                  </div>
                </div>
                <div>
                  "A leading cause of cardiomyopathy-related death worldwide.
                  Fortunately"
                </div>
              </div>
              <div className="d-flex flex-column justify-content-between shadow-sm p-3 mb-3 rounded-3">
                <div className="d-flex flex-row justify-content-between mb-3">
                  <div className="d-flex flex-column">
                    <span>Subhodip Das</span>
                    <span className="fs-7">18 Mar 2023</span>
                  </div>
                  <div className="d-flex flex-row gap-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      class="bi bi-check2 text-primary"
                      viewBox="0 0 16 16"
                    >
                      <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-x-lg text-black"
                      viewBox="0 0 16 16"
                    >
                      <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                    </svg>
                  </div>
                </div>
                <div>
                  "26 million individuals globally and is a
                  cardiomyopathy-related death worldwide."
                </div>
              </div>
            </div>
            <h5 className="mb-3">Reviews and Comments</h5>
            <Col lg="12 border border-1 rounded-2 mt-3 p-3">
              <Form>
                <Col lg="12" className="mb-2 mt-0">
                  <Form.Floating className="custom-form-floating">
                    <textarea
                      class="form-control"
                      placeholder="Leave a comment here"
                      id="floatingTextarea2"
                    ></textarea>
                    <Form.Label htmlFor="fname">Write your comments</Form.Label>
                  </Form.Floating>
                </Col>
                <Col lg="12" className="mb-2">
                  <h6 className="mb-2 mt-3 fw-normal">Add Reference Files</h6>
                  <Form.Control type="file" id="customFile" />
                </Col>
                <Col lg="12" className="mb-2">
                  <h6 className="mb-2 mt-3 fw-normal">Upload Submission</h6>
                  <Form.Control type="file" id="customFile" />
                </Col>
                <Button
                  type="button"
                  variant="primary"
                  className="w-100 py-3 mt-2 fs-5 rounded-3"
                >
                  Submit Updated File
                </Button>
              </Form>
            </Col>
          </div>
        </Row>
      </Col>
    </Fragment>
  );
};

export default withAuthorization(memo(ActionResubmit));

import React, { memo, useState } from "react";
import Card from "../../../../components/bootstrap/card";
import { Accordion, Button, Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import ReactQuill from "../../../../components/partials/common/react-quill";
import { findIcon } from "../../../dashboard/icons";

const CourseDetailFields = () => {
  const [addSessionDoctorList, setAddSessionDoctorList] = useState([
    { id: 1, session_doctor_id: "", session_doctor_description: "" },
  ]);
  const [isEdit, setIsEdit] = useState(false);
  const [courseName, setCourseName] = useState("Course Name 1");
  const courseNameRef = React.useRef();
  // ADD SESSION DOCTOR FUNCTIONS

  const addSessionDoctor = () =>
    setAddSessionDoctorList([
      ...addSessionDoctorList,
      {
        id: addSessionDoctorList.length + 1,
        session_doctor_id: "",
        session_doctor_description: "",
      },
    ]);
  const deleteSessionDoctor = (id) =>
    setAddSessionDoctorList(
      addSessionDoctorList.filter((_session_doc) => _session_doc.id != id)
    );
  const specialities = [
    { value: "Diabetology - (parent)-1", label: "Diabetology - (parent)-1" },
    {
      value: "----- Diabetology-Medicine-108",
      label: "----- Diabetology-Medicine-108",
    },
    {
      value: ".............. Diabetology-Medicine-T1DM-1006t",
      label: ".............. Diabetology-Medicine-T1DM-1006",
    },
    {
      value: ".............. Diabetology-Medicine-T2DM-1008",
      label: ".............. Diabetology-Medicine-T2DM-1008",
    },
    {
      value: ".............. Diabetology-Medicine-Mood disorder-1029",
      label: ".............. Diabetology-Medicine-Mood disorder-1029",
    },
    // Add more options here...
  ];
  const handleEdit = () => {
    setIsEdit(!isEdit);
    setTimeout(() => {
      if (courseNameRef?.current) {
        courseNameRef.current.focus();
      }
    }, 100);
  };
  return (
    <>
      <div className="d-flex justify-content-start align-items-baseline">
        <Form.Control
          type="text"
          className={`bg-transparent border-0 fs-3 w-auto ${
            isEdit ? "" : "fw-bold"
          }`}
          value={courseName}
          ref={courseNameRef}
          disabled={!isEdit}
          onChange={(e) => setCourseName(e.target.value)}
        />
        <span
          className="text-primary cursor-pointer ms-2"
          onClick={() => handleEdit()}
        >
          {findIcon(isEdit ? "Square Tick" : "Pencil", "solid", "24")}
        </span>
      </div>
      <Card>
        <Card.Body>
          <>
            <Row>
              {/* <---------------- SPECIALITIES INPUT SECTION --------------------> */}

              <Col lg="12" className="d-flex flex-column">
                <h5 className="mb-3">
                  Specialities<span className="text-danger">*</span>
                </h5>
                <Select
                  name="select_speciality"
                  className="mb-3"
                  options={specialities}
                  placeholder="Select Advance Search Options"
                  isMulti
                />
              </Col>

              {/* <---------------- DESCRIPTION INPUT SECTION --------------------> */}

              <Col lg="12" className="d-flex flex-column mb-4">
                <h5 className="mb-3">
                  Description <span className="text-danger">*</span>
                </h5>
                <ReactQuill />
              </Col>

              {/* <---------------- PRIVACY STATUS INPUT SECTION --------------------> */}

              <Col lg="6" className="d-flex flex-column mt-4">
                <h5 className="mb-3">
                  Privacy Status<span className="text-danger">*</span>
                </h5>
                <Form.Floating className="custom-form-floating mb-3">
                  <Form.Select
                    type="name"
                    placeholder="Privacy Status"
                    name="privacy_status"
                  >
                    <option value="" selected hidden>
                      Please Select Privacy Status
                    </option>
                    <option value="Public">Public</option>
                    <option value="Private">Private</option>
                    <option value="Unlisted">Unlisted</option>
                  </Form.Select>
                </Form.Floating>
              </Col>

              {/* <---------------- FEATURED INPUT SECTION --------------------> */}

              <Col lg="6" className="d-flex flex-column mt-4">
                <h5 className="mb-3">
                  Featured<span className="text-danger">*</span>
                </h5>
                <Form.Floating className="custom-form-floating mb-3">
                  <Form.Select
                    type="name"
                    placeholder="Featured"
                    name="featured"
                  >
                    <option value="" selected hidden>
                      Please Select Concern
                    </option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </Form.Select>
                </Form.Floating>
              </Col>

              {/* <---------------- DURATION INPUT SECTION --------------------> */}

              <Col lg="6" className="d-flex flex-column">
                <h5 className="mb-3">
                  Duration (In Hours) <span className="text-danger">*</span>
                </h5>
                <Form.Floating className="custom-form-floating">
                  <Form.Control
                    type="time"
                    className=""
                    autoComplete="duration"
                    placeholder="duration"
                  />
                  <label htmlFor="floatingInput">Please Select Duration</label>
                </Form.Floating>
              </Col>

              {/* <---------------- CLIENT INPUT SECTION --------------------> */}

              <Col lg="6" className="d-flex flex-column">
                <h5 className="mb-3">Client</h5>
                <Form.Floating className="custom-form-floating mb-3">
                  <Form.Select type="name" placeholder="Client" name="client">
                    <option value="" selected hidden>
                      Please Select Client
                    </option>
                    <option value="yes">Clirnet</option>
                    <option value="no">Cipla</option>
                    <option value="no">Doctube</option>
                  </Form.Select>
                </Form.Floating>
              </Col>

              {/* <---------------- CHANNEL INPUT SECTION --------------------> */}

              <Col lg="6" className="d-flex flex-column">
                <h5 className="mb-3">Channel</h5>
                <Form.Floating className="custom-form-floating mb-3">
                  <Form.Select type="name" placeholder="Channel" name="channel">
                    <option value="" selected hidden>
                      Please Select Channel
                    </option>
                    <option value="This is test channel by Crocin">
                      This is test channel by Crocin
                    </option>
                    <option value="This is brand channel Rozat">
                      This is brand channel Rozat
                    </option>
                    <option value="Indian Society for Study of Pain Andhra Pradesh ">
                      Indian Society for Study of Pain Andhra Pradesh{" "}
                    </option>
                  </Form.Select>
                </Form.Floating>
              </Col>

              {/* <---------------- SPONSOR INPUT SECTION --------------------> */}

              <Col lg="6" className="d-flex flex-column">
                <h5 className="mb-3">Sponsors</h5>
                <Select
                  name="select_speciality"
                  className="mb-3"
                  options={specialities}
                  placeholder="Select Sponsors"
                  isMulti
                />
              </Col>

              {/* <---------------- PUBLISHING DATE INPUT SECTION --------------------> */}

              <Col lg="6" className="d-flex flex-column">
                <h5 className="mb-3">
                  Publishing Date<span className="text-danger">*</span>
                </h5>
                <Form.Floating className="custom-form-floating  mb-3">
                  <Form.Control
                    type="datetime-local"
                    className=""
                    autoComplete="Publishing Date"
                    placeholder="publishing_date"
                  />
                  <label htmlFor="floatingInput">
                    Please Enter Publishing Date
                  </label>
                </Form.Floating>
              </Col>

              {/* <---------------- FEATURED VIDEO URL INPUT SECTION --------------------> */}

              <Col lg="6" className="d-flex flex-column">
                <h5 className="mb-3">{"Featured Video (URL)"}</h5>
                <Form.Floating className="custom-form-floating mb-3">
                  <Form.Control
                    type="name"
                    placeholder="Featured Video"
                    name="featured_video"
                  />
                  <Form.Label>Please Enter Featured Video</Form.Label>
                </Form.Floating>
              </Col>

              {/* <---------------- FEATURED IMAGE INPUT SECTION --------------------> */}

              <Col lg="6" className="d-flex flex-column">
                <h5 className="mb-3">
                  {"Featured Image * (width >= 1726 and height >= 840)"}
                  <span className="text-danger">*</span>
                </h5>
                <Form.Floating className="custom-form-floating mb-3">
                  <Form.Control
                    type="file"
                    placeholder="Featured Image"
                    name="featured_image"
                  />
                </Form.Floating>
              </Col>

              {/* <---------------- CERTIFICATE INPUT SECTION --------------------> */}

              <Col lg="6" className="d-flex flex-column">
                <h5 className="mb-3">
                  Certificate<span className="text-danger">*</span>
                </h5>
                <Form.Floating className="custom-form-floating mb-3">
                  <Form.Select
                    type="name"
                    placeholder="Certificate"
                    name="certificate"
                  >
                    <option value="" selected hidden>
                      Please Select Certificate
                    </option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </Form.Select>
                </Form.Floating>
              </Col>
            </Row>
            {addSessionDoctorList.map((_session_doc, idx) => (
              <Row key={_session_doc.id}>
                <Col className="d-flex flex-column">
                  <h5 className="mb-3">Session Doctor ID</h5>
                  <Form.Floating className="custom-form-floating mb-3">
                    <Form.Control
                      type="name"
                      placeholder="session_doctor_id"
                      name="session_doctor_id"
                    />
                    <Form.Label>Please Enter Session Doctor ID</Form.Label>
                  </Form.Floating>
                </Col>
                <Col className="d-flex flex-column">
                  <h5 className="mb-3">Session Doctor Description</h5>
                  <Form.Floating className="custom-form-floating mb-3">
                    <Form.Control
                      type="name"
                      placeholder="session_doctor_description"
                      name="session_doctor_description"
                    />
                    <Form.Label>
                      Please Enter Session Doctor Description
                    </Form.Label>
                  </Form.Floating>
                </Col>
                <Col
                  lg="2"
                  className="d-flex justify-content-center align-items-center gap-2"
                >
                  {addSessionDoctorList.length - 1 == idx && (
                    <Button
                      variant="success"
                      className="p-0 icon-32 rounded-circle d-flex align-items-center justify-content-center"
                      type="submit"
                      onClick={addSessionDoctor}
                    >
                      {findIcon("Plus", "outline", "20")}
                    </Button>
                  )}
                  {addSessionDoctorList.length != 1 && (
                    <Button
                      variant="danger"
                      className="p-0 icon-32 rounded-circle d-flex align-items-center justify-content-center"
                      type="submit"
                      onClick={() => deleteSessionDoctor(_session_doc.id)}
                    >
                      {findIcon("Trash", "outline", "20")}
                    </Button>
                  )}
                </Col>
              </Row>
            ))}
          </>
        </Card.Body>
      </Card>
      <div className="d-flex justify-content-end align-items-center gap-2">
        <Button className="" variant="secondary">
          Save as Draft
        </Button>
        <Button className="" variant="success">
          Add Modules Here
        </Button>
      </div>{" "}
    </>
  );
};

export default memo(CourseDetailFields);

import React from "react";
import { Col, Row } from "react-bootstrap";
import Card from "../../components/bootstrap/card";
import { Skeleton } from "@mui/material";

const RequisitionAcceptanceLoader = () => {
  return (
    <>
      <Row>
        <Col>
          <Card>
            <div className="card-header mb-4">
              <Skeleton variant="text" width={200} height={30} />
            </div>
          </Card>
        </Col>
        <Card className="p-3 mb-3">
          <h5 className="d-flex justify-content-start align-items-center gap-2 mb-3 pt-3">
            <Skeleton variant="text" width={50} height={20} />
            <Skeleton variant="text" width={200} height={20} />
          </h5>
          <div className="d-flex justify-content-start align-items-center gap-2">
            <Skeleton variant="text" width={100} height={15} />
            <Skeleton variant="text" width="100%" height={15} />
          </div>
          <Skeleton variant="text" width="100%" height={15} />
          <Skeleton variant="text" width="70%" height={15} />

          <div className="d-flex flex-row flex-wrap px-3 mt-4 gap-3">
            <span className="rounded-3 border border-1 d-flex px-3 py-2 justify-content-start gap-2">
              <Skeleton variant="text" width={50} height={20} />
              <Skeleton variant="text" width={100} height={20} />
            </span>
            <span className="rounded-3 border border-1 px-3 py-2 d-flex justify-content-start gap-2">
              <Skeleton variant="text" width={40} height={20} />
              <Skeleton variant="text" width={110} height={20} />
            </span>
            <span className="rounded-3 border border-1 px-3 py-2 d-flex justify-content-start gap-2">
              <Skeleton variant="text" width={50} height={20} />
              <Skeleton variant="text" width={80} height={20} />
            </span>
            <span className="rounded-3 border bg-soft-primary px-3 py-2 d-flex justify-content-start gap-2">
              <Skeleton variant="text" width={60} height={20} />
              <Skeleton variant="text" width={50} height={20} />
            </span>
            <span className="rounded-3 bg-soft-warning border px-3 py-2 d-flex justify-content-start gap-2">
              <Skeleton variant="text" width={40} height={20} />
              <Skeleton variant="text" width={40} height={20} />
            </span>
            <span className="rounded-3 bg-soft-info border px-3 py-2 d-flex justify-content-start gap-2">
              <Skeleton variant="text" width={40} height={20} />
              <Skeleton variant="text" width={40} height={20} />
            </span>
          </div>
          <div className="text-end">
            <span className="d-flex justify-content-end gap-2">
              <Skeleton variant="text" width={40} height={20} />
              <Skeleton variant="text" width={100} height={20} />
            </span>
          </div>
        </Card>

        <div className="d-flex gap-2 gap-md-3 justify-content-end flex-wrap flex-md-nowrap my-3 mx-3">
          <Skeleton variant="rounded" width={120} height={40} />
          <Skeleton variant="rounded" width={120} height={40} />
        </div>
      </Row>
    </>
  );
};

export default RequisitionAcceptanceLoader;

import React, { memo, useEffect, useMemo, useState } from "react";
// import Card from "../../../../components/bootstrap/card";
import { Row, Col } from "react-bootstrap";
import TreeView, { flattenTree } from "react-accessible-treeview";
import cx from "classnames";
// import { findIcon } from "../../../dashboard/icons";
// import ReactTable from "../../../../components/table/react-table";
// import { PRINICIPLE_ENTITIES_GET } from "../../../../api/endpoints/content-endpoints";
// import postService from "../../../../api/services/post-service";
import { Button, Modal } from "react-bootstrap";

// import postService from "../../../api/services/post-service";
import Flatpickr from "react-flatpickr";
import Select from "react-select";

import Masonry from "react-masonry-css";
import Card from "../../../../components/bootstrap/card";
import { findIcon } from "../../../dashboard/icons";
import withAuthorization from "../../../../components/hoc/with-authorization";
import searchService from "../../../../api/services/search-service";
import ContentCard from "../../content-library/components/content-card";
import FilePreviewModal from "../../../../components/partials/common/file-preview-modal";
import ContentCardLoader from "../../../../skeleton/common/content-card-loader";
const breakpointColumnsObj = {
  default: 4,
  1100: 3,
  768: 2,
  500: 1,
};

const Search = ({ modalClose, contentList }) => {
  const [folder, setFolder] = useState(null); //state for principle entities
  const [keyword, SetKeyword] = useState("");
  const [content, setContent] = useState();
  const [noContent, setNoContent] = useState(false);
  const [item, setItem] = useState({});
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  function formatJSON(data, parentId = 0) {
    const result = [];
    const children = data.filter(
      (entity) => entity.principal_entity_parent_id === parentId
    );

    children.forEach((child) => {
      const node = {
        name: child.principal_entity_name,
        children: formatJSON(data, child.principal_entity_id),
      };
      result.push(node);
    });

    return result;
  }

  const fetchPrincipleEntites = async () => {
    // const response = await postService(PRINICIPLE_ENTITIES_GET, {
    //   default: "all",
    //   pagination: {
    //     limit: 50,
    //     offset: 0,
    //   },
    // });
    // console.log("response", response?.data?.data?.rows);
    // const formattedJSON = formatJSON(response?.data?.data?.rows);
    // setFolder(flattenTree(formattedJSON[0]));
    // console.log("formatted", formattedJSON);
    // console.log("FLATTEN_TREE", {
    //   name: "",
    //   children: flattenTree(formattedJSON[0]),
    // });
  };
  useEffect(() => {
    // fetchPrincipleEntites();

    return () => {};
  }, []);

  const ArrowIcon = ({ isOpen, className }) => {
    const baseClass = "arrow";
    const classes = cx(
      baseClass,
      { [`${baseClass}--closed`]: !isOpen },
      { [`${baseClass}--open`]: isOpen },
      className
    );
    return (
      <>
        <span className="folder-icon position-absolute">
          {findIcon("Folder", "dual-tone", "18")}
        </span>
        <span className="down-arrow-icon position-absolute transition">
          {findIcon("Chevron Down", "outline", "18")}
        </span>
      </>
    );
  };
  const brandOptions = [
    { value: "brand 1", label: "Brand 1" },
    { value: "brand 2", label: "Brand 2" },
  ];
  const fileTypeOptions = [
    { value: "Nevada", label: "Gujarat Medical Council (GJMC)" },
    { value: "Oregon", label: "Delhi Medical Council (DMC)" },
    { value: "Washington", label: "Madhya Pradesh Medical Council (MPMC)" },
    { value: "Colorada", label: "Chattisgarh Medical Council (CGMC)" },
    { value: "Idaho", label: "Bihar Medical Council (BMC)" },
  ];
  const tagOptions = [
    { value: "Nevada", label: "India" },
    { value: "Oregon", label: "China" },
    { value: "Washington", label: "Indonesia" },
    { value: "Colorada", label: "Iceland" },
    { value: "Idaho", label: "Hong Kong" },
  ];

  const fetchSearch = async (keyword) => {
    setLoading(true);

    let response = await searchService("cipla_requisition", {
      requisition: keyword,
      description: keyword,
    });
    if (response.status == 200) {
      let output = response?.data?.output || null;
      let toProceedLoop =
        output &&
        typeof output == "object" &&
        Array.isArray(output) &&
        output?.length > 0;
      if (toProceedLoop) {
        setContent(output);
        setNoContent(false);
        setLoading(false);
      }
    } else {
      setNoContent(true);
      setLoading(false);
    }
  };

  const onEnterClick = (e) => {
    // alert(e.target.value);
  };
  return (
    <>
      <div className="">
        <Card>
          <Card.Body>
            <Row>
              <Col lg={12} className="mb-4">
                <div className="form-group input-group  search-input mb-0 w-auto">
                  <input
                    type="search"
                    className="form-control"
                    placeholder="Search..."
                    onKeyDown={onEnterClick}
                    onChange={(e) => SetKeyword(e.target.value)}
                    value={keyword}
                  />
                  <span
                    className="input-group-text cursorPointer"
                    onClick={() => fetchSearch(keyword)}
                  >
                    <svg
                      width="18"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="11.7669"
                        cy="11.7666"
                        r="8.98856"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></circle>
                      <path
                        d="M18.0186 18.4851L21.5426 22"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                    </svg>
                  </span>
                  <span
                    className="input-group-text cursorPointer"
                    onClick={() => {
                      setLoading(false);
                      setContent([]);
                      setNoContent(false);
                      SetKeyword("");
                    }}
                  >
                    {findIcon("Refresh", "outline", 20)}
                  </span>
                </div>
              </Col>
              <Col lg={12}>
                <h6 className="mb-3">Advance Search</h6>
              </Col>
              <Col>
                <Select
                  className="react-select-customize"
                  options={brandOptions}
                  placeholder="Brand"
                />
              </Col>
              <Col>
                <Select
                  className="react-select-customize"
                  options={tagOptions}
                  placeholder="Tag"
                />
              </Col>
              <Col>
                <Select
                  className="react-select-customize"
                  options={fileTypeOptions}
                  placeholder="File Type"
                />
              </Col>
              <Col>
                <div className="form-group mb-0 ">
                  <Flatpickr
                    options={{ mode: "range", minDate: "today" }}
                    className="form-control range_flatpicker"
                    placeholder="Upload date"
                  />
                </div>
              </Col>
            </Row>{" "}
            <Row>
              <Col lg={12} className="mt-5"></Col>
            </Row>
          </Card.Body>
        </Card>

        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {/* {contentList?.length > 0 && contentList.map((i) => i)} */}
          {!noContent &&
            !loading &&
            content &&
            content.map((item, index) => (
              <ContentCard
                key={`content-${index}`}
                id={item.content_id}
                src={item?.image?.content_thumnail_url}
                date={item.added_on}
                title={item.content_file_name}
                citation={item?.citation}
                titleHtml={item?.content_file_name}
                coverImage={item?.coverImage}
                descriptionHtml={item?.content_file_name}
                description={item?.content_file_name}
                brand={item.content_brand_id?.principal_entity_name}
                icon="https://storage.googleapis.com/cipla-cms-dev/icons/file_type/pdf.svg"
                onClickPreview={() => {
                  setShow(true);
                  setItem({
                    name: item?.content_file_name,
                    url: item?.content_thumnail_url,
                  });
                }}
                file={item?.file_url}
                // filePreviewModal={
                //   showFilePreview == item.content_id
                // }
                type={
                  item?.content_file_type
                    ? item?.content_file_type?.content_file_type
                    : ""
                }
                onViewModalClose={() => {
                  setShow(false);
                  setItem({});
                }}
                createdBy={item?.created_by?.name}
              />
            ))}
          {noContent && !loading && <h4>No result found for your search!</h4>}
          {loading && [1, 2, 3, 4, 5, 6, 7, 8].map(() => <ContentCardLoader />)}
        </Masonry>

        {show && (
          <FilePreviewModal
            createdOn={new Date()}
            mediaType="pdf"
            show={show}
            viewModalClose={() => setShow(false)}
            title={item.name}
            brand="Brand 1"
            createdBy="Super Admin"
            description={item.name}
            icon="https://storage.googleapis.com/cipla-cms-dev/icons/file_type/pdf.svg"
            file_url={item.url}
          />
        )}
      </div>
    </>
  );
};

export default withAuthorization(memo(Search));

import React, {
  Fragment,
  useRef,
  useState,
  useCallback,
  useEffect,
  memo,
} from "react";
import { Button, Card, Col, Form, Row, Modal } from "react-bootstrap";
import { findIcon } from "../../../../dashboard/icons";
import ReactTable from "../../../../../components/table/react-table";
import postService from "../../../../../api/services/post-service";
import {
  ALL_CLIENTS,
  ADD_CLIENT,
  DELETE_CLIENT,
  UPDATE_CLIENT,
  GET_CLIENT_BY_ID,
} from "../../../../../api/endpoints/client-management-endpoints";
import ViewModal from "../../../../../components/partials/common/view-modal";
import DeleteModal from "../../../../../components/partials/common/delete-modal";
import { getService } from "../../../../../api/services/get-services";
import { changeTableStateToPayload } from "../../../../../common/utils/utils";
import * as yup from "yup";
import { useFormik } from "formik";
import ActionButtonBar from "../../../../../components/partials/common/action-button-bar";
import toast from "react-hot-toast";
import FloatingButton from "../../../../../components/form-components/floating-button";
import withAuthorization from "../../../../../components/hoc/with-authorization";
import ReactTableLoader from "../../../../../skeleton/react-table-loader/react-table-loader";

const AllClients = () => {
  const [createModalShow, setCreateModalShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [clientList, setClientList] = useState([]);
  const [viewModalShow, setViewModalShow] = useState(false);
  const [viewClient, setViewClient] = useState({});
  const [totalCount, setTotalCount] = useState(0);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const post_json = useRef({
    default: "all",
    pagination: {
      limit: 10,
      offset: 0,
    },
    sort: {
      client_id: "desc",
    },
  });
  //yup and formik for validation of form fields of client_name

  const validationSchema = yup.object().shape({
    client_name: yup.string().trim().required("Client Name is required"),
    client_description: yup
      .string()
      .trim()
      .required("Client Description is required"),
  });
  //formik
  const formik = useFormik({
    initialValues: {
      client_name: "",
      client_description: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      // alert(JSON.stringify(values, null, 2));
      console.log("values===>", values);

      const postValues = {
        client_name: values.client_name.trim(),
        client_description: values.client_description.trim(),
      };

      try {
        setLoading(true);
        const response = await postService(
          isEdit ? UPDATE_CLIENT + "/" + selectedClient : ADD_CLIENT,
          {
            ...postValues,
            status: 1,
          }
        );
        if (response) {
          setLoading(false);
          setCreateModalShow(false);
          fetchClients(post_json.current);
          isEdit && setIsEdit(false);
          toast.success(response?.data?.message);
        }
      } catch (error) {
        setLoading(false);
        console.log("CLIENT_SERVICE_ERROR", error);
      }
    },
  });
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setValues,
    resetForm,
  } = formik;
  //fetch specialities
  const fetchClients = async (data) => {
    try {
      // alert("fetching");
      // setLoading(true);
      const response = await postService(ALL_CLIENTS, data);
      console.log("CLIENT_SERVICE_RESPONSE", response.data.data.rows);
      console.log("COLUMNS", Object.keys(response.data.data.rows[0]));
      if (response.data.data.rows.length > 0) {
        setClientList(response.data.data.rows);
        setTotalCount(response.data.data.totalCount);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log("CLIENT_SERVICE_ERROR", error);
    }
  };
  const viewModal = async (client) => {
    // alert(JSON.stringify(client, null, 2));
    const { client_id, client_name, client_description } = client;
    let dataJson = {
      "Client ID": client_id,
      "Client Name": client_name,
      "Client Description": client_description,
    };
    setViewClient(dataJson);
    setViewModalShow(true);
  };
  const viewModalClose = () => {
    setViewModalShow(false);
  };
  const deleteClient = async () => {
    try {
      setLoading(true);
      const response = await getService(`${DELETE_CLIENT}/${selectedClient}`);
      if (response) {
        setLoading(false);
        setDeleteModalShow(false);
        fetchClients(post_json.current);
        toast.success(response?.data?.message);
      }
    } catch (error) {
      setLoading(false);
      console.log("CLIENT_SERVICE_ERROR", error);
    }
  };
  useEffect(() => {
    setLoading(true);
    fetchClients(post_json.current);
  }, []);
  const onTableStateChange = useCallback(
    async (state) => {
      let payload = changeTableStateToPayload(state);
      fetchClients(payload);
    },
    [clientList, loading, post_json.current]
  );
  const onDeleteClient = (client) => {
    // alert("deleting" + client.client_id);
    setSelectedClient(client.client_id);
    setDeleteModalShow(true);
  };
  const columns = [
    {
      Header: "Client ID",
      accessor: "client_id",
    },
    {
      Header: "Client Name",
      accessor: "client_name",
    },
    {
      Header: "Client Description",
      accessor: "client_description",
    },
    {
      Header: "Action",
      accessor: "action",
      Cell: ({ row }) => (
        <ActionButtonBar
          row={row}
          onView={(data) => viewModal(data)}
          onEdit={(data) => onEditClientClick(data)}
          onDelete={(data) => onDeleteClient(data)}
        />
      ),
    },
  ];

  const handleCreate = () => {
    resetForm();
    setCreateModalShow(true);
  };
  const onEditClientClick = (client) => {
    console.log("client===>", client);
    setIsEdit(true);
    setSelectedClient(client.client_id);
    //set formik values
    setValues({
      client_name: client.client_name,
      client_description: client.client_description,
    });
    setCreateModalShow(true);
  };

  return (
    <Fragment>
      <FloatingButton onClick={handleCreate} />
      <Row>
        <Col sm="12">
          <Card>
            <Card.Header className="d-flex justify-content-between align-items-center">
              <h4 className="card-title">All Clients</h4>
              {/* <Button
                type="button"
                variant="primary"
                className="fw-bold rounded-3"
                onClick={() => handleCreate()}
              >
                {findIcon("Plus", "dual-tone", 32)} Add Client
              </Button> */}
            </Card.Header>
            <Card.Body className="px-3">
              {!loading ? (
                clientList.length > 0 && (
                  <ReactTable
                    data={clientList}
                    columns={columns}
                    recordsTotal={totalCount}
                    onTableStateChange={onTableStateChange}
                    initialState={post_json.current}
                    isRowSelectionAvailable={false}
                    recordsPerPage={10}
                  />
                )
              ) : (
                <ReactTableLoader />
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <DeleteModal
        show={deleteModalShow}
        modalClose={() => setDeleteModalShow(false)}
        onSubmit={deleteClient}
      />
      <ViewModal
        title={"View Client Information"}
        show={viewModalShow}
        modalClose={viewModalClose}
        data={viewClient}
      />
      {/* create a add Client modal with Client name and description */}
      <Modal
        show={createModalShow}
        onHide={() => {
          setCreateModalShow(false);
          setIsEdit(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{isEdit ? "Edit" : "Add"} Client</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>
                Client Name<span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Client Name"
                name="client_name"
                onChange={handleChange}
                value={values.client_name}
                onBlur={handleBlur}
                isInvalid={touched.client_name && errors.client_name}
              />
              <Form.Control.Feedback type="invalid">
                {errors.client_name}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>
                Client Description<span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Client Description"
                name="client_description"
                onChange={handleChange}
                value={values.client_description}
                onBlur={handleBlur}
                isInvalid={
                  touched.client_description && errors.client_description
                }
              />
              <Form.Control.Feedback type="invalid">
                {errors.client_description}
              </Form.Control.Feedback>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setCreateModalShow(false);
              setIsEdit(false);
            }}
          >
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default withAuthorization(memo(AllClients));

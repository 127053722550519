import React, { useState } from "react";
import Card from "../../../../components/bootstrap/card";
import { Button, Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import ReactQuill from "../../../../components/partials/common/react-quill";
import { findIcon } from "../../../dashboard/icons";

const AddGr = () => {
  const [addSpqList, setAddSpqList] = useState([
    { id: 1, spq_id: "", duration: "", skip: "" },
  ]);
  const [addGrAttachList, setAddGrAttachList] = useState([
    { id: 1, title: "", description: "", type: "", url: "" },
  ]);
  const [addSessionDoctorList, setAddSessionDoctorList] = useState([
    { id: 1, session_doctor_id: "", session_doctor_description: "" },
  ]);

  // ADD SESSION DOCTOR FUNCTIONS

  const addSessionDoctor = () =>
    setAddSessionDoctorList([
      ...addSessionDoctorList,
      {
        id: addSessionDoctorList.length + 1,
        session_doctor_id: "",
        session_doctor_description: "",
      },
    ]);
  const deleteSessionDoctor = (id) =>
    setAddSessionDoctorList(
      addSessionDoctorList.filter((_session_doc) => _session_doc.id != id)
    );

  // ADD GR FUNCTIONS

  const addGrAttach = () =>
    setAddGrAttachList([
      ...addGrAttachList,
      {
        id: addGrAttachList.length + 1,
        title: "",
        description: "",
        type: "",
        url: "",
      },
    ]);
  const deleteGrAttach = (id) =>
    setAddGrAttachList(addGrAttachList.filter((_gr) => _gr.id != id));

  // ADD SPQ FUNCTIONS

  const addSpq = () =>
    setAddSpqList([
      ...addSpqList,
      {
        id: addSpqList.length + 1,
        spq_id: "",
        duration: "",
        skip: "",
      },
    ]);
  const deleteSpq = (id) =>
    setAddSpqList(addSpqList.filter((_spq) => _spq.id != id));
  const specialities = [
    { value: "Diabetology - (parent)-1", label: "Diabetology - (parent)-1" },
    {
      value: "----- Diabetology-Medicine-108",
      label: "----- Diabetology-Medicine-108",
    },
    {
      value: ".............. Diabetology-Medicine-T1DM-1006t",
      label: ".............. Diabetology-Medicine-T1DM-1006",
    },
    {
      value: ".............. Diabetology-Medicine-T2DM-1008",
      label: ".............. Diabetology-Medicine-T2DM-1008",
    },
    {
      value: ".............. Diabetology-Medicine-Mood disorder-1029",
      label: ".............. Diabetology-Medicine-Mood disorder-1029",
    },
    // Add more options here...
  ];
  return (
    <>
      <div className="d-flex justify-content-between align-items-center flex-wrap mb-4 gap-3">
        <div className="d-flex flex-column">
          <h3 className="m-0">Add Grand Round</h3>
        </div>
        <div className="d-flex justify-content-between align-items-center rounded flex-wrap gap-3"></div>
      </div>
      <Card>
        <Card.Body>
          <Row>
            <h6 className="mb-4">About GR</h6>
            <Col lg="6">
              <Form.Floating className="custom-form-floating mb-3">
                <Form.Control
                  type="name"
                  placeholder="gr_title"
                  name="gr_title"
                />
                <Form.Label>Gr Title *</Form.Label>
              </Form.Floating>
            </Col>
            <Col lg="6">
              <Form.Floating className="custom-form-floating mb-3">
                <Form.Control
                  type="name"
                  placeholder="gr_video_title"
                  name="gr_video_title"
                />
                <Form.Label>Gr Video Title</Form.Label>
              </Form.Floating>
            </Col>
            <Col lg="6">
              <Form.Floating className="custom-form-floating mb-3">
                <Form.Control type="name" placeholder="Tags" name="tags" />
                <Form.Label>Please Enter Tags</Form.Label>
              </Form.Floating>
            </Col>
            <Col lg="6">
              <Form.Floating className="custom-form-floating mb-3">
                <Form.Control type="name" placeholder="Notes" name="notes" />
                <Form.Label>Please Enter Notes</Form.Label>
              </Form.Floating>
            </Col>
            <Col lg="4">
              <Form.Floating className="custom-form-floating mb-3">
                <Form.Control
                  type="name"
                  placeholder="Color Palette"
                  name="color_palette"
                />
                <Form.Label>Please Enter Color Palette</Form.Label>
              </Form.Floating>
            </Col>
            <Col lg="4">
              <Form.Floating className="custom-form-floating mb-3">
                <Form.Select
                  type="name"
                  placeholder="GR Q&A status ( i.e enable/disable q&a box)"
                  name="gr_qna_status"
                >
                  <option value="" selected hidden>
                    Please Select GR Q&A status
                  </option>
                  <option value="yes">Yes</option>
                  <option value="No">No</option>
                </Form.Select>
              </Form.Floating>
            </Col>
            <Col lg="4">
              <Form.Floating className="custom-form-floating mb-3">
                <Form.Control
                  type="file"
                  placeholder="gr_preview_image"
                  name="gr_preview_image"
                />
              </Form.Floating>
            </Col>
            <Col lg="12" className="d-flex flex-column">
              <h6 className="mb-3">
                Description <span className="text-danger">*</span>
              </h6>
              <ReactQuill />
            </Col>
            <Col lg="6">
              <Form.Floating className="custom-form-floating mb-3">
                <Form.Control
                  type="name"
                  placeholder="chief_scientific_editor"
                  name="chief_scientific_editor"
                />
                <Form.Label>Please Enter Chief Scientific Editor</Form.Label>
              </Form.Floating>
            </Col>
            <Col lg="6">
              <Form.Floating className="custom-form-floating mb-3">
                <Form.Select
                  name="select_speciality"
                  placeholder="Select Advance Search Options"
                  name="specialities"
                  // options={specialities}
                >
                  <option value="" selected hidden>
                    Please Select Specialities
                  </option>
                  <option value="yes">One</option>
                  <option value="No">Two</option>
                </Form.Select>
              </Form.Floating>
            </Col>
            <Col lg="6">
              <Form.Floating className="custom-form-floating mb-3">
                <Form.Select
                  type="name"
                  placeholder="GR Q&A status"
                  name="gr_live_status"
                >
                  <option value="" selected hidden>
                    Please Select GR live status
                  </option>
                  <option value="open">Open</option>
                  <option value="close">Close</option>
                </Form.Select>
              </Form.Floating>
            </Col>
            <Col lg="6">
              <Form.Floating className="custom-form-floating mb-3">
                <Form.Control
                  type="name"
                  placeholder="Gr live video Source"
                  name="gr_live_video_source"
                />
                <Form.Label>Please Enter Gr live video Source</Form.Label>
              </Form.Floating>
            </Col>
            <h6 className="mb-3">Sponsor & Env Setting</h6>
            <Col lg="6">
              <Form.Floating className="custom-form-floating mb-3">
                <Form.Control
                  type="name"
                  placeholder="select_sponsor"
                  name="select_sponsor"
                />
                <Form.Label>Please Enter Select Sponsor</Form.Label>
              </Form.Floating>
            </Col>
            <Col lg="6">
              <Form.Floating className="custom-form-floating mb-3">
                <Form.Select
                  type="name"
                  placeholder="select_client"
                  name="select_client"
                >
                  <option value="" selected hidden>
                    Please Select Client
                  </option>
                  <option value="Public">Clirnet</option>
                  <option value="Private">Cipla</option>
                  <option value="Unlisted">Doctube</option>
                </Form.Select>
              </Form.Floating>
            </Col>
            <Col lg="6">
              <Form.Floating className="custom-form-floating mb-3">
                <Form.Select
                  type="name"
                  placeholder="Privacy Status"
                  name="privacy_status"
                >
                  <option value="" selected hidden>
                    Please Select Privacy Status
                  </option>
                  <option value="Public">Public</option>
                  <option value="Private">Private</option>
                  <option value="Unlisted">Unlisted</option>
                </Form.Select>
              </Form.Floating>
            </Col>
            <Col lg="6">
              <Form.Floating className="custom-form-floating mb-3">
                <Form.Select type="name" placeholder="Env" name="env">
                  <option value="" selected hidden>
                    Please Select Env
                  </option>
                  <option value="GL">GL</option>
                  <option value="IN">IN</option>
                </Form.Select>
              </Form.Floating>
            </Col>
            <h6 className="mb-3">GR Type Setting</h6>
            <Col lg="6">
              <Form.Floating className="custom-form-floating mb-3">
                <Form.Select type="name" placeholder="gr_type" name="gr_type">
                  <option value="" selected hidden>
                    Please Enter Gr Type
                  </option>
                  <option value="Video">Video</option>
                  <option value="Audio">Audio</option>
                </Form.Select>
              </Form.Floating>
            </Col>
            <Col lg="6">
              <Form.Floating className="custom-form-floating mb-3">
                <Form.Select
                  type="name"
                  placeholder="template_id"
                  name="template_id"
                >
                  <option value="" selected hidden>
                    Please Select Template ID
                  </option>
                  <option value="Classic Blue Medwiki">
                    Classic Blue Medwiki
                  </option>
                </Form.Select>
              </Form.Floating>
            </Col>
            <Col lg="6">
              <Form.Floating className="custom-form-floating mb-3">
                <Form.Select
                  type="Specialities"
                  placeholder="Select Video Archive Options"
                  name="select_video_archive"
                >
                  <option value="" selected hidden>
                    Select Video Archive
                  </option>
                  <option value="Video Archive">Option A</option>
                  <option value="Video Archive">Option B</option>
                </Form.Select>
              </Form.Floating>
            </Col>
            <Col lg="6">
              <Form.Floating className="custom-form-floating mb-3">
                <Form.Select
                  type="Specialities"
                  placeholder="Select Advance Search Options"
                  name="select_compendium"
                  // options={specialities}
                >
                  <option value="" selected hidden>
                    Select Compendium
                  </option>
                  <option value="Video Archive">Option A</option>
                  <option value="Video Archive">Option B</option>
                </Form.Select>
              </Form.Floating>
            </Col>
            <Col lg="6">
              <Form.Floating className="custom-form-floating mb-3">
                <Form.Select
                  type="Specialities"
                  placeholder="Select Session"
                  name="select_session"
                  // options={specialities}
                >
                  <option value="" selected hidden>
                    Select Session
                  </option>
                  <option value="Video Archive">Option A</option>
                  <option value="Video Archive">Option B</option>
                </Form.Select>
              </Form.Floating>
            </Col>
            <Col lg="6">
              <Form.Floating className="custom-form-floating mb-3">
                <Form.Select
                  type="Survey"
                  placeholder="Select Survey"
                  name="select_survey"
                  // options={specialities}
                >
                  <option value="" selected hidden>
                    Select Survey
                  </option>
                  <option value="Video Archive">Option A</option>
                  <option value="Video Archive">Option B</option>
                </Form.Select>
              </Form.Floating>
            </Col>
            <h6 className="mb-3">Association Setting</h6>
            <Col lg="6">
              <Form.Floating className="custom-form-floating mb-3">
                <Form.Select
                  type="Status"
                  placeholder="Select Association Status"
                  name="select_association_status"
                  // options={specialities}
                >
                  <option value="" selected hidden>
                    Select Association Status
                  </option>
                  <option value="Video Archive">Option A</option>
                  <option value="Video Archive">Option B</option>
                </Form.Select>
              </Form.Floating>
            </Col>
            <Col lg="6">
              <Form.Floating className="custom-form-floating mb-3">
                <Form.Control
                  type="name"
                  placeholder="assosiation_setting"
                  name="assosiation_setting"
                />
                <Form.Label>Please Enter Association Setting</Form.Label>
              </Form.Floating>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      {/* <---------------- ADD SESSION DOCTOR SECTION --------------------> */}

      <div className="d-flex justify-content-between align-items-center flex-wrap my-4 gap-3">
        <h5 className="m-0">Add Session Doctor</h5>
      </div>
      <Card>
        <Card.Body>
          <Row>
            {addSessionDoctorList.map((_session_doc) => (
              <Col
                lg="12"
                className="d-flex flex-row gap-3 justify-content-center align-items-center mb-3"
                key={_session_doc.id}
              >
                <Form.Floating className="custom-form-floating">
                  <Form.Control
                    type="name"
                    placeholder="session_doctor_id"
                    name="session_doctor_id"
                  />
                  <Form.Label>Doctor ID</Form.Label>
                </Form.Floating>
                <Form.Floating className="custom-form-floating flex-grow-1">
                  <Form.Control
                    type="name"
                    placeholder="session_doctor_description"
                    name="session_doctor_description"
                  />
                  <Form.Label>Doctor Description</Form.Label>
                </Form.Floating>
                {addSessionDoctorList.length != 1 && (
                  <Button
                    variant="danger"
                    className="p-0 icon-32 rounded-circle d-flex align-items-center justify-content-center"
                    type="submit"
                    onClick={() => deleteSessionDoctor(_session_doc.id)}
                  >
                    {findIcon("Trash", "outline", "20")}
                  </Button>
                )}
              </Col>
            ))}
            <Col
              lg="12"
              className="d-flex align-items-center justify-content-center"
            >
              <Button
                variant="outline-primary"
                className="d-flex align-items-center justify-content-center border border-2 border-dashed border-primary"
                type="submit"
                onClick={addSessionDoctor}
              >
                Add More &nbsp; {findIcon("Plus", "outline", "16")}
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      {/* 

      <---------------- ADD GR ATTACH SECTION -------------------->
      
      */}
      <div className="d-flex justify-content-between align-items-center flex-wrap my-4 gap-3">
        <h5 className="m-0">Add GR Attach Files</h5>
      </div>
      <Card>
        <Card.Body>
          {addGrAttachList.map((_gr) => (
            <div
              className="d-flex p-3 mb-3 rounded-3 border border-1 border-dashed border-secondary"
              key={_gr.id}
            >
              <Row>
                <Col lg="12" className="d-flex flex-column">
                  <Form.Floating className="custom-form-floating mb-3">
                    <Form.Control
                      type="name"
                      placeholder="Content Title"
                      name="content_title"
                    />
                    <Form.Label>Please Enter Content Title</Form.Label>
                  </Form.Floating>
                  <Col lg="12" className="d-flex flex-column">
                    <p className="mb-2 fs-7 text-black">
                      Content Description <span className="text-danger">*</span>
                    </p>
                    <ReactQuill />
                  </Col>
                </Col>
                <Col lg="12" className="d-flex flex-row gap-3">
                  <Form.Floating className="custom-form-floating mb-3 flex-grow-1">
                    <Form.Select
                      type="name"
                      placeholder="Content Type"
                      name="content_type"
                    >
                      <option value="" selected hidden>
                        Please Select Content Type
                      </option>
                      <option value="Image">Image</option>
                      <option value="PDF">PDF</option>
                      <option value="Word Document">Word Document</option>
                    </Form.Select>
                  </Form.Floating>
                  <Form.Floating className="custom-form-floating mb-3 flex-grow-1">
                    <Form.Control
                      type="file"
                      placeholder="upload url"
                      name="upload_url"
                    />
                  </Form.Floating>
                </Col>
              </Row>
              <div className="d-flex">
                {addGrAttachList.length != 1 && (
                  <Button
                    variant="danger"
                    className="p-0 icon-32 rounded-circle d-flex align-items-center justify-content-center"
                    type="submit"
                    onClick={() => deleteGrAttach(_gr.id)}
                  >
                    {findIcon("Trash", "outline", "20")}
                  </Button>
                )}
              </div>
            </div>
          ))}
          <Col
            lg="12"
            className="d-flex align-items-center justify-content-center"
          >
            <Button
              variant="outline-primary"
              className="d-flex align-items-center justify-content-center border border-2 border-dashed border-primary"
              type="submit"
              onClick={addGrAttach}
            >
              Add More &nbsp; {findIcon("Plus", "outline", "16")}
            </Button>
          </Col>
        </Card.Body>
      </Card>

      {/* 

      <---------------- ADD SPQ SECTION -------------------->

      */}
      <div className="d-flex justify-content-between align-items-center flex-wrap my-4 gap-3">
        <div className="d-flex flex-column">
          <h5 className="m-0">Add SPQ</h5>
        </div>
      </div>
      <Card>
        <Card.Body>
          <Row>
            {addSpqList.map((_spq, index) => (
              <Col
                lg="12"
                key={_spq.id}
                className="d-flex gap-3 mb-3 align-items-center justify-content-center"
              >
                <Form.Floating className="custom-form-floating flex-grow-1">
                  <Form.Select type="name" placeholder="spq_id" name="spq_id">
                    <option value="" selected hidden>
                      Select SPQ ID
                    </option>
                    <option value=" Cancer Prevention - 24 ">
                      {" "}
                      Cancer Prevention - 24{" "}
                    </option>
                    <option value=" COVID-19 Vaccines update - 48 ">
                      {" "}
                      COVID-19 Vaccines update - 48{" "}
                    </option>
                    <option value=" Pediatric Ear Infections - 213 ">
                      {" "}
                      Pediatric Ear Infections - 213{" "}
                    </option>
                  </Form.Select>
                </Form.Floating>
                <Form.Floating className="custom-form-floating flex-grow-1">
                  <Form.Control
                    type="name"
                    placeholder="duration"
                    name="duration"
                  />
                  <Form.Label>Please Enter Duration</Form.Label>
                </Form.Floating>
                <Form.Floating className="custom-form-floating flex-grow-1">
                  <Form.Select type="name" placeholder="Skip" name="skip">
                    <option value="" selected hidden>
                      Please Select Concern
                    </option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </Form.Select>
                </Form.Floating>
                {addSpqList.length != 1 && (
                  <Button
                    variant="danger"
                    className="p-0 icon-32 rounded-circle d-flex align-items-center justify-content-center"
                    type="submit"
                    onClick={() => deleteSpq(_spq.id)}
                  >
                    {findIcon("Trash", "outline", "20")}
                  </Button>
                )}
              </Col>
            ))}
            <Col
              lg="12"
              className="d-flex align-items-center justify-content-center"
            >
              <Button
                variant="outline-primary"
                className="d-flex align-items-center justify-content-center border border-2 border-dashed border-primary"
                type="submit"
                onClick={addSpq}
              >
                Add More &nbsp; {findIcon("Plus", "outline", "16")}
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      {/* 

      <---------------- BUTTON SECTION -------------------->

      */}
      <div className="d-flex justify-content-end align-items-center gap-2">
        <Button variant="secondary">Cancel</Button>
        <Button variant="warning">Save as Draft</Button>
        <Button variant="primary">Submit</Button>
      </div>
    </>
  );
};

export default AddGr;

import React, { memo, useState, useEffect } from "react";
import Card from "../../../../components/bootstrap/card";
import { Row, Col, Button, Badge } from "react-bootstrap";
import TreeView, { flattenTree } from "react-accessible-treeview";
import cx from "classnames";
import { Link, useParams } from "react-router-dom";
import { findIcon } from "../../../dashboard/icons";
import withAuthorization from "../../../../components/hoc/with-authorization";
import { Chip, Skeleton, Tooltip } from "@mui/material";
import Progress from "../../../../components/progress";
import { CircularProgressbar } from "react-circular-progressbar";
import Circularprogressbar from "../../../../components/circularprogressbar";
import { getVariableColor } from "../../../../utilities/root-var";
import { getService } from "../../../../api/services/get-services";
import {
  GET_AVAILABLE_CONTENTS,
  GET_CONTENT_DETAILS,
} from "../../../../api/endpoints/creator-recommendation-endpoints";
import searchService from "../../../../api/services/search-service";
import useRedirect from "../../../../components/custom-hooks/use-redirect";
import FilePreviewModal from "../../../../components/partials/common/file-preview-modal";

const ViewCloudDetails = () => {
  const folder = {
    name: "",
    children: [
      {
        name: "Pathological Conditions",
        children: [
          {
            name: "Pathologic Processes",
            children: [
              {
                name: "Fibrosis",
                children: [
                  {
                    name: "Cicatrix",
                    children: [],
                  },
                  {
                    name: "Liver Cirrhosis ",
                    children: [],
                  },
                  {
                    name: "Radiation Fibrosis Syndrome",
                    children: [],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  };
  const [viewTag, setViewTag] = useState({});
  const data = flattenTree(folder);
  console.log(data);
  const ArrowIcon = ({ isOpen, className }) => {
    const baseClass = "arrow";
    const classes = cx(
      baseClass,
      { [`${baseClass}--closed`]: !isOpen },
      { [`${baseClass}--open`]: isOpen },
      className
    );
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        class="bi bi-arrow-bar-right"
        viewBox="0 0 16 16"
      >
        <path
          fill-rule="evenodd"
          d="M6 8a.5.5 0 0 0 .5.5h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L12.293 7.5H6.5A.5.5 0 0 0 6 8Zm-2.5 7a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5Z"
        />
      </svg>
    );
  };
  const svg1 = findIcon("Eye", "outline");
  const onEachNodeClick = (element) => {
    console.log("element", element);
    if (element.children.length === 0) {
      setViewTag({ tag: element.name, reach: "1000" });
    }
  };
  const { redirectTo } = useRedirect();

  const [contentDetails, setContentDetails] = useState({
    potentialAudience: "10",
    stats: [
      {
        label: "Text/PDF",
        value: "5",
      },
      {
        label: "Video",
        value: "10",
      },
      {
        label: "Infographic",
        value: "20",
      },
      {
        label: "Activity",
        value: "40",
      },
    ],
  });
  const [contentDetailsLoading, setContentDetailsLoading] = useState(true);

  const [availableContents, setAvailableContents] = useState([]);
  const [availableContentLoading, setAvailableContentLoading] = useState(true);

  const [contentIdeas, setContentIdeas] = useState([]);
  const [contentIdeasLoading, setContentIdeasLoading] = useState(true);

  const [selectedItem, setSelectedItem] = useState({});

  const [show, setShow] = useState(false);

  const { keyword } = useParams();

  async function fetchData() {
    let response = await searchService(GET_AVAILABLE_CONTENTS, {
      query: keyword,
    });
    if (response.status == 200) {
      let availableContents = response?.data?.availableContents;
      setAvailableContents(availableContents);
      setAvailableContentLoading(false);
    }
  }

  async function fetchContentData() {
    let response = await searchService(GET_CONTENT_DETAILS, { query: keyword });
    if (response.status == 200) {
      let contentIdeas = response?.data?.output?.output;
      var data = Object.keys(contentIdeas).map((key) => {
        return contentIdeas[key];
      });
      setContentIdeas(data);
      setContentIdeasLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
    fetchContentData();
  }, []);

  return (
    <>
      <Row className="page-title mb-4">
        <h1 className="mb-1 mt-0 fs-3">{keyword}</h1>
      </Row>
      <Row>
        {/* <Col lg="6" className="h-100">
          <Card className="h-100">
            <Card.Header>
              <Card.Header.Title className="bg-soft-primary px-3 py-3 border border-primary w-100 rounded-3 text-center">
                <h5 className="m-0 text-primary">Navigation Tree</h5>
              </Card.Header.Title>
            </Card.Header>
            <Card.Body>
              <TreeView
                className="list-unstyled"
                data={data}
                aria-label="Checkbox tree"
                expandedIds={[0, 1, 2, 3]}
                multiSelect
                propagateSelect
                propagateSelectUpwards
                togglableSelect
                nodeRenderer={({
                  element,
                  isBranch,
                  isExpanded,
                  getNodeProps,
                  level,
                  handleExpand,
                  className,
                }) => {
                  return (
                    <div
                      {...getNodeProps({ onClick: handleExpand })}
                      style={{ marginLeft: 5 * (level - 1) }}
                      // onClick={() => {alert("clicked")}}
                    >
                      <div onClick={() => onEachNodeClick(element)}>
                        {isBranch && <ArrowIcon isOpen={isExpanded} />}
                        <span>{element.name}</span>
                      </div>
                    </div>
                  );
                }}
              />
            </Card.Body>
          </Card>
        </Col> */}
        <Col lg="4">
          <Card onClick={() => redirectTo("/requisition/listing")}>
            <Card.Body className="bg-white rounded-3">
              <div className="d-flex align-items-start gap-3 ">
                <div className="bg-soft-primary rounded p-3 d-flex align-items-center">
                  {findIcon("User Group", "dual-tone", "32")}
                </div>
                <div className="d-flex flex-column">
                  <h4 className="mt-0 mb-2 fw-semibold">
                    {contentDetails?.potentialAudience}%
                  </h4>
                  <span className="fs-6 text-black fw-semibold">Potential Audience</span>

                </div>
              </div>
            </Card.Body>
          </Card>

        </Col>
        <Col lg="8">
          <Card className="rounded-3 overflow-hidden">
            <Card.Body className="bg-soft-primary rounded-3">
              <Row className="mx-n2">
                {contentDetails?.stats &&
                  contentDetails?.stats.length > 0 &&
                  contentDetails?.stats.map((item) => (
                    <Col sm={3} className="px-2">
                      <div className="d-flex align-items-center gap-3 flex-column p-3 bg-white rounded-3">
                        <div className="flex-shrink-0">
                          <Circularprogressbar
                            Linecap="rounded"
                            trailstroke="#ddd"
                            strokewidth="2px"
                            stroke="#3256b8"
                            style={{ width: 50, height: 50, }}
                            value={item.value}
                            id={item.id}
                          >
                            <small>{item.value}%</small>
                          </Circularprogressbar>
                        </div>
                        <h4 className="m-0 fs-6 text-black fw-semibold">{item.label}</h4>
                      </div>
                    </Col>
                  ))}
              </Row>
            </Card.Body>
          </Card>
        </Col>
        {!availableContentLoading &&
          availableContents &&
          availableContents.length > 0 && (
            <Col lg="12" className="h-auto">
              <Card className="h-auto">
                <Card.Header>
                  <h4>Available Content </h4>
                </Card.Header>
                <div className="table-responsive">
                  <table className="table table-borderless iq-file-manager-table mb-0">
                    <thead>
                      <tr className="border-bottom bg-transparent text-dark">
                        <th scope="col" className="fw-bold">
                          Content Name
                        </th>
                        <th scope="col" className="fw-bold">
                          Speciality
                        </th>
                        <th scope="col" className="fw-bold">
                          Date
                        </th>
                        <th scope="col" className="fw-bold">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {availableContents.map((item) => (
                        <tr>
                          <td width="80%">
                            <h6 className="text-wrap">{item.name}</h6>
                          </td>
                          <td>
                            <h6>{item.speciality.split(",")[0]}</h6>
                          </td>
                          <td>
                            <small className="text-muted">{item.date}</small>
                          </td>
                          <td
                            className="cursorPointer"
                            onClick={() => {
                              setSelectedItem(item);
                              setShow(true);
                            }}
                          >
                            {svg1}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </Card>
            </Col>
          )}
        {availableContentLoading && (
          <Col lg="12" className="h-auto">
            <Card className="h-auto">
              <Card.Header>
                <h4>Available Content </h4>
              </Card.Header>
              <div className="table-responsive">
                <table className="table table-borderless iq-file-manager-table mb-0">
                  <thead>
                    <tr className="border-bottom bg-transparent text-dark">
                      <th scope="col" className="fw-bold">
                        Content Name
                      </th>
                      <th scope="col" className="fw-bold">
                        Speciality
                      </th>
                      <th scope="col" className="fw-bold">
                        Date
                      </th>
                      <th scope="col" className="fw-bold">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {[1, 2, 3, 4, 5].map((item) => (
                      <tr>
                        <td width="80%">
                          <h6>
                            <Skeleton variant="text" />
                          </h6>
                        </td>
                        <td>
                          <h6>
                            <Skeleton variant="text" />
                          </h6>
                        </td>
                        <td>
                          <small className="text-muted">
                            <Skeleton variant="text" />
                          </small>
                        </td>
                        <td>
                          <Skeleton variant="circular" width={25} height={25} />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Card>
          </Col>
        )}

        {/* button of assigning vendor */}
        {!contentIdeasLoading && (
          <Col lg="12" className="h-auto">
            <Card className="h-auto">
              <Card.Header>
                <h4>Content Ideas</h4>
              </Card.Header>
              <div className="table-responsive">
                <table className="table table-borderless iq-file-manager-table mb-0">
                  <thead>
                    <tr className="border-bottom bg-transparent text-dark">
                      <th scope="col" className="fw-bold">
                        Content Name
                      </th>
                      <th scope="col" className="fw-bold">
                        Speciality
                      </th>
                      <th scope="col" className="fw-bold">
                        Priority
                      </th>
                      <th scope="col" className="fw-bold">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {contentIdeas.map((item) => (
                      <tr>
                        <td>
                          <h6 className="text-wrap">{item.name}</h6>
                        </td>
                        <td>
                          <h6>{item.speciality}</h6>
                        </td>
                        <td>
                          {/* <h6>{new Date().toDateString}</h6> */}
                          <small className="text-muted">{item.priority}</small>
                          <Badge
                            pill
                            bg={
                              item.Priority == "Medium"
                                ? "warning"
                                : item.Priority == "Low"
                                  ? "success"
                                  : item.Priority == "High"
                                    ? "danger"
                                    : ""
                            }
                            className="me-2"
                          >
                            <span className="btn-inner">{item.Priority}</span>
                          </Badge>
                        </td>
                        <td>
                          <div
                            className="bg-soft-primary px-3 py-3 border border-primary w-100 rounded-3 text-center cursorPointer"
                            onClick={() => redirectTo("requisition/create")}
                          >
                            <h5 className="m-0 fs-7 text-primary">
                              Assign to Vendor
                            </h5>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Card>
            {/* button of assigning vendor */}
          </Col>
        )}
        {contentIdeasLoading && (
          <Col lg="12" className="h-auto">
            <Card className="h-auto">
              <Card.Header>
                <h4>Content Ideas</h4>
              </Card.Header>
              <div className="table-responsive">
                <table className="table table-borderless iq-file-manager-table mb-0">
                  <thead>
                    <tr className="border-bottom bg-transparent text-dark">
                      <th scope="col" className="fw-bold">
                        Content Name
                      </th>
                      <th scope="col" className="fw-bold">
                        Speciality
                      </th>
                      <th scope="col" className="fw-bold">
                        Priority
                      </th>
                      <th scope="col" className="fw-bold">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {[1, 2, 3, 4, 5].map((item) => (
                      <tr>
                        <td width="100%">
                          <h6 className="text-wrap">
                            <Skeleton variant="text" />
                          </h6>
                        </td>
                        <td>
                          <h6>
                            <Skeleton variant="text" />
                          </h6>
                        </td>
                        <td>
                          {/* <h6>{new Date().toDateString}</h6> */}
                          <Badge
                            pill
                            bg={
                              item.Priority == "Medium"
                                ? "warning"
                                : item.Priority == "Low"
                                  ? "success"
                                  : item.Priority == "High"
                                    ? "danger"
                                    : ""
                            }
                            className="me-2"
                          >
                            <span className="btn-inner">
                              <Skeleton variant="text" width={40} />
                            </span>
                          </Badge>
                        </td>
                        <td>
                          <div className="bg-soft-primary px-3 py-3 border border-primary w-100 rounded-3 text-center">
                            <h5 className="m-0 fs-7 text-primary">
                              <Skeleton variant="text" width={80} />
                            </h5>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Card>
            {/* button of assigning vendor */}
          </Col>
        )}
      </Row >

      {show && (
        <FilePreviewModal
          createdOn={new Date()}
          mediaType="pdf"
          show={show}
          viewModalClose={() => setShow(false)}
          title={selectedItem.name}
          brand="Brand 1"
          createdBy="Super Admin"
          description={selectedItem.name}
          icon="https://storage.googleapis.com/cipla-cms-dev/icons/file_type/pdf.svg"
          file_url="https://storage.googleapis.com/cipla-cms-dev/cipla/cluster-1/division-1/sub-division-1/brand-1/pdf/443_what_is_the_use_of_milrinone/1695212192_959191695212144_86.pdf"
        />
      )
      }
    </>
  );
};

export default withAuthorization(memo(ViewCloudDetails));

import { Fragment, memo, useContext, useEffect, useState } from "react";

//Router
import { Link, useLocation } from "react-router-dom";

//React-bootstrap
import {
  Accordion,
  AccordionContext,
  Nav,
  OverlayTrigger,
  Tooltip,
  useAccordionButton,
} from "react-bootstrap";

//Componets
import { useGetMenuItems } from "../../../../queries/query-hooks.js/menu-hook";
import SidebarMenu from "../../components/sidebar/sidebar-menu";
import fileTypes from "../../../../views/modules/add-content/data/file-types";
import { findIcon } from "../../../../views/dashboard/icons";

function CustomToggle({ children, eventKey, onClick }) {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(eventKey, (active) =>
    onClick({ state: !active, eventKey: eventKey })
  );

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <Link
      to="#"
      aria-expanded={isCurrentEventKey ? "true" : "false"}
      className="nav-link"
      role="button"
      onClick={(e) => {
        decoratedOnClick(isCurrentEventKey);
      }}
    >
      {children}
    </Link>
  );
}

const VerticalNav = memo(() => {
  const [activeMenu, setActiveMenu] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const [active, setActive] = useState("");

  //location
  let location = useLocation();
  //get all menu items

  const post_json = {
    default: "all",
    pagination: {
      limit: 50,
      offset: 0,
    },
  };
  const { data: menuItems, isLoading } = useGetMenuItems(post_json);

  useEffect(() => {
    console.log("MENU_ITEMS", menuItems);
  }, [menuItems]);

  return (
    <Fragment>
      <Accordion as="ul" className="navbar-nav iq-main-menu">
        <li className="nav-item static-item">
          <Link className="nav-link static-item disabled" to="#" tabIndex="-1">
            <span className="default-icon">Home</span>
            <span className="mini-icon">-</span>
          </Link>
        </li>
        <SidebarMenu isTag="true" pathname="/dashboard" title="Dashboard">
          <i className="icon"> {findIcon("Home", "dual-tone", "20")}</i>
        </SidebarMenu>
        {/* DASHBOARD */}
        <SidebarMenu
          isTag="false"
          minititle="D"
          pathname="/add-content/dashboard"
          title="Add Content"
        >
          <i className="icon">{findIcon("Document Plus", "dual-tone", "20")}</i>
        </SidebarMenu>
        <Accordion.Item
          as="li"
          eventKey="requisition"
          bsPrefix={`nav-item ${active === "requisition" ? "active" : ""} `}
          onClick={() => setActive("requisition")}
        >
          <CustomToggle
            eventKey="requisition"
            onClick={(activeKey) => setActiveMenu(activeKey)}
          >
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip>Requisition</Tooltip>}
            >
              <i className="icon">
                {findIcon("Requisition", "dual-tone", "20")}
              </i>
            </OverlayTrigger>
            <span className="item-name">Requisition</span>
            <i className="right-icon">
              <svg
                className="icon-18"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </i>
          </CustomToggle>
          <Accordion.Collapse eventKey="requisition">
            <ul className="sub-nav">
              <SidebarMenu
                isTag="false"
                minititle={findIcon("Home", "dual-tone", "18")}
                pathname="/requisition/dashboard"
                title="Dashboard"
              >
                <i className="icon">{findIcon("Home", "dual-tone", "18")}</i>
              </SidebarMenu>
              <SidebarMenu
                isTag="false"
                minititle={findIcon("View Grid", "dual-tone", "18")}
                pathname="/requisition/my-requisitions"
                title="My Requisitions"
              >
                <i className="icon">
                  {findIcon("View Grid", "dual-tone", "18")}
                </i>
              </SidebarMenu>
              <SidebarMenu
                isTag="false"
                minititle={findIcon("In Progress", "dual-tone", "18")}
                pathname="/requisition/in-progress"
                title="In-progress"
              >
                <i className="icon">
                  {findIcon("In Progress", "dual-tone", "18")}
                </i>
              </SidebarMenu>
              <SidebarMenu
                isTag="false"
                minititle={findIcon("Pending", "dual-tone", "18")}
                pathname="/requisition/pending"
                title="Pending"
              >
                <i className="icon">{findIcon("Pending", "dual-tone", "18")}</i>
              </SidebarMenu>
              <SidebarMenu
                isTag="false"
                minititle={findIcon("Square Tick", "dual-tone", "18")}
                pathname="/requisition/completed"
                title="Completed"
              >
                <i className="icon">
                  {findIcon("Square Tick", "dual-tone", "18")}
                </i>
              </SidebarMenu>
              <SidebarMenu
                isTag="false"
                minititle={findIcon("Trash", "dual-tone", "18")}
                pathname="/requisition/draft"
                title="Draft"
              >
                <i className="icon">{findIcon("Trash", "dual-tone", "18")}</i>
              </SidebarMenu>
              <SidebarMenu
                isTag="false"
                minititle={findIcon("Document", "dual-tone", "18")}
                pathname="/requisition/listing"
                title="All Requisitions"
              >
                <i className="icon">
                  {findIcon("Document", "dual-tone", "18")}
                </i>
              </SidebarMenu>
            </ul>
          </Accordion.Collapse>
        </Accordion.Item>
        {/* ACTIONS */}
        <Accordion.Item
          as="li"
          eventKey="action"
          bsPrefix={`nav-item ${active === "action" ? "active" : ""} `}
          onClick={() => setActive("action")}
        >
          <CustomToggle
            eventKey="action"
            onClick={(activeKey) => setActiveMenu(activeKey)}
          >
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip>Action</Tooltip>}
            >
              <i className="icon">{findIcon("Action", "dual-tone", "20")}</i>
            </OverlayTrigger>
            <span className="item-name">Action</span>
            <i className="right-icon">
              <svg
                className="icon-18"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </i>
          </CustomToggle>
          <Accordion.Collapse eventKey="action">
            <ul className="sub-nav">
              <SidebarMenu
                isTag="false"
                minititle={findIcon("View Grid", "dual-tone", "18")}
                pathname="/action/dashboard"
                title="My Actions"
              >
                <i className="icon">
                  {findIcon("View Grid", "dual-tone", "18")}
                </i>
              </SidebarMenu>
              {/* <SidebarMenu
                isTag="false"
                minititle={findIcon("List", "dual-tone", "18")}
                pathname="/action/listing"
                title="All Action"
              >
                <i className="icon">
                  {findIcon("List", "dual-tone", "18")}
                </i>
              </SidebarMenu> */}
              <SidebarMenu
                isTag="false"
                minititle={findIcon("List", "dual-tone", "18")}
                pathname="/action/all-action"
                title="View All Actions"
              >
                <i className="icon">{findIcon("List", "dual-tone", "18")}</i>
              </SidebarMenu>
              <SidebarMenu
                isTag="false"
                minititle={findIcon("Pending", "dual-tone", "18")}
                pathname="/action/pending"
                title="Pending"
              >
                <i className="icon">{findIcon("Pending", "dual-tone", "18")}</i>
              </SidebarMenu>
              <SidebarMenu
                isTag="false"
                minititle={findIcon("In Progress", "dual-tone", "18")}
                pathname="/action/in-progress"
                title="In-progress"
              >
                <i className="icon">
                  {findIcon("In Progress", "dual-tone", "18")}
                </i>
              </SidebarMenu>
              <SidebarMenu
                isTag="false"
                minititle={findIcon("Overdue", "dual-tone", "18")}
                pathname="/action/overdue"
                title="Overdue"
              >
                <i className="icon">{findIcon("Overdue", "dual-tone", "18")}</i>
              </SidebarMenu>
              <SidebarMenu
                isTag="false"
                minititle={findIcon("Square Tick", "dual-tone", "18")}
                pathname="/action/completed"
                title="Completed"
              >
                <i className="icon">
                  {findIcon("Square Tick", "dual-tone", "18")}
                </i>
              </SidebarMenu>
            </ul>
          </Accordion.Collapse>
        </Accordion.Item>
        {/* CONTENT-LIBRARY */}
        <Accordion.Item
          as="li"
          eventKey="sidebar-content-library"
          bsPrefix={`nav-item ${active === "content-library" ? "active" : ""} `}
          onClick={() => setActive("content-library")}
        >
          <CustomToggle
            eventKey="sidebar-content-library"
            onClick={(activeKey) => setActiveMenu(activeKey)}
          >
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip>Content Library</Tooltip>}
            >
              <i className="icon">
                {findIcon("Content Library", "dual-tone", "20")}
              </i>
            </OverlayTrigger>
            <span className="item-name">Content Library</span>
            <i className="right-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon-18"
                width="18"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </i>
          </CustomToggle>
          <Accordion.Collapse eventKey="sidebar-content-library">
            <ul className="sub-nav">
              <SidebarMenu
                isTag="false"
                minititle={findIcon("View Grid", "dual-tone", "18")}
                pathname="/content-library/dashboard"
                title="Dashboard"
              >
                <i className="icon">
                  {findIcon("View Grid", "dual-tone", "18")}
                </i>
              </SidebarMenu>
              <SidebarMenu
                isTag="false"
                minititle={findIcon("File", "dual-tone", "18")}
                pathname="/content-library/my-content"
                title="My Content "
              >
                <i className="icon">{findIcon("File", "dual-tone", "18")}</i>
              </SidebarMenu>
              <SidebarMenu
                isTag="false"
                minititle={findIcon("Research", "dual-tone", "18")}
                pathname="/content-library/research"
                title="Research"
              >
                <i className="icon">
                  {findIcon("Research", "dual-tone", "18")}
                </i>
              </SidebarMenu>
              <Nav.Item as="li">
                <Link
                  className={`${location.pathname === "/content-library/listing"
                      ? "active"
                      : ""
                    } nav-link`}
                  to="/content-library/listing"
                >
                  <i className="icon">
                    {findIcon("Folder", "dual-tone", "18")}
                  </i>
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip>Library </Tooltip>}
                  >
                    <i className="sidenav-mini-icon">
                      {findIcon("Folder", "dual-tone", "18")}
                    </i>
                  </OverlayTrigger>
                  <span className="item-name">Library </span>
                  <div>
                    <span className="badge bg-warning d-inline-block rounded-pill">
                      21
                    </span>
                  </div>
                </Link>
              </Nav.Item>
              <li className="nav-item ">
                <Link
                  className="nav-link"
                  data-bs-toggle="collapse"
                  to="#blog1"
                  role="button"
                  aria-expanded="false"
                  aria-controls="blog"
                >
                  <i className="icon">
                    {findIcon("Dashboard", "dual-tone", "18")}
                  </i>
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip>Browse by Category</Tooltip>}
                  >
                    <i className="sidenav-mini-icon">
                      {findIcon("Dashboard", "dual-tone", "18")}
                    </i>
                  </OverlayTrigger>
                  <span className="item-name">Browse by Category</span>

                  <i className="right-icon">
                    <svg
                      className="submit iocon-18"
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M9 5l7 7-7 7"
                      />
                    </svg>
                  </i>
                </Link>
                <ul
                  className="sub-nav collapse"
                  id="blog1"
                  data-bs-parent="#blog1"
                >
                  {fileTypes.map((item, index) => (
                    <SidebarMenu
                      isTag="false"
                      minititle={findIcon(
                        item.file_type_icon,
                        "dual-tone",
                        "18"
                      )}
                      pathname={
                        "/content-library/category/" + item.file_type_code
                      }
                      title={item.file_type_name}
                      key={index + 1}
                    >
                      <i className="icon">
                        {findIcon(item.file_type_icon, "dual-tone", "18")}
                      </i>
                    </SidebarMenu>
                  ))}
                </ul>
              </li>
              <li className="nav-item ">
                <Link
                  className="nav-link"
                  data-bs-toggle="collapse"
                  to="#Advanced"
                  role="button"
                  aria-expanded="false"
                  aria-controls="Advanced"
                >
                  <i className="icon">{findIcon("Setting", "dual-tone", "18")}
                  </i>
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip>Advanced</Tooltip>}
                  >
                    <i className="sidenav-mini-icon">{findIcon("Setting", "dual-tone", "18")}</i>
                  </OverlayTrigger>
                  {/* <i className="sidenav-mini-icon"> B C </i> */}
                  <span className="item-name">Advanced </span>
                  <i className="right-icon">
                    <svg
                      className="submit iocon-18"
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M9 5l7 7-7 7"
                      />
                    </svg>
                  </i>
                </Link>
                <ul
                  className="sub-nav collapse"
                  id="Advanced"
                  data-bs-parent="#Advanced"
                >
                  <li className="nav-item ">
                    <Link
                      className="nav-link"
                      data-bs-toggle="collapse"
                      to="#Content"
                      role="button"
                      aria-expanded="false"
                      aria-controls="Content"
                    >
                      <i className="icon">
                        {findIcon("Browse By Type", "dual-tone", "18")}
                      </i>
                      <OverlayTrigger
                        placement="right"
                        overlay={<Tooltip>Browse by Content Type</Tooltip>}
                      >
                        <i className="sidenav-mini-icon">{findIcon("Browse By Type", "dual-tone", "18")}</i>
                      </OverlayTrigger>
                      <span className="item-name">Browse by Type</span>
                      <i className="right-icon">
                        <svg
                          className="submit iocon-18"
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M9 5l7 7-7 7"
                          />
                        </svg>
                      </i>
                    </Link>
                    <ul
                      className="sub-nav collapse"
                      id="Content"
                      data-bs-parent="#Content"
                    >
                      <SidebarMenu
                        isTag="false"
                        minititle={findIcon("File", "dual-tone", "18")}

                        pathname="/content-library/advanced/content/article"
                        title="Article"
                      >
                        <i className="icon">
                          {findIcon("File", "dual-tone", "18")}

                        </i>
                      </SidebarMenu>
                      <SidebarMenu
                        isTag="false"
                        minititle={findIcon("Start", "dual-tone", "18")}
                        pathname="/content-library/advanced/content/video"
                        title="Video"
                      >
                        <i className="icon">
                          {findIcon("Start", "dual-tone", "18")}

                        </i>
                      </SidebarMenu>
                      <SidebarMenu
                        isTag="false"
                        minititle={findIcon("Microphone On", "dual-tone", "18")}
                        pathname="/content-library/advanced/content/audio"
                        title="Audio"
                      >
                        <i className="icon">
                          {findIcon("Microphone On", "dual-tone", "18")}
                        </i>
                      </SidebarMenu>
                      <SidebarMenu
                        isTag="false"
                        minititle={findIcon("Text", "dual-tone", "18")}
                        pathname="/content-library/advanced/content/text"
                        title="Text"
                      >
                        <i className="icon">
                          {findIcon("Text", "dual-tone", "18")}
                        </i>
                      </SidebarMenu>
                      <SidebarMenu
                        isTag="false"
                        minititle={findIcon("Photograph", "dual-tone", "18")}
                        pathname="/content-library/advanced/content/image"
                        title="Image"
                      >
                        <i className="icon">
                          {findIcon("Photograph", "dual-tone", "18")}
                        </i>
                      </SidebarMenu>
                      <SidebarMenu
                        isTag="false"
                        minititle={findIcon("User Group", "dual-tone", "18")}
                        pathname="/content-library/advanced/content/rtm"
                        title="RTM"
                      >
                        <i className="icon">
                          {findIcon("User Group", "dual-tone", "18")}
                        </i>
                      </SidebarMenu>
                      {/* <SidebarMenu
                        isTag="false"
                        minititle="BT"
                        pathname="/content-library/advanced/content/others"
                        title="Others"
                      >
                        <i className="icon">
                          <svg
                            width="22"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            class="icon-22"
                            height="22"
                          >
                            <path
                              opacity="0.4"
                              d="M22 11.9998C22 17.5238 17.523 21.9998 12 21.9998C6.477 21.9998 2 17.5238 2 11.9998C2 6.47776 6.477 1.99976 12 1.99976C17.523 1.99976 22 6.47776 22 11.9998Z"
                              fill="currentColor"
                            ></path>
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M7.52075 10.8035C6.85975 10.8035 6.32275 11.3405 6.32275 11.9995C6.32275 12.6595 6.85975 13.1975 7.52075 13.1975C8.18175 13.1975 8.71875 12.6595 8.71875 11.9995C8.71875 11.3405 8.18175 10.8035 7.52075 10.8035ZM11.9999 10.8035C11.3389 10.8035 10.8019 11.3405 10.8019 11.9995C10.8019 12.6595 11.3389 13.1975 11.9999 13.1975C12.6609 13.1975 13.1979 12.6595 13.1979 11.9995C13.1979 11.3405 12.6609 10.8035 11.9999 10.8035ZM15.2813 11.9995C15.2813 11.3405 15.8183 10.8035 16.4793 10.8035C17.1403 10.8035 17.6773 11.3405 17.6773 11.9995C17.6773 12.6595 17.1403 13.1975 16.4793 13.1975C15.8183 13.1975 15.2813 12.6595 15.2813 11.9995Z"
                              fill="currentColor"
                            ></path>
                          </svg>
                        </i>
                      </SidebarMenu> */}
                    </ul>
                  </li>
                  {/* <li className="nav-item ">
                    <Link
                      className="nav-link"
                      data-bs-toggle="collapse"
                      to="#Brand"
                      role="button"
                      aria-expanded="false"
                      aria-controls="Brand"
                    >
                      <i className="icon">
                        <svg
                          class="icon-22"
                          xmlns="http://www.w3.org/2000/svg"
                          id="Layer_2"
                          data-name="Layer 2"
                          viewBox="0 0 436.38 389.93"
                          width="20"
                          height="20"
                          fill="none"
                        >
                          <g id="Capa_1" data-name="Capa 1">
                            <path
                              d="m340.38,0h-133.98c-8.48,0-16.62,3.38-22.62,9.38L9.37,183.74c-12.5,12.5-12.5,32.75,0,45.26l133.98,133.98c12.5,12.5,32.75,12.5,45.25,0l174.4-174.37c6-6.02,9.38-14.16,9.38-22.66V32c0-17.68-14.32-32-32-32Zm-56,112c-13.25,0-24-10.75-24-24s10.75-24,24-24,24,10.75,24,24-10.75,24-24,24Z"
                              fill="currentColor"
                            />
                            <path
                              d="m404.38,32l-.02,148.94c0,7.38-2.93,14.46-8.16,19.68l-177.22,177.22,2.72,2.72c12.5,12.5,32.75,12.5,45.25,0l160.03-160c6.02-6,9.39-14.14,9.39-22.62V64c0-17.68-14.32-32-32-32Z"
                              fill="currentColor"
                              opacity="0.4"
                            />
                          </g>
                        </svg>
                      </i>
                      <OverlayTrigger
                        placement="right"
                        overlay={<Tooltip>Browse by Brand</Tooltip>}
                      >
                        <i className="sidenav-mini-icon"> B C</i>
                      </OverlayTrigger>
                      <i className="sidenav-mini-icon"> B C </i>
                      <span className="item-name">Browse by Brand</span>
                      <i className="right-icon">
                        <svg
                          className="submit iocon-18"
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M9 5l7 7-7 7"
                          />
                        </svg>
                      </i>
                    </Link>
                    <ul
                      className="sub-nav collapse"
                      id="Brand"
                      data-bs-parent="#Brand"
                    >
                      <SidebarMenu
                        isTag="false"
                        minititle="I"
                        pathname="/content-library/advanced/brand/listing"
                        title="Auto Generated from Brand List"
                      >
                        <i className="icon">
                          <svg
                            enable-background="new 0 0 512 512"
                            height="20"
                            viewBox="0 0 512 512"
                            width="20"
                            xmlns="http://www.w3.org/2000/svg"
                            class="icon-20"
                          >
                            <path
                              d="m467 391h-297.408c6.695 13.984 10.408 29.332 10.408 45 0 15.648-3.702 30.987-10.402 45h297.402c10.46 0 12.003-7.531 40.605-34.395 5.859-5.859 5.859-15.352 0-21.211-28.566-26.829-30.165-34.394-40.605-34.394z"
                              fill="currentColor"
                              opacity="0.4"
                            />
                            <path
                              d="m75 361c-41.353 0-75 33.647-75 75s33.647 76 75 76c42.997 0 75-36.225 75-76 0-39.788-32.062-75-75-75z"
                              fill="currentColor"
                            />
                            <path
                              d="m377 301c10.46 0 12.003-7.531 40.605-34.395 5.859-5.859 5.859-15.352 0-21.211-28.566-26.829-30.165-34.394-40.605-34.394h-207.408c6.695 13.984 10.408 29.332 10.408 45 0 15.648-3.702 30.987-10.402 45z"
                              fill="currentColor"
                              opacity="0.4"
                            />
                            <circle
                              cx="75"
                              cy="256"
                              r="75"
                              fill="currentColor"
                            />
                            <path
                              d="m257 121c10.46 0 12.003-7.531 40.605-34.395 5.859-5.859 5.859-15.352 0-21.211-28.566-26.829-30.165-34.394-40.605-34.394h-87.408c6.695 13.984 10.408 29.332 10.408 45 0 15.648-3.702 30.987-10.402 45z"
                              fill="currentColor"
                              opacity="0.4"
                            />
                            <path
                              d="m75 0c-41.353 0-75 34.647-75 76s33.647 75 75 75c42.997 0 75-35.225 75-75 0-39.788-32.062-76-75-76z"
                              fill="currentColor"
                            />
                          </svg>
                        </i>
                      </SidebarMenu>
                    </ul>
                  </li> */}
                  <SidebarMenu
                    isTag="false"
                    minititle={findIcon("Content Tree", "dual-tone", "18")}
                    pathname="/content-library/advanced/content-tree"
                    title="Browse Content Tree"
                  >
                    <i className="icon">
                      {findIcon("Content Tree", "dual-tone", "18")}
                    </i>
                  </SidebarMenu>
                </ul>
              </li>
            </ul>
          </Accordion.Collapse>
        </Accordion.Item>
        {/* CONTENT TAG BROWSING */}
        {/* <SidebarMenu
          isTag="true"
          pathname="/content-tags"
          title="Browse By Tags"
        >
          <i className="icon">
            <svg
              class="icon-22"
              xmlns="http://www.w3.org/2000/svg"
              id="Layer_2"
              data-name="Layer 2"
              viewBox="0 0 436.38 389.93"
              width="20"
              height="20"
              fill="none"
            >
              <g id="Capa_1" data-name="Capa 1">
                <path
                  d="m340.38,0h-133.98c-8.48,0-16.62,3.38-22.62,9.38L9.37,183.74c-12.5,12.5-12.5,32.75,0,45.26l133.98,133.98c12.5,12.5,32.75,12.5,45.25,0l174.4-174.37c6-6.02,9.38-14.16,9.38-22.66V32c0-17.68-14.32-32-32-32Zm-56,112c-13.25,0-24-10.75-24-24s10.75-24,24-24,24,10.75,24,24-10.75,24-24,24Z"
                  fill="currentColor"
                />
                <path
                  d="m404.38,32l-.02,148.94c0,7.38-2.93,14.46-8.16,19.68l-177.22,177.22,2.72,2.72c12.5,12.5,32.75,12.5,45.25,0l160.03-160c6.02-6,9.39-14.14,9.39-22.62V64c0-17.68-14.32-32-32-32Z"
                  fill="currentColor"
                  opacity="0.4"
                />
              </g>
            </svg>
          </i>
        </SidebarMenu> */}
        {/* VAULT */}
        <SidebarMenu isTag="true" pathname="/vault" title="Vault">
          <i className="icon">
            {findIcon("Bookmark", "dual-tone", "20")}
          </i>
        </SidebarMenu>
        {/* SEARCH */}
        <SidebarMenu isTag="true" pathname="/search" title="Search">
          <i className="icon">
            {findIcon("Search", "dual-tone", "20")}

          </i>
        </SidebarMenu>

        {/* TOOLS */}
        {/* <Accordion.Item
          as="li"
          eventKey="tools"
          bsPrefix={`nav-item ${active === "tools" ? "active" : ""} `}
          onClick={() => setActive("tools")}
        >
          <CustomToggle
            eventKey="tools"
            onClick={(activeKey) => setActiveMenu(activeKey)}
          >
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip>Tools</Tooltip>}
            >
              <i className="icon">
                <svg
                  fill="none"
                  class="icon-22"
                  enable-background="new 0 0 512.001 512.001"
                  height="22"
                  viewBox="0 0 512.001 512.001"
                  width="22"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g>
                    <path
                      d="m163.748 231.581c1.586 5.936 6.639 10.285 12.709 11.01l83.051 9.902 9.902 83.051c.729 6.156 5.176 11.153 11.01 12.709 5.934 1.584 12.222-.601 15.899-5.5l50.092-66.974 76.866 32.948c5.645 2.413 12.182 1.161 16.522-3.179s5.594-10.876 3.181-16.522l-32.95-76.866 66.974-50.092c4.9-3.677 7.086-9.965 5.5-15.899-1.584-5.936-6.639-10.307-12.709-11.01l-83.049-9.902-9.902-83.051c-.716-6.081-5.076-11.125-11.012-12.709-5.934-1.586-12.222.601-15.899 5.5l-50.09 66.974-76.868-32.948c-5.645-2.415-12.18-1.161-16.522 3.179-4.34 4.34-5.592 10.876-3.179 16.522l32.948 76.866-66.974 50.092c-4.899 3.677-7.083 9.965-5.5 15.899z"
                      fill="currentColor"
                    />
                    <path
                      d="m439.8 93.416 21.213-21.213c5.863-5.863 5.863-15.35 0-21.213-5.861-5.863-15.35-5.863-21.213 0l-21.213 21.213c-5.863 5.863-5.863 15.352 0 21.213 5.863 5.863 15.35 5.863 21.213 0z"
                      fill="currentColor"
                    />
                    <path
                      d="m288.191 58.479c8.005-2.144 12.75-10.369 10.605-18.375l-7.767-28.982c-2.166-8.007-10.369-12.751-18.376-10.607-8.005 2.144-12.75 10.369-10.605 18.375l7.767 28.982c2.123 7.93 10.306 12.757 18.376 10.607z"
                      fill="currentColor"
                    />
                    <path
                      d="m358.065 319.272c-8.007 2.144-12.751 10.369-10.607 18.375l7.769 28.982c2.122 7.93 10.305 12.757 18.375 10.607 8.007-2.144 12.751-10.369 10.607-18.375l-7.769-28.982c-2.155-7.995-10.378-12.762-18.375-10.607z"
                      fill="currentColor"
                    />
                    <path
                      d="m182.125 135.563-28.982-7.767c-8.007-2.144-16.232 2.598-18.376 10.605-2.151 8.082 2.688 16.256 10.607 18.375l28.982 7.769c8.007 2.144 16.23-2.6 18.375-10.607 2.133-8.017-2.632-16.242-10.606-18.375z"
                      fill="currentColor"
                    />
                    <path
                      d="m453.524 223.814c-2.151 8.08 2.688 16.254 10.607 18.375l28.982 7.767c8.007 2.144 16.23-2.598 18.375-10.605s-2.64-16.252-10.605-18.375l-28.982-7.769c-8.008-2.144-16.233 2.6-18.377 10.607z"
                      fill="currentColor"
                    />
                    <path
                      d="m25.611 507.603c5.861 5.863 15.35 5.863 21.213 0l190.204-190.202-4.519-37.908-37.905-4.519-190.208 190.204c-5.861 5.863-5.861 15.35 0 21.213z"
                      fill="currentColor"
                      opacity="0.4"
                    />
                  </g>
                </svg>
              </i>
            </OverlayTrigger>
            <span className="item-name">Tools</span>
            <i className="right-icon">
              <svg
                className="icon-18"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </i>
          </CustomToggle>
          <Accordion.Collapse eventKey="tools">
            <ul className="sub-nav">
              <SidebarMenu
                isTag="false"
                // staticIcon='true'
                minititle="AU"
                pathname="/tools/page-generator"
                title="Page Generator"
              >
                <i className="icon">
                  <svg
                    width="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon-22"
                    height="20"
                  >
                    <path
                      opacity="0.4"
                      d="M18.8088 9.021C18.3573 9.021 17.7592 9.011 17.0146 9.011C15.1987 9.011 13.7055 7.508 13.7055 5.675V2.459C13.7055 2.206 13.5036 2 13.253 2H7.96363C5.49517 2 3.5 4.026 3.5 6.509V17.284C3.5 19.889 5.59022 22 8.16958 22H16.0463C18.5058 22 20.5 19.987 20.5 17.502V9.471C20.5 9.217 20.299 9.012 20.0475 9.013C19.6247 9.016 19.1177 9.021 18.8088 9.021Z"
                      fill="currentColor"
                    ></path>
                    <path
                      opacity="0.4"
                      d="M16.0842 2.56737C15.7852 2.25637 15.2632 2.47037 15.2632 2.90137V5.53837C15.2632 6.64437 16.1742 7.55437 17.2802 7.55437C17.9772 7.56237 18.9452 7.56437 19.7672 7.56237C20.1882 7.56137 20.4022 7.05837 20.1102 6.75437C19.0552 5.65737 17.1662 3.69137 16.0842 2.56737Z"
                      fill="currentColor"
                    ></path>
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M8.97398 11.3877H12.359C12.77 11.3877 13.104 11.0547 13.104 10.6437C13.104 10.2327 12.77 9.89868 12.359 9.89868H8.97398C8.56298 9.89868 8.22998 10.2327 8.22998 10.6437C8.22998 11.0547 8.56298 11.3877 8.97398 11.3877ZM8.97408 16.3819H14.4181C14.8291 16.3819 15.1631 16.0489 15.1631 15.6379C15.1631 15.2269 14.8291 14.8929 14.4181 14.8929H8.97408C8.56308 14.8929 8.23008 15.2269 8.23008 15.6379C8.23008 16.0489 8.56308 16.3819 8.97408 16.3819Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </i>
              </SidebarMenu>
              <SidebarMenu
                isTag="false"
                // staticIcon='true'
                minititle="AU"
                pathname="/tools/email-generator"
                title="Email Generator"
              >
                <i className="icon">
                  <svg
                    width="18"
                    height="18"
                    xmlns="http://www.w3.org/2000/svg"
                    data-name="Layer 2"
                    viewBox="0 0 512 511.94"
                    fill="none"
                    class="icon-18"
                  >
                    <g id="Layer_1-2" data-name="Layer 1">
                      <path
                        id="Shape"
                        d="m271.91,153.26c-7.75-7.93-19.83-9.73-29.57-4.42-10.18,5.74-18.37,14.46-23.45,24.99-2.14,3.94-3.86,8.1-5.15,12.39-3.55,12.02-2.77,27.7,7.03,35.55,10.72,8.61,28.8,4.97,38.97-3.81,7.23-6.51,13.48-14.04,18.55-22.34,1.73-7.52,3.38-15.25,4.9-22.58-.27-.37-.5-.76-.71-1.17-2.17-6.88-5.77-13.23-10.58-18.61h0Z"
                        fill="currentColor"
                      />
                      <path
                        opacity="0.4"
                        id="Shape-2"
                        data-name="Shape"
                        d="m221.06,12.55l-20.87,14.82h111.59l-20.3-14.39c-20.29-17.14-49.93-17.32-70.42-.42h0Z"
                        fill="currentColor"
                      />
                      <path
                        opacity="0.4"
                        id="Shape-3"
                        data-name="Shape"
                        d="m448,213.41l58.14-48.32-58.14-41.22v89.54Z"
                        fill="currentColor"
                      />
                      <path
                        id="Shape-4"
                        data-name="Shape"
                        d="m82.19,46.05l.09,182.56,138.76,115.32c20.3,16.76,49.64,16.76,69.93-.01l138.74-115.31V45.73c-.03.19-.39-.07-1.01-.07H83.29c-.4-.02-.8.12-1.1.39h0Zm98.49,71.52c3.08-3,6.29-5.88,9.62-8.63,29.44-24.64,69.12-33.21,106.1-22.9,35.8,11.43,60.54,44.14,61.78,81.71,1.34,21.62-5.71,42.93-19.67,59.48-10.77,13.57-28.23,19.98-45.22,16.61-6.95-1.44-13.02-5.65-16.8-11.66-.62-1.09-1.15-2.23-1.58-3.4-1.06,1.01-2.09,2.07-3.17,3.01-10,8.44-22.63,13.12-35.71,13.24-9.66.17-19.09-3-26.69-8.97-17.55-14.04-18.03-38.44-13.11-55.06,1.66-5.53,3.88-10.88,6.64-15.96,6.86-13.84,17.83-25.22,31.41-32.59,16.91-8.77,37.57-5.45,50.87,8.19,1.27,1.34,2.46,2.75,3.58,4.22.97-5.21,1.55-8.46,1.57-8.59.57-3.22,2.81-5.89,5.88-7s6.5-.51,9.01,1.59,3.69,5.38,3.13,8.59c-.34,1.91-8.34,47.15-16.54,78.11-.58,1.72-.44,3.6.41,5.2,1.26,1.75,3.17,2.92,5.3,3.27,10.4,1.74,20.91-2.44,27.29-10.84,10.85-12.98,16.28-29.63,15.18-46.51-.89-29.9-20.5-56-48.96-65.19-31.07-8.55-64.36-1.25-89,19.52-2.94,2.44-5.79,4.99-8.54,7.66-14.21,13.83-36.91,42.51-29.26,79.36,5.14,25.74,23.52,46.84,48.32,55.45,42.88,13.68,104.5,15.33,136.96-25.04,3.19-3.84,8.87-4.41,12.76-1.29s4.55,8.8,1.49,12.74c-24,29.85-60.54,40.53-96.37,40.53-20.49-.1-40.86-3.31-60.38-9.52-31.2-10.44-54.38-36.86-60.67-69.15-6.89-33.15,5.66-68.21,34.41-96.17h0Z"
                        fill="currentColor"
                      />
                      <path
                        opacity="0.4"
                        id="Shape-5"
                        data-name="Shape"
                        d="m512,501.61V184l-191.1,158.83,191.1,158.78Z"
                        fill="currentColor"
                      />
                      <path
                        opacity="0.4"
                        id="Shape-6"
                        data-name="Shape"
                        d="m0,184v317.6l191.1-158.77L0,184Z"
                        fill="currentColor"
                      />
                      <path
                        opacity="0.4"
                        id="Shape-7"
                        data-name="Shape"
                        d="m306.59,354.71l-3.94,3.28c-27.07,22.37-66.21,22.38-93.28.01l-3.96-3.29L16.17,511.94h479.67l-189.24-157.23Z"
                        fill="currentColor"
                      />
                      <path
                        opacity="0.4"
                        id="Shape-8"
                        data-name="Shape"
                        d="m5.85,165.09l58.15,48.33v-89.55L5.85,165.09Z"
                        fill="currentColor"
                      />
                    </g>
                  </svg>
                </i>
              </SidebarMenu>
              <SidebarMenu
                isTag="false"
                // staticIcon='true'
                minititle="AU"
                pathname="/tools/image-generator"
                title="Image Generator"
              >
                <i className="icon">
                  <svg
                    width="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon-22"
                    height="20"
                  >
                    <path
                      d="M21.9999 14.7024V16.0859C21.9999 16.3155 21.9899 16.5471 21.9699 16.7767C21.6893 19.9357 19.4949 22 16.3286 22H7.67126C6.06806 22 4.71535 21.4797 3.74341 20.5363C3.36265 20.1864 3.042 19.7753 2.7915 19.3041C3.12217 18.9021 3.49291 18.462 3.85363 18.0208C4.46485 17.289 5.05603 16.5661 5.42677 16.0959C5.97788 15.4142 7.43078 13.6196 9.44481 14.4617C9.85563 14.6322 10.2164 14.8728 10.547 15.0833C11.3586 15.6247 11.6993 15.7851 12.2705 15.4743C12.9017 15.1335 13.3125 14.4617 13.7434 13.76C13.9739 13.388 14.2043 13.0281 14.4548 12.6972C15.547 11.2736 17.2304 10.8926 18.6332 11.7348C19.3346 12.1559 19.9358 12.6872 20.4969 13.2276C20.6172 13.3479 20.7374 13.4592 20.8476 13.5695C20.9979 13.7198 21.4989 14.2211 21.9999 14.7024Z"
                      fill="currentColor"
                    ></path>
                    <path
                      opacity="0.4"
                      d="M16.3387 2H7.67134C4.27455 2 2 4.37607 2 7.91411V16.086C2 17.3181 2.28056 18.4119 2.79158 19.3042C3.12224 18.9022 3.49299 18.4621 3.85371 18.0199C4.46493 17.2891 5.05611 16.5662 5.42685 16.096C5.97796 15.4143 7.43086 13.6197 9.44489 14.4618C9.85571 14.6323 10.2164 14.8729 10.5471 15.0834C11.3587 15.6248 11.6994 15.7852 12.2705 15.4734C12.9018 15.1336 13.3126 14.4618 13.7435 13.759C13.9739 13.3881 14.2044 13.0282 14.4549 12.6973C15.5471 11.2737 17.2305 10.8927 18.6333 11.7349C19.3347 12.1559 19.9359 12.6873 20.497 13.2277C20.6172 13.348 20.7375 13.4593 20.8477 13.5696C20.998 13.7189 21.499 14.2202 22 14.7025V7.91411C22 4.37607 19.7255 2 16.3387 2Z"
                      fill="currentColor"
                    ></path>
                    <path
                      d="M11.4543 8.79668C11.4543 10.2053 10.2809 11.3783 8.87313 11.3783C7.46632 11.3783 6.29297 10.2053 6.29297 8.79668C6.29297 7.38909 7.46632 6.21509 8.87313 6.21509C10.2809 6.21509 11.4543 7.38909 11.4543 8.79668Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </i>
              </SidebarMenu>
            </ul>
          </Accordion.Collapse>
        </Accordion.Item> */}
        {/* MASTER MANAGEMENT*/}
        <Accordion.Item
          as="li"
          eventKey="master-management"
          bsPrefix={`nav-item ${active === "master-management" ? "active" : ""
            } `}
          onClick={() => setActive("master-management")}
        >
          <CustomToggle
            eventKey="master-management"
            onClick={(activeKey) => setActiveMenu(activeKey)}
          >
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip>Master Management</Tooltip>}
            >
              <i className="icon">
                {findIcon("Master Management", "dual-tone", "20")}

              </i>
            </OverlayTrigger>
            <span className="item-name">Master Management</span>
            <i className="right-icon">
              <svg
                className="icon-18"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </i>
          </CustomToggle>
          <Accordion.Collapse eventKey="master-management">
            <ul className="sub-nav">
              <SidebarMenu
                isTag="false"
                pathname="/master-management/speciality/all"
                minititle={findIcon("Star", "dual-tone", "18")}
                title="Speciality"

              >
                <i className="icon">
                  {findIcon("Star", "dual-tone", "18")}
                </i>
              </SidebarMenu>
              <SidebarMenu
                isTag="false"
                minititle={findIcon("Link", "dual-tone", "18")}
                pathname="/master-management/target-source/all"
                title="Target Source"
              >
                <i className="icon">
                  {findIcon("Link", "dual-tone", "18")}
                </i>
              </SidebarMenu>
              <SidebarMenu
                isTag="false"
                minititle={findIcon("Content Tree", "dual-tone", "18")}
                pathname="/master-management/department/all"
                title="Department"
              >
                <i className="icon">
                  {findIcon("Content Tree", "dual-tone", "18")}
                </i>
              </SidebarMenu>
              <SidebarMenu
                isTag="false"
                minititle={findIcon("Chevron Arrow Right", "dual-tone", "18")}
                pathname="/master-management/action-type/all"
                title="Action Type"
              >
                <i className="icon">
                  {findIcon("Chevron Arrow Right", "dual-tone", "18")}
                </i>
              </SidebarMenu>
              <SidebarMenu
                isTag="false"
                minititle={findIcon("Shield Check", "dual-tone", "18")}
                pathname="/master-management/priority/all"
                title="Priority"
              >
                <i className="icon">
                  {findIcon("Shield Check", "dual-tone", "18")}
                </i>
              </SidebarMenu>
              <SidebarMenu
                isTag="false"
                minititle={findIcon("View Grid", "dual-tone", "18")}
                pathname="/master-management/content-category/all"
                title="Content Category"
              >
                <i className="icon">
                  {findIcon("View Grid", "dual-tone", "18")}
                </i>
              </SidebarMenu>
              <SidebarMenu
                isTag="false"
                minititle={findIcon("File", "dual-tone", "18")}
                pathname="/master-management/principle-entity/all"
                title="Principle Entity"
              >
                <i className="icon">
                  {findIcon("File", "dual-tone", "18")}
                </i>
              </SidebarMenu>
              <SidebarMenu
                isTag="false"
                minititle={findIcon("User", "dual-tone", "18")}
                pathname="/master-management/client/all"
                title="Client"
              >
                <i className="icon">
                  {findIcon("User", "dual-tone", "18")}
                </i>
              </SidebarMenu>
              <SidebarMenu
                isTag="false"
                minititle={findIcon("Browse By Type", "dual-tone", "18")}
                pathname="/master-management/file-type/all"
                title="File Type"
              >
                <i className="icon">
                  {findIcon("Browse By Type", "dual-tone", "18")}
                </i>
              </SidebarMenu>
              <SidebarMenu
                isTag="false"
                minititle={findIcon("View Grid", "dual-tone", "18")}
                pathname="/master-management/menu/all"
                title="Menu"
              >
                <i className="icon">
                  {findIcon("View Grid", "dual-tone", "18")}
                </i>
              </SidebarMenu>
              <SidebarMenu
                isTag="false"
                minititle={findIcon("User Group", "dual-tone", "18")}
                pathname="/master-management/council/all"
                title="Council"
              >
                <i className="icon">
                  {findIcon("User Group", "dual-tone", "18")}
                </i>
              </SidebarMenu>
              <SidebarMenu
                isTag="false"
                minititle={findIcon("Location", "dual-tone", "18")}
                pathname="/master-management/country/all"
                title="Country"
              >
                <i className="icon">
                  {findIcon("Location", "dual-tone", "18")}
                </i>
              </SidebarMenu>
              <SidebarMenu
                isTag="false"
                minititle={findIcon("Location", "dual-tone", "18")}
                pathname="/master-management/city/all"
                title="City"
              >
                <i className="icon">
                  {findIcon("Location", "dual-tone", "18")}
                </i>
              </SidebarMenu>
              <SidebarMenu
                isTag="false"
                minititle={findIcon("Location", "dual-tone", "18")}
                pathname="/master-management/state/all"
                title="State"
              >
                <i className="icon">
                  {findIcon("Location", "dual-tone", "18")}
                </i>
              </SidebarMenu>
              <SidebarMenu
                isTag="false"
                minititle={findIcon("Location", "dual-tone", "18")}
                pathname="/master-management/zone/all"
                title="Zone"
              >
                <i className="icon">
                  {findIcon("Location", "dual-tone", "18")}
                </i>
              </SidebarMenu>
              <SidebarMenu
                isTag="false"
                minititle={findIcon("Home", "dual-tone", "18")}
                pathname="/master-management/association/all"
                title="Association"
              >
                <i className="icon">
                  {findIcon("Home", "dual-tone", "18")}
                </i>
              </SidebarMenu>
            </ul>
          </Accordion.Collapse>
        </Accordion.Item>

        {/* GRAND ROUND */}
        {/* <Accordion.Item
          as="li"
          eventKey="grand-round"
          bsPrefix={`nav-item ${active === "grand-round" ? "active" : ""} `}
          onClick={() => setActive("grand-round")}
        >
          <CustomToggle
            eventKey="grand-round"
            onClick={(activeKey) => setActiveMenu(activeKey)}
          >
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip>Grand Round</Tooltip>}
            >
              <i className="icon">
                {findIcon("Content Library", "dual-tone", "20")}
              </i>
            </OverlayTrigger>
            <span className="item-name">Grand Round</span>
            <i className="right-icon">
              <svg
                className="icon-18"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </i>
          </CustomToggle>
          <Accordion.Collapse eventKey="grand-round">
            <ul className="sub-nav">
              <SidebarMenu
                isTag="false"
                // staticIcon='true'
                minititle={findIcon("List", "dual-tone", "18")}

                pathname="/gr/listing"
                title="All Gr"
              >
                <i className="icon">
                  {findIcon("List", "dual-tone", "18")}

                </i>
              </SidebarMenu>
            </ul>
          </Accordion.Collapse>
        </Accordion.Item> */}

        {/* COURSES */}
        {/* <Accordion.Item
          as="li"
          eventKey="courses"
          bsPrefix={`nav-item ${active === "courses" ? "active" : ""} `}
          onClick={() => setActive("courses")}
        >
          <CustomToggle
            eventKey="courses"
            onClick={(activeKey) => setActiveMenu(activeKey)}
          >
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip>Courses</Tooltip>}
            >
              <i className="icon">
                {findIcon("Presentations", "dual-tone", "20")}

              </i>
            </OverlayTrigger>
            <span className="item-name">Courses</span>
            <i className="right-icon">
              <svg
                className="icon-18"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </i>
          </CustomToggle>
          <Accordion.Collapse eventKey="courses">
            <ul className="sub-nav">
              <SidebarMenu
                isTag="false"
                // staticIcon='true'
                minititle={findIcon("List", "dual-tone", "18")}

                pathname="/courses/listing"
                title="All Courses"
              >
                <i className="icon">
                  {findIcon("List", "dual-tone", "18")}

                </i>
              </SidebarMenu>
            </ul>
          </Accordion.Collapse>
        </Accordion.Item> */}
        {/* SURVEY */}
        {/* <Accordion.Item
          as="li"
          eventKey="spq"
          bsPrefix={`nav-item ${active === "spq" ? "active" : ""} `}
          onClick={() => setActive("spq")}
        >
          <CustomToggle
            eventKey="spq"
            onClick={(activeKey) => setActiveMenu(activeKey)}
          >
            <OverlayTrigger placement="right" overlay={<Tooltip>Spq</Tooltip>}>
              <i className="icon">
                {findIcon("Requisition", "dual-tone", "20")}

              </i>
            </OverlayTrigger>
            <span className="item-name">Spq</span>
            <i className="right-icon">
              <svg
                className="icon-18"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </i>
          </CustomToggle>
          <Accordion.Collapse eventKey="spq">
            <ul className="sub-nav">
              <SidebarMenu
                isTag="false"
                // staticIcon='true'
                minititle={findIcon("Requisition", "dual-tone", "18")}

                pathname="/spq/survey-listing"
                title="All Survey"
              >
                <i className="icon">
                  {findIcon("Requisition", "dual-tone", "18")}

                </i>
              </SidebarMenu>
              <SidebarMenu
                isTag="false"
                // staticIcon='true'
                minititle={findIcon("Chart Square Bar", "dual-tone", "18")}

                pathname="/spq/poll-listing"
                title="All Poll"
              >
                <i className="icon">
                  {findIcon("Chart Square Bar", "dual-tone", "18")}

                </i>
              </SidebarMenu>
              <SidebarMenu
                isTag="false"
                // staticIcon='true'
                minititle={findIcon("Suggestion", "dual-tone", "18")}

                pathname="/spq/quiz-listing"
                title="All Quiz"
              >
                <i className="icon">
                  {findIcon("Suggestion", "dual-tone", "18")}
                </i>
              </SidebarMenu>
            </ul>
          </Accordion.Collapse>
        </Accordion.Item> */}
        {/* CHANNEL */}
        {/* <Accordion.Item
          as="li"
          eventKey="channel"
          bsPrefix={`nav-item ${active === "channel" ? "active" : ""} `}
          onClick={() => setActive("channel")}
        >
          <CustomToggle
            eventKey="channel"
            onClick={(activeKey) => setActiveMenu(activeKey)}
          >
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip>Channel</Tooltip>}
            >
              <i className="icon">
                {findIcon("Suggestion", "dual-tone", "20")}
              </i>
            </OverlayTrigger>
            <span className="item-name">Channel</span>
            <i className="right-icon">
              <svg
                className="icon-18"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </i>
          </CustomToggle>
          <Accordion.Collapse eventKey="channel">
            <ul className="sub-nav">
              <SidebarMenu
                isTag="false"
                // staticIcon='true'
                minititle={findIcon("List", "dual-tone", "18")}
                pathname="/channel/listing"
                title="All Channel"
              >
                <i className="icon">
                  {findIcon("List", "dual-tone", "18")}
                </i>
              </SidebarMenu>
              <SidebarMenu
                isTag="false"
                // staticIcon='true'
                minititle={findIcon("Election", "dual-tone", "18")}
                pathname="/channel/election"
                title="Election"
              >
                <i className="icon">
                  {findIcon("Election", "dual-tone", "18")}
                </i>
              </SidebarMenu>
              <SidebarMenu
                isTag="false"
                // staticIcon='true'
                minititle={findIcon("User Group", "dual-tone", "18")}
                pathname="/channel/user"
                title="User"
              >
                <i className="icon">
                  {findIcon("User Group", "dual-tone", "18")}
                </i>
              </SidebarMenu>
              <SidebarMenu
                isTag="false"
                // staticIcon='true'
                minititle={findIcon("Location Arrow", "dual-tone", "18")}

                pathname="/channel/share-page"
                title="Share page"
              >
                <i className="icon">
                  {findIcon("Location Arrow", "dual-tone", "18")}

                </i>
              </SidebarMenu>
              <SidebarMenu
                isTag="false"
                // staticIcon='true'
                minititle={findIcon("Heart", "dual-tone", "18")}

                pathname="/channel/subscription"
                title="Subscription"
              >
                <i className="icon">
                  {findIcon("Heart", "dual-tone", "18")}

                </i>
              </SidebarMenu>
            </ul>
          </Accordion.Collapse>
        </Accordion.Item> */}
        {/* CAMPAIGN */}
        {/* <Accordion.Item
          as="li"
          eventKey="campaign"
          bsPrefix={`nav-item ${active === "campaign" ? "active" : ""} `}
          onClick={() => setActive("campaign")}
        >
          <CustomToggle
            eventKey="campaign"
            onClick={(activeKey) => setActiveMenu(activeKey)}
          >
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip>Campaign</Tooltip>}
            >
              <i className="icon">
                {findIcon("Campaign", "dual-tone", "20")}
              </i>
            </OverlayTrigger>
            <span className="item-name">Campaign</span>
            <i className="right-icon">
              <svg
                className="icon-18"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </i>
          </CustomToggle>
          <Accordion.Collapse eventKey="campaign">
            <ul className="sub-nav">
              <SidebarMenu
                isTag="false"
                // staticIcon='true'
                minititle={findIcon("List", "dual-tone", "18")}

                pathname="/campaign/listing"
                title="All Campaign"
              >
                <i className="icon">
                  {findIcon("List", "dual-tone", "18")}

                </i>
              </SidebarMenu>
              <SidebarMenu
                isTag="false"
                // staticIcon='true'
                minititle={findIcon("Ad Board", "dual-tone", "18")}

                pathname="/campaign/ad-campaign"
                title="Ad Campaign"
              >
                <i className="icon">
                  {findIcon("Ad Board", "dual-tone", "18")}

                </i>
              </SidebarMenu>
              <SidebarMenu
                isTag="false"
                // staticIcon='true'
                minititle={findIcon("Setting", "dual-tone", "18")}

                pathname="/campaign/manage-creative"
                title="Manage Creative"
              >
                <i className="icon">
                  {findIcon("Setting", "dual-tone", "18")}

                </i>
              </SidebarMenu>
            </ul>
          </Accordion.Collapse>
        </Accordion.Item> */}
        {/*CLIENT-CAMPAIGN */}
        {/* <Accordion.Item
          as="li"
          eventKey="client-campaign"
          bsPrefix={`nav-item ${active === "client-campaign" ? "active" : ""} `}
          onClick={() => setActive("client-campaign")}
        >
          <CustomToggle
            eventKey="client-campaign"
            onClick={(activeKey) => setActiveMenu(activeKey)}
          >
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip>Client Campaign</Tooltip>}
            >
              <i className="icon">
                <svg
                  height="20"
                  viewBox="0 0 512 512"
                  width="20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon-22"
                >
                  <path
                    d="m429.058594 166.4375 36.738281-61.257812-58.976563-58.976563-61.261718 36.738281c-9.738282-5.050781-19.964844-9.269531-30.511719-12.625l-17.34375-70.316406h-83.40625l-17.34375 70.316406c-10.546875 3.355469-20.773437 7.574219-30.515625 12.625l-61.257812-36.738281-58.976563 58.976563 36.738281 61.261718c-5.050781 9.738282-9.269531 19.964844-12.625 30.511719l-70.316406 17.34375v83.40625l70.316406 17.34375c3.355469 10.546875 7.574219 20.773437 12.625 30.515625l-36.738281 61.257812 58.976563 58.976563 61.261718-36.738281c9.738282 5.054687 19.964844 9.269531 30.511719 12.625l17.34375 70.316406h83.40625l17.34375-70.316406c10.546875-3.355469 20.773437-7.574219 30.515625-12.625l61.257812 36.738281 58.976563-58.976563-36.738281-61.261718c5.054687-9.738282 9.269531-19.964844 12.625-30.511719l70.316406-17.34375v-83.40625l-70.316406-17.34375c-3.355469-10.546875-7.574219-20.773437-12.625-30.515625zm-173.058594 224.5625c-74.441406 0-135-60.558594-135-135s60.558594-135 135-135 135 60.558594 135 135-60.558594 135-135 135zm0 0"
                    fill="currentColor"
                    opacity="0.4"
                  />
                  <path
                    d="m301 226c0-24.8125-20.1875-45-45-45s-45 20.1875-45 45 20.1875 45 45 45 45-20.1875 45-45zm0 0"
                    fill="currentColor"
                  />
                  <path
                    d="m256 271c-36 0-66.300781 25.5-73.5 59.699219 18.898438 18.902343 45 30.300781 73.5 30.300781s54.601562-11.398438 73.5-30.300781c-7.042969-34.503907-37.59375-59.699219-73.5-59.699219zm0 0"
                    fill="currentColor"
                  />
                </svg>
              </i>
            </OverlayTrigger>
            <span className="item-name">Client Campaign</span>
            <i className="right-icon">
              <svg
                className="icon-18"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </i>
          </CustomToggle>
          <Accordion.Collapse eventKey="client-campaign">
            <ul className="sub-nav">
              <SidebarMenu
                isTag="false"
                // staticIcon='true'
                minititle="AU"
                pathname="/client-campaign/brand-report"
                title="Brand Report"
              >
                <i className="icon">
                  <svg
                    width="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon-22"
                    height="20"
                  >
                    <path
                      d="M11.9488 14.54C8.49884 14.54 5.58789 15.1038 5.58789 17.2795C5.58789 19.4562 8.51765 20.0001 11.9488 20.0001C15.3988 20.0001 18.3098 19.4364 18.3098 17.2606C18.3098 15.084 15.38 14.54 11.9488 14.54Z"
                      fill="currentColor"
                    ></path>
                    <path
                      opacity="0.4"
                      d="M11.949 12.467C14.2851 12.467 16.1583 10.5831 16.1583 8.23351C16.1583 5.88306 14.2851 4 11.949 4C9.61293 4 7.73975 5.88306 7.73975 8.23351C7.73975 10.5831 9.61293 12.467 11.949 12.467Z"
                      fill="currentColor"
                    ></path>
                    <path
                      opacity="0.4"
                      d="M21.0881 9.21923C21.6925 6.84176 19.9205 4.70654 17.664 4.70654C17.4187 4.70654 17.1841 4.73356 16.9549 4.77949C16.9244 4.78669 16.8904 4.802 16.8725 4.82902C16.8519 4.86324 16.8671 4.90917 16.8895 4.93889C17.5673 5.89528 17.9568 7.0597 17.9568 8.30967C17.9568 9.50741 17.5996 10.6241 16.9728 11.5508C16.9083 11.6462 16.9656 11.775 17.0793 11.7948C17.2369 11.8227 17.3981 11.8371 17.5629 11.8416C19.2059 11.8849 20.6807 10.8213 21.0881 9.21923Z"
                      fill="currentColor"
                    ></path>
                    <path
                      d="M22.8094 14.817C22.5086 14.1722 21.7824 13.73 20.6783 13.513C20.1572 13.3851 18.747 13.205 17.4352 13.2293C17.4155 13.232 17.4048 13.2455 17.403 13.2545C17.4003 13.2671 17.4057 13.2887 17.4316 13.3022C18.0378 13.6039 20.3811 14.916 20.0865 17.6834C20.074 17.8032 20.1698 17.9068 20.2888 17.8888C20.8655 17.8059 22.3492 17.4853 22.8094 16.4866C23.0637 15.9589 23.0637 15.3456 22.8094 14.817Z"
                      fill="currentColor"
                    ></path>
                    <path
                      opacity="0.4"
                      d="M7.04459 4.77973C6.81626 4.7329 6.58077 4.70679 6.33543 4.70679C4.07901 4.70679 2.30701 6.84201 2.9123 9.21947C3.31882 10.8216 4.79355 11.8851 6.43661 11.8419C6.60136 11.8374 6.76343 11.8221 6.92013 11.7951C7.03384 11.7753 7.09115 11.6465 7.02668 11.551C6.3999 10.6234 6.04263 9.50765 6.04263 8.30991C6.04263 7.05904 6.43303 5.89462 7.11085 4.93913C7.13234 4.90941 7.14845 4.86348 7.12696 4.82926C7.10906 4.80135 7.07593 4.78694 7.04459 4.77973Z"
                      fill="currentColor"
                    ></path>
                    <path
                      d="M3.32156 13.5127C2.21752 13.7297 1.49225 14.1719 1.19139 14.8167C0.936203 15.3453 0.936203 15.9586 1.19139 16.4872C1.65163 17.4851 3.13531 17.8066 3.71195 17.8885C3.83104 17.9065 3.92595 17.8038 3.91342 17.6832C3.61883 14.9167 5.9621 13.6046 6.56918 13.3029C6.59425 13.2885 6.59962 13.2677 6.59694 13.2542C6.59515 13.2452 6.5853 13.2317 6.5656 13.2299C5.25294 13.2047 3.84358 13.3848 3.32156 13.5127Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </i>
              </SidebarMenu>
              <SidebarMenu
                isTag="false"
                // staticIcon='true'
                minititle="AU"
                pathname="/client-campaign/brand-management"
                title="Brand Management"
              >
                <i className="icon">
                  <svg
                    width="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon-22"
                    height="20"
                  >
                    <path
                      d="M11.9488 14.54C8.49884 14.54 5.58789 15.1038 5.58789 17.2795C5.58789 19.4562 8.51765 20.0001 11.9488 20.0001C15.3988 20.0001 18.3098 19.4364 18.3098 17.2606C18.3098 15.084 15.38 14.54 11.9488 14.54Z"
                      fill="currentColor"
                    ></path>
                    <path
                      opacity="0.4"
                      d="M11.949 12.467C14.2851 12.467 16.1583 10.5831 16.1583 8.23351C16.1583 5.88306 14.2851 4 11.949 4C9.61293 4 7.73975 5.88306 7.73975 8.23351C7.73975 10.5831 9.61293 12.467 11.949 12.467Z"
                      fill="currentColor"
                    ></path>
                    <path
                      opacity="0.4"
                      d="M21.0881 9.21923C21.6925 6.84176 19.9205 4.70654 17.664 4.70654C17.4187 4.70654 17.1841 4.73356 16.9549 4.77949C16.9244 4.78669 16.8904 4.802 16.8725 4.82902C16.8519 4.86324 16.8671 4.90917 16.8895 4.93889C17.5673 5.89528 17.9568 7.0597 17.9568 8.30967C17.9568 9.50741 17.5996 10.6241 16.9728 11.5508C16.9083 11.6462 16.9656 11.775 17.0793 11.7948C17.2369 11.8227 17.3981 11.8371 17.5629 11.8416C19.2059 11.8849 20.6807 10.8213 21.0881 9.21923Z"
                      fill="currentColor"
                    ></path>
                    <path
                      d="M22.8094 14.817C22.5086 14.1722 21.7824 13.73 20.6783 13.513C20.1572 13.3851 18.747 13.205 17.4352 13.2293C17.4155 13.232 17.4048 13.2455 17.403 13.2545C17.4003 13.2671 17.4057 13.2887 17.4316 13.3022C18.0378 13.6039 20.3811 14.916 20.0865 17.6834C20.074 17.8032 20.1698 17.9068 20.2888 17.8888C20.8655 17.8059 22.3492 17.4853 22.8094 16.4866C23.0637 15.9589 23.0637 15.3456 22.8094 14.817Z"
                      fill="currentColor"
                    ></path>
                    <path
                      opacity="0.4"
                      d="M7.04459 4.77973C6.81626 4.7329 6.58077 4.70679 6.33543 4.70679C4.07901 4.70679 2.30701 6.84201 2.9123 9.21947C3.31882 10.8216 4.79355 11.8851 6.43661 11.8419C6.60136 11.8374 6.76343 11.8221 6.92013 11.7951C7.03384 11.7753 7.09115 11.6465 7.02668 11.551C6.3999 10.6234 6.04263 9.50765 6.04263 8.30991C6.04263 7.05904 6.43303 5.89462 7.11085 4.93913C7.13234 4.90941 7.14845 4.86348 7.12696 4.82926C7.10906 4.80135 7.07593 4.78694 7.04459 4.77973Z"
                      fill="currentColor"
                    ></path>
                    <path
                      d="M3.32156 13.5127C2.21752 13.7297 1.49225 14.1719 1.19139 14.8167C0.936203 15.3453 0.936203 15.9586 1.19139 16.4872C1.65163 17.4851 3.13531 17.8066 3.71195 17.8885C3.83104 17.9065 3.92595 17.8038 3.91342 17.6832C3.61883 14.9167 5.9621 13.6046 6.56918 13.3029C6.59425 13.2885 6.59962 13.2677 6.59694 13.2542C6.59515 13.2452 6.5853 13.2317 6.5656 13.2299C5.25294 13.2047 3.84358 13.3848 3.32156 13.5127Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </i>
              </SidebarMenu>
              <SidebarMenu
                isTag="false"
                // staticIcon='true'
                minititle="AU"
                pathname="/client-campaign/group"
                title="Campaign Group"
              >
                <i className="icon">
                  <svg
                    width="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon-22"
                    height="20"
                  >
                    <path
                      d="M11.9488 14.54C8.49884 14.54 5.58789 15.1038 5.58789 17.2795C5.58789 19.4562 8.51765 20.0001 11.9488 20.0001C15.3988 20.0001 18.3098 19.4364 18.3098 17.2606C18.3098 15.084 15.38 14.54 11.9488 14.54Z"
                      fill="currentColor"
                    ></path>
                    <path
                      opacity="0.4"
                      d="M11.949 12.467C14.2851 12.467 16.1583 10.5831 16.1583 8.23351C16.1583 5.88306 14.2851 4 11.949 4C9.61293 4 7.73975 5.88306 7.73975 8.23351C7.73975 10.5831 9.61293 12.467 11.949 12.467Z"
                      fill="currentColor"
                    ></path>
                    <path
                      opacity="0.4"
                      d="M21.0881 9.21923C21.6925 6.84176 19.9205 4.70654 17.664 4.70654C17.4187 4.70654 17.1841 4.73356 16.9549 4.77949C16.9244 4.78669 16.8904 4.802 16.8725 4.82902C16.8519 4.86324 16.8671 4.90917 16.8895 4.93889C17.5673 5.89528 17.9568 7.0597 17.9568 8.30967C17.9568 9.50741 17.5996 10.6241 16.9728 11.5508C16.9083 11.6462 16.9656 11.775 17.0793 11.7948C17.2369 11.8227 17.3981 11.8371 17.5629 11.8416C19.2059 11.8849 20.6807 10.8213 21.0881 9.21923Z"
                      fill="currentColor"
                    ></path>
                    <path
                      d="M22.8094 14.817C22.5086 14.1722 21.7824 13.73 20.6783 13.513C20.1572 13.3851 18.747 13.205 17.4352 13.2293C17.4155 13.232 17.4048 13.2455 17.403 13.2545C17.4003 13.2671 17.4057 13.2887 17.4316 13.3022C18.0378 13.6039 20.3811 14.916 20.0865 17.6834C20.074 17.8032 20.1698 17.9068 20.2888 17.8888C20.8655 17.8059 22.3492 17.4853 22.8094 16.4866C23.0637 15.9589 23.0637 15.3456 22.8094 14.817Z"
                      fill="currentColor"
                    ></path>
                    <path
                      opacity="0.4"
                      d="M7.04459 4.77973C6.81626 4.7329 6.58077 4.70679 6.33543 4.70679C4.07901 4.70679 2.30701 6.84201 2.9123 9.21947C3.31882 10.8216 4.79355 11.8851 6.43661 11.8419C6.60136 11.8374 6.76343 11.8221 6.92013 11.7951C7.03384 11.7753 7.09115 11.6465 7.02668 11.551C6.3999 10.6234 6.04263 9.50765 6.04263 8.30991C6.04263 7.05904 6.43303 5.89462 7.11085 4.93913C7.13234 4.90941 7.14845 4.86348 7.12696 4.82926C7.10906 4.80135 7.07593 4.78694 7.04459 4.77973Z"
                      fill="currentColor"
                    ></path>
                    <path
                      d="M3.32156 13.5127C2.21752 13.7297 1.49225 14.1719 1.19139 14.8167C0.936203 15.3453 0.936203 15.9586 1.19139 16.4872C1.65163 17.4851 3.13531 17.8066 3.71195 17.8885C3.83104 17.9065 3.92595 17.8038 3.91342 17.6832C3.61883 14.9167 5.9621 13.6046 6.56918 13.3029C6.59425 13.2885 6.59962 13.2677 6.59694 13.2542C6.59515 13.2452 6.5853 13.2317 6.5656 13.2299C5.25294 13.2047 3.84358 13.3848 3.32156 13.5127Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </i>
              </SidebarMenu>
            </ul>
          </Accordion.Collapse>
        </Accordion.Item> */}
        {/* Help and Support */}
        {/* <Accordion.Item
          as="li"
          eventKey="tools"
          bsPrefix={`nav-item ${active === "help-and-support" ? "active" : ""
            } `}
          onClick={() => setActive("help-and-support")}
        >
          <CustomToggle
            eventKey="help-and-support"
            onClick={(activeKey) => setActiveMenu(activeKey)}
          >
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip>Help and Support</Tooltip>}
            >
              <i className="icon">
                {findIcon("Help And Support", "dual-tone", "20")}
              </i>
            </OverlayTrigger>
            <span className="item-name">Help and Support</span>
            <i className="right-icon">
              <svg
                className="icon-18"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </i>
          </CustomToggle>
          <Accordion.Collapse eventKey="help-and-support">
            <ul className="sub-nav">
              <SidebarMenu
                isTag="false"
                // staticIcon='true'
                minititle={findIcon("Faq", "dual-tone", "18")}

                pathname="/help-and-support/FAQ"
                title="FAQ"
              >
                <i className="icon">
                  {findIcon("Faq", "dual-tone", "18")}

                </i>
              </SidebarMenu>
              <SidebarMenu
                isTag="false"
                // staticIcon='true'
                minititle={findIcon("Ticket", "dual-tone", "18")}

                pathname="/help-and-support/raise-support-ticket"
                title="Raise Support Ticket"
              >
                <i className="icon">
                  {findIcon("Ticket", "dual-tone", "18")}

                </i>
              </SidebarMenu>
              <SidebarMenu
                isTag="false"
                // staticIcon='true'
                minititle={findIcon("Resources", "dual-tone", "18")}

                pathname="/help-and-support/resources"
                title="Resources"
              >
                <i className="icon">
                  {findIcon("Resources", "dual-tone", "18")}

                </i>
              </SidebarMenu>
            </ul>
          </Accordion.Collapse>
        </Accordion.Item> */}
        {/* Creator Recommendation */}
        <Accordion.Item
          as="li"
          eventKey="tools"
          bsPrefix={`nav-item ${active === "creator-recommendation" ? "active" : ""
            } `}
          onClick={() => setActive("creator-recommendation")}
        >
          <CustomToggle
            eventKey="creator-recommendation"
            onClick={(activeKey) => setActiveMenu(activeKey)}
          >
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip>Creator Recommendation</Tooltip>}
            >
              <i className="icon">
                {findIcon("Star", "dual-tone", "20")}
              </i>
            </OverlayTrigger>
            <span className="item-name">Creator Recommendation</span>
            <i className="right-icon">
              <svg
                className="icon-18"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </i>
          </CustomToggle>
          <Accordion.Collapse eventKey="creator-recommendation">
            <ul className="sub-nav">
              <SidebarMenu
                isTag="false"
                // staticIcon='true'
                minititle={findIcon("Cloud", "dual-tone", "18")}
                pathname="/creator-recommendation/view"
                title="View Cloud"
              >
                <i className="icon">
                  {findIcon("Cloud", "dual-tone", "18")}
                </i>
              </SidebarMenu>
            </ul>
          </Accordion.Collapse>
        </Accordion.Item>

        {/* <li className="nav-item static-item">
          <Link className="nav-link static-item disabled" to="#" tabIndex="-1">
            <span className="default-icon">Tools</span>
            <span className="mini-icon">-</span>
          </Link>
        </li> */}
        {/* Page Generator */}
        {/* <SidebarMenu
          isTag="true"
          pathname="/page-generator"
          title="Page Generator"
        >
          <i className="icon">
            <svg
              width="20"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="icon-22"
              height="20"
            >
              <path
                opacity="0.4"
                d="M18.8088 9.021C18.3573 9.021 17.7592 9.011 17.0146 9.011C15.1987 9.011 13.7055 7.508 13.7055 5.675V2.459C13.7055 2.206 13.5036 2 13.253 2H7.96363C5.49517 2 3.5 4.026 3.5 6.509V17.284C3.5 19.889 5.59022 22 8.16958 22H16.0463C18.5058 22 20.5 19.987 20.5 17.502V9.471C20.5 9.217 20.299 9.012 20.0475 9.013C19.6247 9.016 19.1177 9.021 18.8088 9.021Z"
                fill="currentColor"
              ></path>
              <path
                opacity="0.4"
                d="M16.0842 2.56737C15.7852 2.25637 15.2632 2.47037 15.2632 2.90137V5.53837C15.2632 6.64437 16.1742 7.55437 17.2802 7.55437C17.9772 7.56237 18.9452 7.56437 19.7672 7.56237C20.1882 7.56137 20.4022 7.05837 20.1102 6.75437C19.0552 5.65737 17.1662 3.69137 16.0842 2.56737Z"
                fill="currentColor"
              ></path>
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.97398 11.3877H12.359C12.77 11.3877 13.104 11.0547 13.104 10.6437C13.104 10.2327 12.77 9.89868 12.359 9.89868H8.97398C8.56298 9.89868 8.22998 10.2327 8.22998 10.6437C8.22998 11.0547 8.56298 11.3877 8.97398 11.3877ZM8.97408 16.3819H14.4181C14.8291 16.3819 15.1631 16.0489 15.1631 15.6379C15.1631 15.2269 14.8291 14.8929 14.4181 14.8929H8.97408C8.56308 14.8929 8.23008 15.2269 8.23008 15.6379C8.23008 16.0489 8.56308 16.3819 8.97408 16.3819Z"
                fill="currentColor"
              ></path>
            </svg>
          </i>
        </SidebarMenu> */}
        {/* Email Generator */}
        {/* <SidebarMenu
          isTag="true"
          pathname="/email-generator"
          title="Email Generator"
        >
          <i className="icon">
            <svg
              width="18"
              height="18"
              xmlns="http://www.w3.org/2000/svg"
              data-name="Layer 2"
              viewBox="0 0 512 511.94"
              fill="none"
              class="icon-18"
            >
              <g id="Layer_1-2" data-name="Layer 1">
                <path
                  id="Shape"
                  d="m271.91,153.26c-7.75-7.93-19.83-9.73-29.57-4.42-10.18,5.74-18.37,14.46-23.45,24.99-2.14,3.94-3.86,8.1-5.15,12.39-3.55,12.02-2.77,27.7,7.03,35.55,10.72,8.61,28.8,4.97,38.97-3.81,7.23-6.51,13.48-14.04,18.55-22.34,1.73-7.52,3.38-15.25,4.9-22.58-.27-.37-.5-.76-.71-1.17-2.17-6.88-5.77-13.23-10.58-18.61h0Z"
                  fill="currentColor"
                />
                <path
                  opacity="0.4"
                  id="Shape-2"
                  data-name="Shape"
                  d="m221.06,12.55l-20.87,14.82h111.59l-20.3-14.39c-20.29-17.14-49.93-17.32-70.42-.42h0Z"
                  fill="currentColor"
                />
                <path
                  opacity="0.4"
                  id="Shape-3"
                  data-name="Shape"
                  d="m448,213.41l58.14-48.32-58.14-41.22v89.54Z"
                  fill="currentColor"
                />
                <path
                  id="Shape-4"
                  data-name="Shape"
                  d="m82.19,46.05l.09,182.56,138.76,115.32c20.3,16.76,49.64,16.76,69.93-.01l138.74-115.31V45.73c-.03.19-.39-.07-1.01-.07H83.29c-.4-.02-.8.12-1.1.39h0Zm98.49,71.52c3.08-3,6.29-5.88,9.62-8.63,29.44-24.64,69.12-33.21,106.1-22.9,35.8,11.43,60.54,44.14,61.78,81.71,1.34,21.62-5.71,42.93-19.67,59.48-10.77,13.57-28.23,19.98-45.22,16.61-6.95-1.44-13.02-5.65-16.8-11.66-.62-1.09-1.15-2.23-1.58-3.4-1.06,1.01-2.09,2.07-3.17,3.01-10,8.44-22.63,13.12-35.71,13.24-9.66.17-19.09-3-26.69-8.97-17.55-14.04-18.03-38.44-13.11-55.06,1.66-5.53,3.88-10.88,6.64-15.96,6.86-13.84,17.83-25.22,31.41-32.59,16.91-8.77,37.57-5.45,50.87,8.19,1.27,1.34,2.46,2.75,3.58,4.22.97-5.21,1.55-8.46,1.57-8.59.57-3.22,2.81-5.89,5.88-7s6.5-.51,9.01,1.59,3.69,5.38,3.13,8.59c-.34,1.91-8.34,47.15-16.54,78.11-.58,1.72-.44,3.6.41,5.2,1.26,1.75,3.17,2.92,5.3,3.27,10.4,1.74,20.91-2.44,27.29-10.84,10.85-12.98,16.28-29.63,15.18-46.51-.89-29.9-20.5-56-48.96-65.19-31.07-8.55-64.36-1.25-89,19.52-2.94,2.44-5.79,4.99-8.54,7.66-14.21,13.83-36.91,42.51-29.26,79.36,5.14,25.74,23.52,46.84,48.32,55.45,42.88,13.68,104.5,15.33,136.96-25.04,3.19-3.84,8.87-4.41,12.76-1.29s4.55,8.8,1.49,12.74c-24,29.85-60.54,40.53-96.37,40.53-20.49-.1-40.86-3.31-60.38-9.52-31.2-10.44-54.38-36.86-60.67-69.15-6.89-33.15,5.66-68.21,34.41-96.17h0Z"
                  fill="currentColor"
                />
                <path
                  opacity="0.4"
                  id="Shape-5"
                  data-name="Shape"
                  d="m512,501.61V184l-191.1,158.83,191.1,158.78Z"
                  fill="currentColor"
                />
                <path
                  opacity="0.4"
                  id="Shape-6"
                  data-name="Shape"
                  d="m0,184v317.6l191.1-158.77L0,184Z"
                  fill="currentColor"
                />
                <path
                  opacity="0.4"
                  id="Shape-7"
                  data-name="Shape"
                  d="m306.59,354.71l-3.94,3.28c-27.07,22.37-66.21,22.38-93.28.01l-3.96-3.29L16.17,511.94h479.67l-189.24-157.23Z"
                  fill="currentColor"
                />
                <path
                  opacity="0.4"
                  id="Shape-8"
                  data-name="Shape"
                  d="m5.85,165.09l58.15,48.33v-89.55L5.85,165.09Z"
                  fill="currentColor"
                />
              </g>
            </svg>
          </i>
        </SidebarMenu> */}
        {/* Image Generator */}
        {/* <SidebarMenu
          isTag="true"
          pathname="/image-generator"
          title="Image Generator"
        >
          <i className="icon">
            <svg
              width="20"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="icon-22"
              height="20"
            >
              <path
                d="M21.9999 14.7024V16.0859C21.9999 16.3155 21.9899 16.5471 21.9699 16.7767C21.6893 19.9357 19.4949 22 16.3286 22H7.67126C6.06806 22 4.71535 21.4797 3.74341 20.5363C3.36265 20.1864 3.042 19.7753 2.7915 19.3041C3.12217 18.9021 3.49291 18.462 3.85363 18.0208C4.46485 17.289 5.05603 16.5661 5.42677 16.0959C5.97788 15.4142 7.43078 13.6196 9.44481 14.4617C9.85563 14.6322 10.2164 14.8728 10.547 15.0833C11.3586 15.6247 11.6993 15.7851 12.2705 15.4743C12.9017 15.1335 13.3125 14.4617 13.7434 13.76C13.9739 13.388 14.2043 13.0281 14.4548 12.6972C15.547 11.2736 17.2304 10.8926 18.6332 11.7348C19.3346 12.1559 19.9358 12.6872 20.4969 13.2276C20.6172 13.3479 20.7374 13.4592 20.8476 13.5695C20.9979 13.7198 21.4989 14.2211 21.9999 14.7024Z"
                fill="currentColor"
              ></path>
              <path
                opacity="0.4"
                d="M16.3387 2H7.67134C4.27455 2 2 4.37607 2 7.91411V16.086C2 17.3181 2.28056 18.4119 2.79158 19.3042C3.12224 18.9022 3.49299 18.4621 3.85371 18.0199C4.46493 17.2891 5.05611 16.5662 5.42685 16.096C5.97796 15.4143 7.43086 13.6197 9.44489 14.4618C9.85571 14.6323 10.2164 14.8729 10.5471 15.0834C11.3587 15.6248 11.6994 15.7852 12.2705 15.4734C12.9018 15.1336 13.3126 14.4618 13.7435 13.759C13.9739 13.3881 14.2044 13.0282 14.4549 12.6973C15.5471 11.2737 17.2305 10.8927 18.6333 11.7349C19.3347 12.1559 19.9359 12.6873 20.497 13.2277C20.6172 13.348 20.7375 13.4593 20.8477 13.5696C20.998 13.7189 21.499 14.2202 22 14.7025V7.91411C22 4.37607 19.7255 2 16.3387 2Z"
                fill="currentColor"
              ></path>
              <path
                d="M11.4543 8.79668C11.4543 10.2053 10.2809 11.3783 8.87313 11.3783C7.46632 11.3783 6.29297 10.2053 6.29297 8.79668C6.29297 7.38909 7.46632 6.21509 8.87313 6.21509C10.2809 6.21509 11.4543 7.38909 11.4543 8.79668Z"
                fill="currentColor"
              ></path>
            </svg>
          </i>
        </SidebarMenu> */}

        <li>
          <hr className="hr-horizontal" />
        </li>
        <li className="nav-item static-item">
          <Link className="nav-link static-item disabled" to="#" tabIndex="-1">
            <span className="default-icon">Settings</span>
            <span className="mini-icon">-</span>
          </Link>
        </li>
        {/* USER MANAGEMENT */}
        <Accordion.Item
          as="li"
          eventKey="user-management"
          bsPrefix={`nav-item ${active === "user-management" ? "active" : ""} `}
          onClick={() => setActive("user-management")}
        >
          <CustomToggle
            eventKey="user-management"
            onClick={(activeKey) => setActiveMenu(activeKey)}
          >
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip>User Management</Tooltip>}
            >
              <i className="icon">
                {findIcon("User Management", "dual-tone", "20")}
              </i>
            </OverlayTrigger>
            <span className="item-name">User Management</span>
            <i className="right-icon">
              <svg
                className="icon-18"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </i>
          </CustomToggle>
          <Accordion.Collapse eventKey="user-management">
            <ul className="sub-nav">
              <SidebarMenu
                isTag="false"
                // staticIcon='true'
                minititle={findIcon("User Group", "dual-tone", "18")}
                pathname="/user-management/listing"
                title="All User"
              >
                <i className="icon">
                  {findIcon("User Group", "dual-tone", "18")}
                </i>
              </SidebarMenu>
              <SidebarMenu
                isTag="false"
                // staticIcon='true'
                minititle={findIcon("User Add", "dual-tone", "18")}
                pathname="/user-management/create"
                title="Add User"
              >
                <i className="icon">
                  {findIcon("User Add", "dual-tone", "18")}
                </i>
              </SidebarMenu>
            </ul>
          </Accordion.Collapse>
        </Accordion.Item>
        <Accordion.Item
          as="li"
          eventKey="role-management"
          bsPrefix={`nav-item ${active === "role-management" ? "active" : ""} `}
          onClick={() => setActive("role-management")}
        >
          <CustomToggle
            eventKey="role-management"
            onClick={(activeKey) => setActiveMenu(activeKey)}
          >
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip>Role Management</Tooltip>}
            >
              <i className="icon">
                {findIcon("Role Management", "dual-tone", "20")}

              </i>
            </OverlayTrigger>
            <span className="item-name">Role Management</span>
            <i className="right-icon">
              <svg
                className="icon-18"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </i>
          </CustomToggle>
          <Accordion.Collapse eventKey="role-management">
            <ul className="sub-nav">
              <SidebarMenu
                isTag="false"
                // staticIcon='true'
                minititle={findIcon("User Group", "dual-tone", "18")}
                pathname="/roles/listing"
                title="All Roles"
              >
                <i className="icon">
                  {findIcon("User Group", "dual-tone", "18")}
                </i>
              </SidebarMenu>
              <SidebarMenu
                isTag="false"
                // staticIcon='true'
                minititle={findIcon("User Add", "dual-tone", "18")}
                pathname="/roles/create"
                title="Add Role"
              >
                <i className="icon">
                  {findIcon("User Add", "dual-tone", "18")}
                </i>
              </SidebarMenu>
            </ul>
          </Accordion.Collapse>
        </Accordion.Item>
        {/* VENDOR MANAGEMENT */}
        <Accordion.Item
          as="li"
          eventKey="vendor-management"
          bsPrefix={`nav-item ${active === "vendor-management" ? "active" : ""
            } `}
          onClick={() => setActive("vendor-management")}
        >
          <CustomToggle
            eventKey="vendor-management"
            onClick={(activeKey) => setActiveMenu(activeKey)}
          >
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip>Vendor Management</Tooltip>}
            >
              <i className="icon">
                {findIcon("User", "dual-tone", "20")}
              </i>
            </OverlayTrigger>
            <span className="item-name">Vendor Management</span>
            <i className="right-icon">
              <svg
                className="icon-18"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </i>
          </CustomToggle>
          <Accordion.Collapse eventKey="vendor-management">
            <ul className="sub-nav">
              <SidebarMenu
                isTag="false"
                minititle={findIcon("User Group", "dual-tone", "18")}
                pathname="/vendor-management/listing"
                title="All Vendor"
              >
                <i className="icon">
                  {findIcon("User Group", "dual-tone", "18")}
                </i>
              </SidebarMenu>
              <SidebarMenu
                isTag="false"
                minititle={findIcon("User Add", "dual-tone", "18")}
                pathname="/vendor-management/create"
                title="Add Vendor"
              >
                <i className="icon">
                  {findIcon("User Add", "dual-tone", "18")}
                </i>
              </SidebarMenu>
            </ul>
          </Accordion.Collapse>
        </Accordion.Item>
      </Accordion>
    </Fragment>
  );
});

VerticalNav.displayName = "VerticalNav";
export default VerticalNav;

import React from "react";

// layout

import Default from "../../../../layouts/dashboard/default";

// pages
import AllActions from "../pages/listing";
import ActionDetails from "../pages/details";
import ActionResubmit from "../pages/resubmit";
import Dashboard from "../pages/dashboard";
import AlternativeDashboard from "../pages/dashboard-v1.js";
import PostApproval from "../pages/post-approval-v1";
import ActionVendorTaskListing from "../pages/vendortasklisting";
import ActionManagerTaskListing from "../pages/managertasklisting";
import ActionApproverTaskListing from "../pages/approvertasklisting";
import RequisitionAcceptance from "../pages/requisition-acceptance";
import ActionList from "../pages/all-action-list";

export const ActionRouter = [
  {
    path: "/action",
    element: <Default />,
    children: [
      {
        path: "dashboard",
        element: <Dashboard />,
      },
      {
        path: "dashboard-v1",
        element: <AlternativeDashboard />,
      },
      {
        path: "listing",
        element: <AllActions />,
      },
      {
        path: "details/:type/:actionId",
        element: <ActionDetails />,
      },
      {
        path: "details/:type/:actionId/:fileType",
        element: <ActionDetails />,
      },
      // {
      //   path: "details/:type/:actionType/:actionId",
      //   element: <ActionDetails />,
      // },
      {
        path: "resubmit",
        element: <ActionResubmit />,
      },
      {
        path: "all-action",
        element: <ActionList />,
      },
      {
        path: "in-progress",
        element: <ActionList />,
      },
      {
        path: "overdue",
        element: <ActionList />,
      },
      {
        path: "pending",
        element: <ActionList />,
      },
      {
        path: "completed",
        element: <ActionList />,
      },
      {
        path: "post-approval/:id",
        element: <PostApproval />,
      },
      {
        path: "all-vendor-actions",
        element: <ActionVendorTaskListing />,
      },
      {
        path: "all-approver-actions",
        element: <ActionApproverTaskListing />,
      },
      {
        path: "all-manager-actions",
        element: <ActionManagerTaskListing />,
      },
      {
        path: "requisition-acceptance/:actionId",
        element: <RequisitionAcceptance />,
      },
    ],
  },
];

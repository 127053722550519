import {
  memo,
  Fragment,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";

//react-bootstrap
import { Badge, Button, Card, Col, Row } from "react-bootstrap";
import ReactTable from "../../../../components/table/react-table";
import useRedirect from "../../../../components/custom-hooks/use-redirect";
import DeleteModal from "../../../../components/partials/common/delete-modal";
import postService from "../../../../api/services/post-service";
import {
  ALL_USERS_GET,
  DELETE_USER_POST,
  USER_DETAILS_GET,
} from "../../../../api/endpoints/user-management-endpoints";
import { findIcon } from "../../../dashboard/icons";
import ViewModal from "../../../../components/partials/common/view-modal";
import { getService } from "../../../../api/services/get-services";
import { changeTableStateToPayload } from "../../../../common/utils/utils";
import FilterBar from "../../../../components/filter-bar";
import withAuthorization from "../../../../components/hoc/with-authorization";
import ReactTableLoader from "../../../../skeleton/react-table-loader/react-table-loader";
import ActionButtonBar from "../../../../components/partials/common/action-button-bar";

const AllUser = memo(() => {
  const { redirectTo } = useRedirect();
  const [modalShow, setModalShow] = useState(false);
  const [userList, setUserList] = useState([]);
  const [viewModalShow, setViewModalShow] = useState(false);
  const [viewUser, setViewUser] = useState({});
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const viewModal = async (user) => {
    // setViewUser(user);
    // setViewModalShow(true);
    console.log("user===>", user);
    let response = await getService(USER_DETAILS_GET + "/" + user.id);
    console.log("response===>", response.data.data);
    if (response.data.data) {
      let {
        departments,
        email,
        employee_code,
        id,
        name,
        phone,
        principal_entity,
        reporting_manager,
        status,
      } = response.data.data;
      let dataObj = {
        Id: id ?? "",
        Name: name ?? "",
        Phone: phone ?? "",
        Email: email ?? "",
        "Employee Code": employee_code ?? "",
        Department:
          departments?.length > 0
            ? departments[0]?.department_id?.depertment_name
            : "",
        "Principal Entity":
          principal_entity?.length > 0
            ? principal_entity[0]?.principal_entity_id?.principal_entity_name
            : "",
        "Reporting Manager": reporting_manager?.name ?? "",
        Status: status?.value ?? "",
      };
      setViewUser(dataObj);
      setViewModalShow(true);
    }
    // setViewUser(response.data.data);
  };
  const viewModalClose = () => {
    setViewModalShow(false);
  };

  const columns = [
    {
      Header: "ID",
      accessor: "id",
    },
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Phone",
      accessor: "phone",
    },
    {
      Header: "Action",
      accessor: "action",
      disableFilters: true,
      Cell: ({ row }) => (
        <ActionButtonBar
          row={row}
          onView={() => viewModal(row.original)}
          onEdit={() =>
            redirectTo("user-management/edit-user/" + row.original.id)
          }
          onDelete={() => {
            setSelectedUser(row.original.id);
            modalOpen();
          }}
        />
      ),
    },
  ];
  const post_json = useRef({
    pagination: {
      limit: 10,
      offset: 0,
    },
    sort: {
      id: "desc",
    },
  });
  const modalClose = () => {
    setModalShow(false);
  };
  const modalOpen = () => {
    setModalShow(true);
  };

  const fetchUsers = async () => {
    try {
      // setLoading(true);
      const response = await postService(ALL_USERS_GET, post_json.current);
      console.log("USER_SERVICE_RESPONSE", response.data.data.rows);
      console.log("COLUMNS", Object.keys(response.data.data.rows[0]));
      if (response.data.data.rows.length > 0) {
        setUserList(response.data.data.rows);
        setTotalCount(response.data.data.totalCount);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log("USER_SERVICE_ERROR", error);
    }
  };
  useEffect(() => {
    setLoading(true);
    fetchUsers();

    return () => {};
  }, []);

  const onTableStateChange = useCallback(
    async (state) => {
      let payload = changeTableStateToPayload(state);
      post_json.current = {
        ...post_json.current,
        ...payload,
      };
      fetchUsers();
    },
    [userList, loading, post_json.current]
  );
  const handleCreate = () => {
    redirectTo("user-management/create");
  };
  const deleteUser = async () => {
    setLoading(true);
    try {
      // setLoading(true);
      const response = await postService(
        `${DELETE_USER_POST}/${selectedUser}`,
        {}
      );
      if (response) {
        setLoading(false);
        setModalShow(false);
        fetchUsers();
      }
    } catch (error) {
      setLoading(false);
      console.log("USER_SERVICE_ERROR", error);
    }
  };

  return (
    <Fragment>
      <Button
        variant="success"
        className="d-flex align-items-center justify-content-center add_floating__button position-fixed rounded-circle p-1 avatar-80 lh-1 border border-white border-2"
        onClick={() => handleCreate()}
      >
        {findIcon("Plus", "outline", "32")}
      </Button>

      <Row>
        <Col sm="12">
          <Card>
            <Card.Header className="d-flex justify-content-between align-items-center">
              <h4 className="card-title">All Users</h4>
            </Card.Header>
            <Card.Body className="px-3">
              {!loading ? (
                userList.length > 0 && (
                  <div className="table-responsive">
                    <ReactTable
                      data={userList}
                      columns={columns}
                      recordsTotal={totalCount}
                      onTableStateChange={onTableStateChange}
                      initialState={post_json.current}
                      isPagination={true}
                      recordsPerPage={10}
                    />
                  </div>
                )
              ) : (
                <ReactTableLoader />
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <DeleteModal
        show={modalShow}
        modalClose={modalClose}
        onSubmit={deleteUser}
      />
      <ViewModal
        title={"View User Information"}
        show={viewModalShow}
        modalClose={viewModalClose}
        data={viewUser}
      />
    </Fragment>
  );
});

AllUser.displayName = "AllUser";
export default withAuthorization(AllUser);

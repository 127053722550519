import React, { memo, forwardRef } from "react";
import EmailEditor from "react-email-editor";

const ReactEmailEditor = forwardRef(({ onReady, onLoad, style = {} }, ref) => {
  const mergeTags = {
    first_name: {
      name: "Product Name",
      value: "{{name}}",
      // sample:'ssss'
    },
    name: {
      name: "Name",
      value: "{{price}}",
      // sample:'aaa'
    },
  };

  return (
    <EmailEditor
      projectId={1071}
      ref={ref}
      onReady={onReady}
      onLoad={onLoad}
      style={style}
      tools={{
        button: {
          enabled: true,
        },
        video: {
          enabled: true,
        },
      }}
      appearance={{
        panels: {
          tools: {
            dock: "left",
          },
        },
      }}
      options={{
        mergeTags: mergeTags,
      }}
    />
  );
});
export default memo(ReactEmailEditor);

import packageJson from "../../../package.json";
const env = process.env.REACT_APP_ENVIRONMENT;
const constants = {
  appName: "Unity",
  appVersion: packageJson.version,
  fileStoragePath: process.env.REACT_APP_FILE_STORAGE_PATH,
};

const dev_config = {
  api_url: "https://unityapidev.clirdev.com/api", // "https://ciplacmsdevapi.clirdev.com/api",
  api_base_url: "https://unityapidev.clirdev.com",
};
const uat_config = {
  api_url: "https://unityapidev.clirdev.com/api",
  api_base_url: "https://unityapidev.clirdev.com",
};
const prod_config = {
  api_url: "https://unityapidev.clirdev.com/api",
  api_base_url: "https://unityapidev.clirdev.com",
};
const environment = () => {
  switch (env) {
    case "1":
      return dev_config;
    case "2":
      return uat_config;
    case "3":
      return prod_config;

    default:
      return "";
  }
};
console.log("return prod config", environment());
const app_config = {
  ...constants,
  ...environment(),
};
export default app_config;

import * as yup from "yup";

const addHTMLInitialValue = {
  body: "",
  body_html: "",
  reference: "",
  reference_html: "",
  referenceFile: null,
  title: "",
  title_html: "",
  speciality: "",
  thumbnail: null,
  base: null,
};

const AddHTMLSchema = yup.object().shape({
  title: yup.string().trim().required("Please Enter a title"),
  reference: yup.string().trim().required("Please Enter a references"),
  speciality: yup.string().trim().required("Please select the speciality"),
  thumbnail: yup.mixed().required("Please add a Thumbnail"),
  base: yup.mixed().required("Please add a Cover Image"),
});

export { addHTMLInitialValue, AddHTMLSchema };

import React from "react";
import { Button } from "react-bootstrap";
import { findIcon } from "../../../views/dashboard/icons";

const ActionButtonBar = ({
  row,
  onView,
  onEdit,
  onDelete,
  permissions = ["view", "edit", "delete"],
}) => {
  const hasViewPermission = permissions.includes("view");
  const hasEditPermission = permissions.includes("edit");
  const hasDeletePermission = permissions.includes("delete");
  return (
    <div className="d-flex justify-content-center align-items-center gap">
      {hasViewPermission && (
        <div className="d-flex justify-content-center">
          <Button
            className="btn-icon btn-sm rounded-pill"
            to="#"
            role="button"
            onClick={() => onView(row.original)}
          >
            <span className="btn-inner">
              {findIcon("Eye", "dual-tone", 32)}
            </span>
          </Button>
        </div>
      )}

      {hasEditPermission && (
        <div className="d-flex justify-content-center">
          <Button
            className="btn-icon btn-sm rounded-pill"
            to="#"
            role="button"
            variant="info"
            onClick={() => onEdit(row.original)}
          >
            <span className="btn-inner">{findIcon("Pencil", "dual-tone")}</span>
          </Button>
        </div>
      )}
      {hasDeletePermission && (
        <div className="d-flex justify-content-center">
          <Button
            className="btn-icon btn-sm rounded-pill"
            to="#"
            role="button"
            variant="danger"
            onClick={() => onDelete(row.original)}
          >
            <span className="btn-inner">
              {findIcon("Trash", "dual-tone", 32)}
            </span>
          </Button>
        </div>
      )}
    </div>
  );
};

export default ActionButtonBar;

import React, { useEffect } from "react";
import { Button, Modal, Col, Form, Row, Table } from "react-bootstrap";

const ViewModal = ({ title, show, modalClose, data }) => {
  useEffect(() => {
    console.log("data===>", data);
  }, []);

  return (
    <>
      <Modal
        show={show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton onClick={modalClose}>
          <Modal.Title id="contained-modal-title-vcenter">{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table>
            <tbody>
              {data &&
                Object.keys(data).map((key, index) => {
                  return (
                    <tr key={index}>
                      <td>{key}</td>
                      <td className="text-wrap">{data[key]}</td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={modalClose}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ViewModal;

import React, { StrictMode, useEffect } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
// import reportWebVitals from './reportWebVitals';

//router
import { RouterProvider, createBrowserRouter } from "react-router-dom";

import Index from "./views/index";

//store

import { Provider } from "react-redux";
//reducer
import { store } from "./store";
import { IndexRouters } from "./router";
import Signin from "./views/modules/auth/pages/sign-in";
import { persistAuthState } from "./store/utils/persist-auth-state";
import { Toaster } from "react-hot-toast";

const router = createBrowserRouter(
  [
    {
      path: "/",
      element: <Signin />,
    },
    ...IndexRouters,
  ],
  { basename: process.env.PUBLIC_URL }
);

store.subscribe(() => {
  const { auth } = store.getState();
  persistAuthState(auth);
});

ReactDOM.createRoot(document.getElementById("root")).render(
  <>
    {/* <ToastContainer
      position="top-right"
      transition={Bounce}
      autoClose={3000}
      closeOnClick
      rtl={false}
      pauseOnHover
      limit={1}
    /> */}
    <Toaster position="top-center" reverseOrder={false} />
    <StrictMode>
      <Provider store={store}>
        <App>
          <RouterProvider router={router}></RouterProvider>
        </App>
      </Provider>
    </StrictMode>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

import React from "react";
import parse from "html-react-parser";
const ArticleSection = ({ content }) => {
  console.log("content", content);
  return (
    <div className="w-100 d-flex flex-column gap-3">
      <img
        className="w-100 oject-fit-cover rounded-3"
        src={
          "https://t3.ftcdn.net/jpg/00/82/30/70/360_F_82307047_vMqQun5fjBzuJF0o2brRxYIs9Ix62fco.jpg"
        }
        alt=""
      />

      {content?.title_html ? parse(content?.title_html) : ""}
      {content?.description_html ? parse(content?.description_html) : ""}
    </div>
  );
};

export default ArticleSection;

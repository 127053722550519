import React from "react";

// layout

import Default from "../../../../layouts/dashboard/default";
import AddCourses from "../pages/add-courses";
import AllCoursesList from "../pages/all-courses-list";
import CoursesDetails from "../pages/courses-details";


// pages

export const CoursesRouter = [
  {
    path: "/courses",
    element: <Default />,
    children: [
      {
        path: "create",
        element: <AddCourses />,
      },
      {
        path: "listing",
        element: <AllCoursesList />,
      },
      {
        path: "details/:id",
        element: <CoursesDetails />,
      },
    ],
  },
];

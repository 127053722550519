import React from "react";
import { Card, Badge } from "react-bootstrap";
import ReactTable from "../../../../components/table/react-table";
import { grData } from "../demo-data";
import FloatingButton from "../../../../components/form-components/floating-button";
import useRedirect from "../../../../components/custom-hooks/use-redirect";
import dayjs from "dayjs";
import ActionButtonBar from "../../../../components/partials/common/action-button-bar";

const AllGrList = () => {
  const { redirectTo } = useRedirect();
  const onDelete = (id) => {
    console.log("Delete", id);
  };

  const grColumns = [
    {
      Header: "ID",
      accessor: "id",
    },
    {
      Header: "Title",
      accessor: "title",
    },
    {
      Header: "Date Of Publication",
      accessor: "publication_date",
      Cell: ({ values }) => dayjs(values).format("DD MMM YYYY"),
    },
    {
      Header: "Client Name",
      accessor: "client_name",
    },
    {
      Header: "Added",
      accessor: "added",
      Cell: ({ values }) => dayjs(values).format("DD MMM YYYY"),
    },
    {
      Header: "Added By",
      accessor: "added_by",
    },
    {
      Header: "Modified By",
      accessor: "modified_by",
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ value }) => <Badge bg="primary">{value}</Badge>,
    },
    {
      Header: "Action",
      accessor: "action",
      Cell: ({ row }) => (
        <ActionButtonBar
          row={row}
          onView={() => redirectTo(`/gr/details/${row.id}`)}
          onEdit={() => redirectTo(`/gr/edit/${row.id}`)}
          onDelete={() => onDelete(row.id)}
          permissions={["view", "edit", "delete"]}
        />
      ),
    },
  ];
  return (
    <>
      <FloatingButton onClick={() => redirectTo("/gr/create")} />
      <div className="d-flex justify-content-between align-items-center flex-wrap mb-4 gap-3">
        <div className="d-flex flex-column">
          <h3 className="m-0">Grand Round Listing</h3>
        </div>
        <div className="d-flex justify-content-between align-items-center rounded flex-wrap gap-3"></div>
      </div>
      <Card>
        <Card.Body>
          <ReactTable
            data={grData}
            columns={grColumns}
            recordsTotal={grData.length}
            // onTableStateChange={onTableStateChange}
            // initialState={post_json.current}
            recordsPerPage={6}
          />
        </Card.Body>
      </Card>
    </>
  );
};

export default AllGrList;

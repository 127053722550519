import { memo, useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import FilePicker from "../../../../components/form-components/file-picker";
import BasicQuill from "../../plugins/pages/quill-editor";
// import KanbanItem from "../../../../components/partials/kanban/kanban-item";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import dayjs from "dayjs";
import {
  ADD_CONTENT,
  CREATE_CONTENT,
  UPLOAD_FILE,
} from "../../../../api/endpoints/add-content-endpoints";
import { getService } from "../../../../api/services/get-services";
import postService from "../../../../api/services/post-service";
import uploadService from "../../../../api/services/upload-service";
import ContentDetails from "../../../../components/partials/common/content-details";
import {
  addArticleInitialValues,
  articleSchema,
} from "../constants/add-article";
import Select from "react-select";

import AddEnviroment from "./add-enviroment";

const AddArticle = ({
  fields,
  actionData,
  onCreateContent,
  isAction,
  contentCategory,
  content_file_type_id = "",
  content_category_id = "",
  brandId = "",
}) => {
  const [loading, setLoading] = useState(true);
  const [formFields, setFormFields] = useState(true);

  const formik = useFormik({
    initialValues: addArticleInitialValues,
    validationSchema: articleSchema,
    onSubmit: (value, action) => {
      const payload = {
        content_category_id:
          content_category_id || contentCategory?.content_category_id,
        content_brand_id: value.brand,
        content_featured_status: value.is_featured ? 1 : 0,
        content_title: value.title,
        content_description: value.body,
        content_file_type_id,
        file_type_code: "article",
        cleaned_text: "",

        content_privacy_status: value.privacy_status,

        content_publication_date: dayjs(value.publication_date).format(
          "YYYY-MM-DD"
        ),

        content_publishing_status: 1,
        content_to_images: {
          thumbnail: value.thumbnail,
          base: value.coverPhoto,
        },
        content_to_attributes: {
          content_question_code: value.question_code,
          content_topic: value?.topic,
          content_category_id: value.category, // case or dieses
          content_type: "text", // text or video
          content_vendor: null, // youtube or vimeo
          content_src: null, // src url
          content_base_like: value.base_likes, // start like
          content_template_id: value.template_id, // template id
          content_note: value.notes, // template id,
          content_client_id: "1",
        },
        content_to_text: {
          citation: value.reference,
          citation_html: value.reference_html,
          title_html: value.title_html,
          description_html: value.body_html,
        },
        content_to_tags: {
          tags_ids: value.tags,
        },
        content_to_sponsor: {
          sponsor_ids: [value.sponsor || "1"],
        },
        content_to_speciality: {
          speciality_ids: value.speciality,
        },
        content_reference_files: {
          reference_file: [value.referenceFile],
        },
      };
      let content_to_env_price_filtered = value.content_to_env_price.filter(
        (item) => {
          return item.env_id !== "0";
        }
      );
      if (content_to_env_price_filtered.length > 0) {
        payload.content_to_env_price = content_to_env_price_filtered;
      }
      if (onCreateContent) {
        onCreateContent(payload);
      } else {
        toast.promise(
          postService(ADD_CONTENT, payload).then((data) => {
            if (!data.isError) {
              if (data.data?.success) {
                resetForm();
              } else {
              }
            }
          }),
          {
            loading: "Loading...",
            success: <b>Article saved!</b>,
            error: <b>Could not save.</b>,
          }
        );
      }
    },
  });
  const {
    touched,
    values,
    errors,
    handleChange,
    handleSubmit,
    setFieldValue,
    resetForm,
  } = formik;

  function getData() {
    getService(CREATE_CONTENT)
      .then((data) => {
        setFormFields(data.data.data);
        setLoading(false);
      })
      .catch((e) => console.log("error", e));
  }
  useEffect(() => {
    console.log("fields", fields);
    if (fields) {
      setFormFields(fields);
    } else {
      getData();
    }
  }, [fields]);

  function uploadFile(files, keyName) {
    if (files.length > 0) {
      let formdata = new FormData();
      [...files].forEach((file) => {
        formdata.append("files[]", file);
      });
      let response = toast.promise(
        uploadService(UPLOAD_FILE, formdata).then((response) => {
          if (response?.data?.data[0]?.name) {
            setFieldValue(keyName, response?.data?.data[0]?.name);
          }
        }),
        {
          loading: "Loading...",
          success: <b>Image saved!</b>,
          error: <b>Could not save.</b>,
        }
      );
    } else {
      setFieldValue(keyName, null);
    }
  }
  useEffect(() => {
    if (brandId) {
      setFieldValue("brand", brandId);
    }
  }, []);
  return (
    <>
      {actionData && (
        <ContentDetails
          brand={
            actionData?.origin?.requisition_brand_id?.principal_entity_name
          }
          date={actionData?.created_at}
          department=""
          title={actionData?.action_name}
          description={actionData?.origin?.requisition_description}
          fileTypes={[]}
          userName={actionData?.action_assign_by?.name}
        />
      )}
      <Form onSubmit={handleSubmit}>
        <Row>
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">Article Images</h4>
              </div>
            </Card.Header>
            <Card.Body className="d-flex flex-column gap-3 flex-md-row">
              <Col lg="6" className="mb-4">
                <FilePicker
                  title={"Add Cover Picture"}
                  source={values.coverPhoto}
                  onUpdate={(val) => uploadFile(val, "coverPhoto")}
                  accepts="image/*"
                  type="image"
                />
                {touched.coverPhoto && "coverPhoto" in errors && (
                  <div style={{ color: "red" }}>{errors.coverPhoto}</div>
                )}
              </Col>
              <Col lg="6" className="mb-4">
                <FilePicker
                  title={"Add Thumbnail"}
                  source={values.thumbnail}
                  onUpdate={(val) => uploadFile(val, "thumbnail")}
                  accepts="image/*"
                  type="image"
                />
                {touched.thumbnail && "thumbnail" in errors && (
                  <div style={{ color: "red" }}>{errors.thumbnail}</div>
                )}
              </Col>
            </Card.Body>
          </Card>

          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">Add Article</h4>
              </div>
            </Card.Header>
            <Card.Body>
              <Row>
                <Form.Group className="col-12 form-group">
                  <Form.Label>
                    Title <span className="text-danger">*</span>{" "}
                  </Form.Label>
                  <div>
                    <BasicQuill
                      name="title"
                      value={values.title}
                      updateData={(value) => {
                        setFieldValue("title", value.rawText);
                        setFieldValue("title_html", value.html);
                      }}

                      // updateData={(e) => setformData(prev => { return { ...prev, article:{...prev.article,title : e} } })}
                    ></BasicQuill>
                    {touched.title && "title" in errors && (
                      <div style={{ color: "red" }}>{errors.title}</div>
                    )}
                  </div>
                </Form.Group>
                <Form.Group className="col-12 form-group">
                  <Form.Label>
                    Body <span className="text-danger">*</span>{" "}
                  </Form.Label>
                  <div>
                    <BasicQuill
                      name="body"
                      value={values.body}
                      updateData={(value) => {
                        setFieldValue("body", value.rawText);
                        setFieldValue("body_html", value.html);
                      }}
                    ></BasicQuill>
                    {touched.body && "body" in errors && (
                      <div style={{ color: "red" }}>{errors.body}</div>
                    )}
                  </div>
                </Form.Group>
                <Form.Group className="col-12 form-group">
                  <Form.Label>
                    Citation <span className="text-danger">*</span>{" "}
                  </Form.Label>
                  <div>
                    <BasicQuill
                      name="reference"
                      value={values.reference}
                      updateData={(value) => {
                        setFieldValue("reference", value.rawText);
                        setFieldValue("reference_html", value.html);
                      }}
                    ></BasicQuill>
                    {touched.reference && "reference" in errors && (
                      <div style={{ color: "red" }}>{errors.reference}</div>
                    )}
                  </div>
                </Form.Group>
                <Form.Group className="col-12 form-group">
                  <Form.Label>Add Reference Files</Form.Label>
                  <Form.Control
                    type="file"
                    id="customFile"
                    name="referenceFile"
                    isInvalid={!!errors.referenceFile}
                    onChange={(e) =>
                      uploadFile(e.target.files, "referenceFile")
                    }
                  />
                </Form.Group>
              </Row>
              <AddEnviroment formik={formik} />
            </Card.Body>
          </Card>

          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">Article Meta</h4>
              </div>
            </Card.Header>
            <Card.Body className="row">
              <Col lg="6">
                {formFields?.brands?.length > 0 && !brandId && (
                  <Form.Floating className="custom-form-floating mb-4">
                    <Form.Select
                      className=""
                      id="floatingInput1"
                      name="brand"
                      value={values.brand}
                      onChange={handleChange}
                      isInvalid={touched.brand && !!errors.brand}
                    >
                      <option value="" selected hidden>
                        {" "}
                        Select Brand
                      </option>
                      {formFields &&
                        formFields?.brands &&
                        formFields?.brands.map((item) => (
                          <option value={item?.principal_entity_id}>
                            {item?.principal_entity_name}
                          </option>
                        ))}
                    </Form.Select>
                    <Form.Label htmlFor="floatingInput">
                      Select Brand <span className="text-danger">*</span>
                    </Form.Label>
                  </Form.Floating>
                )}
                <Form.Floating className="custom-form-floating mb-4">
                  <Select
                    options={(
                      formFields?.specialities || formFields?.specialty
                    )?.map(({ speciality_id, speciality_name }) => ({
                      value: speciality_id,
                      label: speciality_name,
                    }))}
                    isMulti
                    placeholder="Select Speciality"
                    onChange={(selected) => {
                      console.log(
                        "selected",
                        selected.map(({ value }) => value)
                      );
                      setFieldValue(
                        "speciality",
                        selected.map(({ value }) => value)
                      );
                    }}
                  />
                </Form.Floating>
                {/* <Form.Floating className="custom-form-floating mb-4">
                  <Form.Select
                    className=""
                    id="floatingInput1"
                    name="type"
                    value={values.type}
                    onChange={handleChange}
                    isInvalid={touched.type && !!errors.type}
                  >
                    <option value="" selected hidden>
                      Select Type
                    </option>
                    <option value="text">Text</option>
                    <option value="disease">Video</option>
                  </Form.Select>
                  <Form.Label htmlFor="floatingInput">
                    Select Type <span className="text-danger">*</span>
                  </Form.Label>
                </Form.Floating> */}
                <Form.Floating className="custom-form-floating mb-4">
                  <Form.Select
                    className=""
                    id="floatingInput1"
                    name="category"
                    value={values.category}
                    onChange={handleChange}
                    isInvalid={touched.category && !!errors.category}
                  >
                    <option value="" selected hidden>
                      {" "}
                      Select Category
                    </option>
                    <option value={1}>Case</option>
                    <option value={2}>Disease</option>
                  </Form.Select>
                  <Form.Label htmlFor="floatingInput">
                    Select Category <span className="text-danger">*</span>
                  </Form.Label>
                </Form.Floating>
              </Col>
              <Col lg="6">
                {formFields?.tags?.length > 0 && (
                  <Form.Group className="form-group mb-4">
                    <Form.Floating className="custom-form-floating mb-4">
                      <Form.Control
                        type="text"
                        placeholder="Question Code"
                        name="question_code"
                        value={values.question_code}
                        onChange={handleChange}
                        isInvalid={
                          touched.question_code && !!errors.question_code
                        }
                      />
                      <Form.Label htmlFor="question_code">
                        Question Code
                      </Form.Label>
                    </Form.Floating>

                    <Form.Floating className="custom-form-floating mb-4">
                      <Form.Control
                        type="text"
                        placeholder="Topic"
                        name="topic"
                        value={values.topic}
                        onChange={handleChange}
                        isInvalid={touched.topic && !!errors.topic}
                      />
                      <Form.Label htmlFor="topic">Topic</Form.Label>
                    </Form.Floating>

                    <Form.Label>Select Tags</Form.Label>
                    <Select
                      options={formFields?.tags?.map(
                        ({ tag_id, tag_name }) => ({
                          value: tag_id,
                          label: tag_name,
                        })
                      )}
                      placeholder="Select Tags"
                      name="tags"
                      isMulti
                      onChange={(value) =>
                        setFieldValue(
                          "tags",
                          value?.map(({ value }) => value) || []
                        )
                      }
                    />
                  </Form.Group>
                )}
              </Col>
              <Col lg="6">
                <Form.Floating className="custom-form-floating mb-4">
                  <Form.Check // prettier-ignore
                    type="switch"
                    id="custom-switch"
                    label="Is the content featured?"
                    name="is_featured"
                    onChange={(e) =>
                      setFieldValue("is_featured", e.target.checked)
                    }
                  />
                </Form.Floating>

                {formFields?.templates?.length > 0 && (
                  <Form.Floating className="custom-form-floating mb-4">
                    <Form.Select
                      className=""
                      id="floatingInput1"
                      name="privacy_status"
                      value={values.privacy_status}
                      onChange={handleChange}
                      isInvalid={
                        touched.privacy_status && !!errors.privacy_status
                      }
                    >
                      <option value="" selected hidden>
                        {" "}
                        Select Privacy Status
                      </option>
                      {formFields &&
                        formFields?.templates &&
                        (formFields?.templates).map((item) => (
                          <option value={item?.template_id}>
                            {item?.template_name}
                          </option>
                        ))}
                    </Form.Select>
                    <Form.Label htmlFor="floatingInput">
                      Select Privacy Status{" "}
                      <span className="text-danger">*</span>
                    </Form.Label>
                  </Form.Floating>
                )}
              </Col>
              {formFields?.sponsors?.length > 0 && (
                <Col lg="6">
                  <Form.Floating className="custom-form-floating mb-4">
                    <Form.Select
                      className=""
                      id="floatingInput1"
                      name="sponsor"
                      value={values.sponsor}
                      onChange={handleChange}
                      isInvalid={touched.sponsor && !!errors.sponsor}
                    >
                      <option value="" selected hidden>
                        {" "}
                        Select Sponsor
                      </option>
                      {formFields &&
                        formFields?.sponsors &&
                        (formFields?.sponsors).map((item) => (
                          <option value={item?.sponsor_id}>
                            {item?.sponsor_name}
                          </option>
                        ))}
                    </Form.Select>
                    <Form.Label htmlFor="floatingInput">
                      Select Sponsor <span className="text-danger">*</span>
                    </Form.Label>
                  </Form.Floating>

                  <Form.Floating className="custom-form-floating mb-4">
                    <Form.Control
                      type="date"
                      placeholder="Publication Date"
                      name="publication_date"
                      value={values.publication_date}
                      onChange={handleChange}
                      isInvalid={
                        touched.publication_date && !!errors.publication_date
                      }
                    />
                    <Form.Label htmlFor="publication_date">
                      Publication Date
                    </Form.Label>
                  </Form.Floating>
                </Col>
              )}
              <Col lg="6">
                <Form.Floating className="custom-form-floating mb-4">
                  <Form.Control
                    type="number"
                    placeholder="Template ID"
                    name="template_id"
                    value={values.template_id}
                    onChange={handleChange}
                    isInvalid={touched.template_id && !!errors.template_id}
                  />
                  <Form.Label htmlFor="template_id">Template ID</Form.Label>
                </Form.Floating>
              </Col>
              <Col lg="6">
                <Form.Floating className="custom-form-floating mb-4">
                  <Form.Control
                    type="number"
                    placeholder="Base Likes"
                    name="base_likes"
                    value={values.base_likes}
                    onChange={handleChange}
                    isInvalid={touched.base_likes && !!errors.base_likes}
                  />
                  <Form.Label htmlFor="base_likes">Base Likes</Form.Label>
                </Form.Floating>
              </Col>
              <Col lg="6">
                <Form.Floating className="custom-form-floating">
                  <textarea
                    class="form-control h-100"
                    placeholder="Notes"
                    id="floatingTextarea2"
                    name="notes"
                    value={values.notes}
                    onChange={handleChange}
                    isInvalid={touched.notes && !!errors.notes}
                  ></textarea>
                  <Form.Label htmlFor="notes">Notes</Form.Label>
                </Form.Floating>
              </Col>
              <div className="d-flex gap-2 gap-md-3 justify-content-end flex-wrap flex-md-nowrap">
                <Button
                  type="submit"
                  variant="btn btn-primary flex-md-grow-0 flex-grow-1 text-nowrap"
                  onClick={handleSubmit}
                >
                  Submit
                </Button>

                <Button
                  type="button"
                  variant="btn btn-info flex-md-grow-0 flex-grow-1 text-nowrap"
                >
                  Save as Draft
                </Button>
                <Button
                  type="button"
                  variant="btn btn-light flex-md-grow-0 flex-grow-1 text-nowrap"
                >
                  Cancel
                </Button>
              </div>
            </Card.Body>
          </Card>
        </Row>
      </Form>
    </>
  );
};

export default memo(AddArticle);

import React from "react";
import ContentDetailsCardLoader from "../common/content-details-card-loader";
import { Card, Col, Row } from "react-bootstrap";
import { Skeleton } from "@mui/material";

const AddMediaLoader = () => {
  return (
    <>
      <ContentDetailsCardLoader />
      <Col>
        <Card>
          <Row>
            <Col xl="6" lg="8">
              <Card.Body>
                <div className="row">
                  <Skeleton
                    variant="rounded"
                    width="100%"
                    height={45}
                    className="mb-3"
                  />
                  <Skeleton
                    variant="text"
                    width={100}
                    height={20}
                    className="mb-2"
                  />
                  <Skeleton
                    variant="rounded"
                    width="100%"
                    height={100}
                    className="mb-3"
                  />
                  <Skeleton
                    variant="text"
                    width={100}
                    height={20}
                    className="mb-2"
                  />
                  <Skeleton
                    variant="rounded"
                    width="100%"
                    height={100}
                    className="mb-3"
                  />
                  <Skeleton
                    variant="text"
                    width={100}
                    height={20}
                    className="mb-2"
                  />
                  <Skeleton
                    variant="rounded"
                    width="100%"
                    height={100}
                    className="mb-3"
                  />
                  <Skeleton
                    variant="text"
                    width={100}
                    height={20}
                    className="mb-2"
                  />
                  <Skeleton
                    variant="rounded"
                    width="100%"
                    height={45}
                  />
                </div>
              </Card.Body>
            </Col>
            <Col xl="6" lg="4" className="">
              <Row className="px-3 pt-3">
                <Col lg="12" className="mb-4">
                  <Skeleton
                    variant="rounded"
                    width="100%"
                    height={150}
                    className="mb-4"
                  />
                </Col>
                <Col lg="6" className="mb-4">
                  <Skeleton
                    variant="rounded"
                    width="100%"
                    height={150}
                    className="mb-4"
                  />
                </Col>
                <Col lg="6" className="mb-4">
                  <Skeleton
                    variant="rounded"
                    width="100%"
                    height={150}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <div className="d-flex gap-2 gap-md-3 justify-content-end flex-wrap flex-md-nowrap my-3 mx-3">
            <Skeleton
              variant="rounded"
              width="10%"
              height={40}
            />

            <Skeleton
              variant="rounded"
              width="10%"
              height={40}
            />
          </div>
        </Card>
      </Col>
    </>
  );
};

export default AddMediaLoader;

import React, { memo } from 'react'
import { Row } from 'react-bootstrap'
import ContentDetails from '../../../../components/partials/common/content-details';
import AddFile from '../../actions/components/add-file';
import withAuthorization from '../../../../components/hoc/with-authorization';

const CreativeDetails = () => {
  return (
    <>
      <ContentDetails />
      <AddFile />
    </>
  );
}

export default withAuthorization(memo(CreativeDetails))
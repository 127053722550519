import React, { useEffect, memo } from "react";
import ReactTable from "../../../../components/table/react-table";
import Card from "../../../../components/bootstrap/card";
import { Row, Col, Button, Badge, Image } from "react-bootstrap";
import Chart from "react-apexcharts";
import { findIcon } from "../../../dashboard/icons";
import pdf from "../../../../assets/modules/file-manager/images/pdf.svg";
import excel from "../../../../assets/modules/file-manager/images/excel.svg";
import word from "../../../../assets/modules/file-manager/images/word.svg";
import Document from "../../content-library/components/document";
import useRedirect from "../../../../components/custom-hooks/use-redirect";
import postService from "../../../../api/services/post-service";
import { MY_ACTIONS_POST } from "../../../../api/endpoints/action-endpoints";
import {
  changeTableStateToPayload,
  getFormatedDate,
} from "../../../../common/utils/utils";
import useRedirectToAction from "../hooks/use-redirect-to-action";
import withAuthorization from "../../../../components/hoc/with-authorization";
import MyActionAnalyticsLoader from "../../../../skeleton/action/my-action-analytics-loader";
import ReactTableLoader from "../../../../skeleton/react-table-loader/react-table-loader";

const Dashboard = () => {
  const redirectToAction = useRedirectToAction();
  const { redirectTo } = useRedirect();
  const [myActions, setMyActions] = React.useState([]);
  const [analyticsLoading, setAnalyticsLoading] = React.useState(false);
  const [pendingLoading, setPendingLoading] = React.useState(false);
  const [myActionLoading, setMyActionLoading] = React.useState(false);
  const [myActionsCount, setMyActionsCount] = React.useState(0);
  const [pendingRequisition, setPendingRequisition] = React.useState([]);
  const [pendingRequisitionCount, setPendingRequisitionCount] =
    React.useState(0);
  const chart1 = {
    options: {
      chart: {
        width: 380,
        type: "pie",
      },
      labels: ["Video", "Article", "Infographic", "Documents"],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
    series: [44, 55, 13, 43],
  };
  const chart2 = {
    options: {
      chart: {
        width: 380,
        type: "polarArea",
      },
      labels: ["High", "Medium", "Low"],
      fill: {
        opacity: 1,
      },
      stroke: {
        width: 1,
        colors: undefined,
      },
      yaxis: {
        show: false,
      },
      // legend: {
      //   position: "bottom",
      // },
      plotOptions: {
        polarArea: {
          rings: {
            strokeWidth: 0,
          },
          spokes: {
            strokeWidth: 0,
          },
        },
      },
    },

    series: [14, 23, 21],
  };
  const chart3 = {
    options: {
      chart: {},
      colors: ["#0000ff", "#ff0000", "#66cdaa"],
      labels: ["Completed", "Pending", "In Progress"],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
    series: [44, 55, 41],
  };
  const details = (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="icon-22"
      width="32"
      height="32"
      viewBox="0 0 24 24"
    >
      <path
        d="M12.1415 6.5899C11.9075 6.71614 11.7616 6.95618 11.7616 7.21726V11.2827H3.73429C3.32896 11.2827 3 11.604 3 12C3 12.3959 3.32896 12.7172 3.73429 12.7172H11.7616V16.7827C11.7616 17.0447 11.9075 17.2848 12.1415 17.4101C12.3755 17.5372 12.6614 17.5286 12.8875 17.39L20.6573 12.6073C20.8708 12.4753 21 12.2467 21 12C21 11.7532 20.8708 11.5247 20.6573 11.3927L12.8875 6.60998C12.7681 6.5373 12.632 6.5 12.4959 6.5C12.3745 6.5 12.2521 6.5306 12.1415 6.5899Z"
        fill="currentColor"
      ></path>
    </svg>
  );
  const my_actions_post_json = React.useRef({
    default: "all",
    pagination: {
      offset: 0,
      limit: 10,
    },
    sort: {
      action_id: "desc",
    },
  });
  const pending_requisition_post_json = React.useRef({
    default: "all",
    pagination: {
      offset: 0,
      limit: 10,
    },
    sort: {
      action_id: "desc",
    },
    filter: {
      action_type_id: {
        type: "eq",
        value: 4,
      },
    },
  });

  const COLUMNS = [
    {
      Header: "Action ID",
      accessor: "action_id",
    },
    {
      Header: "Action Name",
      accessor: "action_name",
    },
    {
      Header: "Action Priority",
      accessor: "action_priority_id",
      Cell: ({ value }) => {
        console.log("action_priority_value", value);
        //return a bootstrap badge according to priority if priority is 1 then danger else if 2 then warning else if 3 then primary
        let bg = "primary";
        if (value?.priority_id == 1) {
          bg = "danger";
        } else if (value?.priority_id == 2) {
          bg = "warning";
        } else if (value?.priority_id == 3) {
          bg = "primary";
        }
        return <Badge bg={bg}>{value.priority_name}</Badge>;
      },
    },
    {
      Header: "Action Assign By",
      accessor: "action_assign_by",
      Cell: ({ value }) => {
        return value?.name ?? "";
      },
    },
    {
      Header: "Created At",
      accessor: "created_at",
      Cell: ({ value }) => {
        return getFormatedDate(value);
      },
    },
    {
      Header: "Action Type",
      accessor: "action_type_id",
      Cell: ({ value }) => {
        return value?.action_type_name ?? "";
      },
    },
    {
      Header: "Action",
      Cell: ({ row }) => {
        return (
          <div className="d-flex justify-content-center">
            <Button
              className="btn btn-icon btn-sm rounded-pill"
              to="#"
              role="button"
              onClick={() => {
                const {
                  action_type_id,
                  action_id,
                  action_to_filetypes,
                  origin = {},
                } = row.original;
                redirectToAction(action_type_id?.action_type_code, action_id, {
                  ...action_to_filetypes,
                  ...origin,
                });
              }}
            >
              <span className="btn-inner">{details}</span>
            </Button>
          </div>
        );
      },
    },
  ];
  const fetchMyActions = async () => {
    let response = await postService(
      MY_ACTIONS_POST,
      my_actions_post_json.current
    );
    console.log("MY_ACTIONS", response.data.data.rows);
    setMyActions(response.data.data.rows);
    setMyActionsCount(response.data.data.totalCount);
    setMyActionLoading(false);
  };
  const fetchPendingRequisition = async () => {
    let response = await postService(
      MY_ACTIONS_POST,
      pending_requisition_post_json.current
    );
    console.log("PENDING_REQUISITION", response.data.data.rows);
    setPendingRequisition(response.data.data.rows);
    setPendingRequisitionCount(response.data.data.totalCount);
    setPendingLoading(false);
  };

  useEffect(() => {
    setPendingLoading(true);
    setMyActionLoading(true);
    fetchMyActions();
    fetchPendingRequisition();
  }, []);
  const onTableStateChange = (state) => {
    console.log("STATE", state);
    my_actions_post_json.current = changeTableStateToPayload(state);
    fetchMyActions();
  };
  const onPendingRequisitionTableStateChange = (state) => {
    console.log("STATE", state);
    pending_requisition_post_json.current = changeTableStateToPayload(state);
    fetchPendingRequisition();
  };

  return (
    <>
      <div className="d-flex flex-column mb-4">
        <h3 className="m-0">Analytics</h3>
      </div>
      <Row>
        <Col sm="12">
          {!analyticsLoading ? (
            <Card>
              <Card.Body className="px-0">
                <div className="d-flex justify-content-between align-items-center px-2">
                  <div className="d-flex flex-column justify-content-center gap-2 px-2">
                    <Chart
                      options={chart1.options}
                      series={chart1.series}
                      type="pie"
                      height="100"
                    />
                    <p className="d-flex flex-column justify-content-center align-items-center">
                      Task Type
                    </p>
                  </div>
                  <div className="d-flex flex-column justify-content-center gap-2 px-2">
                    <Chart
                      options={chart2.options}
                      series={chart2.series}
                      type="polarArea"
                      height="100"
                    />
                    <p className="d-flex flex-column justify-content-center align-items-center">
                      Task Priority
                    </p>
                  </div>
                  <div className="d-flex flex-column justify-content-center justify-items-center gap-2 px-2">
                    <Chart
                      options={chart3.options}
                      series={chart3.series}
                      type="donut"
                      height="100"
                    />{" "}
                    <p className="d-flex flex-column justify-content-center align-items-center">
                      Task Status
                    </p>
                  </div>
                </div>
              </Card.Body>
            </Card>
          ) : (
            <MyActionAnalyticsLoader />
          )}
        </Col>
        {!pendingLoading && pendingRequisition?.length > 0 ? (
          <Col sm="12">
            <Card>
              <Card.Header className="d-flex justify-content-between">
                <div className="header-title">
                  <h4 className="card-title">Pending Requisitions</h4>
                </div>
              </Card.Header>
              <Card.Body className="px-0">
                <ReactTable
                  data={pendingRequisition}
                  columns={COLUMNS}
                  onTableStateChange={onPendingRequisitionTableStateChange}
                  recordsTotal={pendingRequisitionCount}
                  initialState={pending_requisition_post_json.current}
                  isRowSelectionAvailable={false}
                  recordsPerPage={10}
                />
              </Card.Body>
            </Card>
          </Col>
        ) : pendingLoading ? (
          <ReactTableLoader />
        ) : null}
        {!myActionLoading && myActions?.length > 0 ? (
          <Col sm="12">
            <Card>
              <Card.Header className="d-flex justify-content-between">
                <div className="header-title">
                  <h4 className="card-title">My Actions</h4>
                </div>
              </Card.Header>
              <Card.Body className="px-0">
                <ReactTable
                  data={myActions}
                  columns={COLUMNS}
                  onTableStateChange={onTableStateChange}
                  recordsTotal={myActionsCount}
                  initialState={my_actions_post_json.current}
                  isRowSelectionAvailable={false}
                  recordsPerPage={10}
                />
              </Card.Body>
            </Card>
          </Col>
        ) : myActionLoading ? (
          <ReactTableLoader />
        ) : null}
      </Row>
    </>
  );
};

export default withAuthorization(memo(Dashboard));

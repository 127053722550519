import React, { memo, useRef, useState } from "react";
import sample from "../../../../lib/email-editor/sample.json";
import { Button, Col, Row, Form, Card } from "react-bootstrap";
import ContentDetails from "../../../../components/partials/common/content-details";
// import { Form } from "react-router-dom";
import { useFormik } from "formik";
import { AddHTMLSchema, addHTMLInitialValue } from "../constants/add-html";
import ReactEmailEditor from "../../../../lib/email-editor/react-email-editor";
import BasicQuill from "../../plugins/pages/quill-editor";
import {
  ADD_CONTENT,
  UPLOAD_FILE,
} from "../../../../api/endpoints/add-content-endpoints";
import postService from "../../../../api/services/post-service";
import toast from "react-hot-toast";
import FilePicker from "../../../../components/form-components/file-picker";
import uploadService from "../../../../api/services/upload-service";

export default function AddHtml({
  content_category_id,
  content_file_type_id,
  formFields,
}) {
  const emailEditorRef = useRef(null);
  const exportHtml = () => {
    // alert("exportHtml");
    return new Promise((resolve) => {
      emailEditorRef.current.editor.exportHtml((data) => {
        const { design, html } = data;
        resolve(html);
      });
    });
  };
  const onReady = () => {
    // emailEditorRef.current.editor.addEventListener(
    //   "onDesignLoad",
    //   function () {
    //     emailEditorRef.current.editor.loadDesign(
    //       { design: {} },
    //       { user: "User Name", company: "Company Name" }
    //     );
    //   }
    // );
  };

  const onDesignLoad = (data) => {
    console.log("onDesignLoad", data);
  };

  const onEditorLoad = () => {
    console.log("loaded");
  };

  const saveDesign = () => {
    emailEditorRef.current.editor.saveDesign((data) => {
      console.log("saveDesign", data);
    });
  };

  const onLoad = () => {
    console.log("onLoad");
  };

  const {
    touched,
    values,
    errors,
    handleChange,
    handleSubmit,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: addHTMLInitialValue,
    validationSchema: AddHTMLSchema,
    onSubmit: (value, action) => {
      exportHtml().then((description_html) => {
        const payload = {
          content_category_id,
          content_brand_id: value.brand,
          content_file_type_id,
          content_title: value.title,
          content_description: null,
          file_type_code: "html",
          title_html: value.title_html,
          description_html,
          citation: value.reference,
          citation_html: value.reference_html,
          reference_files: value.referenceFile,
          thumbnail: value?.thumbnail?.name,
          base: value?.base.name,
        };

        postService(ADD_CONTENT, payload).then((data) => {
          if (!data.isError) {
            toast.success(data.data.message);
            resetForm();
          }
        });
      });
    },
  });

  function setFile(files, keyName) {
    if (files.length > 0) {
      let formdata = new FormData();
      [...files].forEach((file) => {
        formdata.append("files[]", file);
      });
      uploadService(UPLOAD_FILE, formdata)
        .then((data) => {
          if (data !== null)
            setFieldValue(keyName, {
              name: data.data.data[0].name,
              url: data.data.data[0].url,
            });
          else toast.error("File upload Failed");
        })
        .catch((err) => console.log(err));
    } else {
      setFieldValue(keyName, null);
    }
  }

  return (
    <Col lg="12" className="d-flex">
      <Card className="w-100">
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <Col lg="12" className="mb-4">
              <Form.Floating className="custom-form-floating">
                <Form.Select
                  className=""
                  id="floatingInput1"
                  name="brand"
                  value={values.brand}
                  onChange={handleChange}
                  isInvalid={touched.brand && !!errors.brand}
                >
                  <option value="" selected hidden>
                    {" "}
                    Select Brand
                  </option>
                  {formFields &&
                    formFields?.brands &&
                    formFields?.brands.map((item, index) => (
                      <option value={item?.principal_entity_id} key={index + 1}>
                        {item?.principal_entity_name}
                      </option>
                    ))}
                </Form.Select>
                <Form.Label htmlFor="floatingInput">
                  Select Brand <span className="text-danger">*</span>
                </Form.Label>
              </Form.Floating>
              <Form.Group className="col-12 form-group">
                <Form.Label>
                  Title <span className="text-danger">*</span>{" "}
                </Form.Label>
                <div>
                  <BasicQuill
                    name="title"
                    value={values.title}
                    updateData={(value) => {
                      setFieldValue("title", value.rawText);
                      setFieldValue("title_html", value.html);
                    }}

                    // updateData={(e) => setformData(prev => { return { ...prev, article:{...prev.article,title : e} } })}
                  ></BasicQuill>
                  {touched.title && "title" in errors && (
                    <div style={{ color: "red" }}>{errors.title}</div>
                  )}
                </div>
              </Form.Group>

              <Form.Group className="col-12 form-group">
                <Form.Label>
                  References <span className="text-danger">*</span>{" "}
                </Form.Label>
                <div>
                  <BasicQuill
                    value={values.reference}
                    updateData={(value) => {
                      setFieldValue("reference", value.rawText);
                      setFieldValue("reference_html", value.html);
                    }}
                  ></BasicQuill>
                  {touched.reference && "reference" in errors && (
                    <div style={{ color: "red" }}>{errors.reference}</div>
                  )}
                </div>
              </Form.Group>

              <Row className="gap-4 gap-lg-0 mt-4">
                <Col lg="6">
                  <FilePicker
                    title="Add Cover Photo"
                    onUpdate={(val) => setFile(val, "base")}
                    accepts="image/png, image/gif, image/jpeg"
                    source={values?.base?.url}
                    type="image"
                  />
                  {touched.base && "base" in errors && (
                    <div style={{ color: "red" }}>{errors.base}</div>
                  )}
                </Col>
                <Col lg="6">
                  <FilePicker
                    title="Add Thumbnail"
                    onUpdate={(val) => setFile(val, "thumbnail")}
                    accepts="image/png, image/gif, image/jpeg"
                    source={values?.thumbnail?.url}
                    type="image"
                  />
                  {touched.thumbnail && "thumbnail" in errors && (
                    <div style={{ color: "red" }}>{errors.thumbnail}</div>
                  )}
                </Col>
              </Row>
            </Col>

            <ReactEmailEditor
              ref={emailEditorRef}
              onReady={onReady}
              onLoad={onLoad}
            />
            <Button
              type="submit"
              variant="btn btn-primary flex-md-grow-0 flex-grow-1 text-nowrap"
            >
              Submit
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </Col>
  );
}

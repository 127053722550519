import React, { useEffect } from "react";
import { Badge } from "react-bootstrap";
import { findIcon } from "../../../../../dashboard/icons";

const DropZone = ({
  title,
  onDrop,
  droppedItems = [],
  onRemove,
  tagSection,
}) => {
  const [dragHover, setDragHover] = React.useState(false);
  const PlusIcon = ({ className, ...rest }) => (
    <svg
      width="40"
      viewBox="0 0 24 24"
      className={className}
      fill="none"
      {...rest}
    >
      <path
        opacity="0.4"
        d="M16.6667 2H7.33333C3.92889 2 2 3.92889 2 7.33333V16.6667C2 20.0622 3.92 22 7.33333 22H16.6667C20.0711 22 22 20.0622 22 16.6667V7.33333C22 3.92889 20.0711 2 16.6667 2Z"
        fill="currentColor"
      ></path>
      <path
        d="M15.3205 12.7083H12.7495V15.257C12.7495 15.6673 12.4139 16 12 16C11.5861 16 11.2505 15.6673 11.2505 15.257V12.7083H8.67955C8.29342 12.6687 8 12.3461 8 11.9613C8 11.5765 8.29342 11.2539 8.67955 11.2143H11.2424V8.67365C11.2824 8.29088 11.6078 8 11.996 8C12.3842 8 12.7095 8.29088 12.7495 8.67365V11.2143H15.3205C15.7066 11.2539 16 11.5765 16 11.9613C16 12.3461 15.7066 12.6687 15.3205 12.7083Z"
        fill="currentColor"
      ></path>
    </svg>
  );
  const CrossIcon = ({ className, ...rest }) => (
    <svg
      fill="none"
      class="icon-16"
      width="16"
      height="16"
      viewBox="0 0 24 24"
      className={className}
      {...rest}
    >
      <path
        d="M14.3955 9.59497L9.60352 14.387"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></path>
      <path
        d="M14.3971 14.3898L9.60107 9.59277"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></path>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.3345 2.75024H7.66549C4.64449 2.75024 2.75049 4.88924 2.75049 7.91624V16.0842C2.75049 19.1112 4.63549 21.2502 7.66549 21.2502H16.3335C19.3645 21.2502 21.2505 19.1112 21.2505 16.0842V7.91624C21.2505 4.88924 19.3645 2.75024 16.3345 2.75024Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></path>
    </svg>
  );
  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const tag = event.dataTransfer.getData("text/plain");
    onDrop(tag, title, tagSection);
    setDragHover(false);
  };
  const handleDragEnter = (event) => {
    event.preventDefault();
    setDragHover(true);
  };
  const handleDragLeave = (event) => {
    event.preventDefault();
    if (event.target === event.currentTarget) {
      setDragHover(false);
    }
  };
  const dragProps = {
    onDragOver: handleDragEnter,
    onDragLeave: handleDragLeave,
  };
  return (
    <div
      className={`sidebar-border-profile-card overflow-hidden position-relative m-0 ${
        dragHover ? "drag-over" : ""
      }`}
      onDrop={handleDrop}
      {...dragProps}
    >
      {droppedItems.length > 0 ? (
        droppedItems.map((item, index) => (
          <Badge
            pill
            key={index + 1}
            className="fw-normal d-block py-2 px-3 flex-wrap fs-7 m-2"
            bg="soft-primary"
          >
            {item}
            <span
              className="ms-2"
              onClick={() => onRemove(index, title, tagSection)}
            >
              <CrossIcon className={"text-dark"} />
            </span>
          </Badge>
        ))
      ) : (
        <>
          <div className="sidebar-btn text-center">
            <span className="sidebar-btn-icon">
              <PlusIcon className={"text-primary"} />
            </span>
          </div>
          <div className="sidebar-border-user-data d-flex flex-column">
            <span className="sidebar-border-data sidebar-border-title mb-1 text-center">
              {title}
            </span>
          </div>
        </>
      )}
    </div>
  );
};

export default DropZone;

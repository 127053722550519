import React from "react";

// layout

import Default from "../../../../layouts/dashboard/default";
import AddSpq from "../pages/add-spq";
import AllSpqList from "../pages/all-spq-list";
import SpqDetails from "../pages/spq-details";


// pages

export const SpqRouter = [
  {
    path: "/spq",
    element: <Default />,
    children: [
      {
        path: "create",
        element: <AddSpq />,
      },
      {
        path: "survey-listing",
        element: <AllSpqList />,
      },
      {
        path: "poll-listing",
        element: <AllSpqList />,
      },
      {
        path: "quiz-listing",
        element: <AllSpqList />,
      },
      {
        path: "details/:id",
        element: <SpqDetails />,
      },
      {
        path: "survey-details/:id",
        element: <SpqDetails />,
      },
      {
        path: "poll-details/:id",
        element: <SpqDetails />,
      },
      {
        path: "quiz-details/:id",
        element: <SpqDetails />,
      },
    ],
  },
];

import React from "react";

// layout

import Default from "../../../../layouts/dashboard/default";
import Search from "../pages/search";


// pages

export const SearchRouter = [
  {
    path: "/search",
    element: <Default />,
    children: [
      {
        path: "/search",
        element: <Search />,
      },
    ],
  },
];

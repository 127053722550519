import React, { memo, useCallback, useEffect, useState } from "react";
import Card from "../../../../components/bootstrap/card";

// Componet
// import Video from "../../content-library/components/video.js";

//Img
import { Button, Col, Row } from "react-bootstrap";
import { findIcon } from "../../../dashboard/icons";
// import ContentCard from "../components/resource-content";
// import ReactPlayer from "react-player";
import {
  GET_RESOURCES_LIST,
  RESOURCES_DETAILS,
} from "../../../../api/endpoints/help-and-support-endpoints";
import { getService } from "../../../../api/services/get-services";
import postService from "../../../../api/services/post-service";
import ContentCard from "../../content-library/components/content-card";
import ContentUploadModal from "../components/content-upload-modal";
import app_config from "../../../../common/data/app_config";
import withAuthorization from "../../../../components/hoc/with-authorization";

const breakpointColumnsObj = {
  default: 4,
  1100: 3,
  768: 2,
  500: 1,
};

const Resources = () => {
  const [show, setShow] = useState(false);
  const [resourcesList, setResourcesList] = useState([]);
  const [resourcesPath, setResourcesPath] = useState("");
  const [showFilePreview, setShowFilePreview] = useState("");
  // const currentID = useRef("");
  const showModalHandler = useCallback(() => setShow(!show), [show]);

  useEffect(() => {
    if (!show) {
      fetchResourcesList();
    }
  }, [show]);
  const fetchResourcesList = async () => {
    const response = await postService(GET_RESOURCES_LIST, {
      filter: {},
      pagination: {
        limit: 5,
        offset: 0,
      },
      sort: {
        resource_id: "desc",
      },
    });
    console.log("GET_RESOURCES_LIST", response.data?.data?.rows);
    // const allFileTypeArray = [...new Set(response.data?.data?.filetypes.map(
    //   (i) => i.file_content_type
    // ))];
    setResourcesList(response.data?.data?.rows);
    // const modifiedResourcesList=allFileTypeArray.map((i)=>{return i})
  };

  const fetchResourcesDetails = useCallback(
    async (id) => {
      const response = await getService(RESOURCES_DETAILS + id);
      if (response && response?.data?.success) {
        setResourcesPath(response?.data?.data?.resource_path);
        // currentID.current = id;
        setShowFilePreview(id);
      }
      console.log("resourcesDetails", response);
    },
    [resourcesPath]
  );
  const onViewModalClose = () => setShowFilePreview("");
  console.log("resourcesPath", resourcesPath);
  return (
    <>
      <Button
        variant="success"
        className="d-flex align-items-center justify-content-center add_floating__button position-fixed rounded-circle p-1 avatar-80 lh-1 border border-white border-2"
        onClick={showModalHandler}
      >
        {findIcon("Plus", "outline", "32")}
      </Button>
      <h3 className="mb-4">Resources</h3>
      <Card>
        <Card.Body>
          <h5>All files</h5>
          <Row className="row-cols-1 row-cols-md-4 row-cols-lg-6">
            {resourcesList &&
              resourcesList.length > 0 &&
              resourcesList.map((i) => (
                <Col key={i.file_type_id}>
                  <ContentCard
                    title={i.resource_name}
                    description={i.resource_description ?? ""}
                    extraClass={""}
                    icon={
                      i?.resource_file_type_id
                        ? i?.resource_file_type_id?.file_type_icon
                        : ""
                    }
                    id={i.resource_id}
                    trash={false}
                    type={
                      i?.resource_file_type_id
                        ? i?.resource_file_type_id?.file_type_code
                        : ""
                    }
                    file={`${app_config.fileStoragePath}${resourcesPath}`}
                    onClickPreview={fetchResourcesDetails}
                    // filePreviewModal={!!resourcesPath}
                    filePreviewModal={showFilePreview == i.resource_id}
                    onViewModalClose={onViewModalClose}
                  />
                </Col>
              ))}
          </Row>
        </Card.Body>
      </Card>

      <ContentUploadModal showModalHandler={showModalHandler} show={show} />
    </>
  );
};

export default withAuthorization(memo(Resources));

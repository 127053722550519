import React, { Fragment, memo, useState } from "react";
import WordCloud from "react-wordcloud";
import useRedirect from "../../../../components/custom-hooks/use-redirect";
import { Card, Col, Row } from "react-bootstrap";
import topics from "../data/topics.json";
import withAuthorization from "../../../../components/hoc/with-authorization";
import { Link } from "react-router-dom";
import FilePreviewModal from "../../../../components/partials/common/file-preview-modal";
const svg1 = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    class="icon-32"
    width="32"
    height="32"
    viewBox="0 0 24 24"
  >
    <path
      d="M22.4541 11.3918C22.7819 11.7385 22.7819 12.2615 22.4541 12.6082C21.0124 14.1335 16.8768 18 12 18C7.12317 18 2.98759 14.1335 1.54586 12.6082C1.21811 12.2615 1.21811 11.7385 1.54586 11.3918C2.98759 9.86647 7.12317 6 12 6C16.8768 6 21.0124 9.86647 22.4541 11.3918Z"
      fill="currentColor"
    ></path>
    <path
      d="M12 17C14.7614 17 17 14.7614 17 12C17 9.23858 14.7614 7 12 7C9.23858 7 7 9.23858 7 12C7 14.7614 9.23858 17 12 17Z"
      fill="white"
    ></path>
    <path
      d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
      fill="currentColor"
    ></path>
    <mask
      id="mask0_18_1017"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="9"
      y="9"
      width="6"
      height="6"
    >
      <path
        d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
        fill="currentColor"
      ></path>
    </mask>
    <g mask="url(#mask0_18_1017)">
      <path
        opacity="0.89"
        d="M13.5 12C14.3284 12 15 11.3284 15 10.5C15 9.67157 14.3284 9 13.5 9C12.6716 9 12 9.67157 12 10.5C12 11.3284 12.6716 12 13.5 12Z"
        fill="white"
        fill-opacity="0.6"
      ></path>
    </g>
  </svg>
);

const words = topics.map((topic) => {
  return {
    text: topic.tag_name,
    value: topic.medwiki_id,
  };
});
const colors = ["#ff0000", "#0000ff", "#009933"];
//generate a random color between the colors array
const randomColor = () => {
  return colors[Math.floor(Math.random() * colors.length)];
};

const size = [1000, 400];
const ViewCloud = () => {
  const [item, setItem] = useState({});
  const [mediaType, setMediaType] = useState("");
  const [show, setShow] = useState(false);
  const table = [
    {
      file: (
        <svg
          width="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.4"
            d="M2 11.0786C2.05 13.4166 2.19 17.4156 2.21 17.8566C2.281 18.7996 2.642 19.7526 3.204 20.4246C3.986 21.3676 4.949 21.7886 6.292 21.7886C8.148 21.7986 10.194 21.7986 12.181 21.7986C14.176 21.7986 16.112 21.7986 17.747 21.7886C19.071 21.7886 20.064 21.3566 20.836 20.4246C21.398 19.7526 21.759 18.7896 21.81 17.8566C21.83 17.4856 21.93 13.1446 21.99 11.0786H2Z"
            fill="currentColor"
          ></path>
          <path
            d="M11.2451 15.3843V16.6783C11.2451 17.0923 11.5811 17.4283 11.9951 17.4283C12.4091 17.4283 12.7451 17.0923 12.7451 16.6783V15.3843C12.7451 14.9703 12.4091 14.6343 11.9951 14.6343C11.5811 14.6343 11.2451 14.9703 11.2451 15.3843Z"
            fill="currentColor"
          ></path>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.211 14.5565C10.111 14.9195 9.762 15.1515 9.384 15.1015C6.833 14.7455 4.395 13.8405 2.337 12.4815C2.126 12.3435 2 12.1075 2 11.8555V8.38949C2 6.28949 3.712 4.58149 5.817 4.58149H7.784C7.972 3.12949 9.202 2.00049 10.704 2.00049H13.286C14.787 2.00049 16.018 3.12949 16.206 4.58149H18.183C20.282 4.58149 21.99 6.28949 21.99 8.38949V11.8555C21.99 12.1075 21.863 12.3425 21.654 12.4815C19.592 13.8465 17.144 14.7555 14.576 15.1105C14.541 15.1155 14.507 15.1175 14.473 15.1175C14.134 15.1175 13.831 14.8885 13.746 14.5525C13.544 13.7565 12.821 13.1995 11.99 13.1995C11.148 13.1995 10.433 13.7445 10.211 14.5565ZM13.286 3.50049H10.704C10.031 3.50049 9.469 3.96049 9.301 4.58149H14.688C14.52 3.96049 13.958 3.50049 13.286 3.50049Z"
            fill="currentColor"
          ></path>
        </svg>
      ),
      name: "The World Health Organisation (WHO) recommendations on the treatment of drug-resistant leprosy",
      date: "23 Sept,11:23 am",
      speciality: "Internal Medicine",
      remove: svg1,
    },
    {
      file: (
        <svg
          width="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.4"
            d="M16.191 2H7.81C4.77 2 3 3.78 3 6.83V17.16C3 20.26 4.77 22 7.81 22H16.191C19.28 22 21 20.26 21 17.16V6.83C21 3.78 19.28 2 16.191 2Z"
            fill="currentColor"
          ></path>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.07996 6.6499V6.6599C7.64896 6.6599 7.29996 7.0099 7.29996 7.4399C7.29996 7.8699 7.64896 8.2199 8.07996 8.2199H11.069C11.5 8.2199 11.85 7.8699 11.85 7.4289C11.85 6.9999 11.5 6.6499 11.069 6.6499H8.07996ZM15.92 12.7399H8.07996C7.64896 12.7399 7.29996 12.3899 7.29996 11.9599C7.29996 11.5299 7.64896 11.1789 8.07996 11.1789H15.92C16.35 11.1789 16.7 11.5299 16.7 11.9599C16.7 12.3899 16.35 12.7399 15.92 12.7399ZM15.92 17.3099H8.07996C7.77996 17.3499 7.48996 17.1999 7.32996 16.9499C7.16996 16.6899 7.16996 16.3599 7.32996 16.1099C7.48996 15.8499 7.77996 15.7099 8.07996 15.7399H15.92C16.319 15.7799 16.62 16.1199 16.62 16.5299C16.62 16.9289 16.319 17.2699 15.92 17.3099Z"
            fill="currentColor"
          ></path>
        </svg>
      ),
      name: " Update in the tuberculosis section of the 2023 WHO model lists of essential medicines",
      date: "20 Nov,12:40 pm",
      speciality: "Pediatrics",
      remove: svg1,
    },
    {
      file: (
        <svg
          width="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.4"
            d="M16.3328 22H7.66618C4.2769 22 2 19.6229 2 16.0843V7.91672C2 4.37811 4.2769 2 7.66618 2H16.3338C19.7231 2 22 4.37811 22 7.91672V16.0843C22 19.6229 19.7231 22 16.3328 22Z"
            fill="currentColor"
          ></path>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.2451 8.67496C11.2451 10.045 10.1301 11.16 8.7601 11.16C7.3891 11.16 6.2751 10.045 6.2751 8.67496C6.2751 7.30496 7.3891 6.18896 8.7601 6.18896C10.1301 6.18896 11.2451 7.30496 11.2451 8.67496ZM19.4005 14.0876C19.6335 14.3136 19.8005 14.5716 19.9105 14.8466C20.2435 15.6786 20.0705 16.6786 19.7145 17.5026C19.2925 18.4836 18.4845 19.2246 17.4665 19.5486C17.0145 19.6936 16.5405 19.7556 16.0675 19.7556H7.6865C6.8525 19.7556 6.1145 19.5616 5.5095 19.1976C5.1305 18.9696 5.0635 18.4446 5.3445 18.1026C5.8145 17.5326 6.2785 16.9606 6.7465 16.3836C7.6385 15.2796 8.2395 14.9596 8.9075 15.2406C9.1785 15.3566 9.4505 15.5316 9.7305 15.7156C10.4765 16.2096 11.5135 16.8876 12.8795 16.1516C13.8132 15.641 14.3552 14.7673 14.827 14.0069L14.8365 13.9916C14.8682 13.9407 14.8997 13.8898 14.9311 13.8391C15.0915 13.5799 15.2495 13.3246 15.4285 13.0896C15.6505 12.7986 16.4745 11.8886 17.5395 12.5366C18.2185 12.9446 18.7895 13.4966 19.4005 14.0876Z"
            fill="currentColor"
          ></path>
        </svg>
      ),
      name: "Bacillus clausii probiotic for gastrointestinal health",
      date: "08 Oct,05:45pm",
      speciality: "Internal Medicine",
      remove: svg1,
    },
  ];

  const table1 = [
    {
      file: (
        <svg
          width="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.4"
            d="M2 11.0786C2.05 13.4166 2.19 17.4156 2.21 17.8566C2.281 18.7996 2.642 19.7526 3.204 20.4246C3.986 21.3676 4.949 21.7886 6.292 21.7886C8.148 21.7986 10.194 21.7986 12.181 21.7986C14.176 21.7986 16.112 21.7986 17.747 21.7886C19.071 21.7886 20.064 21.3566 20.836 20.4246C21.398 19.7526 21.759 18.7896 21.81 17.8566C21.83 17.4856 21.93 13.1446 21.99 11.0786H2Z"
            fill="currentColor"
          ></path>
          <path
            d="M11.2451 15.3843V16.6783C11.2451 17.0923 11.5811 17.4283 11.9951 17.4283C12.4091 17.4283 12.7451 17.0923 12.7451 16.6783V15.3843C12.7451 14.9703 12.4091 14.6343 11.9951 14.6343C11.5811 14.6343 11.2451 14.9703 11.2451 15.3843Z"
            fill="currentColor"
          ></path>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.211 14.5565C10.111 14.9195 9.762 15.1515 9.384 15.1015C6.833 14.7455 4.395 13.8405 2.337 12.4815C2.126 12.3435 2 12.1075 2 11.8555V8.38949C2 6.28949 3.712 4.58149 5.817 4.58149H7.784C7.972 3.12949 9.202 2.00049 10.704 2.00049H13.286C14.787 2.00049 16.018 3.12949 16.206 4.58149H18.183C20.282 4.58149 21.99 6.28949 21.99 8.38949V11.8555C21.99 12.1075 21.863 12.3425 21.654 12.4815C19.592 13.8465 17.144 14.7555 14.576 15.1105C14.541 15.1155 14.507 15.1175 14.473 15.1175C14.134 15.1175 13.831 14.8885 13.746 14.5525C13.544 13.7565 12.821 13.1995 11.99 13.1995C11.148 13.1995 10.433 13.7445 10.211 14.5565ZM13.286 3.50049H10.704C10.031 3.50049 9.469 3.96049 9.301 4.58149H14.688C14.52 3.96049 13.958 3.50049 13.286 3.50049Z"
            fill="currentColor"
          ></path>
        </svg>
      ),
      name: "Guidelines regarding the treatment of dengue",
      date: "16 Oct,11:23m",
      speciality: "Internal Medicine",
      remove: svg1,
    },
    {
      file: (
        <svg
          width="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.4"
            d="M16.191 2H7.81C4.77 2 3 3.78 3 6.83V17.16C3 20.26 4.77 22 7.81 22H16.191C19.28 22 21 20.26 21 17.16V6.83C21 3.78 19.28 2 16.191 2Z"
            fill="currentColor"
          ></path>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.07996 6.6499V6.6599C7.64896 6.6599 7.29996 7.0099 7.29996 7.4399C7.29996 7.8699 7.64896 8.2199 8.07996 8.2199H11.069C11.5 8.2199 11.85 7.8699 11.85 7.4289C11.85 6.9999 11.5 6.6499 11.069 6.6499H8.07996ZM15.92 12.7399H8.07996C7.64896 12.7399 7.29996 12.3899 7.29996 11.9599C7.29996 11.5299 7.64896 11.1789 8.07996 11.1789H15.92C16.35 11.1789 16.7 11.5299 16.7 11.9599C16.7 12.3899 16.35 12.7399 15.92 12.7399ZM15.92 17.3099H8.07996C7.77996 17.3499 7.48996 17.1999 7.32996 16.9499C7.16996 16.6899 7.16996 16.3599 7.32996 16.1099C7.48996 15.8499 7.77996 15.7099 8.07996 15.7399H15.92C16.319 15.7799 16.62 16.1199 16.62 16.5299C16.62 16.9289 16.319 17.2699 15.92 17.3099Z"
            fill="currentColor"
          ></path>
        </svg>
      ),
      name: "Role of Racecadotril and Loperamide in the treatment of acute diarrhoea",
      date: "20 Nov,12:40pm",
      speciality: "Internal Medicine",
      remove: svg1,
    },
    {
      file: (
        <svg
          width="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.4"
            d="M16.3328 22H7.66618C4.2769 22 2 19.6229 2 16.0843V7.91672C2 4.37811 4.2769 2 7.66618 2H16.3338C19.7231 2 22 4.37811 22 7.91672V16.0843C22 19.6229 19.7231 22 16.3328 22Z"
            fill="currentColor"
          ></path>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.2451 8.67496C11.2451 10.045 10.1301 11.16 8.7601 11.16C7.3891 11.16 6.2751 10.045 6.2751 8.67496C6.2751 7.30496 7.3891 6.18896 8.7601 6.18896C10.1301 6.18896 11.2451 7.30496 11.2451 8.67496ZM19.4005 14.0876C19.6335 14.3136 19.8005 14.5716 19.9105 14.8466C20.2435 15.6786 20.0705 16.6786 19.7145 17.5026C19.2925 18.4836 18.4845 19.2246 17.4665 19.5486C17.0145 19.6936 16.5405 19.7556 16.0675 19.7556H7.6865C6.8525 19.7556 6.1145 19.5616 5.5095 19.1976C5.1305 18.9696 5.0635 18.4446 5.3445 18.1026C5.8145 17.5326 6.2785 16.9606 6.7465 16.3836C7.6385 15.2796 8.2395 14.9596 8.9075 15.2406C9.1785 15.3566 9.4505 15.5316 9.7305 15.7156C10.4765 16.2096 11.5135 16.8876 12.8795 16.1516C13.8132 15.641 14.3552 14.7673 14.827 14.0069L14.8365 13.9916C14.8682 13.9407 14.8997 13.8898 14.9311 13.8391C15.0915 13.5799 15.2495 13.3246 15.4285 13.0896C15.6505 12.7986 16.4745 11.8886 17.5395 12.5366C18.2185 12.9446 18.7895 13.4966 19.4005 14.0876Z"
            fill="currentColor"
          ></path>
        </svg>
      ),
      name: "Recommendations on the immunisation schedule for infants",
      date: "08 Oct,05:45pm",
      speciality: "Paediatrics",
      remove: svg1,
    },
  ];

  const { redirectTo } = useRedirect();
  const callbacks = {
    getWordColor: (word) => randomColor(),
    getWordFontFamily: (word) => "poppins",
    onWordClick: (w) => redirectTo("creator-recommendation/view/" + w.text),
    onWordMouseOver: (w) => console.log(`hovered: ${w.text}`),
    getWordTooltip: (word) => "",
  };

  const options = {
    //colors will be red and blue

    rotations: 2,
    rotationAngles: [0, 0],
    deterministic: false,
    enableOptimizations: false,
    enableTooltip: true,
    fontFamily: "sans-serif",
    fontSizes: [14, 18],
    fontStyle: "normal",
    fontWeight: "normal",
    padding: 3,
    scale: "sqrt",
    spiral: "rectangular",
    tooltipOptions: {},
    transitionDuration: 600,
  };
  return (
    <Fragment>
      {/* heading of the page */}
      <Row className="page-title">
        <h1 className="mb-3 mt-0 fs-3">Creator Recommendation</h1>
      </Row>
      {/* content of the page */}
      <Row>
        <Col lg="12">
          <Row>
            <Col lg="6">
              <Card>
                <Card.Header className="d-flex align-tems-center justify-content-between">
                  <h4>
                    Trending Topics{" "}
                    <span style={{ fontSize: "12px" }}>(Global)</span>
                  </h4>
                  <Link to="/content-library/listing" className="text-primary">
                    View all
                  </Link>
                </Card.Header>
                <div className="table-responsive">
                  <table className="table table-borderless iq-file-manager-table mb-0">
                    <thead>
                      <tr className="border-bottom bg-transparent text-dark">
                        <th scope="col">Topic</th>
                        <th scope="col">Speciality</th>
                        <th scope="col">Date</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {table.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <div className="d-flex align-items-center gap-2">
                                <span className="avatar-40 bg-soft-primary rounded-pill">
                                  {item.file}
                                </span>
                                <h6 className="mb-0 text-wrap">{item.name}</h6>
                              </div>
                            </td>
                            <td>
                              <small className="text-muted">
                                {item.speciality}
                              </small>
                            </td>
                            <td>
                              <small className="text-primary">
                                {item.date}
                              </small>
                            </td>
                            <td>
                              <Link
                                className="d-flex align-items-center text-danger"
                                onClick={() => {
                                  setMediaType("image");
                                  setItem(item);
                                  setShow(true);
                                }}
                              >
                                <span className="btn-inner">{item.remove}</span>
                              </Link>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </Card>
            </Col>
            <Col lg="6">
              <Card>
                <Card.Header className="d-flex align-tems-center justify-content-between">
                  <h4>
                    Trending Topics{" "}
                    <span style={{ fontSize: "12px" }}>(Internal)</span>
                  </h4>
                  <Link to="/content-library/listing" className="text-primary">
                    View all
                  </Link>
                </Card.Header>
                <div className="table-responsive">
                  <table className="table table-borderless iq-file-manager-table mb-0">
                    <thead>
                      <tr className="border-bottom bg-transparent text-dark">
                        <th scope="col">Topic</th>
                        <th scope="col">Speciality</th>
                        <th scope="col">Date</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {table1.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <div className="d-flex align-items-center gap-2">
                                <span className="avatar-40 bg-soft-primary rounded-pill">
                                  {item.file}
                                </span>
                                <h6 className="mb-0 text-wrap">{item.name}</h6>
                              </div>
                            </td>
                            <td>
                              <small className="text-muted">
                                {item.speciality}
                              </small>
                            </td>
                            <td>
                              <small className="text-primary">
                                {item.date}
                              </small>
                            </td>
                            <td>
                              <Link
                                className="d-flex align-items-center text-danger"
                                onClick={() => {
                                  setMediaType("image");
                                  setItem(item);
                                  setShow(true);
                                }}
                              >
                                <span className="btn-inner">{item.remove}</span>
                              </Link>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col lg="12">
          <Card>
            <Card.Header>
              <h4>Topics with high interest</h4>
            </Card.Header>
            <WordCloud
              callbacks={callbacks}
              options={options}
              size={size}
              words={words}
            />
          </Card>
        </Col>
      </Row>
      {show && (
        <FilePreviewModal
          createdOn={new Date()}
          mediaType="pdf"
          show={show}
          viewModalClose={() => setShow(false)}
          title={item.name}
          brand="Brand 1"
          createdBy="Super Admin"
          description={item.name}
          icon="https://storage.googleapis.com/cipla-cms-dev/icons/file_type/pdf.svg"
          file_url="https://storage.googleapis.com/cipla-cms-dev/cipla/cluster-1/division-1/sub-division-1/brand-1/pdf/443_what_is_the_use_of_milrinone/1695212192_959191695212144_86.pdf"
        />
      )}
    </Fragment>
  );
};

export default withAuthorization(memo(ViewCloud));

import { useQuery } from "@tanstack/react-query";
import axiosInstance from "../../api/instances/axiosInstance";
import { ALL_MENU_ITEMS } from "../query-constants/menu-contants";
import { GET_ALL_MENU_ITEMS } from "../../api/endpoints/menu-endpoints";
const staleTime = 300000;
const cacheTime = 600000;
export const useGetMenuItems = (post_json) => {
  return useQuery(
    [ALL_MENU_ITEMS],
    () => axiosInstance.post(GET_ALL_MENU_ITEMS, post_json),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      select: (res) => res.data ?? [],
    }
  );
};

import React, {
  Fragment,
  useEffect,
  useRef,
  useState,
  useCallback,
  memo,
} from "react";
import { Badge, Button, Card, Col, Row, Table } from "react-bootstrap";
import { Form, useLocation } from "react-router-dom";
import Flatpickr from "react-flatpickr";
import useRedirect from "../../../../components/custom-hooks/use-redirect";
import postService from "../../../../api/services/post-service";
import { ALL_ACTIONS_POST } from "../../../../api/endpoints/action-endpoints";
import ReactTable from "../../../../components/table/react-table";
import {
  changeTableStateToPayload,
  getFormatedDate,
} from "../../../../common/utils/utils";
import {
  STATUS_CONSTANTS,
  actionStatus,
  getStatusId,
} from "../constants/action-constants";
import useRedirectToAction from "../hooks/use-redirect-to-action";
import * as dayjs from "dayjs";
import withAuthorization from "../../../../components/hoc/with-authorization";
import ReactTableLoader from "../../../../skeleton/react-table-loader/react-table-loader";
import ColumnTextWrapper from "../../../../components/common-components/column-text-wrapper";
const ActionList = () => {
  const { redirectTo } = useRedirect();
  const redirectToAction = useRedirectToAction();
  const [actions, setActions] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const { pathname } = useLocation();
  const [loading, setLoading] = useState(false);
  const statusName =
    pathname.split("/").length > 2 ? pathname.split("/")[2] : "";

  const statusId = getStatusId(statusName);
  console.log("statusName", statusName);
  const [pageTitle, setPageTitle] = React.useState("");
  useEffect(() => {
    // alert(statusId);
  }, [pathname]);
  const getInitialFilter = () =>
    statusId == 0
      ? statusName == "overdue"
        ? {
            status: { type: "eq", value: STATUS_CONSTANTS.PENDING },
            action_due_date: {
              type: "gt",
              value: dayjs().format("YYYY-MM-DD 00-00-00"),
            },
          }
        : {}
      : { status: { type: "eq", value: statusId } };
  const details = (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="icon-22"
      width="32"
      height="32"
      viewBox="0 0 24 24"
    >
      <path
        d="M12.1415 6.5899C11.9075 6.71614 11.7616 6.95618 11.7616 7.21726V11.2827H3.73429C3.32896 11.2827 3 11.604 3 12C3 12.3959 3.32896 12.7172 3.73429 12.7172H11.7616V16.7827C11.7616 17.0447 11.9075 17.2848 12.1415 17.4101C12.3755 17.5372 12.6614 17.5286 12.8875 17.39L20.6573 12.6073C20.8708 12.4753 21 12.2467 21 12C21 11.7532 20.8708 11.5247 20.6573 11.3927L12.8875 6.60998C12.7681 6.5373 12.632 6.5 12.4959 6.5C12.3745 6.5 12.2521 6.5306 12.1415 6.5899Z"
        fill="currentColor"
      ></path>
    </svg>
  );

  const post_json = useRef({
    default: "all",
    pagination: {
      offset: 0,
      limit: 10,
    },
    sort: {
      action_id: "desc",
    },
  });
  const COLUMNS = [
    {
      Header: "ID",
      accessor: "action_id",
    },
    {
      Header: "Name",
      accessor: "action_name",
      Cell: ({ row }) => (
        <ColumnTextWrapper>{row.values.action_name}</ColumnTextWrapper>
      ),
    },
    {
      Header: "Priority",
      accessor: "action_priority_id",
      Cell: ({ value }) => {
        console.log("action_priority_value", value);
        //return a bootstrap badge according to priority if priority is 1 then danger else if 2 then warning else if 3 then primary
        let bg = "primary";
        if (value?.priority_id == 1) {
          bg = "danger";
        } else if (value?.priority_id == 2) {
          bg = "warning";
        } else if (value?.priority_id == 3) {
          bg = "primary";
        }
        return <Badge bg={bg}>{value.priority_name}</Badge>;
      },
    },
    {
      Header: "Assigned By",
      accessor: "action_assign_by",
      Cell: ({ value }) => {
        return value?.name ?? "";
      },
    },
    {
      Header: "Created At",
      accessor: "created_at",
      Cell: ({ value }) => {
        return getFormatedDate(value);
      },
    },
    {
      Header: "Type",
      accessor: "action_type_id",
      Cell: ({ value }) => {
        return value?.action_type_name ?? "";
      },
    },
    {
      Header: "Action",
      Cell: ({ row }) => {
        return (
          <div className="d-flex justify-content-center">
            <Button
              className="btn btn-icon btn-sm rounded-pill"
              to="#"
              role="button"
              onClick={() => {
                const { action_type_id, action_id, action_to_filetypes } =
                  row.original;
                // console.log("action_type_id", row.original);
                redirectToAction(action_type_id?.action_type_code, action_id, {
                  ...action_to_filetypes,
                });
              }}
            >
              <span className="btn-inner">{details}</span>
            </Button>
          </div>
        );
      },
    },
  ];

  const fetchActions = async () => {
    let response = await postService(ALL_ACTIONS_POST, post_json.current);
    console.log("response", response);
    console.log("rows", response.data.data.rows);
    if (response.data.success) {
      setActions(response.data.data.rows);
      setTotalCount(response.data.data.totalCount);
      setLoading(false);
      console.log("COLUMNS", Object.keys(response.data));
    }
  };
  useEffect(() => {
    setLoading(true);
    post_json.current.filter = getInitialFilter();
    setPageTitle(actionStatus[statusId]);
    fetchActions();
    return () => {
      //set all states to initial state
      setActions([]);
      setTotalCount(0);
      setLoading(false);
    };
  }, [pathname]);
  const onTableStateChange = useCallback(
    (state) => {
      let payload = changeTableStateToPayload(state);
      console.log("payload", payload);
      post_json.current = payload;
      fetchActions();
    },
    [actions, post_json.current, loading]
  );

  return (
    <>
      <Fragment>
        <div className="d-flex justify-content-between align-items-center flex-wrap mb-4 gap-3">
          <div className="d-flex flex-column">
            <h3 className="m-0">
              {" "}
              {pageTitle || "All Actions"} ({totalCount})
            </h3>
          </div>
          {/* <div className="d-flex justify-content-between align-items-center rounded flex-wrap gap-3">
              <div className="form-group mb-0 ">
                <select className="form-select">
                  <option defaultValue>Sort By</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
              </div>
              <div className="form-group mb-0 ">
                <Flatpickr
                  options={{ mode: "range", minDate: "today" }}
                  className="form-control range_flatpicker"
                  placeholder="24 Jan 2022 to 23 Feb 2022"
                />
              </div>
            </div> */}
        </div>
        <Row>
          <Col lg="12">
            <Card>
              <Card.Body>
                <div className="fancy-table table-responsive rounded">
                  {/* implement react table */}
                  {!loading ? (
                    actions?.length > 0 && (
                      <ReactTable
                        columns={COLUMNS}
                        data={actions}
                        onTableStateChange={onTableStateChange}
                        recordsTotal={totalCount}
                        recordsPerPage={10}
                        isRowSelectionAvailable={false}
                        initialState={post_json.current}
                      />
                    )
                  ) : (
                    <ReactTableLoader />
                  )}
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Fragment>
    </>
  );
};

export default withAuthorization(memo(ActionList));

import React from "react";

// layout

import Default from "../../../../layouts/dashboard/default";
import AddCourses from "../pages/add-channel";
import AllCoursesList from "../pages/all-channel-list";
import CoursesDetails from "../pages/channel-details";
import Election from "../pages/election";
import User from "../pages/user";
import SharePage from "../pages/share-page";
import Subscription from "../pages/subscription";
import ChannelDetails from "../pages/channel-details";
import AllChannelList from "../pages/all-channel-list";
import AddChannel from "../pages/add-channel";


// pages

export const ChannelRouter = [
  {
    path: "/channel",
    element: <Default />,
    children: [
      {
        path: "create",
        element: <AddChannel />,
      },
      {
        path: "listing",
        element: <AllChannelList />,
      },
      {
        path: "user",
        element: <User />,
      },
      {
        path: "election",
        element: <Election />,
      },
      {
        path: "share-page",
        element: <SharePage />,
      },
      {
        path: "subscription",
        element: <Subscription />,
      },
      {
        path: "details/:id",
        element: <ChannelDetails />,
      },
    ],
  },
];

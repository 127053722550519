import React from "react";
import { Badge } from "react-bootstrap";

const Tag = ({ bg = "primary", tag = "", onDragStart }) => {
  const handleDragStart = (event) => {
    event.dataTransfer.setData("text/plain", tag);
    onDragStart();
  };

  return (
    <Badge
      pill
      bg={bg}
      className="fw-normal d-block py-2 px-3 flex-wrap fs-6 cursor-grab"
      draggable
      onDragStart={handleDragStart}
    >
      {tag}
    </Badge>
  );
};

export default Tag;

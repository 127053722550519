import React, { Fragment, memo, useEffect } from "react";
import { Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
// import img4 from "../../../../assets/modules/blog/images/blog-avatar/07.png";
// import img5 from "../../../../assets/modules/blog/images/blog-avatar/08.png";
// import AddArticle from "../components/add-article";
import AddFile from "../../add-content/components/add-file";
// import AddRequisition from "../components/requisition-review-approval";
import toast from "react-hot-toast";
import {
  EDIT_ACTION_GET,
  UPDATE_ACTION_POST,
} from "../../../../api/endpoints/action-endpoints";
import { getService } from "../../../../api/services/get-services";
import postService from "../../../../api/services/post-service";
import useRedirect from "../../../../components/custom-hooks/use-redirect";
import withAuthorization from "../../../../components/hoc/with-authorization";
import AddHtml from "../components/add-html";
import RequisitionReviewApproval from "../components/requisition-review-approval";
import { STATUS_CONSTANTS } from "../constants/action-constants";
import AddArticleLoader from "../../../../skeleton/action/add-article-loader";
import AddFileLoader from "../../../../skeleton/action/add-file-loader";
import AddHtmlLoader from "../../../../skeleton/action/add-html-loader";
import AddMediaLoader from "../../../../skeleton/action/add-media-loader";
import RequisitionReviewApprovalLoader from "../../../../skeleton/action/requisition-review-approval-loader";
import useBackdrop from "../../../../components/custom-hooks/use-backdrop";
import AddArticle from "../../add-content/components/add-article";
import AddMedia from "../../add-content/components/add-media";
import content_categories from "../../add-content/data/content_categories";
const ActionDetails = () => {
  const { type, fileType, actionId } = useParams();
  const { redirectTo } = useRedirect();
  const [{ fileTypeId, fileContentType, fileTypeCode }, setFileType] =
    React.useState({
      fileTypeId: null,
      fileContentType: null,
      fileTypeCode: null,
    });
  const [content_category_id, setContentCategoryId] = React.useState(null);
  const [actionData, setActionData] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [formFields, setFormFields] = React.useState({
    specialities: [],
  });

  async function getData() {
    let response = await getService(EDIT_ACTION_GET + actionId);
    if (response) {
      if (response.data.success) {
        let actionEditResponse = response.data?.data;
        let {
          file_type_id = "",
          file_type_code = "",
          file_content_type = "",
        } = actionEditResponse?.action_to_filetypes?.action_file_type_id ?? {};

        let { content_category_id = "" } =
          actionEditResponse?.action_to_content_categories
            ?.action_content_category_id ?? {};
        setActionData(actionEditResponse);
        setFileType({
          fileTypeId: file_type_id,
          fileContentType: file_content_type,
          fileTypeCode: file_type_code,
        });
        setContentCategoryId(content_category_id);

        setFormFields({ specialities: actionEditResponse?.specialities });
        setLoading(false);
      }
    }
  }
  useEffect(() => {
    // setLoading(true);
    getData();
  }, []);
  const { showBackdrop, hideBackdrop } = useBackdrop();
  //console.log("FILE_TYPE_ID", fileTypeId);
  //console.log("ACTION_EDIT_STATE", actionData);
  const onCreateContentSubmit = async (payload) => {
    showBackdrop();
    let postObj = {
      action_id: actionId,
      status: STATUS_CONSTANTS.COMPLETED,
      content_create: {
        ...payload,
        content_file_type_id: fileTypeId,
        file_type_code: fileTypeCode,
        file_content_type: fileContentType,
      },
    };
    let response = await postService(UPDATE_ACTION_POST + actionId, postObj);
    if (response) {
      hideBackdrop();
      if (response.data.success) {
        toast.success(response.data.message);
        redirectTo("action/all-action");
        // resetForm();
      } else {
        toast.error("Error Adding content!");
      }
    }
  };
  // if (!actionData) return null;
  return (
    <>
      <Fragment>
        {/* Start Survey JS Section */}
        {/* {type == "survey" && (
          <Row>
            {actionType ? (
              <ReviewApproval actionType={actionType} type={"survey"} />
            ) : (
              <>
                <ContentDetails type={"Survey"} />
                <SurveyCreatorWidget />
              </>
            )}
          </Row>
        )} */}

        {/* End Survey JS Section */}

        {/* VENDOR TASK DETAILS START */}
        {/* {type == "vendor-task-list" && (
          <Row>
            <VenderActionList actionType={"requisition-review"} />
          </Row>
        )} */}
        {/* VENDOR TASK DETAILS END */}
        {/* Requisition review or approval OR Content review or approval */}
        {(type == "requisition-review" ||
          type == "content-review" ||
          type == "requisition-approval" ||
          type == "content-approval") && (
          <>
            {!loading && actionData ? (
              <Row>
                <RequisitionReviewApproval
                  actionType={type}
                  actionId={actionId}
                  actionEditData={actionData}
                />
              </Row>
            ) : (
              <RequisitionReviewApprovalLoader />
            )}
          </>
        )}

        {/* Start PDF upload */}
        {type == "file" && (
          <Row>
            {!loading ? (
              <AddFile
                type="file"
                formFields={formFields}
                content_file_type_id={fileTypeId}
                accepts="*"
                actionData={actionData}
                onCreateContent={onCreateContentSubmit}
                brandId={
                  actionData?.origin?.requisition_brand_id?.principal_entity_id
                }
                content_category_id={
                  content_category_id || content_categories.E_PAPER
                }
              />
            ) : (
              <AddFileLoader />
            )}
          </Row>
        )}

        {/* UPLOAD VIDEO */}
        {type == "video" && (
          <Row>
            {!loading ? (
              <AddMedia
                type="video"
                formFields={formFields}
                accepts="video/*"
                brandId={
                  actionData?.origin?.requisition_brand_id?.principal_entity_id
                }
                content_file_type_id={fileTypeId}
                content_category_id={
                  content_category_id || content_categories.CLINICAL_VIDEO
                }
                actionData={actionData}
                isAction
                onCreateContent={onCreateContentSubmit}
              />
            ) : (
              <AddMediaLoader />
            )}
          </Row>
        )}
        {/* UPLOAD IMAGE */}
        {type == "infographic" && (
          <Row>
            {!loading ? (
              <AddMedia
                type="image"
                formFields={formFields}
                accepts="image/*"
                content_file_type_id={fileTypeId}
                actionData={actionData}
                onCreateContent={onCreateContentSubmit}
              />
            ) : (
              <AddMediaLoader />
            )}
          </Row>
        )}
        {/* UPLOAD AUDIO */}
        {type == "audio" && (
          <Row>
            {!loading ? (
              <AddMedia
                type="audio"
                formFields={formFields}
                accepts="audio/*"
                brandId={
                  actionData?.origin?.requisition_brand_id?.principal_entity_id
                }
                content_file_type_id={fileTypeId}
                actionData={actionData}
                onCreateContent={onCreateContentSubmit}
              />
            ) : (
              <AddMediaLoader />
            )}
          </Row>
        )}

        {/* UPLOAD PDF */}
        {type == "pdf" && (
          <Row>
            {!loading ? (
              <AddFile
                type="pdf"
                formFields={formFields}
                content_file_type_id={fileTypeId}
                brandId={
                  actionData?.origin?.requisition_brand_id?.principal_entity_id
                }
                accepts="application/pdf"
                actionData={actionData}
                onCreateContent={onCreateContentSubmit}
                content_category_id={
                  content_category_id || content_categories.E_PAPER
                }
              />
            ) : (
              <AddFileLoader />
            )}
          </Row>
        )}
        {/* UPLOAD PPT */}
        {type == "ppt" && (
          <Row>
            {!loading ? (
              <AddFile
                type="ppt"
                formFields={formFields}
                content_file_type_id={fileTypeId}
                accepts="application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.slideshow,application/vnd.openxmlformats-officedocument.presentationml.presentation"
                actionData={actionData}
                onCreateContent={onCreateContentSubmit}
              />
            ) : (
              <AddFileLoader />
            )}
          </Row>
        )}

        {/* UPLOAD DOCX */}
        {type == "docx" && (
          <Row>
            {!loading ? (
              <AddFile
                type="docx"
                formFields={formFields}
                content_file_type_id={fileTypeId}
                actionData={actionData}
                onCreateContent={onCreateContentSubmit}
                accepts=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              />
            ) : (
              <AddFileLoader />
            )}
          </Row>
        )}

        {/* UPLOAD EXCEL */}
        {type == "excel" && (
          <Row>
            {!loading ? (
              <AddFile
                type="xlsx"
                formFields={formFields}
                content_file_type_id={fileTypeId}
                accepts=".xls,.xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                actionData={actionData}
                onCreateContent={onCreateContentSubmit}
              />
            ) : (
              <AddFileLoader />
            )}
          </Row>
        )}
        {/* UPLOAD zip */}
        {type == "zip" && (
          <Row>
            {!loading ? (
              <AddFile
                type="zip"
                formFields={formFields}
                content_file_type_id={fileTypeId}
                actionData={actionData}
                onCreateContent={onCreateContentSubmit}
                accepts=".zip,.rar,.7zip,zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed"
              />
            ) : (
              <AddFileLoader />
            )}
          </Row>
        )}

        {(type === "html" || type === "email") && (
          <Row>
            {!loading ? (
              <AddHtml
                content_file_type_id={fileTypeId}
                formFields={formFields}
                actionData={actionData}
                onCreateContent={onCreateContentSubmit}
              />
            ) : (
              <AddHtmlLoader />
            )}
          </Row>
        )}
        {/* Start Add Medwiki Section */}
        {type == "article" && (
          <Row>
            {!loading ? (
              <AddArticle
                fields={formFields}
                actionData={actionData}
                onCreateContent={onCreateContentSubmit}
                isAction
                content_category_id={
                  content_category_id || content_categories.MEDWIKI
                }
                content_file_type_id={fileTypeId}
                key={fileTypeId}
              />
            ) : (
              <AddArticleLoader />
            )}
          </Row>
        )}
        {/* End Add Medwiki Section */}
      </Fragment>
    </>
  );
};

export default withAuthorization(memo(ActionDetails));

import React from 'react'

//FileManager layout
import Dashboard from '../pages/dashboard'

// pages
import FilemanagerLayout from '../layouts/creative-library'
import DocumentFolder from '../pages/document-folder'
import Imagefolder from '../pages/image-folder'
import Videofolder from '../pages/video-folder'
import AllCreatives from '../pages/creative-approval-listing'
import Trash from '../pages/trash'
import CreativeDetails from '../pages/creative-details'

export const CreativeApprovalRouter = [
    {
        path: 'creative-approval',
        element: <FilemanagerLayout />,
        children: [
            {
                path: 'dashboard',
                element: <Dashboard />
            },
            {
                path: 'image-folder',
                element: <Imagefolder />
            },
            {
                path: 'video-folder',
                element: <Videofolder />
            },
            {
                path: 'document-folder',
                element: <DocumentFolder />
            },
            {
                path: 'listing',
                element: <AllCreatives />
            },
            {
                path: 'trash',
                element: <Trash />
            },
            {
                path: 'details/:id',
                element: <CreativeDetails />
            }
        ]
    }
]
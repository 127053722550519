import { storages } from "../../common/utils/utils";
import { updateStorage } from "../../utilities/setting";

export const persistAuthState = (state) => {
  try {
    updateStorage(storages.local, "authState", state);
  } catch (error) {
    // Handle error if unable to persist state
    console.error("Error persisting authentication state:", error);
  }
};

import React, { memo } from "react";
import { Button, Col, Image } from "react-bootstrap";
import ReactPlayer from "react-player";
import audio from "../../../../../../assets/modules/file-manager/images/audio_1.jpg";
import { previewSecondstoMinutes } from "../../../../../../common/utils/utils";

const AudioPreviewSection = ({
  playing,
  onChangeAudioState,
  onRaiseAudioProblemClick,
  onChangeProgress,
}) => {
  return (
    <>
      <div className="h-100 w-100 test d-flex align-items-center justify-content-center p-3 rounded-3 bg-soft-secondary position-relative">
        <div className="position-absolute d-flex align-items-center justify-content-center">
          <Image
            // src={item.Url}
            src={
              "https://www.udiscovermusic.com/wp-content/uploads/2019/09/Bryan-Adams-Get-Up-album-cover-820.jpg"
            }
            className="bg-soft-primary rounded w-50 h-50 opacity-50 rounded-3"
            alt="profile-image"
          />
        </div>
        <ReactPlayer
          url="https://www.learningcontainer.com/wp-content/uploads/2020/02/Kalimba.mp3"
          controls
          playing={playing}
          onPlay={() => onChangeAudioState("playing", true)}
          onPause={() => onChangeAudioState("playing", false)}
          onProgress={(e) => onChangeProgress(e.playedSeconds, "audio")}
          onSeek={(e) => onChangeProgress(e, "audio")}
          height={"54px"}
          width="100%"
          className="d-flex align-items-center justify-content-center rounded-3 mx-4 shadow-lg rounded-5"
        />
      </div>
      <Button
        variant="danger"
        className="rounded-pill position-absolute top-0 end-0 mt-3 me-3 cursor-pointer"
        // style={topRightStyle(99)}
        onClick={onRaiseAudioProblemClick}
      >
        Raise a Problem
      </Button>
    </>
  );
};
export default memo(AudioPreviewSection);
{
  /* {images.length > 0 && (
        <div className="d-flex gap-2 px-3 pb-0">
          {images.map((item, index) => {
            return (
              <div
                key={index + 1}
                className={`p-2 border border-1 rounded-3 cursor-pointer ${
                  item.snapDuration === selectedImage.snapDuration
                    ? "border-primary bg-soft-primary"
                    : ""
                }`}
                onClick={() => onSnapThumbnailClick(item, index)}
              >
                <Image
                  src={audio}
                  className="bg-soft-primary rounded img-fluid avatar-90"
                  alt="profile-image"
                />
                <span className="d-block fs-7 mt-1">
                  {previewSecondstoMinutes(item.snapDuration)}
                </span>
              </div>
            );
          })}
        </div>
      )} */
}

import React from "react";
import { Col, Row } from "react-bootstrap";
import Card from "../../components/bootstrap/card";
import { Skeleton } from "@mui/material";
import ContentDetails from "../../components/partials/common/content-details";
import ContentDetailsCardLoader from "../common/content-details-card-loader";

const PostApprovalLoader = () => {
  let fileList = [...Array(6).keys()].map((_el) => _el + 1);
  return (
    <>
      <Row>
        <Col lg="12">
          <ContentDetailsCardLoader />
        </Col>
        <Col lg="6">
          <div className="d-flex w-100">
            <Card className="w-100">
              <Card.Body>
                <Row>
                  {fileList.map((_i) => (
                    <Col
                      lg="4"
                      className="d-flex align-items-center mb-5 gap-2"
                      key={_i}
                    >
                      <Skeleton variant="rounded" width={40} height={40} />
                      <Skeleton variant="text" width={80} height={25} />
                    </Col>
                  ))}
                </Row>
              </Card.Body>
            </Card>
          </div>
        </Col>
        <Col lg="6">
          <Card className="py-2">
            <Card.Header>
              <Skeleton variant="text" width={130} height={20} />
            </Card.Header>
            <Card.Body className="pb-3 pt-1">
              <div className="d-flex flex-row align-items-center gap-2 mt-3">
                <Skeleton variant="rounded" width={90} height={35} />
                <Skeleton variant="rounded" width={90} height={35} />
              </div>
              <div className="mt-5">
                <Row>
                  <Col md={6}>
                    <Skeleton
                      variant="rounded"
                      width="100%"
                      height={40}
                      className="mb-4"
                    />
                  </Col>
                  <Col md={6}>
                    <Skeleton
                      variant="rounded"
                      width="100%"
                      height={40}
                      className="mb-4"
                    />
                  </Col>
                </Row>
              </div>
            </Card.Body>
          </Card>
        </Col>

        <Col lg="6">
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <Skeleton variant="text" width={130} height={30} />
              </div>
            </Card.Header>
            <Card.Body>
              <div className="d-flex gap-2 flex-wrap mb-4">
                {fileList.map((_tg) => (
                  <Skeleton
                    variant="rounded"
                    width={80}
                    height={30}
                    className="rounded-pill"
                  />
                ))}
              </div>
              <div className="d-flex justify-content-between">
                {[1, 2, 3].map((_tg) => (
                  <Skeleton
                    variant="rounded"
                    width={160}
                    height={100}
                    key={_tg}
                  />
                ))}
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col lg="6">
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <Skeleton variant="text" width={130} height={30} />
              </div>
            </Card.Header>
            <Card.Body>
              <div className="d-flex gap-2 flex-wrap mb-4">
                {fileList.map((_tg) => (
                  <Skeleton
                    variant="rounded"
                    width={80}
                    height={30}
                    className="rounded-pill"
                  />
                ))}
              </div>
              <Row className="d-flex justify-content-between">
                {[1, 2, 3, 4, 5, 6].map((_tg) => (
                  <Col lg="4" className="mb-3">
                    <Skeleton
                      variant="rounded"
                      width={160}
                      height={100}
                      key={_tg}
                    />
                  </Col>
                ))}
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {/* <Row>
        <Col lg="12">
          <div className="d-flex gap-2 gap-md-3 justify-content-end flex-wrap flex-md-nowrap">
            <Button
              type="button"
              variant="btn btn-primary flex-md-grow-0 flex-grow-1 text-nowrap"
              onClick={() => setStep(2)}
            >
              Publish
            </Button>

            <Button
              type="button"
              variant="btn btn-light flex-md-grow-0 flex-grow-1 text-nowrap"
            >
              Cancel
            </Button>
          </div>
        </Col>
      </Row> */}
    </>
  );
};

export default PostApprovalLoader;

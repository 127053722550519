import React, { Fragment, memo } from "react";
import { previewSecondstoMinutes } from "../../../../../../common/utils/utils";
import { Image } from "react-bootstrap";
import audioThumbnail from "../../../../../../assets/modules/file-manager/images/audio_1.jpg";

const ThumbnailSlider = ({
  fileType,
  images,
  selectedImage,
  onSnapThumbnailClick,
}) => {
  return (
    <Fragment>
      {/* {images.length > 0 && (
        <div className="d-flex p-2 flex-column justify-content-center overflow-scroll h-100"> */}
      {images.map((item, index) => {
        let isActivePage = (fileType) => {
          if (fileType === "video" || fileType === "audio") {
            return item.snapDuration === selectedImage.snapDuration;
          } else if (
            fileType === "pdf" ||
            fileType === "image" ||
            fileType === "ppt"
          ) {
            return item.PageNumber === selectedImage.PageNumber;
          }
        };
        console.log("isActivePage", isActivePage(fileType));
        return (
          <div
            key={index + 1}
            className={`p-1 border border-1 rounded-3 cursor-pointer mb-3 position-relative ${
              isActivePage(fileType) ? "border-info bg-info" : ""
            }`}
            onClick={() => onSnapThumbnailClick(item, index)}
          >
            <Image
              src={fileType == "audio" ? audioThumbnail : item.Url}
              // src={
              //   "https://www.udiscovermusic.com/wp-content/uploads/2019/09/Bryan-Adams-Get-Up-album-cover-820.jpg"
              // }
              className="bg-soft-primary rounded img-fluid"
              alt="profile-image"
            />
            {/* convert item.snapDuration to minutes and seconds */}
            {(fileType === "video" || fileType === "audio") && (
              <span className="fs-7 position-absolute top-0 end-0 z-1 bg-dark rounded-pill text-white px-2 mt-2 me-2">
                {previewSecondstoMinutes(item.snapDuration)}
              </span>
            )}
          </div>
        );
      })}
      {/* </div>
      )} */}
    </Fragment>
  );
};

export default memo(ThumbnailSlider);

import React from "react";
import { Col, Row } from "react-bootstrap";
import Card from "../../components/bootstrap/card";
import { Skeleton } from "@mui/material";

const ContentDetailsCardLoader = () => {
  return (
    <>
      <Row>
        <Col lg="12">
          <Card>
            <Card.Body>
              <div>
                <Skeleton variant="text" width={60} height={20} />
                <Skeleton variant="text" width={300} height={30} />
                <div className="d-flex flex-column">
                  <Skeleton variant="text" width={60} height={20} />
                  <span className="d-flex justify-content-start gap-2 mb-4">
                    <Skeleton variant="text" width={40} height={20} /> |{" "}
                    <Skeleton variant="text" width={40} height={20} />
                  </span>
                </div>
                <Skeleton variant="text" width='100%' height={15} />
                <Skeleton variant="text" width='100%' height={15} />
                <Skeleton variant="text" width='50%' height={15} />
                <div className="d-flex justify-content-end gap-3">
                  <Skeleton variant="text" width={80} height={20} />
                  <span> | </span>
                  <Skeleton variant="text" width={80} height={20} />
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default ContentDetailsCardLoader;

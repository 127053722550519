import * as dayjs from "dayjs";
import { useFormik } from "formik";
import { memo, useEffect } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import toast from "react-hot-toast";
import Select from "react-select";
import {
  ADD_CONTENT,
  UPLOAD_FILE,
} from "../../../../api/endpoints/add-content-endpoints";
import postService from "../../../../api/services/post-service";
import uploadService from "../../../../api/services/upload-service";
import { getFormatedDate } from "../../../../common/utils/utils";
import FilePicker from "../../../../components/form-components/file-picker";
import ContentDetails from "../../../../components/partials/common/content-details";
import BasicQuill from "../../plugins/pages/quill-editor";
import {
  addAudioInitialValues,
  addAudioSchema,
  addImageInitialValues,
  addImageSchema,
  addVideoInitialValues,
  addVideoSchema,
} from "../constants/add-media";
import AddEnviroment from "./add-enviroment";

const AddMedia = ({
  type = "",
  formFields,
  content_category_id,
  accepts,
  content_file_type_id,
  actionData = null,
  onCreateContent,
  brandId = "",
}) => {
  const GR_CATEGORY_ID = 6;

  function getInitialValues() {
    if (type === "video") return addVideoInitialValues;
    else if (type === "image") return addImageInitialValues;
    else if (type === "audio") return addAudioInitialValues;
    else return addVideoInitialValues;
  }

  function getSchema() {
    if (type === "video") return addVideoSchema;
    else if (type === "image") return addImageSchema;
    else if (type === "audio") return addAudioSchema;
    else return addVideoSchema;
  }

  const formik = useFormik({
    initialValues: getInitialValues(),
    validationSchema: getSchema(),
    onSubmit: (value, action) => {
      const payload = {
        content_category_id,
        content_brand_id: value.brand,
        content_featured_status: value.is_featured ? 1 : 0,
        content_title: value.title,
        content_description: value.description,
        content_file_type_id,
        file_type_code: type,
        cleaned_text: "",
        content_privacy_status: value.privacy_status,
        content_publication_date: dayjs(value.publication_date).format(
          "YYYY-MM-DD"
        ),
        content_publishing_status: 1,
        content_to_av: {
          title_html: value.title_html,
          description_html: value.description_html,
          storage_link: value.file.name,
          path: value.file.name,
          streaming_link: "",
          audio_link: "",
          transcript: "",
          srt_link: "",
        },
        content_to_reference_files: {
          reference_file: value?.referenceFile?.name
            ? [value?.referenceFile?.name]
            : [],
        },
        content_to_images: {
          thumbnail: value?.thumbnail?.name,
          base: value?.coverImage?.name,
        },
        content_to_sponsor: {
          sponsor_ids: [value?.sponsor || "1"],
        },
        content_to_tags: {
          tags_ids: value.tags,
        },
        content_to_attributes: {
          content_question_code: value.question_code,
        },
        content_to_speciality: {
          speciality_ids: value.speciality,
        },
      };
      if (onCreateContent && typeof onCreateContent === "function") {
        onCreateContent(payload);
      } else {
        if (
          !value.file.name ||
          !value.coverImage.name ||
          !value.thumbnail.name
        ) {
          toast.error("Please upload all necessary files");
          return;
        }
        let content_to_attributes_temp = {
          gr_video_title: value.gr_video_title,
          gr_vendor_vid_src: value.gr_vendor_vid_src,
          gr_vid_vendor: value.gr_vid_vendor,
          gr_qna_type: value.gr_qna_type ? 1 : 0,
          gr_start_date: value.gr_start_date,
          gr_end_date: value.gr_end_date,
        };

        if (content_category_id === GR_CATEGORY_ID) {
          payload.content_to_attributes = {
            ...payload.content_to_attributes,
            ...content_to_attributes_temp,
          };
        }

        let content_to_env_price_filtered = value.content_to_env_price.filter(
          (item) => {
            return item.env_id !== "0";
          }
        );
        if (content_to_env_price_filtered.length > 0) {
          payload.content_to_env_price = content_to_env_price_filtered;
        }

        toast.promise(
          postService(ADD_CONTENT, payload)
            .then((data) => {
              if (!data.isError) {
                if (data.data?.success) {
                  resetForm();
                } else {
                }
              }
            })
            .catch((err) => toast.error("Error Adding content!")),
          {
            loading: "Adding...",
            success: "Added Successfully!",
            error: "Something went wrong",
          }
        );
      }
    },
  });
  const {
    touched,
    values,
    errors,
    handleChange,
    handleSubmit,
    setFieldValue,
    resetForm,
  } = formik;

  useEffect(() => {
    if (brandId) {
      setFieldValue("brand", brandId);
    }
  }, []);

  function setFile(files, keyName) {
    if (files.length > 0) {
      let formdata = new FormData();
      [...files].forEach((file) => {
        formdata.append("files[]", file);
      });
      toast.promise(
        uploadService(UPLOAD_FILE, formdata)
          .then((data) => {
            if (data !== null) setFieldValue(keyName, data.data.data[0]);
          })
          .catch((err) => console.log(err)),
        {
          loading: "Uploading...",
          success: "Uploaded Successfully!",
          error: "Something went wrong",
        }
      );
    } else {
      setFieldValue(keyName, null);
    }
  }

  return (
    <>
      {actionData && (
        <ContentDetails
          brand={
            actionData?.origin?.requisition_brand_id?.principal_entity_name
          }
          date={getFormatedDate(actionData?.created_at)}
          department=""
          title={actionData?.action_name}
          description={actionData?.origin?.requisition_description}
          fileTypes={[]}
          userName={actionData?.action_assign_by?.name}
        />
      )}
      <Col>
        <Card>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col xl="6" lg="8">
                <Card.Body>
                  <div className="row">
                    {!brandId && (
                      <Col lg="12" className="mb-4">
                        <Form.Floating className="custom-form-floating">
                          <Form.Select
                            className=""
                            id="floatingInput1"
                            name="brand"
                            value={values.brand}
                            onChange={handleChange}
                            isInvalid={touched.brand && !!errors.brand}
                          >
                            <option value="" selected hidden>
                              {" "}
                              Select Brand
                            </option>
                            {formFields &&
                              formFields?.brands &&
                              formFields?.brands.map((item) => (
                                <option value={item?.principal_entity_id}>
                                  {item?.principal_entity_name}
                                </option>
                              ))}
                          </Form.Select>
                          <Form.Label htmlFor="floatingInput">
                            Select Brand <span className="text-danger">*</span>
                          </Form.Label>
                        </Form.Floating>
                      </Col>
                    )}
                    <Form.Group className="col-12 form-group">
                      <Form.Label className="d-flex justify-content-between">
                        <div>
                          Title <span className="text-danger">*</span>{" "}
                        </div>
                        {/* <div className="form-check form-switch form-check-inline">
													<FormCheck.Input
														type="checkbox"
														id="switch2"
														defaultChecked={isRichTextEditor}
														value={isRichTextEditor}
														onChange={() =>
															setIsRichTextEditor(!isRichTextEditor)
														}
													/>
													<FormCheck.Label className=" pl-2" htmlFor="switch2">
														Rich text editor
													</FormCheck.Label>
												</div> */}
                      </Form.Label>
                      {/* {!isRichTextEditor ? (
												<Form.Control
													type="title"
													placeholder="Title"
													name="title"
													value={values.title}
													onChange={handleChange}
													isInvalid={touched.title && !!errors.title}
												/>
											) : ( */}
                      <div>
                        <BasicQuill
                          value={values.title}
                          updateData={(value) => {
                            setFieldValue("title", value.rawText);
                            setFieldValue("title_html", value.html);
                          }}
                        ></BasicQuill>
                        {touched.title && "title" in errors && (
                          <div style={{ color: "red" }}>{errors.title}</div>
                        )}
                      </div>
                      {/* )} */}
                    </Form.Group>
                    <Form.Group className="col-12 form-group">
                      <Form.Label>
                        Description <span className="text-danger">*</span>{" "}
                      </Form.Label>

                      {/* {!isRichTextEditor ? (
												<Form.Control
													type="description"
													placeholder="Description"
													name="description"
													value={values.description}
													onChange={handleChange}
													isInvalid={
														touched.description && !!errors.description
													}
												/>
											) : ( */}
                      <div>
                        <BasicQuill
                          value={values.description}
                          updateData={(value) => {
                            setFieldValue("description", value.rawText);
                            setFieldValue("description_html", value.html);
                          }}
                        ></BasicQuill>
                        {touched.description && "description" in errors && (
                          <div style={{ color: "red" }}>
                            {errors.description}
                          </div>
                        )}
                      </div>
                      {/* )} */}
                    </Form.Group>
                    <Form.Group className="col-12 form-group">
                      <Form.Label>
                        References <span className="text-danger">*</span>{" "}
                      </Form.Label>
                      {/* {!isRichTextEditor ? (
												<Form.Control
													type="title"
													placeholder="References"
													name="references"
													value={values.references}
													onChange={handleChange}
													isInvalid={touched.references && !!errors.references}
												/>
											) : ( */}
                      <div>
                        <BasicQuill
                          value={values.references}
                          updateData={(value) => {
                            setFieldValue("references", value.rawText);
                            setFieldValue("references_html", value.html);
                          }}
                        ></BasicQuill>
                        {touched.references && "references" in errors && (
                          <div style={{ color: "red" }}>
                            {errors.references}
                          </div>
                        )}
                      </div>
                      {/* )} */}
                    </Form.Group>
                    <Form.Group className="col-12 form-group">
                      <Form.Label>Add Reference Files</Form.Label>
                      <Form.Control
                        type="file"
                        id="customFile"
                        name="referenceFile"
                        isInvalid={!!errors.referenceFile}
                        onChange={(e) =>
                          setFile(e.target.files, "referenceFile")
                        }
                      />
                    </Form.Group>

                    <Form.Floating className="custom-form-floating mb-4">
                      <textarea
                        class="form-control h-100"
                        placeholder="Notes"
                        id="floatingTextarea2"
                        name="notes"
                        value={values.notes}
                        onChange={handleChange}
                        isInvalid={touched.notes && !!errors.notes}
                      ></textarea>
                      <Form.Label htmlFor="fname">Notes</Form.Label>
                    </Form.Floating>

                    <AddEnviroment formik={formik} />
                  </div>
                </Card.Body>
              </Col>
              <Col xl="6" lg="4" className="">
                <Row className="px-3 pt-3">
                  <Col lg="12" className="mb-4">
                    <FilePicker
                      title={
                        values.file !== null
                          ? values.file?.name
                          : type == "video"
                          ? "Add Video"
                          : type == "image"
                          ? "Add Image"
                          : type == "audio"
                          ? "Add Audio"
                          : ""
                      }
                      onUpdate={(val) => setFile(val, "file")}
                      accepts={accepts}
                      type={type}
                      source={values?.file?.url}
                    />
                  </Col>
                  {touched.file && "file" in errors && (
                    <div style={{ color: "red" }}>{errors.file}</div>
                  )}
                  <Col lg="6" className="mb-4">
                    <FilePicker
                      title={"Add Cover Image"}
                      accepts="image/png, image/gif, image/jpeg"
                      onUpdate={(val) => setFile(val, "coverImage")}
                      source={values?.coverImage?.url}
                      type="image"
                    />
                    {touched.coverImage && "coverImage" in errors && (
                      <div style={{ color: "red" }}>{errors.coverImage}</div>
                    )}
                  </Col>
                  <Col lg="6" className="mb-4">
                    <FilePicker
                      title={"Add Thumbnail"}
                      onUpdate={(val) => setFile(val, "thumbnail")}
                      accepts="image/png, image/gif, image/jpeg"
                      source={values?.thumbnail?.url}
                      type="image"
                    />
                    {touched.thumbnail && "thumbnail" in errors && (
                      <div style={{ color: "red" }}>{errors.thumbnail}</div>
                    )}
                  </Col>
                </Row>
                <Card.Header className="d-flex justify-content-between">
                  <div className="header-title">
                    <h4 className="card-title">
                      {type == "video"
                        ? "Video Meta"
                        : type == "image"
                        ? "Image Meta"
                        : type == "audio"
                        ? "Audio Meta"
                        : ""}
                    </h4>
                  </div>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Form.Group className="form-group mb-4">
                      <Form.Floating className="custom-form-floating mb-4">
                        <Form.Control
                          type="text"
                          placeholder="Question Code"
                          name="question_code"
                          value={values.question_code}
                          onChange={handleChange}
                          isInvalid={
                            touched.question_code && !!errors.question_code
                          }
                        />
                        <Form.Label htmlFor="question_code">
                          Question Code
                        </Form.Label>
                      </Form.Floating>
                      <Form.Label>Select Tags</Form.Label>
                      <Select
                        options={formFields?.tags?.map(
                          ({ tag_id, tag_name }) => ({
                            value: tag_id,
                            label: tag_name,
                          })
                        )}
                        placeholder="Select Tags"
                        name="tags"
                        isMulti
                        onChange={(value) =>
                          setFieldValue(
                            "tags",
                            value?.map(({ value }) => value) || []
                          )
                        }
                      />
                    </Form.Group>

                    <Form.Floating className="custom-form-floating mb-4">
                      <Select
                        options={(
                          formFields?.specialities || formFields?.specialty
                        )?.map(({ speciality_id, speciality_name }) => ({
                          value: speciality_id,
                          label: speciality_name,
                        }))}
                        isMulti
                        placeholder="Select Speciality"
                        onChange={(selected) => {
                          console.log(
                            "selected",
                            selected.map(({ value }) => value)
                          );
                          setFieldValue(
                            "speciality",
                            selected.map(({ value }) => value)
                          );
                        }}
                      />
                    </Form.Floating>

                    <Form.Floating className="custom-form-floating mb-4">
                      <Form.Check // prettier-ignore
                        type="switch"
                        id="custom-switch"
                        label="Is the content featured?"
                        name="is_featured"
                        onChange={(e) =>
                          setFieldValue("is_featured", e.target.checked)
                        }
                      />
                    </Form.Floating>

                    <Form.Floating className="custom-form-floating mb-4">
                      <Form.Select
                        className=""
                        id="floatingInput1"
                        name="privacy_status"
                        value={values.privacy_status}
                        onChange={handleChange}
                        isInvalid={
                          touched.privacy_status && !!errors.privacy_status
                        }
                      >
                        <option value="" selected hidden>
                          {" "}
                          Select Privacy Status
                        </option>
                        {formFields &&
                          formFields?.templates &&
                          (formFields?.templates).map((item) => (
                            <option value={item?.template_id}>
                              {item?.template_name}
                            </option>
                          ))}
                      </Form.Select>
                      <Form.Label htmlFor="floatingInput">
                        Select Privacy Status{" "}
                        <span className="text-danger">*</span>
                      </Form.Label>
                    </Form.Floating>

                    <Form.Floating className="custom-form-floating mb-4">
                      <Form.Select
                        className=""
                        id="floatingInput1"
                        name="sponsor"
                        value={values.sponsor}
                        onChange={handleChange}
                        isInvalid={touched.sponsor && !!errors.sponsor}
                      >
                        <option value="" selected hidden>
                          {" "}
                          Select Sponsor
                        </option>
                        {formFields &&
                          formFields?.sponsors &&
                          (formFields?.sponsors).map((item) => (
                            <option value={item?.sponsor_id}>
                              {item?.sponsor_name}
                            </option>
                          ))}
                      </Form.Select>
                      <Form.Label htmlFor="floatingInput">
                        Select Sponsor <span className="text-danger">*</span>
                      </Form.Label>
                    </Form.Floating>

                    <Form.Floating className="custom-form-floating mb-4">
                      <Form.Control
                        type="date"
                        placeholder="Publication Date"
                        name="publication_date"
                        value={values.publication_date}
                        onChange={handleChange}
                        isInvalid={
                          touched.publication_date && !!errors.publication_date
                        }
                      />
                      <Form.Label htmlFor="publication_date">
                        Publication Date
                      </Form.Label>
                    </Form.Floating>

                    <Form.Floating className="custom-form-floating mb-4">
                      <Form.Control
                        type="number"
                        placeholder="Template ID"
                        name="template_id"
                        value={values.template_id}
                        onChange={handleChange}
                        isInvalid={touched.template_id && !!errors.template_id}
                      />
                      <Form.Label htmlFor="template_id">Template ID</Form.Label>
                    </Form.Floating>

                    <Form.Floating className="custom-form-floating mb-4">
                      <Form.Control
                        type="number"
                        placeholder="Base Likes"
                        name="base_likes"
                        value={values.base_likes}
                        onChange={handleChange}
                        isInvalid={touched.base_likes && !!errors.base_likes}
                      />
                      <Form.Label htmlFor="base_likes">Base Likes</Form.Label>
                    </Form.Floating>

                    {content_category_id === GR_CATEGORY_ID && (
                      <>
                        <Form.Group className="form-group mb-4">
                          <Form.Floating className="custom-form-floating mb-4">
                            <Form.Control
                              type="text"
                              placeholder="Grand round video title"
                              name="gr_video_title"
                              value={values.gr_video_title}
                              onChange={handleChange}
                              isInvalid={
                                touched.gr_video_title &&
                                !!errors.gr_video_title
                              }
                            />
                            <Form.Label htmlFor="gr_video_title">
                              Grand round video title
                            </Form.Label>
                          </Form.Floating>
                        </Form.Group>
                        <Form.Group className="form-group mb-4">
                          <Form.Floating className="custom-form-floating mb-4">
                            <Form.Check // prettier-ignore
                              type="switch"
                              id="custom-switch"
                              label="Grand Round QNA Type"
                              name="gr_qna_type"
                              onChange={(e) =>
                                setFieldValue("gr_qna_type", e.target.checked)
                              }
                            />
                          </Form.Floating>
                        </Form.Group>
                        <Form.Group className="form-group mb-4">
                          <Form.Floating className="custom-form-floating mb-4">
                            <Form.Control
                              type="text"
                              placeholder="gr_vendor_vid_src"
                              name="gr_vendor_vid_src"
                              value={values.gr_vendor_vid_src}
                              onChange={handleChange}
                              isInvalid={
                                touched.gr_vendor_vid_src &&
                                !!errors.gr_vendor_vid_src
                              }
                            />
                            <Form.Label htmlFor="gr_vendor_vid_src">
                              Grand Round Video Source
                            </Form.Label>
                          </Form.Floating>
                        </Form.Group>
                        <Form.Group className="form-group mb-4">
                          <Form.Floating className="custom-form-floating">
                            <Form.Select
                              className=""
                              id="floatingInput1"
                              name="gr_vid_vendor"
                              value={values.gr_vid_vendor}
                              onChange={handleChange}
                              isInvalid={
                                touched.gr_vid_vendor && !!errors.gr_vid_vendor
                              }
                            >
                              <option value="" selected hidden>
                                {" "}
                                Grand Round video vendor
                              </option>
                              <option value={"youtube"}>{"youtube"}</option>
                              <option value={"vimeo"}>{"vimeo"}</option>
                              <option value={"other"}>{"other"}</option>
                              {/* {formFields &&
                            formFields?.brands &&
                            formFields?.brands.map((item) => (
                              <option value={item?.principal_entity_id}>
                                {item?.principal_entity_name}
                              </option>
                            ))} */}
                            </Form.Select>
                            <Form.Label htmlFor="floatingInput">
                              Grand Round video vendor{" "}
                              <span className="text-danger">*</span>
                            </Form.Label>
                          </Form.Floating>
                        </Form.Group>
                        <Form.Group className="form-group mb-4">
                          <Form.Floating className="custom-form-floating mb-4">
                            <Form.Control
                              type="date"
                              placeholder="gr_start_date"
                              name="gr_start_date"
                              value={values.gr_start_date}
                              onChange={handleChange}
                              isInvalid={
                                touched.gr_start_date && !!errors.gr_start_date
                              }
                            />
                            <Form.Label htmlFor="gr_start_date">
                              Grand Round Start date
                            </Form.Label>
                          </Form.Floating>
                        </Form.Group>
                        <Form.Group className="form-group mb-4">
                          <Form.Floating className="custom-form-floating mb-4">
                            <Form.Control
                              type="date"
                              placeholder="gr_end_date"
                              name="gr_end_date"
                              value={values.gr_end_date}
                              onChange={handleChange}
                              isInvalid={
                                touched.gr_end_date && !!errors.gr_end_date
                              }
                            />
                            <Form.Label htmlFor="gr_end_date">
                              Grand Round End Date
                            </Form.Label>
                          </Form.Floating>
                        </Form.Group>
                      </>
                    )}
                  </Row>
                </Card.Body>
              </Col>
            </Row>
            <div className="d-flex gap-2 gap-md-3 justify-content-end flex-wrap flex-md-nowrap my-3 mx-3">
              <Button
                type="submit"
                variant="btn btn-primary flex-md-grow-0 flex-grow-1 text-nowrap"
              >
                Submit
              </Button>
              <Button
                type="button"
                variant="btn btn-light flex-md-grow-0 flex-grow-1 text-nowrap"
              >
                Cancel
              </Button>
            </div>
          </Form>
        </Card>
      </Col>
    </>
  );
};

export default memo(AddMedia);

import React from "react";
import ContentDetailsCardLoader from "../common/content-details-card-loader";
import { Card, Col, Row } from "react-bootstrap";
import { Skeleton } from "@mui/material";

const AddFileLoader = () => {
  return (
    <>
      <ContentDetailsCardLoader />
      <Col lg="12" className="d-flex">
        <Card className="w-100">
          <Card.Body>
            <Row className="gap-4 gap-lg-0">
              <Col lg="6">
                <Skeleton
                  variant="rounded"
                  width="100%"
                  height={150}
                  className="mb-4"
                />
                <Row className="gap-4 gap-lg-0 mt-4">
                  <Col lg="6">
                    <Skeleton
                      variant="rounded"
                      width="100%"
                      height={150}
                      className="mb-4"
                    />
                  </Col>
                  <Col lg="6">
                    <Skeleton
                      variant="rounded"
                      width="100%"
                      height={150}
                      className="mb-4"
                    />
                  </Col>
                </Row>
              </Col>
              <Col lg="6">
                <Row className="h-100 flex-column flex-nowrap">
                  <Skeleton
                    variant="rounded"
                    width="100%"
                    height={45}
                    className="mb-3"
                  />
                  <Skeleton
                    variant="text"
                    width={100}
                    height={20}
                    className="mb-2"
                  />
                  <Skeleton
                    variant="rounded"
                    width="100%"
                    height={90}
                    className="mb-3"
                  />
                  <Skeleton
                    variant="text"
                    width={100}
                    height={20}
                    className="mb-2"
                  />
                  <Skeleton
                    variant="rounded"
                    width="100%"
                    height={90}
                    className="mb-3"
                  />
                  <Col lg="12" className="d-flex justify-content-end">
                    <Skeleton
                      variant="rounded"
                      width="20%"
                      height={40}
                      className="mb-3"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </>
  );
};

export default AddFileLoader;

import React, { useEffect, useState } from "react";
//scss
import "shepherd.js/dist/css/shepherd.css";
import "flatpickr/dist/flatpickr.css";
import "choices.js/public/assets/styles/choices.min.css";
import "./assets/scss/hope-ui.scss";
import "./assets/scss/pro.scss";
import "./assets/scss/custom.scss";
import "./assets/scss/dark.scss";
import "./assets/scss/rtl.scss";
import "./assets/scss/customizer.scss";
import "./assets/custom/scss/custom.scss";

// Redux Selector / Action
import { useDispatch } from "react-redux";

// import state selectors
import { setSetting } from "./store/setting/actions";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import * as authSelectors from "./store/auth/selectors";
import useAbly from "./components/custom-hooks/use-ably";
import Backdrop from "./components/common-components/backdrop";
function App({ children }) {
  const queryClient = new QueryClient();
  const dispatch = useDispatch();
  dispatch(setSetting());
  // useAbly();
  const user = useSelector(authSelectors.user);

  return (
    <QueryClientProvider client={queryClient}>
      <div className="App">{children}</div>
      <Backdrop />
    </QueryClientProvider>
  );
}

export default App;

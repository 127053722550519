import { memo, useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  FormCheck,
  Modal,
  Row,
} from "react-bootstrap";
import toast from "react-hot-toast";
import { UPLOAD_FILE } from "../../../../api/endpoints/add-content-endpoints";
import mlService from "../../../../api/services/ml-service";
import uploadService from "../../../../api/services/upload-service";
import FilePicker from "../../../../components/form-components/file-picker";
import withAuthorization from "../../../../components/hoc/with-authorization";
import ContentDetails from "../../../../components/partials/common/content-details";
import ReactEmailEditor from "../../../../lib/email-editor/react-email-editor";
import sample from "../../../../lib/email-editor/sample.json";
import PostApprovalLoader from "../../../../skeleton/action/post-approval-loader";
import DropZone from "../components/sub-components/content-publish/dropzone";
import Tag from "../components/sub-components/content-publish/tag";
import useBackdrop from "../../../../components/custom-hooks/use-backdrop";
import { getService } from "../../../../api/services/get-services";
import { EDIT_ACTION_GET } from "../../../../api/endpoints/action-endpoints";
import { useParams } from "react-router-dom";
const PostApproval = () => {
  const { id: actionId } = useParams();
  const { showBackdrop, hideBackdrop } = useBackdrop();
  const [actionData, setActionData] = useState(null);
  const breakpointColumnsObj = {
    default: 3,
    1100: 3,
    768: 2,
    500: 1,
  };
  function getRandomSoftColor() {
    const softColors = [
      "soft-primary",
      "soft-secondary",
      "soft-warning",
      "soft-danger",
    ];
    const randomIndex = Math.floor(Math.random() * softColors.length);
    return softColors[randomIndex];
  }
  const medicalreview = [
    { id: 1, name: "Primary" },
    { id: 2, name: "Secondary" },
    { id: 3, name: "Irrelevant" },
  ];
  const nonMedicalReview = [
    { id: 1, name: "Brand" },
    { id: 2, name: "Location" },
    { id: 3, name: "Person" },
    { id: 4, name: "Organization" },
    { id: 5, name: "Event" },
    { id: 6, name: "Others" },
  ];

  const [tagAndDropzones, setTagAndDropzones] = useState([]);

  const templates = [
    { id: 1, title: "Select Template", value: "Select Template" },
    { id: 2, title: "Template 1", value: "Template 1" },
    { id: 3, title: "Template 2", value: "Template 2" },
    { id: 4, title: "Template 3", value: "Template 3" },
  ];
  const [selectedValue, setSelectedValue] = useState("");
  console.log(selectedValue);
  const [loading, setLoading] = useState(true);
  const [fileType, setFileType] = useState(null);
  const [step, setStep] = useState(1);
  const [selectedTags, setSelectedTags] = useState({});
  const [active, setActive] = useState("System");
  const [show, setShow] = useState(false);
  const [templateloading, setTemplateloading] = useState(true);

  const radioButton = [
    { id: 1, value: "System", type: "radio", name: "radios", label: "System" },
    { id: 2, value: "Direct", type: "radio", name: "radios", label: "Direct" },
  ];

  const handleChange = (e) => {
    setActive(e.target.value);
  };

  const handleSelectedChange = (e) => {
    if (e.target.value == "Select Template") {
      setSelectedValue("");
    } else {
      setSelectedValue(e.target.value);
    }
  };

  const emailEditorRef = useRef(null);
  const onReady = () => {};

  const onDesignLoad = (data) => {
    // console.log('onDesignLoad', data);
    setTemplateloading(false);
  };
  const onLoad = () => {
    // console.log('onLoad');

    emailEditorRef.current?.editor?.loadDesign(sample);

    emailEditorRef.current?.editor?.addEventListener(
      "design:loaded",
      onDesignLoad
    );
  };
  const handleTagDragStart = () => {
    // Add any additional logic you might need when a tag is dragged
  };

  const handleDropzoneDrop = (tag, dropzoneName, tagSection) => {
    let dropzone = dropzoneName.toLowerCase();
    setSelectedTags((prev) => {
      if (prev[dropzone]?.includes(tag)) {
        return prev;
      }
      if (!prev[dropzone]) {
        prev[dropzone] = [];
      }
      return {
        ...prev,
        [dropzone]: [...prev[dropzone], tag],
      };
    });
    //remove that tag from the tagAndDropzones
    let tagAndDropzoneCopy = [...tagAndDropzones];
    let tagSectionIndex = tagAndDropzoneCopy.findIndex(
      (tagAndDropzone) => tagAndDropzone.key === tagSection
    );
    let tagIndex = tagAndDropzoneCopy[tagSectionIndex].tags.findIndex(
      (tagAndDropzone) => tagAndDropzone.tag === tag
    );
    tagAndDropzoneCopy[tagSectionIndex].tags.splice(tagIndex, 1);
    setTagAndDropzones(tagAndDropzoneCopy);
  };

  const onRemove = (index, tagType, tagSection) => {
    let tag = tagType.toLowerCase();
    setSelectedTags((prev) => {
      return {
        ...prev,
        [tag]: prev[tag].filter((_, i) => i !== index),
      };
    });
  };
  const [file, setFile] = useState(null);
  function setImage(files, keyName) {
    if (files.length > 0) {
      let formdata = new FormData();
      [...files].forEach((file) => {
        formdata.append("files[]", file);
      });
      uploadService(UPLOAD_FILE, formdata)
        .then((data) => {
          if (data !== null) setFile(data.data.data[0]);
          else toast.error("File upload Failed");
        })
        .catch((err) => console.log(err));
    } else {
      setFile(null);
    }
  }
  async function extractTags(url) {
    showBackdrop();
    const medicalTagsResponse = await mlService("image_to_tag_v1", { url });
    const nonMedicalTagResponse = await mlService("image_to_non-tag_v1", {
      url,
    });
    let medicalTags = medicalTagsResponse?.data?.output?.tags;
    let nonMedicalTags = nonMedicalTagResponse?.data?.output?.tags;

    let withColorMedicalTags = medicalTags?.map((item) => {
      return {
        ...item,
        _id: item?.id,
        tag: item?.tags,
        bgClass: getRandomSoftColor(),
      };
    });

    let withColorNonMedicalTags = nonMedicalTags?.map((item) => {
      return { ...item, bgClass: getRandomSoftColor() };
    });

    setTagAndDropzones([
      {
        key: "medical",
        name: "Medical Tags",
        tags: withColorMedicalTags,
        dropzones: medicalreview,
      },
      {
        key: "non_medical",
        name: "Non Medical Tags",
        tags: withColorNonMedicalTags,
        dropzones: nonMedicalReview,
      },
    ]);

    hideBackdrop();
  }
  async function getData() {
    let response = await getService(EDIT_ACTION_GET + actionId);
    if (response) {
      if (response.data.success) {
        let actionEditResponse = response.data?.data;
        let {
          file_type_id = "",
          file_type_code = "",
          file_content_type = "",
        } = actionEditResponse?.action_to_filetypes?.action_file_type_id ?? {};
        setActionData(actionEditResponse);
        setFileType({
          fileTypeId: file_type_id,
          fileContentType: file_content_type,
          fileTypeCode: file_type_code,
        });
        setLoading(false);
      }
    }
  }
  useEffect(() => {
    // setLoading(true);
    getData();
  }, []);
  useEffect(() => {
    if (file?.url) {
      extractTags(file?.url);
    }
  }, [file?.url]);

  if (loading) {
    return <PostApprovalLoader />;
  }
  return (
    <>
      <Row>
        <Col lg="12">
          {actionData && (
            <ContentDetails
              brand={
                actionData?.origin?.requisition_brand_id?.principal_entity_name
              }
              date={actionData?.created_at}
              department=""
              title={actionData?.action_name}
              description={actionData?.origin?.requisition_description}
              fileTypes={[]}
              userName={actionData?.action_assign_by?.name}
            />
          )}
        </Col>
        <Col lg="6">
          <div className="d-flex w-100">
            <Card className="w-100">
              <Card.Body>
                <FilePicker
                  title={file !== null ? file?.name : "Add Image"}
                  onUpdate={(val) => setImage(val, "file")}
                  accepts="image/*"
                  type="image"
                  source={file?.url}
                />
              </Card.Body>
            </Card>
          </div>
        </Col>
        <Col lg="6">
          <Card className="py-2">
            <Card.Header>Select Distribution Medium</Card.Header>
            <Card.Body className="pb-3 pt-1">
              <div className="d-flex flex-row align-items-center gap-2 mt-3">
                {radioButton.map((btn, index) => {
                  return (
                    <Form.Check
                      key={btn.label}
                      className={`border border-1 ${
                        active === btn.value ? "bg-primary text-white" : ""
                      }  rounded-3 px-3 py-2 d-flex align-items-center justify-content-center`}
                    >
                      <FormCheck.Input
                        type={btn.type}
                        name={btn.name}
                        className="m-0"
                        id={btn.label}
                        value={btn.value}
                        checked={active === btn.value}
                        onChange={handleChange}
                      />
                      <FormCheck.Label className="ms-2" htmlFor={btn.label}>
                        {btn.value}
                      </FormCheck.Label>
                    </Form.Check>
                  );
                })}
              </div>
              {active == "System" && (
                <div className="mt-5">
                  <Row>
                    <Col md={6}>
                      <Form.Floating className="custom-form-floating mb-4 mb-md-0">
                        <Form.Select className="" id="floatingInput1">
                          <option value="" disabled selected>
                            Please Select target destination
                          </option>

                          <option value="text">Video</option>

                          <option value="video">Infographic</option>

                          <option value="audio">Slide</option>
                        </Form.Select>
                        <Form.Label htmlFor="floatingInput">
                          Target Destination
                        </Form.Label>
                      </Form.Floating>
                    </Col>
                    <Col md={6}>
                      <Form.Floating className="custom-form-floating w-100 h-50 p-0">
                        <Form.Control
                          type="number"
                          placeholder="Enter Destination ID"
                          name="Destination_id"
                        />
                        <Form.Label>Destination ID</Form.Label>
                      </Form.Floating>
                    </Col>
                  </Row>
                </div>
              )}
              {active == "Direct" && (
                <div className="mt-5">
                  <Row>
                    <Col lg={12}>
                      <Form.Floating className="custom-form-floating mb-4 mb-md-0">
                        <Form.Select
                          className="mb-4"
                          id="floatingInput1"
                          onChange={handleSelectedChange}
                        >
                          {templates?.map((temp) => (
                            <option key={temp.value} value={temp.value}>
                              {temp.title}
                            </option>
                          ))}
                        </Form.Select>
                        <Form.Label htmlFor="floatingInput">
                          Templates
                        </Form.Label>
                      </Form.Floating>
                    </Col>
                    <Col lg={12}>
                      <Button
                        className="btn btn-info flex-grow-1 flex-sm-grow-0 "
                        type="submit"
                        onClick={() => setShow(true)}
                        disabled={!selectedValue}
                      >
                        Preview
                      </Button>
                    </Col>

                    <Modal
                      className="fade"
                      id="post-modal"
                      tabIndex="-1"
                      show={show}
                      onHide={() => setShow(false)}
                      size="xl"
                    >
                      <Modal.Header closeButton>
                        <Col>
                          <h5 className="modal-title" id="post-modalLabel">
                            Template Section
                          </h5>
                        </Col>
                      </Modal.Header>
                      <Modal.Body>
                        <Col>
                          <ReactEmailEditor
                            ref={emailEditorRef}
                            onReady={onReady}
                            onLoad={onLoad}
                          />
                        </Col>
                        {!templateloading && (
                          <Col className="text-center">
                            <Button
                              className="btn btn-primary flex-grow-1 flex-sm-grow-0 mt-4"
                              type="submit"
                              onClick={() => setShow(false)}
                            >
                              Confirm
                            </Button>
                          </Col>
                        )}
                      </Modal.Body>
                    </Modal>
                  </Row>
                </div>
              )}
            </Card.Body>
          </Card>
        </Col>
        {tagAndDropzones.map((tagAndDropzone, index) => {
          return (
            <Col lg="6" key={tagAndDropzone.key}>
              <Card>
                <Card.Header className="d-flex justify-content-between">
                  <div className="header-title">
                    <h4 className="card-title">{tagAndDropzone.name}</h4>
                  </div>
                </Card.Header>
                <Card.Body>
                  <div className="d-flex gap-2 flex-wrap">
                    {tagAndDropzone.tags.length > 0 &&
                      tagAndDropzone.tags.map((tag, index) => {
                        return (
                          <Tag
                            key={tag._id}
                            tag={tag?.tag}
                            tagSection={tagAndDropzone.key}
                            bg={tag?.bgClass}
                            onDragStart={handleTagDragStart}
                          />
                        );
                      })}
                  </div>
                  <Row>
                    {tagAndDropzone.dropzones.map((dropZone, index) => {
                      return (
                        <Col lg="4" className="mt-4" key={dropZone.id}>
                          <DropZone
                            key={dropZone.id}
                            droppedItems={
                              selectedTags[dropZone?.name.toLowerCase()] || []
                            }
                            title={dropZone?.name}
                            onRemove={onRemove}
                            tagSection={tagAndDropzone.key}
                            onDrop={handleDropzoneDrop}
                          />
                        </Col>
                      );
                    })}
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          );
        })}
      </Row>
      <Row>
        <Col lg="12">
          <div className="d-flex gap-2 gap-md-3 justify-content-end flex-wrap flex-md-nowrap">
            <Button
              type="button"
              variant="btn btn-primary flex-md-grow-0 flex-grow-1 text-nowrap"
              onClick={() => setStep(2)}
            >
              Publish
            </Button>

            <Button
              type="button"
              variant="btn btn-light flex-md-grow-0 flex-grow-1 text-nowrap"
            >
              Cancel
            </Button>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default withAuthorization(memo(PostApproval));

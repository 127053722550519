export const grData = [
  {
    id: 23,
    title: "Clinical Protocol",
    publication_date: "2023-06-08",
    client_name: "CLIRNET",
    added: "2023-06-08 15:31:03",
    added_by: "Sanjay Dey",
    modified_by: "Indranil Kundu",
    status: "Pending",
  },
  {
    id: 24,
    title: "Advanced Life Support Update",
    publication_date: "2023-06-10",
    client_name: "CLIRNET",
    added: "2023-06-10 16:31:03",
    added_by: "Swagata Das",
    modified_by: "Indranil Kundu",
    status: "Success",
  },
  {
    id: 25,
    title: "Device-based Treatment for Heart Failure",
    publication_date: "2023-06-12",
    client_name: "CLIRNET",
    added: "2023-06-12 17:31:03",
    added_by: "Saif Imroz",
    modified_by: "Indranil Kundu",
    status: "Success",
  },
  {
    id: 26,
    title: "Prevention and Management of Oral Malignancy",
    publication_date: "2023-06-14",
    client_name: "CLIRNET",
    added: "2023-06-14 18:31:03",
    added_by: "Sumit Mandol",
    modified_by: "Saif Imroz",
    status: "Success",
  },
  {
    id: 27,
    title: "Robot- assisted Surgery in Healthcare",
    publication_date: "2023-06-16",
    client_name: "CLIRNET",
    added: "2023-06-16 19:31:03",
    added_by: "Sumit Mandol",
    modified_by: "Indranil Kundu",
    status: "Pending",
  },
  {
    id: 237,
    title: "Liver Transplantation",
    publication_date: "2023-06-21",
    client_name: "CLIRNET",
    added: "2023-06-21 17:31:03",
    added_by: "Sanjay Dey",
    modified_by: "Indranil Kundu",
    status: "Pending",
  },
  {
    id: 468,
    title: "Clinical Protocol",
    publication_date: "2023-06-08",
    client_name: "CLIRNET",
    added: "2023-06-08 15:31:03",
    added_by: "Sanjay Dey",
    modified_by: "Indranil Kundu",
    status: "Success",
  },
  {
    id: 76,
    title: "Advanced Life Support Update",
    publication_date: "2023-06-10",
    client_name: "CLIRNET",
    added: "2023-06-10 16:31:03",
    added_by: "Swagata Das",
    modified_by: "Indranil Kundu",
    status: "Pending",
  },
  {
    id: 77,
    title: "Device-based Treatment for Heart Failure",
    publication_date: "2023-06-12",
    client_name: "CLIRNET",
    added: "2023-06-12 17:31:03",
    added_by: "Saif Imroz",
    modified_by: "Indranil Kundu",
    status: "Pending",
  },
  {
    id: 78,
    title: "Prevention and Management of Oral Malignancy",
    publication_date: "2023-06-14",
    client_name: "CLIRNET",
    added: "2023-06-14 18:31:03",
    added_by: "Sumit Mandol",
    modified_by: "Saif Imroz",
    status: "Success",
  },
  {
    id: 43,
    title: "Robot- assisted Surgery in Healthcare",
    publication_date: "2023-06-16",
    client_name: "CLIRNET",
    added: "2023-06-16 19:31:03",
    added_by: "Sumit Mandol",
    modified_by: "Indranil Kundu",
    status: "Success",
  },
  {
    id: 44,
    title: "Liver Transplantation",
    publication_date: "2023-06-21",
    client_name: "CLIRNET",
    added: "2023-06-21 17:31:03",
    added_by: "Sanjay Dey",
    modified_by: "Indranil Kundu",
    status: "Pending",
  },
  {
    id: 45,
    title: "Clinical Protocol",
    publication_date: "2023-06-08",
    client_name: "CLIRNET",
    added: "2023-06-08 15:31:03",
    added_by: "Sanjay Dey",
    modified_by: "Indranil Kundu",
    status: "Pending",
  },
  {
    id: 46,
    title: "Advanced Life Support Update",
    publication_date: "2023-06-10",
    client_name: "CLIRNET",
    added: "2023-06-10 16:31:03",
    added_by: "Swagata Das",
    modified_by: "Indranil Kundu",
    status: "Pending",
  },
  {
    id: 47,
    title: "Device-based Treatment for Heart Failure",
    publication_date: "2023-06-12",
    client_name: "CLIRNET",
    added: "2023-06-12 17:31:03",
    added_by: "Saif Imroz",
    modified_by: "Indranil Kundu",
    status: "Success",
  },
  {
    id: 48,
    title: "Prevention and Management of Oral Malignancy",
    publication_date: "2023-06-14",
    client_name: "CLIRNET",
    added: "2023-06-14 18:31:03",
    added_by: "Sumit Mandol",
    modified_by: "Saif Imroz",
    status: "Success",
  },
  {
    id: 49,
    title: "Robot- assisted Surgery in Healthcare",
    publication_date: "2023-06-16",
    client_name: "CLIRNET",
    added: "2023-06-16 19:31:03",
    added_by: "Sumit Mandol",
    modified_by: "Indranil Kundu",
    status: "Pending",
  },
  {
    id: 50,
    title: "Liver Transplantation",
    publication_date: "2023-06-21",
    client_name: "CLIRNET",
    added: "2023-06-21 17:31:03",
    added_by: "Sanjay Dey",
    modified_by: "Indranil Kundu",
    status: "Pending",
  },
  // Add more data as needed
];
// ID	Title	Date Of Publication	Client Name	Added	Added By	Modified By	Status	Actions

const fileTypes = [
  {
    file_type_id: 4,
    file_type_name: "Zip",
    file_content_type: "file",
    file_type_code: "zip",
    file_type_icon: "Zip",
  },
  {
    file_type_id: 5,
    file_type_name: "Image",
    file_content_type: "file",
    file_type_code: "image",
    file_type_icon: "Photograph",
  },
  {
    file_type_id: 12,
    file_type_name: "Infographic",
    file_content_type: "file",
    file_type_code: "infographic",
    file_type_icon: "Infographic",
  },
  {
    file_type_id: 6,
    file_type_name: "PDF",
    file_content_type: "file",
    file_type_code: "pdf",
    file_type_icon: "PDF",
  },
  {
    file_type_id: 7,
    file_type_name: "HTML",
    file_content_type: "file",
    file_type_code: "html",
    file_type_icon: "HTML",
  },
  {
    file_type_id: 8,
    file_type_name: "Email",
    file_content_type: "text",
    file_type_code: "email",
    file_type_icon: "Email",
  },
  {
    file_type_id: 10,
    file_type_name: "Docx",
    file_content_type: "file",
    file_type_code: "docx",
    file_type_icon: "Document",
  },
  {
    file_type_id: 1,
    file_type_name: "Audio",
    file_content_type: "av",
    file_type_code: "audio",
    file_type_icon: "Microphone On",
  },
  {
    file_type_id: 2,
    file_type_name: "Article",
    file_content_type: "text",
    file_type_code: "article",
    file_type_icon: "File",
  },
  {
    file_type_id: 3,
    file_type_name: "PPT",
    file_content_type: "file",
    file_type_code: "ppt",
    file_type_icon: "PPT",
  },
  {
    file_type_id: 9,
    file_type_name: "Video",
    file_content_type: "av",
    file_type_code: "video",
    file_type_icon: "Start",
  },
  {
    file_type_id: 11,
    file_type_name: "Excel",
    file_content_type: "file",
    file_type_code: "excel",
    file_type_icon: "Excel",
  },
];

export default fileTypes;

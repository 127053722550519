const content_categories = {
  LIVE_CME: 1,
  MEDWIKI: 2,
  DIGITAL_CME: 3,
  GR: 6,
  SPQ: 7,
  CLINICAL_VIDEO: 11,
  E_PAPER: 13,
  TRAINING: 15,
  COURSE: 28,
  MODULE: 29,
};
export default content_categories;

import React from "react";

// layout

import Default from "../../../../layouts/dashboard/default";
import AddGr from "../pages/add-gr";
import AllGrList from "../pages/all-gr-list";
import GrDetails from "../pages/gr-details";

// pages

export const GrRouter = [
  {
    path: "/gr",
    element: <Default />,
    children: [
      {
        path: "edit/:id",
        element: <AddGr />,
      },
      {
        path: "create",
        element: <AddGr />,
      },
      {
        path: "listing",
        element: <AllGrList />,
      },
      {
        path: "details/:id",
        element: <GrDetails />,
      },
    ],
  },
];

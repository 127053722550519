import React from "react";

// layout

import Default from "../../../../layouts/dashboard/default";
import ManageCreative from "../pages/manage-creative";
import AllCampaignList from "../pages/all-campaign-list";
import AdCampaign from "../pages/ad-campaign";


// pages

export const CampaignRouter = [
  {
    path: "/campaign",
    element: <Default />,
    children: [
      {
        path: "ad-campaign",
        element: <AdCampaign />,
      },
      {
        path: "listing",
        element: <AllCampaignList />,
      },
      {
        path: "manage-creative",
        element: <ManageCreative />,
      },
    ],
  },
];

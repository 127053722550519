import dayjs from "dayjs";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  FormCheck,
  InputGroup,
  Nav,
  Row,
  Tab,
} from "react-bootstrap";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  UPDATE_ACTION_POST,
  UPLOAD_FILES_POST,
} from "../../../../api/endpoints/action-endpoints";
import postService from "../../../../api/services/post-service";
import uploadService from "../../../../api/services/upload-service";
import article_1 from "../../../../assets/modules/file-manager/images/article_1.jpg";
import app_config from "../../../../common/data/app_config";
import useRedirect from "../../../../components/custom-hooks/use-redirect";
import * as authSelectors from "../../../../store/auth/selectors";
import { findIcon } from "../../../dashboard/icons";
import { STATUS_CONSTANTS } from "../constants/action-constants";
import AudioPreviewSection from "./sub-components/content-review/audio-preview-section";
import CanvasPlayground from "./sub-components/content-review/canvas-playground";
import Reviews from "./sub-components/content-review/reviews";
import ThumbnailSlider from "./sub-components/content-review/thumbnail-slider";
import VideoPreviewSection from "./sub-components/content-review/video-preview-section";
import useOutsideClickDetector from "../../../../components/custom-hooks/use-outside-click-detector";
import pdfPages from "../data/ebookPages";
import SubmitButton from "../../../../components/form-components/submit-button";
import useBackdrop from "../../../../components/custom-hooks/use-backdrop";
import ArticleSection from "./sub-components/content-review/article-section";

function ContentReview({
  actionId = "",
  actionEditData = {},
  isApproval = false,
}) {
  const [content, setContent] = useState(null);
  const { fileType } = useParams();
  const FILE_TYPE =
    fileType ||
    actionEditData?.content_create?.content_json?.file_type_code ||
    "image";
  const isLeftPanel = FILE_TYPE !== "image";
  const user = useSelector(authSelectors.user);
  const [commentsOnFile, setCommentsOnFile] = useState([]);
  const [videoState, setVideoState] = useState({
    playing: true,
    playedSeconds: 0,
    loadedSeconds: 0,
    duration: 0,
    volume: 1,
    muted: true,
    playbackRate: 1.0,
    loop: false,
  });
  const [audioState, setAudioState] = useState({
    playing: false,
    playedSeconds: 0,
    loadedSeconds: 0,
    duration: 0,
    volume: 1,
    muted: true,
    playbackRate: 1.0,
    loop: false,
  });
  const _played_seconds = useRef(0);
  const _audio_played_seconds = useRef(0);
  const [showAllComments, setShowAllComments] = useState(false);
  const updateVideoState = (stateName, stateValue) => {
    setVideoState((prev) => {
      return { ...prev, [stateName]: stateValue };
    });
  };
  const updateAudioState = (stateName, stateValue) => {
    setAudioState((prev) => {
      return { ...prev, [stateName]: stateValue };
    });
  };

  const canvas = useRef(null);

  const videoRef = useRef(null);
  const audioRef = useRef(null);
  const canvasRef = useRef(null);
  const [showVideo, setShowVideo] = useState(true);
  const [pulseAnimation, setPulseAnimation] = useState(false);
  const [values, setValues] = useState({
    requisition_files: [],
    input: "",
    mainComment: "",
  });
  const [selectedCoordinate, setSelectedCoordinate] = useState({
    x: 0,
    y: 0,
  });
  const [zoom, setZoom] = useState(1);
  const [selectedComment, setSelectedComment] = useState(null);
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState({});
  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }
  const wrapperRef = useRef(null);
  const handleClickOutside = () => {
    setPulseAnimation(false);
    setSelectedCoordinate({ x: 0, y: 0 });
    setSelectedComment(null);
  };
  useOutsideClickDetector(wrapperRef, handleClickOutside);

  useEffect(() => {
    // set the images array and set the selected image

    let {
      content_comments = [],
      content_create = {},
      comments = [],
    } = actionEditData || {};
    setContent(content_create?.content_json);
    if (
      FILE_TYPE === "video" ||
      FILE_TYPE === "audio" ||
      FILE_TYPE === "article"
    )
      return;
    let generatedImages =
      // content_create?.content_json?.extract_main_file_images ?? [];
      pdfPages;

    let tempImages = generatedImages?.map((item, index) => {
      return {
        Url: item.url,
        PageNumber: index + 1,
        otherPeopleComments: [],
        myComments: [],
      };
    });
    if (FILE_TYPE == "image") {
      tempImages = [
        {
          Url: `https://media.istockphoto.com/id/1470218176/photo/black-and-white-portrait-of-damaged-mannequin-head.jpg?s=1024x1024&w=is&k=20&c=GI9AQabr9Ho6Y6kMVcWsDAXSaTFxZMPXhvbN1xPEPPE=`,
          PageNumber: 1,
          otherPeopleComments: [],
          myComments: [],
        },
      ];
    }
    // if (content_comments.length > 0) {
    let tempImagesArray = tempImages.map((_img, _index) => {
      let samePageComments = content_comments?.find(
        (item) => item.page_number === _img.PageNumber
      ) || { comment: [] };
      console.log("samePageComments", samePageComments?.comment);

      return {
        Url: _img.Url,
        PageNumber: _img.PageNumber,
        otherPeopleComments: samePageComments?.comment,
        myComments: [],
      };
    });

    setImages(tempImagesArray);
    setSelectedImage({ ...tempImagesArray[0], index: 0 });
    if (comments.length > 0) {
      setCommentsOnFile(comments);
    }
    // }
  }, []);

  useEffect(() => {
    //blink the selected comment
    if (selectedComment && selectedComment.x > 0 && selectedComment.y > 0) {
      // alert(JSON.stringify(selectedComment, null, 2));
      setPulseAnimation(true);
      setSelectedCoordinate({ x: selectedComment.x, y: selectedComment.y });
    }
  }, [selectedComment]);
  const { redirectTo } = useRedirect();
  const inputRef = useRef();
  const handleChangeValues = (e) => {
    let { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };
  const uploadFiles = (files) => {
    return new Promise(async (resolve, reject) => {
      // Check if files array is empty
      if (files.length === 0) {
        reject("No files to upload.");
        return;
      }
      let formdata = new FormData();
      files.forEach((file) => {
        formdata.append("files[]", file);
      });

      // 1. Upload files to server
      let response = await uploadService(UPLOAD_FILES_POST, formdata);
      if (response.data?.success) {
        // 2. Simulating successful upload and generating URLs
        const urls = response.data.data.map((file) => file.name);
        resolve(urls);
      } else {
        reject("Something went wrong");
      }
    });
  };
  const handleChangeReferenceFiles = async (e) => {
    const { files } = e.target;
    const urls = await uploadFiles([...files]);
    if (urls) {
      setValues({
        ...values,
        requisition_files: [...values.requisition_files, ...urls],
      });
    }
  };
  const onCrossImageClick = (e) => {
    let tempRequisitionFiles = values.requisition_files
      ? [...values.requisition_files]
      : [];
    tempRequisitionFiles = tempRequisitionFiles.filter(
      (file, idx) => idx !== e
    );
    setValues({
      ...values,
      requisition_files: tempRequisitionFiles,
    });
  };
  const plotDotsToCanvas = (x, y) => {
    let index = selectedImage.index;
    const fileList = [...images];
    fileList[index].myComments = [
      ...fileList[index]?.myComments,
      {
        x,
        y,
        comment: values.input,
        user_id: { id: user.id, name: user.name },
        created_at: dayjs().format("YYYY-MM-DD hh:mm:ss"),
      },
    ];

    setImages(fileList);
    setSelectedImage({ ...fileList[index], index });
  };
  const onAddComment = (e) => {
    e.preventDefault();
    const isCoOrdinateNotAvailable =
      !selectedCoordinate || !selectedCoordinate.x || !selectedCoordinate.y;
    if (isCoOrdinateNotAvailable && FILE_TYPE != "audio") {
      toast.error("Please select where do you want to add comment");
      return;
    }

    plotDotsToCanvas(selectedCoordinate.x, selectedCoordinate.y);
    setPulseAnimation(false);
    setValues({ requisition_files: [], input: "" });
    setSelectedCoordinate({ x: 0, y: 0 });
  };

  function onEachImageClick(payload) {
    setSelectedCoordinate({ x: 0, y: 0 });
    setSelectedImage(payload);
  }
  const onReturnToVideoClick = useCallback(() => {
    setShowVideo(true);
  }, [showVideo]);
  const handleCanvasClick = useCallback(
    (event) => {
      console.log("clicked");
      const rect = canvas.current.getBoundingClientRect();
      const x = (event.clientX - rect.left) / zoom;
      const y = (event.clientY - rect.top) / zoom;

      //if the x and y is available in the 10 radius of any of the comment then show the comment
      const findNeighbourhood = (item) => {
        if (
          item.x > x - 10 &&
          item.x < x + 10 &&
          item.y > y - 10 &&
          item.y < y + 10
        )
          return true;
      };
      const isMyCommentAvailable =
        selectedImage.myComments.some(findNeighbourhood);
      const otherPeopleCommentsAvailable =
        selectedImage.otherPeopleComments.some(findNeighbourhood);

      if (isMyCommentAvailable) {
        let availableMyComment =
          selectedImage.myComments.find(findNeighbourhood);
        setSelectedComment(availableMyComment);
      } else if (otherPeopleCommentsAvailable) {
        let availableOtherComment =
          selectedImage.otherPeopleComments.find(findNeighbourhood);

        setSelectedComment(availableOtherComment);
      } else {
        setSelectedComment(null);
        setSelectedCoordinate({ x, y });
        setPulseAnimation(true);
      }
    },
    [selectedImage, zoom, selectedComment, selectedCoordinate, pulseAnimation]
  );
  const onEachReviewClick = useCallback(
    (tempSelectedComment) => {
      setSelectedComment(tempSelectedComment);
    },
    [selectedComment]
  );
  const { showBackdrop, hideBackdrop } = useBackdrop();
  const submitCompleteReview = async (e, status) => {
    e && e.preventDefault();
    let commentsToPost = [];
    images.forEach((item) => {
      item.myComments?.length > 0 &&
        commentsToPost.push({
          page_number: item.PageNumber,
          comment: item.myComments,
        });
    });

    let { mainComment } = values;
    let postData = {
      action_id: actionId,
      content_comments: commentsToPost,
      comment: mainComment,
    };
    showBackdrop();
    let response = await postService(
      UPDATE_ACTION_POST + actionId,
      isApproval ? { ...postData, status } : postData
    );
    ////console.log("RESPONSE", response);
    if (response.data?.success) {
      hideBackdrop();
      toast.success("Action updated successfully");
      redirectTo("action/all-action");
    }
  };
  const onRaiseProblemClick = useCallback(async () => {
    const video = videoRef.current.getInternalPlayer();
    const canvas = canvasRef.current;
    showBackdrop();
    // Set the canvas dimensions to match the video's dimensions
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    // Draw the current video frame on the canvas
    const context = canvas.getContext("2d");

    context.drawImage(video, 0, 0, canvas.width, canvas.height);

    // Get the screenshot as a data URL
    const screenshotDataUrl = canvas.toDataURL("image/png", 1.0);

    // You can do something with the screenshot data URL, like display it in an image element or save it
    console.log(screenshotDataUrl);

    let file = dataURLtoFile(screenshotDataUrl, "screenshot.png");

    let uploadResponse = await uploadFiles([file]);
    if (uploadResponse?.length > 0) {
      //set images state

      let tempImage = {
        Url: app_config.fileStoragePath + uploadResponse[0],
        snapDuration: videoState.playedSeconds,
        otherPeopleComments: [],
        myComments: [],
      };
      setImages((prev) => {
        // set the tempImage in the images array with PageNumber increased by 1
        return [...prev, { ...tempImage, PageNumber: prev.length + 1 }];
      });
      setSelectedImage({
        ...tempImage,
        index: images.length,
        PageNumber: images.length + 1,
      });
      setShowVideo(false);
      hideBackdrop();
    }
  }, [
    images,
    selectedImage,
    selectedCoordinate,
    showVideo,
    videoState.playedSeconds,
    audioState.playedSeconds,
  ]);
  const onRaiseAudioProblemClick = useCallback(async () => {
    // create images array with audio snapDuration
    if (audioState.playing) {
      updateAudioState("playing", false);
    }
    let tempImage = {
      Url: "",
      snapDuration: audioState.playedSeconds,
      otherPeopleComments: [],
      myComments: [],
    };
    setImages((prev) => {
      // set the tempImage in the images array with PageNumber increased by 1
      return [...prev, { ...tempImage, PageNumber: prev.length + 1 }];
    });
    setSelectedImage({
      ...tempImage,
      index: images.length,
      PageNumber: images.length + 1,
    });
  }, [audioState.playedSeconds, audioState.playing, images]);
  const onSnapThumbnailClick = useCallback(
    (item, index) => {
      FILE_TYPE == "video" && setShowVideo(false);
      onEachImageClick({ ...item, index: index });
    },
    [showVideo, selectedCoordinate, selectedImage]
  );
  const onReadyPlayer = useCallback(() => {
    videoRef.current.seekTo(_played_seconds.current ?? 0);
  }, [videoRef]);
  const onChangeProgress = useCallback(
    (playedSeconds, type) => {
      if (type === "audio") {
        updateAudioState("playedSeconds", playedSeconds);
        _audio_played_seconds.current = playedSeconds;
      } else {
        updateVideoState("playedSeconds", playedSeconds);
        _played_seconds.current = playedSeconds;
        console.log("playedSeconds", playedSeconds);
      }
    },
    [videoState.playedSeconds, audioState.playedSeconds]
  );
  const getActiveThumbnailElement = (images, playedSeconds) => {
    let activeThumbnailIndex = images.findIndex((item) => {
      return (
        item.snapDuration > playedSeconds - 5 &&
        item.snapDuration < playedSeconds + 5
      );
    });

    return {
      element: activeThumbnailIndex > -1 ? images[activeThumbnailIndex] : null,
      index: activeThumbnailIndex,
    };
  };
  const activeElement = useMemo(
    () => getActiveThumbnailElement(images, videoState.playedSeconds),
    [videoState.playedSeconds, images]
  );
  const onChangeAudioState = useCallback(
    (stateName, stateValue) => {
      setAudioState((prev) => {
        return { ...prev, [stateName]: stateValue };
      });
    },
    [audioState.playing]
  );
  const onChangeVideoState = useCallback(
    (stateName, stateValue) => {
      setVideoState((prev) => {
        return { ...prev, [stateName]: stateValue };
      });
    },
    [videoState.playing]
  );
  const getToShowComments = () => {
    if (!selectedImage || !selectedImage?.Url) return [];
    if (showAllComments) {
      return [
        ...selectedImage.otherPeopleComments,
        ...selectedImage.myComments,
      ];
    } else {
      // extract my comments from otherpeople comments by user id
      let otherPeopleComments = [...selectedImage.otherPeopleComments];
      let myComments = [...selectedImage.myComments];

      myComments = [
        ...myComments,
        ...otherPeopleComments.filter((item) => item.user_id.id === user.id),
      ];
      return [...myComments];
    }
  };
  const toShowComments = useMemo(
    () => getToShowComments(),
    [showAllComments, selectedImage]
  );

  return (
    <Row className="flex-column">
      <Col
        lg="12"
        className="d-flex justify-content-between align-items-center mb-3"
      >
        <h5 className="text-capitalize m-0">
          {actionEditData?.action_name ?? "Not Available"}
        </h5>
        <div className="badge bg-soft-primary text-wrap py-2 px-3 rounded-pill">
          Details
        </div>
      </Col>
      <Col>
        <Card className="p-3">
          <Card.Body className="p-0" ref={wrapperRef}>
            <Row className="px-3" style={{ height: "600px" }}>
              {/* <Col lg="12" className="mb-3 content-details">
                <div className="border border-1 rounded-3 p-2">
                  good to see details
                </div>
                style={{ height: 500 }}
              </Col> */}
              <Col
                lg="9"
                className="position-relative ps-0 h-100"
                id="video_preview"
              >
                {FILE_TYPE === "article" ? (
                  <ArticleSection content={content} />
                ) : (
                  <>
                    {" "}
                    {FILE_TYPE !== "image" && (
                      <div className="h-100 overflow-auto w-15 rounded-3 bg-white p-1 float-start bg-soft-secondary">
                        <ThumbnailSlider
                          fileType={FILE_TYPE}
                          images={images}
                          selectedImage={selectedImage}
                          onSnapThumbnailClick={onSnapThumbnailClick}
                        />
                      </div>
                      // <Col className="bg-info text-white position-absolute start-0 top-50 rounded-start rounded-5 pe-1 py-1 curosor-pointer">
                      //   {findIcon("Chevron Right", "outline", 24)}
                      // </Col>
                    )}
                    <div className="w-85 float-start h-100 ps-2">
                      <div className="d-flex w-100 align-items-center h-100 justify-content-center bg-soft-secondary rounded-3 p-2 position-relative flex-column ">
                        {!showVideo && (
                          <div
                            className={
                              "d-flex position-absolute end-0 z-3 gap-2 pe-3 " +
                              (FILE_TYPE == "video"
                                ? "bottom-0 pb-3"
                                : "top-0 pt-3")
                            }
                          >
                            <Button
                              variant="light p-0 icon-40 fs-3 d-flex align-items-center justify-content-center"
                              onClick={() => {
                                setZoom(zoom + 0.1);
                              }}
                            >
                              +
                            </Button>
                            <Button
                              variant="light p-0 icon-40 fs-3 d-flex align-items-center justify-content-center"
                              onClick={() => {
                                setZoom(zoom - 0.1);
                              }}
                            >
                              -
                            </Button>
                          </div>
                        )}
                        {FILE_TYPE === "video" && showVideo ? (
                          <>
                            <VideoPreviewSection
                              ref={videoRef}
                              playing={videoState.playing}
                              onChangeVideoState={onChangeVideoState}
                              onChangeProgress={onChangeProgress}
                              onSnapThumbnailClick={onSnapThumbnailClick}
                              onRaiseProblemClick={onRaiseProblemClick}
                              activeElement={activeElement}
                              videoUrl={
                                process.env.REACT_APP_FILE_STORAGE_PATH +
                                content?.main
                              }
                              onReadyPlayer={onReadyPlayer}
                            />
                            <canvas
                              ref={canvasRef}
                              style={{ display: "none" }}
                              crossOrigin="anonymous"
                            />
                          </>
                        ) : FILE_TYPE === "audio" ? (
                          <AudioPreviewSection
                            playing={audioState.playing}
                            onChangeAudioState={onChangeAudioState}
                            onChangeProgress={onChangeProgress}
                            onRaiseAudioProblemClick={onRaiseAudioProblemClick}
                          />
                        ) : (
                          <>
                            <CanvasPlayground
                              ref={canvas}
                              fileType={FILE_TYPE}
                              selectedImage={selectedImage}
                              zoom={zoom}
                              handleCanvasClick={handleCanvasClick}
                              onReturnToVideoClick={onReturnToVideoClick}
                              pulseAnimation={pulseAnimation}
                              selectedCoordinate={selectedCoordinate}
                            />
                          </>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </Col>
              <Col
                lg="3"
                className="bg-soft-secondary border border-2 border-soft-secondary d-flex flex-column p-0 rounded-3 h-100"
              >
                <div className="d-flex justify-content-between p-3 bg-white fw-semibold rounded-top">
                  <div>Comments</div>
                  <Form.Check className="form-switch m-0">
                    <FormCheck.Label
                      className="form-check-label fw-normal fs-7"
                      htmlFor="flexSwitchCheckChecked"
                    >
                      All Comments
                    </FormCheck.Label>
                    <FormCheck.Input
                      className="form-check-input"
                      type="checkbox"
                      id="flexSwitchCheckChecked"
                      defaultChecked
                      onChange={(e) => setShowAllComments(e.target.checked)}
                    />
                  </Form.Check>
                </div>
                <Reviews
                  comments={toShowComments}
                  selectedComment={selectedComment}
                  onEachReviewClick={onEachReviewClick}
                />
                <Form className="w-100 d-flex" onSubmit={onAddComment}>
                  <InputGroup className="  rounded-bottom">
                    <Form.Control
                      ref={inputRef}
                      className="border-0 flex-grow-1 py-3 bg-white"
                      placeholder="Leave a comment here"
                      id="floatingTextarea2"
                      name="input"
                      value={values.input}
                      onChange={handleChangeValues}
                      autoComplete="off"
                    />
                    <Button
                      variant="btn-sm px-2 py-0"
                      type="submit"
                      onClick={onAddComment}
                      className="text-primary bg-soft-primary"
                    >
                      {findIcon("Location Arrow", "dual-tone", 30)}
                    </Button>
                  </InputGroup>
                </Form>
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <Card className="p-3 mt-3">
          {/* <Card.Header className="p-0 mb-3 d-flex justify-content-between align-items-center">
            <h5 className="m-0">Add Comment</h5>
          </Card.Header> */}
          <Card.Body className="p-0">
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
              <Nav variant="pills" className="flex-row">
                <Nav.Item>
                  <Nav.Link eventKey="first">Add Comment</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="second">Comments on File</Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="first">
                  <Form>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Control
                        as="textarea"
                        rows={3}
                        name="mainComment"
                        value={values.mainComment}
                        onChange={handleChangeValues}
                      />
                    </Form.Group>
                    <Form.Control
                      type="file"
                      id="customFile"
                      multiple
                      onChange={handleChangeReferenceFiles}
                    />

                    {/* preview added images with a cross button at right top corner */}
                    <div className="d-flex flex-wrap flex-row gap-3 mt-3">
                      {values.requisition_files?.map((file, index) => (
                        <div className="position-relative" key={index + 1}>
                          <img
                            className="rounded img-fluid avatar-120"
                            src={app_config.fileStoragePath + file}
                            alt="profile"
                            loading="lazy"
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = article_1;
                            }}
                          />
                          <span
                            className="position-absolute top-0 end-0"
                            onClick={() => onCrossImageClick(index)}
                          >
                            {findIcon("Square X", "solid", 20)}
                          </span>
                        </div>
                      ))}
                    </div>
                    {isApproval && (
                      <SubmitButton
                        variant="primary"
                        className="me-3"
                        type="button"
                        onClick={(e) =>
                          submitCompleteReview(e, STATUS_CONSTANTS.REJECTED)
                        }
                      >
                        Reject
                      </SubmitButton>
                    )}
                    <SubmitButton
                      variant="primary"
                      type="button"
                      onClick={(e) =>
                        submitCompleteReview(e, STATUS_CONSTANTS.COMPLETED)
                      }
                    >
                      {isApproval ? "Approve" : "Submit"}
                    </SubmitButton>
                  </Form>
                </Tab.Pane>
                <Tab.Pane eventKey="second">
                  <Reviews comments={commentsOnFile} />
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Card.Body>
        </Card>
        {/* <PreviousVersions /> */}
      </Col>
    </Row>
  );
}

export default memo(ContentReview);
{
  /* {isLeftPanel && (
                    <Col lg="2" className="">
                      <div className="px-2 rounded-3 w-100 h-100 scroll-content rounded-2">
                        {images &&
                          images.length > 0 &&
                          images.map((item, index) => (
                            <>
                              <img
                                src={item.Url}
                                key={index}
                                alt="image"
                                onClick={() =>
                                  onEachImageClick({ ...item, index: index })
                                }
                                style={{
                                  height: 150,
                                }}
                                className={`w-100 object-fit-contain border border-1 p-2 rounded-2 mb-1 ${
                                  selectedImage.Url === item.Url
                                    ? "border-primary border-2"
                                    : ""
                                }`}
                              />
                              {item?.snapDuration && (
                                <span className="d-block fs-7 mb-3">
                                  {previewSecondstoMinutes(item.snapDuration)}
                                </span>
                              )}
                            </>
                          ))}
                      </div>
                    </Col>
                  )} */
}
